// Libraries
import I18n from 'i18next'
import zxcvbn from 'zxcvbn'
import PropTypes from 'prop-types'
import { escapeRegExp, defaultTo, get } from 'lodash'
import React, { Component } from 'react'
import { Form, Icon, Input, Button, Card } from 'antd'

// Components
import { Anchor, StrengthIndicator } from 'Components'

// Images
import Images from 'Images'

const { Item: FormItem } = Form
const { Password } = Input

class CreatePasswordForm extends Component {
  static propTypes = {
    hidden: PropTypes.bool,
    fetching: PropTypes.bool,
    form: PropTypes.object,
    onSubmit: PropTypes.func,
    switchForm: PropTypes.func
  }

  static defaultProps = {
    hidden: false,
    onSubmit: () => {},
    switchForm: () => {}
  }

  /**
   * Validation du nouveau mot de passe
   */
  handleNewPassword = (rule, value, callback) => {
    let callbackMessage

    // Ãvaluation du mot de passe
    const passwordReview = zxcvbn(value)

    // Mot de passe  pas suffisamment complexe
    if (value && defaultTo(get(passwordReview, 'score'), 0) < 1) {
      callbackMessage = I18n.t('fields.password.new.errors.score')
    }

    callback(callbackMessage)
  }

  render() {
    const { hidden, fetching, form, onSubmit, switchForm } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={onSubmit}>
        <Card
          style={{ display: hidden ? 'none' : 'block' }}
          title={
            <img src={Images.logo} className='logo' alt='Logo application' />
          }
          actions={[
            <Anchor onClick={() => switchForm('login')}>
              {I18n.t('common.back')}
            </Anchor>,
            <Button type='primary' htmlType='submit' loading={fetching}>
              {I18n.t('common.next')}
            </Button>
          ]}
        >
          <FormItem>
            <StrengthIndicator
              visible={!hidden}
              value={form.getFieldValue('newPassword')}
            >
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: I18n.t('fields.password.requiredMessage')
                  },
                  {
                    validator: this.handleNewPassword
                  }
                ],
                hidden
              })(
                <Password
                  prefix={<Icon type='lock' />}
                  type='password'
                  placeholder={I18n.t('fields.password.placeholder')}
                />
              )}
            </StrengthIndicator>
          </FormItem>

          <FormItem>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: I18n.t('fields.password.confirm.requiredMessage')
                },
                {
                  // Mot de passe identique au prÃ©cÃ©dent ?
                  pattern: `^${escapeRegExp(
                    form.getFieldValue('newPassword')
                  )}$`,
                  message: I18n.t('fields.password.confirm.patternMessage')
                }
              ],
              hidden
            })(
              <Password
                prefix={<Icon type='lock' />}
                type='password'
                placeholder={I18n.t('fields.password.confirm.placeholder')}
              />
            )}
          </FormItem>
        </Card>
      </Form>
    )
  }
}

export default CreatePasswordForm
