import React, { Component } from 'react'

// Libraries
import { Layout } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link } from '@react-navigation/web'
import { get, replace, map, defaultTo, includes, size } from 'lodash'

// Components
import { LanguageSwitcher } from 'Components'
import { Dropdown } from 'Navigation/Layout'

const { Header } = Layout

class CustomHeader extends Component {
  static propTypes = {
    langs: PropTypes.array,
    dynamicTitle: PropTypes.string,
    descriptor: PropTypes.object
  }

  static defaultProps = {
    langs: ['fr'],
    crumbs: []
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  _renderBreadcrumbs = () => {
    const { descriptor } = this.props

    const crumbs = get(descriptor, 'options.crumbs', [])

    return map(crumbs, ({ routeName, title }, index) => (
      <span key={index} className='breadcrumbs-crumb'>
        <Link routeName={routeName}>{title}</Link>
      </span>
    ))
  }

  render() {
    const { descriptor, langs, dynamicTitle } = this.props
    const { title = '' } = get(descriptor, 'options', {})

    // Titre sans 'undefined'
    const safeTitle = includes(dynamicTitle, 'undefined')
      ? title
      : defaultTo(dynamicTitle, title)

    return (
      <Header>
        {/* Titre de la page */}
        <h1 className='title'>
          <span className='breadcrumbs'>
            {this._renderBreadcrumbs()}
            <span className='active'>{safeTitle || title}</span>
          </span>
        </h1>

        <nav>
          <ul>
            {/* Dropdown menu */}
            <li>
              <Dropdown />
            </li>

            {/* Permutateur de langue */}
            {size(langs) > 1 && (
              <li className='layout-header-navbar-item language-switcher'>
                <LanguageSwitcher />
              </li>
            )}
          </ul>
        </nav>
      </Header>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    // RÃ©cupÃ©ration du titre dynamique liÃ©s Ã  la state (ex: {{foo.count}} => state.foo.count)
    // Remplacement par les valeurs associÃ©es (ex: {{foo.count}} => 1)
    dynamicTitle: replace(
      get(ownProps, 'descriptor.options.dynamicTitle', ''),
      /{{([\w.]+)}}/g,
      (match, path) => {
        return get(state, path)
      }
    ),
    langs: get(state, 'lang.whitelist')
  }
}

export default connect(mapStateToProps)(CustomHeader)
