// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import {
  Form,
  Drawer,
  Button,
  Skeleton,
  Menu,
  Dropdown,
  Icon,
  Input,
  Select,
  Spin
} from 'antd'
import {
  get,
  isNil,
  map,
  size,
  head,
  defaultTo,
  cloneDeep,
  forEach,
  find,
  omitBy,
  isEmpty,
  remove
} from 'lodash'

// Helpers
import { createFormFields } from 'Helpers'

// Styles
import './DrawerLayout.less'

const { Item: FormItem } = Form
const { Option, OptGroup } = Select

let UNSAVED_FORM = null

class DrawerLayout extends Component {
  static propTypes = {
    // DonnÃ©es de la vue
    icon: PropTypes.string,
    title: PropTypes.string,
    data: PropTypes.object,
    rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Actions disponibles
    actions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Formulaire
    form: PropTypes.object,

    // ContrÃ´le de l'affichage
    visible: PropTypes.bool,

    // Modale de modification
    modalEditable: PropTypes.bool,
    modalControl: PropTypes.object,

    // TÃ©moins d'activitÃ© API
    loading: PropTypes.bool,

    // Fonctions
    onClose: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // DonnÃ©es de la vue
    title: 'components.drawerLayout.title',
    data: {},
    rows: {},

    // Actions disponibles
    actions: {
      update: {
        buttonType: 'primary'
      }
    },

    // ContrÃ´le de l'affichage
    visible: false,

    // Modale de modification
    modalEditable: true,
    modalControl: null,

    // TÃ©moins d'activitÃ© API
    loading: true,

    // Fonctions
    onClose: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {
      loading: true,
      isEditing: false,
      selectedAction: head(get(props, 'actions'))
    }
  }

  /**
   * Changement de visibilitÃ© du panneau
   */
  handleDrawerVisibilityChanged = () => {
    const { visible } = this.props

    // Panneau ouvert
    if (visible) {
      this.setState({ visible })

      // Panneau fermÃ©
    } else {
      this.setState({ visible })
    }
  }

  /**
   * Permet d'effectuer l'action demandÃ©e
   */
  handleAction = action => {
    const { data, modalControl } = this.props

    switch (get(action, 'name')) {
      // Passage en mode edition / Enregistrement des modifications
      case 'save':
      case 'update':
        this.askUpdate()
        break

      // Ouverture de la modale de modification
      case 'updateInModal':
        modalControl.open(data)
        break

      // Tous les autres cas effectuent les actions du onClick
      default:
        defaultTo(get(action, 'onClick'), () => {})(data)
    }
  }

  /**
   * Changement d'action pour le bouton d'action Ã  choix multiple
   */
  handleSelectAction = ({ key }) => {
    this.setState({
      selectedAction: key
    })
  }

  /**
   * Fermeture du mode Ã©dition
   */
  quitEditMode = () => {
    const { form } = this.props

    // Reset du formulaire
    form.resetFields()
    form.setFieldsValue(UNSAVED_FORM)

    // Sortie du mode Ã©dition
    this.setState({ isEditing: false })
  }

  /**
   * Demande de mise Ã  jour de l'Ã©lÃ©ment
   * Passage en mode Ã©dition
   * ou
   * Mise Ã  jour API
   */
  askUpdate = () => {
    const { data, onChange } = this.props
    const { isEditing } = this.state

    // Pas encore en mode Ã©dition
    if (!isEditing) {
      this.setState({ isEditing: true })
    } else {
      this.props.form.validateFields((error, formData) => {
        if (!error) {
          onChange(formData, data).then(() => {
            this.setState({
              isEditing: false
            })
          })
        }
      })
    }
  }

  /**
   * Rendu des actions disponibles dans la modale
   */
  renderActions = actions => {
    const { selectedAction } = this.state

    return (
      <Menu
        selectedKeys={[defaultTo(selectedAction, get(head(actions), 'name'))]}
        className='drawer-layout-actions-menu'
        onClick={action => this.handleSelectAction(action, actions)}
      >
        {map(actions, ({ name }) => (
          <Menu.Item className='drawer-layout-actions-menu-item' key={name}>
            {/* Nom de l'action */}
            <span className='drawer-layout-actions-menu-item-title'>
              {I18n.t(`components.drawerLayout.actions.${name}.title`)}
            </span>

            {/* Description de l'action */}
            <span className='drawer-layout-actions-menu-item-description'>
              {I18n.t(`components.drawerLayout.actions.${name}.description`)}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  /**
   * Rendu du bouton d'action Ã  choix multiples
   */
  renderActionButton = () => {
    const { loading, modalEditable, modalControl, actions } = this.props

    // RÃ©cupÃ©ration des actions multiples disponibles
    const availableActions = cloneDeep(
      map(defaultTo(actions, []), ({ name, ...props }, index) => ({
        ...props,
        name: defaultTo(name, index)
      }))
    )

    // Ajout de l'action update
    // dans une modale si le modalControl est donnÃ©
    if (
      find(availableActions, { name: 'update' }) &&
      !isNil(modalControl) &&
      modalEditable
    ) {
      availableActions.push({
        name: 'updateInModal',
        buttonType: 'primary'
      })
    }

    // Suppression des boutons masquÃ©s
    remove(availableActions, { hidden: true })

    // RÃ©cupÃ©ration de l'action sÃ©lectionnÃ©e
    const selectedAction = defaultTo(
      find(availableActions, { name: get(this.state, 'selectedAction') }),
      head(availableActions)
    )

    return size(availableActions) > 1 ? (
      <Dropdown.Button
        disabled={loading}
        onClick={() => this.handleAction(selectedAction)}
        overlay={this.renderActions(availableActions)}
        placement='topRight'
        type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        icon={<Icon type='up' />}
      >
        {I18n.t(
          `components.drawerLayout.actions.${get(selectedAction, 'name')}.label`
        )}
      </Dropdown.Button>
    ) : (
      !isEmpty(availableActions) && (
        <Button
          disabled={loading}
          onClick={() => this.handleAction(selectedAction)}
          type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        >
          {I18n.t(
            `components.drawerLayout.actions.${get(
              selectedAction,
              'name'
            )}.label`
          )}
        </Button>
      )
    )
  }

  /**
   * Rendu d'une ligne en mode classique
   */
  renderRow = ({ key, dataIndex, render, title, ...row }) => {
    const { data, loading } = this.props
    const { visible } = this.state

    return (
      <Skeleton
        key={key}
        className='drawer-layout-description-item-skeleton'
        paragraph={false}
        loading={!visible || loading}
      >
        <p className='drawer-layout-description-item'>
          {/* Nom de la ligne */}
          <span className='drawer-layout-description-item-name'>
            {I18n.t(title)}
          </span>

          {/* Valeur de la ligne */}
          <span className='drawer-layout-description-item-value'>
            {!isNil(render)
              ? render(get(data, dataIndex), data)
              : get(data, dataIndex)}
          </span>
        </p>
      </Skeleton>
    )
  }

  /**
   * Rendu d'une ligne en mode Ã©dition
   */
  renderRowEditing = ({ key, dataIndex, formField = {}, ...row }) => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      required,
      pattern,
      customInput,
      format,
      help,
      options,
      type,
      ...field
    } = formField

    // Construction des rÃ¨gles de l'input
    const rules = [
      {
        required: !isNil(required),
        message: I18n.t(required)
      }
    ]

    // Construction des rÃ¨gles liÃ©es au pattern de l'input
    if (!isNil(pattern)) {
      rules.push({
        type: get(pattern, 'type'),
        pattern: get(pattern, 'pattern'),
        transform: get(pattern, 'transform'),
        validator: get(pattern, 'validator'),
        message: I18n.t(get(pattern, 'message'))
      })
    }

    // Construction du nom de classe
    field.className = `drawer-layout-form-input ${defaultTo(
      get(field, 'className'),
      ''
    )}`

    // Formatage du placeholder
    field.placeholder = I18n.t(defaultTo(get(field, 'placeholder'), ''))

    // CrÃ©ation des options du sÃ©lecteur
    const createOption = ({ options = [], ...props }) =>
      !isEmpty(options) ? (
        <OptGroup {...props}>{map(options, createOption)}</OptGroup>
      ) : (
        <Option
          key={get(props, 'id')}
          disabled={defaultTo(get(props, 'disabled'), false)}
          value={get(props, 'value')}
        >
          {I18n.t(get(props, 'label'))}
        </Option>
      )

    // Construction de l'input (automatique ou custom)
    const GeneratedInput =
      // Input tableau
      get(pattern, 'type') === 'array' ? (
        <Select mode='tags' allowClear tokenSeparators={[',']} {...field}>
          {map(options, createOption)}
        </Select>
      ) : // Input password
      get(pattern, 'type') === 'password' ? (
        <Input.Password {...field} />
      ) : // Select Options
      type === 'select' ? (
        <Select allowClear {...field}>
          {map(options, createOption)}
        </Select>
      ) : (
        <Input {...field} />
      )

    const CustomInput = !isNil(customInput)
      ? customInput(field)
      : GeneratedInput

    return (
      <FormItem
        key={key}
        help={help}
        className='drawer-layout-form-item'
        label={I18n.t(get(formField, 'label'))}
      >
        {getFieldDecorator(dataIndex, {
          preserve: true,
          rules
        })(CustomInput)}
      </FormItem>
    )
  }

  /**
   * Rendu des lignes formatÃ©es
   */
  renderRows = () => {
    const { rows, loading } = this.props
    const { isEditing } = this.state

    // Rendu en fonction du mode (edition / affichage)
    const renderer = isEditing ? this.renderRowEditing : this.renderRow

    // Conteneurs en fonction de l'Ã©tat du panneau
    // Conteneur de chargement pour le mode Ã©dition (Skeleton sinon)
    const LoadingWrapper = isEditing && loading ? Spin : 'div'
    // Conteneur de formulaire pour le mode Ã©dition
    const FormWrapper = isEditing ? Form : 'div'

    return (
      <LoadingWrapper>
        <FormWrapper className='drawer-layout-form'>
          {map(rows, renderer)}
        </FormWrapper>
      </LoadingWrapper>
    )
  }

  render() {
    const { visible, title, icon, loading, onClose, form } = this.props
    const { isEditing } = this.state

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched()
    const isValidForm = isEmpty(formErrors) && formChanged

    return (
      <Drawer
        width={640}
        title={
          <span>
            {/* IcÃ´ne illustrative (optionnelle) */}
            {icon && (
              <Icon
                className='drawer-layout-title-icon'
                key='icon'
                type={I18n.t(icon)}
              />
            )}

            {/* Titre */}
            <span key='title' className='drawer-layout-title'>
              {I18n.t(title)}
            </span>
          </span>
        }
        placement='right'
        onClose={onClose}
        visible={visible}
        className='drawer-layout'
        afterVisibleChange={this.handleDrawerVisibilityChanged}
      >
        {/* Informations principales */}
        <main className='drawer-layout-description'>
          {/* Informations dÃ©taillÃ©s  */}
          <section className='drawer-layout-description-group'>
            <h3 className='drawer-layout-description-group-title'>
              {I18n.t('components.drawerLayout.mainSection')}
            </h3>

            {/* Rendu des lignes */}
            {this.renderRows()}
          </section>
        </main>

        {/* Footer du drawer */}
        <footer className='drawer-layout-footer'>
          {/* Mode Ã©dition */}
          {!isEditing ? (
            <Fragment>
              {/* Rendu du bouton Ã  choix multiples */}
              {this.renderActionButton()}
            </Fragment>
          ) : (
            <Fragment>
              {/* Bouton enregistrement du mode Ã©dition */}
              <Button
                loading={loading}
                type='primary'
                onClick={() => this.handleAction('save')}
                key='save'
                disabled={!isValidForm}
              >
                {I18n.t('components.drawerLayout.actions.save.title')}
              </Button>

              {/* Bouton annuler du mode Ã©dition */}
              <Button
                key='cancel'
                disabled={loading}
                onClick={this.quitEditMode}
              >
                {I18n.t('components.drawerLayout.actions.cancel.title')}
              </Button>
            </Fragment>
          )}
        </footer>
      </Drawer>
    )
  }
}

export default Form.create({
  mapPropsToFields(props) {
    const defaultProps = get(DrawerLayout, 'defaultProps', {})
    const formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

    // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
    forEach(formData, (value, index) => {
      const format = get(
        find(map(get(props, 'rows')), { dataIndex: index }),
        'formField.format'
      )

      formData[index] = !isNil(format) ? format(value) : value
    })

    // Sauvegarde des valeurs pour reset
    UNSAVED_FORM = formData

    return createFormFields(Form, formData)
  }
})(DrawerLayout)
