// Libraries
import {
  get,
  forEach,
  includes,
  isEmpty,
  isNull,
  isNumber,
  map,
  pickBy
} from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Common components
import { TableLayout } from 'Components'

// Specs
import { TableSpecs } from 'Views/Companies/Specs'

// Styles
import './Styles/List.less'

class CompaniesList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,
    totalCompanies: PropTypes.number,

    // DonnÃ©es de construction du tableau
    TableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    dataList: [],
    totalCompanies: 0
  }

  constructor(props) {
    super(props)

    this.state = {
      // donnÃ©es des filtres
      searchText: '',
      currentPage: 0,
      showPageSize: 10,
      sortField: 'dateCreated',
      sortOrder: 'descend',
      sortFilters: {},
      data: []
    }
  }

  // gestion des filtres/champs de recherche/sorters actif sur la table
  handleTableChange = (search, current, pageSize, field, order, filters) => {
    const {
      searchText,
      currentPage,
      showPageSize,
      sortField,
      sortOrder,
      sortFilters
    } = this.state
    const { constructorFunctions } = this.props

    // s'il n'y a pas de valeur dans le champs de recherche, alors je prends les donnÃ©es passÃ©es en props
    // sinon je prends les donnÃ©es issues du state
    current = isNumber(current) ? current : currentPage
    pageSize = isNumber(pageSize) ? pageSize : showPageSize
    field = !isEmpty(field) ? field : sortField
    order = !isEmpty(order) ? order : sortOrder
    filters = !isEmpty(filters) || isNull(search) ? filters : sortFilters
    search = !isNull(search) ? search : searchText

    get(constructorFunctions, 'handleTableOrdered', () => {})(
      current,
      pageSize,
      field,
      order,
      filters,
      search
    )
  }

  render() {
    const {
      loading,
      dataList,
      totalCompanies,
      constructorFunctions,
      profile
    } = this.props

    // Ajout des donnÃ©es au tableau
    TableSpecs.rows = dataList

    // Ajout des actions au clic sur un ligne du tableau
    TableSpecs.onRow = (company, id) => ({
      onClick: () =>
        get(constructorFunctions, 'handleRowClick', () => {})(company, id)
    })

    // Pagination
    TableSpecs.pagination.total = totalCompanies
    TableSpecs.totalItems = totalCompanies

    // Changement de la valeur dans la barre de recherche
    TableSpecs.onSearch = searchText => {
      this.setState({ searchText })
      this.handleTableChange(searchText)
    }

    TableSpecs.onChange = (pagination, filters, sorter, extra) => {
      const { current, pageSize } = pagination
      const { field, order } = sorter

      // Je retire les filtres vides (rÃ©initialisÃ©s)
      filters = pickBy(filters, filter => !isEmpty(filter))

      // Je boucle sur les filtres afin de reconstruire les clÃ©s/valeurs pour l'API
      forEach(filters, (value, key) => {
        const filterValue = value[0]
        if (key === 'dateCreated') {
          delete filters['dateCreated']
          filters['dateCreated__gte'] = filterValue[0]
          filters['dateCreated__lte'] = filterValue[1]
        } else if (key === 'validityPeriod') {
          delete filters['validityPeriod']
          filters['validityPeriod_id__startDate__gte'] = filterValue[0]
          filters['validityPeriod_id__endDate__lte'] = filterValue[1]
        } else {
          filters[key] = value
        }
      })

      // Je stocke dans le state afin de rÃ©cupÃ©rer les valeurs en cas de recherche via la barre de recherche
      this.setState({
        currentPage: current,
        showPageSize: pageSize,
        sortField: field,
        sortOrder: order,
        sortFilters: filters
      })

      this.handleTableChange(null, current, pageSize, field, order, filters)
    }

    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR')

    if (isUserExtern) TableSpecs.actions.global.create.hidden = true
    if (isUserExtern) TableSpecs.actions.selection.delete.hidden = true

    // Ajout des actions multiples sur le tableau
    TableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteManyCompanies'
    )

    // Ajout des actions des boutons
    TableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreateCompany'
    )

    return (
      <main className='screen companies companies-list'>
        <TableLayout loading={loading} {...TableSpecs} />
      </main>
    )
  }
}

export default CompaniesList
