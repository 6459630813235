import { createResource } from 'redux-rest-resource'
import {
  remove,
  includes,
  map,
  get,
  cloneDeep,
  toNumber,
  defaultTo
} from 'lodash'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'employee',
  pluralName: 'employees',
  url: `${baseURL}/employees`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: '.' },
    fetchArchives: {
      isArray: true,
      method: 'GET',
      gerundName: 'fetching',
      url: './archive',
      reduce: (
        { archives = [], ...state },
        { body: archivedItems, status }
      ) => {
        const isFetching = status === 'pending'

        if (status === 'resolved') {
          archives = archivedItems
        }

        return { ...state, archives, isFetching }
      }
    },
    get: { method: 'GET', gerundName: 'getting', url: './:id' },
    getReceptionSecurityPermit: {
      method: 'GET',
      gerundName: 'getReceptionSecurityPermit',
      url: './:id/receptionsecuritypermit'
    },
    update: {
      method: 'PATCH',
      gerundName: 'updating',
      url: './:id',
      assignResponse: true,
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    updateSecurityForm: {
      method: 'PATCH',
      gerundName: 'updating form',
      url: './:id/receptionsecuritypermit',
      assignResponse: true,
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    updateGivenHabilitation: {
      method: 'PUT',
      gerundName: 'updating given habilitation',
      url: './:id/habilitations',
      assignResponse: true,
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },

    create: {
      method: 'POST',
      gerundName: 'creating',
      url: '.'
    },
    exportSecurityPermit: {
      method: 'GET',
      gerundName: 'export securityPermit',
      url: './:id/receptionsecuritypermit/export'
    },
    createSecurityForm: {
      method: 'POST',
      gerundName: 'creating form',
      url: './:id/receptionsecuritypermit',
      assignResponse: true,
      reduce: ({ item, ...state }, { body: createItem, status }) => {
        const isCreating = status === 'pending'

        if (status === 'resolved') {
            item = createItem
        }

        return { ...state, item, isCreating }
      }
    },
    createGivenHabilitation: {
      method: 'POST',
      gerund: 'creating given habilitation',
      url: './:id/habilitations',
      assignResponse: true,
      reduce: ({ item, ...state }, { body: createItem, status }) => {
        const isCreating = status === 'pending'

        if (status === 'resolved') {
            item = createItem
        }

        return { ...state, item, isCreating }
      }
    },
    createGivenHabilitationProofFile: {
      method: 'POST',
      gerund: 'creating given habilitation proof file',
      url: './habilitations/import',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    addHabilitationFile: {
      method: 'POST',
      gerundName: 'uploading operating mode file',
      url: './:id/habilitations',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    signSecurityForm: {
      method: 'PATCH',
      gerundName: 'signing',
      url: './:id/receptionsecuritypermit/sign',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    order: {
      method: 'PATCH',
      gerundName: 'ordering',
      url: './order'
    },
    archive: {
      method: 'PATCH',
      gerundName: 'archiving',
      url: './:id/archive',
      reduce: ({ items, ...state }, { context: archivedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(archivedItem, 'id'), toNumber(archivedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    archiveMany: {
      isArray: true,
      alias: 'archive',
      method: 'PATCH',
      gerundName: 'archiving',
      url: './archive',
      reduce: ({ items, ...state }, { context: archivedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(archivedItems, archivedItem =>
                defaultTo(get(archivedItem, 'id'), toNumber(archivedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    delete: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './:id',
      reduce: ({ items, ...state }, { context: deletedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './delete',
      reduce: ({ items, ...state }, { context: deletedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(deletedItems, deletedItem =>
                defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    deleteGivenHabilitation: {
      method: 'DELETE',
      gerundName: 'deleting one given habilitation',
      url: './:id/habilitations',
      reduce: ({ items, ...state }, { context: deletedItem, status }) => {
        if (status === 'resolved') {
          remove(items, {
            id: defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    deleteManyGivenHabilitation: {
      method: 'DELETE',
      gerundName: 'deleting many given habilitation',
      url: './:id/habilitations',
      reduce: ({ items, ...state }, { context: deletedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(deletedItems, deletedItem =>
                defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    }
  }
})
