// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as preventiveMeasureActions } from 'Resources/PreventiveMeasureResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/PreventiveMeasures/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class PreventiveMeasuresConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    preventiveMeasure: PropTypes.object,
    preventiveMeasures: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    preventiveMeasure: {},
    preventiveMeasures: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchPreventiveMeasuresData()

    if (this.getContext() === 'single') {
      this.getPreventiveMeasureData().then(this.askUpdatePreventiveMeasure)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchPreventiveMeasuresData = () => {
    this.props.actions.fetchPreventiveMeasures().catch(() => {
      message.error(I18n.t('api.errors.preventiveMeasure.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getPreventiveMeasureData = (
    preventiveMeasure = this.props.navigation.getParam('id')
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getPreventiveMeasure(preventiveMeasure)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.preventiveMeasure.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyPreventiveMeasure = (preventiveMeasure, clearTableSelections) => {
    this.props.actions
      .archiveManyPreventiveMeasure(preventiveMeasure)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.preventiveMeasure.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.preventiveMeasure.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archivePreventiveMeasure = preventiveMeasure => {
    this.props.actions
      .archivePreventiveMeasure(preventiveMeasure)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.preventiveMeasure.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.preventiveMeasure.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyPreventiveMeasure = (preventiveMeasure, clearTableSelections) => {
    this.props.actions
      .archivePreventiveMeasures(preventiveMeasure)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.preventiveMeasure.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.preventiveMeasure.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deletePreventiveMeasure = preventiveMeasure => {
    this.props.actions
      .archivePreventiveMeasure(preventiveMeasure)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.preventiveMeasure.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.preventiveMeasure.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un preventiveMeasure
   */
  updatePreventiveMeasure = (
    updatedPreventiveMeasure,
    originalPreventiveMeasure
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updatePreventiveMeasure({
          id: get(originalPreventiveMeasure, 'id'),
          ...updatedPreventiveMeasure
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.preventiveMeasure.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.preventiveMeasure.update'))
        })
    })

  /**
   * CrÃ©ation d'un preventiveMeasure
   */
  createPreventiveMeasure = createdPreventiveMeasure =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createPreventiveMeasure(createdPreventiveMeasure)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.preventiveMeasure.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.preventiveMeasure.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyPreventiveMeasures = (...params) => {
    confirm({
      title: I18n.t('pages.preventiveMeasures.modal.archive.multiple.title'),
      content: I18n.t(
        'pages.preventiveMeasures.modal.archive.multiple.content'
      ),
      okText: I18n.t('pages.preventiveMeasures.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.preventiveMeasures.modal.archive.multiple.cancelText'
      ),
      onOk: () => {
        this.archivePreventiveMeasure(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchivePreventiveMeasure = () => {
    const { preventiveMeasure } = this.props

    confirm({
      title: I18n.t('pages.preventiveMeasures.modal.archive.single.title'),
      content: I18n.t('pages.preventiveMeasures.modal.archive.single.content'),
      okText: I18n.t('pages.preventiveMeasures.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.preventiveMeasures.modal.archive.single.cancelText'
      ),
      onOk: () => {
        this.archivePreventiveMeasure(preventiveMeasure)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyPreventiveMeasures = (...params) => {
    confirm({
      title: I18n.t('pages.preventiveMeasures.modal.delete.multiple.title'),
      content: I18n.t('pages.preventiveMeasures.modal.delete.multiple.content'),
      okText: I18n.t('pages.preventiveMeasures.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.preventiveMeasures.modal.delete.multiple.cancelText'
      ),
      onOk: () => {
        this.deleteManyPreventiveMeasure(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeletePreventiveMeasure = () => {
    const { preventiveMeasure } = this.props

    confirm({
      title: I18n.t('pages.preventiveMeasures.modal.delete.single.title'),
      content: I18n.t('pages.preventiveMeasures.modal.delete.single.content'),
      okText: I18n.t('pages.preventiveMeasures.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.preventiveMeasures.modal.delete.single.cancelText'
      ),
      onOk: () => {
        this.deletePreventiveMeasure(preventiveMeasure)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdatePreventiveMeasure = () => {
    this.setState({ openedModal: 'updatePreventiveMeasure' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreatePreventiveMeasure = () => {
    this.setState({ openedModal: 'createPreventiveMeasure' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updatePreventiveMeasure') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = preventiveMeasure => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(preventiveMeasure, 'id') })

    this.getPreventiveMeasureData(preventiveMeasure)
      .then(() =>
        // Ouverture de la modale
        this.askUpdatePreventiveMeasure()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('PreventiveMeasure', { id: get(preventiveMeasure, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderPreventiveMeasure(formattedOrderedData)
      .then(() =>
        message.success(I18n.t('api.success.preventiveMeasure.order'))
      )
      .catch(() => message.error(I18n.t('api.errors.preventiveMeasure.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      preventiveMeasures,
      preventiveMeasure
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getPreventiveMeasureData: this.getPreventiveMeasureData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreatePreventiveMeasure: this.askCreatePreventiveMeasure,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdatePreventiveMeasure: this.askUpdatePreventiveMeasure,
      askUpdateManyPreventiveMeasures: this.askUpdatePreventiveMeasure,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchivePreventiveMeasure: this.askArchivePreventiveMeasure,
      askArchiveManyPreventiveMeasures: this.askArchiveManyPreventiveMeasures,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeletePreventiveMeasure: this.askDeletePreventiveMeasure,
      askDeleteManyPreventiveMeasures: this.askDeleteManyPreventiveMeasures,

      // RÃ©initialisation du mot de passe d'un preventiveMeasure
      askResetPasswordPreventiveMeasure: this.askResetPasswordPreventiveMeasure
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeletePreventiveMeasure

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={preventiveMeasures}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updatePreventiveMeasure'
              ? this.updatePreventiveMeasure
              : this.createPreventiveMeasure
          }
          visible={includes(
            ['createPreventiveMeasure', 'updatePreventiveMeasure'],
            openedModal
          )}
          data={
            includes(['transition', 'updatePreventiveMeasure'], openedModal)
              ? preventiveMeasure
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(PreventiveMeasuresConstructor, 'defaultProps', {})

  return {
    preventiveMeasures: defaultTo(
      get(state, 'preventiveMeasure.items'),
      defaultProps.preventiveMeasures
    ),
    preventiveMeasure: defaultTo(
      get(state, 'preventiveMeasure.item'),
      defaultProps.preventiveMeasure
    ),
    isGathering:
      defaultTo(
        get(state, 'preventiveMeasure.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'preventiveMeasure.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'preventiveMeasure.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...preventiveMeasureActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreventiveMeasuresConstructor)
