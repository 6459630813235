// Libraries
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Common components
import { TableLayout } from 'Components'

// Specs
import { TableSpecs } from '../Specs'

// Styles
import './Styles/List.less'

class HabilitationsList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,

    // DonnÃ©es de construction du tableau
    TableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    dataList: []
  }

  constructor(props) {
    super(props)

    this.state = {
      pagination: TableSpecs.pagination
    }
  }

  handleOnChange = pagination => {
    this.setState({ pagination })
  }

  render() {
    const { loading, dataList, constructorFunctions } = this.props
    const { pagination } = this.state

    // Ajout des donnÃ©es au tableau
    TableSpecs.rows = dataList

    // Ajout des actions au clic sur un ligne du tableau
    TableSpecs.onRow = (habilitation, id) => ({
      onClick: () =>
        get(constructorFunctions, 'handleRowClick', () => {})(habilitation, id)
    })

    // Pagination courante
    TableSpecs.pagination = pagination
    TableSpecs.onChange = this.handleOnChange

    TableSpecs.onDraggedRowReleased = draggedData =>
      get(constructorFunctions, 'handleDraggedRowReleased', () => {})(
        draggedData
      )

    // Ajout des actions multiples sur le tableau
    TableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteManyHabilitations'
    )

    // Ajout des actions des boutons
    TableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreateHabilitation'
    )

    return (
      <main className='screen habilitations habilitations-List'>
        <TableLayout loading={loading} {...TableSpecs} />
      </main>
    )
  }
}

export default HabilitationsList
