// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { defaultTo, find, get, has, includes, isEmpty, isNil, map, reject } from 'lodash'

// Ressources
import { actions as userActions } from 'Resources/UserResource'
import { actions as authActions } from 'Resources/AuthResource'
import { actions as companyActions } from 'Resources/CompanyResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { CompanyUserModalSpecs, ModalSpecs } from 'Views/Users/Specs'

// Ãcrans
import { List } from './Screens'

const { confirm } = Modal

class UsersConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    user: PropTypes.object,
    profile: PropTypes.object,
    users: PropTypes.array,
    companies: PropTypes.array,
    superCompany: PropTypes.object,
    roles: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    user: {},
    profile: {},
    users: [],
    roles: [],
    companies: [],
    superCompany: {},

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null,
      companyChosen: null
    }
  }

  componentDidMount = async () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue

    this.fetchRolesData()
    await this.fetchUsersData()

    const context = this.getContext()

    if (context !== 'single') await this.fetchCompaniesData()
    else {
      this.getUserData().then(response => {
        const user = find(this.props.users, { id: get(response, 'id') })

        const isUserExtern =
          includes(map(get(user, 'roles'), 'name'), 'EXTERN_READER') ||
          includes(map(get(user, 'roles'), 'name'), 'EXTERN_CREATOR')

        this.fetchCompaniesData().then(() => {
          isUserExtern ? this.askUpdateCompanyUser(user) : this.askUpdateUser()
        })
      })
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchUsersData = () =>
    new Promise((resolve, reject) => {
      this.props.actions
        .fetchUsers()
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(e => {
          reject(e)
          e.status === 403
            ? message.error(I18n.t('api.errors.exceptions.403.desc'))
            : message.error(I18n.t('api.errors.user.fetch'))
        })
    })

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchRolesData = () => {
    this.props.actions.fetchRolesAuth().catch(() => {
      message.error(I18n.t('api.errors.user.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchCompaniesData = () =>
    new Promise((resolve, reject) => {
      this.props.actions
        .fetchAllCompanies()
        .then(response => {
          const companies = get(response, 'body')
          // DÃ©tails rÃ©cupÃ©rÃ©s
          this.fetchSuperCompanyData(companies).catch(err => {
            reject(err)
          })

          this.fetchUserCompanyData(companies).catch(err => {
            reject(err)
          })

          resolve(companies, response)
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.company.fetch'))
        })
    })

  /**
   * RÃ©cupÃ©ration de l'entreprise basÃ© sur la recherce
   * @param value
   */
  onCompanySearch = value => {
    if (!value) return

    this.props.actions
      .fetchCompanies(null, {
        query: {
          filters: {
            name__icontains: value
          }
        }
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.fetch'))
      })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es de la super company
   */
  fetchSuperCompanyData = (companies) =>
    new Promise((resolve) => {
      // DÃ©tails rÃ©cupÃ©rÃ©s
      const supercompany = find(companies, {
        isSupercompany: true
      })
      this.setState({ supercompany })
      resolve(companies)
    })

  fetchUserCompanyData = (companies) =>
    new Promise((resolve) => {
      // DÃ©tails rÃ©cupÃ©rÃ©s
      const companyChosen = find(companies, { id: this.props.profile.company.id })
      this.setState({ companyChosen })
      resolve(companies)
    })

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getUserData = (user = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getUser(user)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.user.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyUser = (user, clearTableSelections) => {
    this.props.actions
      .archiveManyUser(user)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.user.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.bulk.archive'))
      })
  }

  /**
   * DÃ©sactivation d'une ligne du tableau
   */
  deactivateUser = user => {
    this.props.actions
      .deactivateUser(user)
      .then(() => {
        this.fetchUsersData()
        this.safelyCloseModal()
        message.success(I18n.t('api.success.user.disable'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.disable'))
      })
  }

  /**
   * Actions groupÃ©es:
   * DÃ©sactivation de plusieurs lignes du tableau
   */
  disableManyUser = (user, clearTableSelections) => {
    this.props.actions
      .disableManyUser(user)
      .then(() => {
        clearTableSelections()
        this.fetchUsersData()
        message.success(I18n.t('api.success.user.bulk.disable'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.bulk.disable'))
      })
  }

  /**
   * Activation d'une ligne du tableau
   */
  activateUser = user => {
    this.props.actions
      .activateUser(user)
      .then(() => {
        this.fetchUsersData()
        this.safelyCloseModal()
        message.success(I18n.t('api.success.user.enable'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.enable'))
        this.props.navigation.setParams({ id: null })
      })
  }

  /**
   * Actions groupÃ©es:
   * Activation de plusieurs lignes du tableau
   */
  activateManyUser = (user, clearTableSelections) => {
    this.props.actions
      .activateManyUser(user)
      .then(() => {
        clearTableSelections()
        this.fetchUsersData()
        message.success(I18n.t('api.success.user.bulk.enable'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.bulk.enable'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveUser = user => {
    this.props.actions
      .archiveUser(user)
      .then(() => {
        this.fetchUsersData()
        this.safelyCloseModal()
        message.success(I18n.t('api.success.user.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyUser = (user, clearTableSelections) => {
    this.props.actions
      .deleteUsers(user)
      .then(() => {
        this.fetchUsersData()
        clearTableSelections()
        message.success(I18n.t('api.success.user.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteUser = user => {
    const detaileduser = find(this.props.users, { id: user.id })

    this.props.actions
      .deleteUser(detaileduser)
      .then(() => {
        this.fetchUsersData()
        this.safelyCloseModal()
        message.success(I18n.t('api.success.user.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.user.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un user
   */
  updateUser = (updatedUser, originalUser) => {

    if(this.isEmailAvailable(updatedUser.email) === false){
      message.error(I18n.t('api.errors.user.email'))
      return
    }

    if(!this.isEmployeeAlreadyAssociated(updatedUser.employee)){
      message.error(I18n.t('api.errors.user.employee'))
      return;
    }

    return new Promise((resolve, reject) => {
      updatedUser.roles = [{ name: updatedUser.roles }]

      this.props.actions
        .updateUser({
          id: get(originalUser, 'id'),
          ...updatedUser
        })
        .then(response => {
          this.fetchUsersData()
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.user.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.user.update'))
        })
    })
  }


  /**
   * CrÃ©ation d'un user
   */
  createUser = createdUser => {
    if (createdUser.company == null) {
      createdUser.company = this.props.superCompany.id
      createdUser.isSupercompany = true
    }

    if(!this.isEmailAvailable(createdUser.email)){
      message.error(I18n.t('api.errors.user.email'))
      return;
    }

    if(this.isEmployeeAlreadyAssociated(createdUser.employee) === false){
      message.error(I18n.t('api.errors.user.employee'))
      return;
    }

    createdUser.roles = Array.isArray(createdUser.roles) ? createdUser.roles : [createdUser.roles];

    return new Promise((resolve, reject) => {
      //createdUser.roles = [createdUser.roles]
      this.props.actions
        .createUser(createdUser)
        .then(response => {
          this.safelyCloseModal()
          this.fetchUsersData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.user.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.user.create'))
        })
    })
  }

  isEmailAvailable = emailParam => {
    const { users } = this.props
    for(let item of users){
      if(emailParam === item.email){
        return false
      }
    }
    return true
  }

  isEmployeeAlreadyAssociated = employeeParam => {
    const { users } = this.props
    for(let item of users){
      if(employeeParam == undefined){
        return true
      } else {
        if(employeeParam === item.employee?.id){
          return false
        }
      }
    }
    return true
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyUsers = (...params) => {
    confirm({
      title: I18n.t('pages.users.modal.archive.multiple.title'),
      content: I18n.t('pages.users.modal.archive.multiple.content'),
      okText: I18n.t('pages.users.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveUser(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveUser = () => {
    const { user } = this.props

    confirm({
      title: I18n.t('pages.users.modal.archive.single.title'),
      content: I18n.t('pages.users.modal.archive.single.content'),
      okText: I18n.t('pages.users.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveUser(user)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande de dÃ©sactivation de plusieurs lignes du tableau
   */
  askDisableManyUsers = (...params) => {
    confirm({
      title: I18n.t('pages.users.modal.disable.multiple.title'),
      content: I18n.t('pages.users.modal.disable.multiple.content'),
      okText: I18n.t('pages.users.modal.disable.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.disable.multiple.cancelText'),
      onOk: () => {
        this.deactivateUser(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de dÃ©sactivation d'une ligne du tableau
   */
  askDisableUser = () => {
    const { user } = this.props

    confirm({
      title: I18n.t('pages.users.modal.disable.single.title'),
      content: I18n.t('pages.users.modal.disable.single.content'),
      okText: I18n.t('pages.users.modal.disable.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.disable.single.cancelText'),
      onOk: () => {
        this.deactivateUser(user)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'activation de plusieurs lignes du tableau
   */
  askEnableManyUsers = (...params) => {
    confirm({
      title: I18n.t('pages.users.modal.enable.multiple.title'),
      content: I18n.t('pages.users.modal.enable.multiple.content'),
      okText: I18n.t('pages.users.modal.enable.multiple.okText'),
      okType: 'primary',
      cancelText: I18n.t('pages.users.modal.enable.multiple.cancelText'),
      onOk: () => {
        this.activateUser(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'activation d'une ligne du tableau
   */
  askEnableUser = () => {
    const { user } = this.props

    confirm({
      title: I18n.t('pages.users.modal.enable.single.title'),
      content: I18n.t('pages.users.modal.enable.single.content'),
      okText: I18n.t('pages.users.modal.enable.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.enable.single.cancelText'),
      onOk: () => {
        this.activateUser(user)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyUsers = (...params) => {
    confirm({
      title: I18n.t('pages.users.modal.delete.multiple.title'),
      content: I18n.t('pages.users.modal.delete.multiple.content'),
      okText: I18n.t('pages.users.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.deleteManyUser(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteUser = () => {
    const { user } = this.props

    confirm({
      title: I18n.t('pages.users.modal.delete.single.title'),
      content: I18n.t('pages.users.modal.delete.single.content'),
      okText: I18n.t('pages.users.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.users.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteUser(user)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateUser = () => {
    this.setState({ openedModal: 'updateUser' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateCompanyUser = () => {
    this.setState({ companyChosen: null })
    CompanyUserModalSpecs.rows.employee.formField.options = null
    CompanyUserModalSpecs.rows = this.formatModalRows(
      CompanyUserModalSpecs.rows
    )
    CompanyUserModalSpecs.actions.delete.onClick = this.askDeleteUser
    CompanyUserModalSpecs.actions.disable.onClick = this.askDisableUser
    CompanyUserModalSpecs.actions.enable.onClick = this.askEnableUser

    this.setState({ openedModal: 'updateCompanyUser' })
    this.setState({ openedModal: 'createCompanyUser' })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateCompanyUser = async user => {
    if (
      !user.company?.id ||
      !find(this.props.companies, { id: user.company?.id })
    ) {
      await this.fetchCompaniesData()
    }

    const company = defaultTo(
      find(this.props.companies, {
        id: user.company?.id || user.company
      }),
      user.company
    )

    this.setState({ companyChosen: company })

    CompanyUserModalSpecs.rows = this.formatModalRows(
      CompanyUserModalSpecs.rows
    )
    CompanyUserModalSpecs.rows.company.formField.options = map(
      this.props.companies.filter(el => !el.isSupercompany),
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )
    CompanyUserModalSpecs.rows.company.formField.initialValue = company?.id
    CompanyUserModalSpecs.rows.employee.formField.options = map(
      company.employees,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )
    CompanyUserModalSpecs.actions.delete.onClick = this.askDeleteUser
    CompanyUserModalSpecs.actions.disable.onClick = this.askDisableUser
    CompanyUserModalSpecs.actions.enable.onClick = this.askEnableUser

    this.setState({ openedModal: 'updateCompanyUser' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateUser = () => {
    this.setState({ openedModal: 'createUser' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateUser' || openedModal === 'updateCompanyUser') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          () =>
            this.setState({ openedModal: null }, () =>
              this.props.navigation.setParams({ id: null })
            ),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = user => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(user, 'id') })

    this.getUserData(user)
      .then(() => {
        if (user.company.isSupercompany) this.askUpdateUser()
        else this.askUpdateCompanyUser(user).catch(err => console.error(err))
      })
      .catch(err => {
        console.error(err)
        this.props.navigation.setParams({ id: null })
      })
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    const { roles } = this.props
    const { openedModal } = this.state
    const { user } = this.props
    // CrÃ©ation de la liste des roles
    if (has(rows, 'role')) {
      let availableRoles
      if (includes(['createCompanyUser', 'updateCompanyUser'], openedModal))
        availableRoles = roles ? roles.filter(el => el.value >= 50) : null
      else availableRoles = roles ? roles.filter(el => el.value < 50) : null

      if (includes(['updateUser', 'updateCompanyUser'], openedModal)) {
        rows.role.formField.initialValue = user.roles[0].name
        rows.role.formField.options = map(availableRoles, role => {
          return {
            id: role,
            label: I18n.t(
              `pages.users.fields.role.options.${get(role, 'name')}`
            ),
            disabled: get(role, 'name') === 'SUPERADMIN',
            value: get(role, 'name'),
            data: get(role, 'name')
          }
        })
      } else {
        rows.role.formField.options = map(availableRoles, role => {
          return {
            id: role,
            label: I18n.t(
              `pages.users.fields.role.options.${get(role, 'name')}`
            ),
            disabled: get(role, 'name') === 'SUPERADMIN',
            value: {
              id: get(role, 'id'),
              name: get(role, 'name'),
              value: get(role, 'value')
            },
            data: roles
          }
        })
      }
    }
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let { isGathering, isUpdating, isCreating, users, user } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getUserData: this.getUserData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateUser: this.askCreateUser,
      askCreateCompanyUser: this.askCreateCompanyUser,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateUser: this.askUpdateUser,
      askUpdateCompanyUser: this.askUpdateCompanyUser,
      askUpdateManyUsers: this.askUpdateUser,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveUser: this.askArchiveUser,
      askArchiveManyUsers: this.askArchiveManyUsers,

      // DÃ©sactivation d'une ou plusieurs ligne(s) du tableau
      askDisableUser: this.askDisableUser,
      askDisableManyUsers: this.askDisableManyUsers,

      // Activation d'une ou plusieurs ligne(s) du tableau
      askEnableUser: this.askEnableUser,
      askEnableManyUsers: this.askEnableManyUsers,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteUser: this.askDeleteUser,
      askDeleteManyUsers: this.askDeleteManyUsers,

      // RÃ©initialisation du mot de passe d'un user
      askResetPasswordUser: this.askResetPasswordUser
    }

    // Ajout des actions sur la modale
    ModalSpecs.rows = this.formatModalRows(ModalSpecs.rows)
    CompanyUserModalSpecs.rows = this.formatModalRows(
      CompanyUserModalSpecs.rows
    )
    ModalSpecs.actions.delete.onClick = CompanyUserModalSpecs.actions.delete.onClick = this.askDeleteUser
    ModalSpecs.actions.disable.onClick = CompanyUserModalSpecs.actions.disable.onClick = this.askDisableUser
    ModalSpecs.actions.enable.onClick = CompanyUserModalSpecs.actions.enable.onClick = this.askEnableUser

    // Choix de l'entreprise extÃ©rieure
    if (includes(['createUser', 'updateUser'], openedModal))
    {
      const company = []
      company.push(this.props.profile.company)
      ModalSpecs.rows.company.formField.options = map(
        company,
        ({ name, id }) => ({
          value: id,
          id,
          label: name
        })
      )

      ModalSpecs.rows.employee.formField.options = map(
        company[0].employees,
        ({ name, id }) => ({
          value: id,
          id,
          label: name
        })
      )
    } else {
      CompanyUserModalSpecs.rows.company.formField.options = map(
        this.props.companies.filter(el => !el.isSupercompany),
        ({ name, id }) => ({
          value: id,
          id,
          label: name
        })
      )

      // SÃ©lection de l'entreprise extÃ©rieure
      CompanyUserModalSpecs.rows.company.formField.onChange = selectedCompany => {
        const company = find(this.props.companies, ['id', selectedCompany])
        CompanyUserModalSpecs.rows.employee.formField.options = map(
          company?.employees.filter(el => !el?.user),
          ({ name, id }) => ({
            value: id,
            id,
            label: name
          })
        )
      }
    }

    CompanyUserModalSpecs.rows.company.formField.onSearch = this.onCompanySearch

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)
    let newUser = { ...user }
    if (user.roles) {
      newUser.role = user.roles[0]
      newUser.roles = user.roles[0]
    }

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={users}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateUser' ? this.updateUser : this.createUser
          }
          visible={includes(['createUser', 'updateUser'], openedModal)}
          data={
            includes(['transition', 'updateUser'], openedModal) ? newUser : null
            //includes(['transition', 'updateUser', 'createUser'], openedModal) ? {company: 188} : {company: null}
          }
          {...ModalSpecs}
        />

        {/* Ouverture de la modale de crÃ©ation / modification d'utilisateurs extÃ©rieurs */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateCompanyUser'
              ? this.updateUser
              : this.createUser
          }
          visible={includes(
            ['createCompanyUser', 'updateCompanyUser'],
            openedModal
          )}
          data={
            includes(['transition', 'updateCompanyUser'], openedModal)
              ? newUser
              : null
          }
          {...CompanyUserModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(UsersConstructor, 'defaultProps', {})
  const superCompany = state.company.items.find(
    el => el.isSupercompany === true
  )

  return {
    users: defaultTo(get(state, 'user.items'), defaultProps.users),
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    user: defaultTo(get(state, 'user.item'), defaultProps.user),
    roles: defaultTo(get(state, 'auth.roles'), defaultProps.roles),
    companies: defaultTo(get(state, 'company.items'), defaultProps.companies),
    superCompany: superCompany,
    isGathering:
      defaultTo(get(state, 'user.isFetching'), defaultProps.isGathering) ||
      defaultTo(get(state, 'user.isFetchingItem'), defaultProps.isGathering),
    isUpdating: defaultTo(
      get(state, 'user.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...userActions,
      ...authActions,
      ...companyActions
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersConstructor)
