import { takeLatest, all } from 'redux-saga/effects'
import { get } from 'lodash'

/* ------------- Types ------------- */

import { StartupTypes } from 'Redux/StartupRedux'
import { types as AuthTypes } from 'Resources/AuthResource'
import { types as ApiTypes } from 'Resources/ApiResource'
import { WebSocketTypes } from 'WebSocket/Redux'

/* ------------- Sagas ------------- */

import {
  startup,
  apiAvailable,
  apiUnavailable,
  watchLanguageChannel
} from 'Sagas/StartupSagas'
import { setSession, sessionExpired } from 'Sagas/AuthSagas'
import {
  handleMessage,
  disconnected,
  connected,
  watchNotificationChannel
} from 'WebSocket/Sagas'

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    // Erreur 401 > sessionExpired
    takeLatest(action => {
      return (
        // Status rejected et erreur 401
        get(action, 'status') === 'rejected' && get(action, 'code') === 401
      )
    }, sessionExpired),

    // Session Utilisateur
    takeLatest(
      action =>
        action.type === AuthTypes.LOGIN_AUTH && action.status === 'resolved',
      setSession
    ),
    takeLatest(
      action =>
        action.type === AuthTypes.LOGOUT_AUTH && action.status === 'resolved',
      sessionExpired
    ),

    // API
    takeLatest(
      action =>
        action.type === ApiTypes.GET_API && action.status === 'resolved',
      apiAvailable
    ),
    takeLatest(
      action =>
        action.type === ApiTypes.GET_API && action.status === 'rejected',
      apiUnavailable
    ),

    // DÃ©marrage de l'application
    takeLatest(StartupTypes.STARTUP, startup),

    // WebSocket
    takeLatest(StartupTypes.STARTUP_DONE, watchLanguageChannel),
    takeLatest(StartupTypes.STARTUP_DONE, watchNotificationChannel),
    takeLatest(WebSocketTypes.WS_MESSAGE_RECEIVED, handleMessage),
    takeLatest(WebSocketTypes.WS_DISCONNECTED, disconnected),
    takeLatest(WebSocketTypes.WS_CONNECTED, connected)
  ])
}
