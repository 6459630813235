// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { Tooltip, Tag, Icon } from 'antd'
import { isNil, map, get, size, defaultTo } from 'lodash'

// Helpers
import { filterPeriod, filterBool } from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'permit',
    kind: 'male'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    }
    // Actions groupÃ©es sur les lignes du tableau
    // selection: {
    //   delete: {
    //     buttonType: 'danger'
    //   }
    // }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.permits.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Colonnes du tableau
  columns: {
    status: {
      title: 'pages.permits.fields.status.title',
      dataIndex: 'status',
      key: 'status',
      render: (status, { dateStatusModified }) => {
        const associatedColors = {
          waiting: '',
          cancelled: 'red',
          closed: 'blue',
          finished: 'orange',
          opened: 'green'
        }

        return !isNil(status) ? (
          <Tooltip
            title={I18n.t(
              `pages.permits.fields.status.options.${status}.tooltip`,
              {
                date:
                  !isNil(dateStatusModified) &&
                  moment(dateStatusModified).isValid()
                    ? moment(dateStatusModified).format('L')
                    : I18n.t('common.unknown.female')
              }
            )}
          >
            <Tag
              className='permit-status'
              color={get(associatedColors, status)}
            >
              {status === 'finished' && <Icon type='warning' />}
              {/* {status === 'waiting' && <Icon type='loading' />} */}
              {I18n.t(`pages.permits.fields.status.options.${status}.title`)}
            </Tag>
          </Tooltip>
        ) : (
          I18n.t('common.unknown.male')
        )
      },
      sorter: true,
      filters: map(
        ['waiting', 'opened', 'finished', 'cancelled', 'closed'],
        status => ({
          text: (
            <Tooltip
              placement='right'
              title={I18n.t(
                `pages.permits.fields.status.options.${status}.tooltip`
              )}
            >
              {I18n.t(
                `pages.permits.fields.status.options.${status}.filterTitle`
              )}
            </Tooltip>
          ),
          value: status
        })
      )
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      render: company =>
        !isNil(company) ? company.name : I18n.t('common.unknown.male'),
      sorter: true
    },
    permitID: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      render: permitID =>
        !isNil(permitID) ? permitID : I18n.t('common.unknown.male'),
      sorter: true
    },
    validityPeriod: {
      title: 'pages.permits.fields.validityPeriod.title',
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      render: validityPeriod =>
        !isNil(validityPeriod) ? (
          <span className='period'>
            <span className='period-date'>
              {moment(get(validityPeriod, 'startDate')).format('L')}
            </span>
            <span className='period-date'>
              {moment(get(validityPeriod, 'endDate')).format('L')}
            </span>
          </span>
        ) : (
          I18n.t('common.unknown.female')
        ),
      // sorter: true,
      ...filterPeriod(null, moment(), [
        'validityPeriod.startDate',
        'validityPeriod.endDate'
      ])
    },
    isSigned: {
      title: 'pages.permits.fields.isSigned.title',
      dataIndex: 'isSigned',
      key: 'isSigned',
      render: isSigned => (
        <Tag color={isSigned ? 'green' : 'volcano'}>
          {I18n.t(`common.${defaultTo(isSigned, false) ? 'yes' : 'no'}`)}
        </Tag>
      ),
      sorter: true,
      ...filterBool('isSigned')
    },
    firePermits: {
      title: 'pages.permits.fields.firePermits.title',
      dataIndex: 'firePermits',
      key: 'firePermits',
      render: firePermits => size(firePermits)
    },
    dateCreated: {
      title: 'pages.permits.fields.dateCreated.title',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: dateCreated =>
        !isNil(dateCreated) ? (
          <Tooltip title={moment(dateCreated).format('L')}>
            {moment(dateCreated).calendar()}
          </Tooltip>
        ) : (
          I18n.t('common.unknown.female')
        ),
      sorter: true,
      ...filterPeriod(null, moment(), 'dateCreated')
    }
  }
}

export default specs
