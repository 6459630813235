// Libraries
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import I18n from 'i18next'
import { Card, Icon, Button } from 'antd'

// Common components
import { TableLayout } from 'Components'

// Specs
import { HabilitationTableSpecs } from 'Views/Companies/Specs'

class HabilitationsList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,

    // DonnÃ©es de construction du tableau
    HabilitationTableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    dataList: []
  }

  constructor(props) {
    super(props)

    this.state = {
      pagination: HabilitationTableSpecs.pagination
    }
  }

  handleOnChange = pagination => {
    this.setState({ pagination })
  }

  render() {
    const { loading, dataList, constructorFunctions } = this.props
    const { pagination } = this.state

    // Ajout des donnÃ©es au tableau
    HabilitationTableSpecs.rows = dataList

    // Pagination courante
    HabilitationTableSpecs.pagination = pagination
    HabilitationTableSpecs.onChange = this.handleOnChange

    // Ajout des actions multiples sur le tableau
    /*HabilitationTableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteManyHabilitationsEmployee'
    )*/

    // Ajout des actions au clic sur un ligne du tableau
    HabilitationTableSpecs.columns.EmployeeName.onCell
      = HabilitationTableSpecs.columns.name.onCell
      = HabilitationTableSpecs.columns.endDate.onCell = (
      supervisor,
      id,
      habilitation,
      employee
    ) => ({
      onClick: () =>
        get(constructorFunctions, 'askUpdateHabilitationEmployee', () => {})(
          supervisor,
          id,
          habilitation,
          employee
        )
    })

    // Ajout des actions des boutons
    HabilitationTableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreateHabilitationEmployee'
    )


    HabilitationTableSpecs.columns.proofFile.render = (habilitationFile) => {
      if (habilitationFile) {
        return (
          <Button type='link'
          icon='edit'
          onClick={e => {window.open(habilitationFile, '_blank')}}
          >
            {I18n.t('pages.habilitations.fields.proofFile.export')}
          </Button>
        );
      } else {
        return (
          <div>
          <Icon type='warning'/>
          {I18n.t('pages.habilitations.fields.proofFile.noExport')}
          </div>
        );
      }
    }

    return (
      <Card className='tab habilitations'>
        <TableLayout loading={loading} {...HabilitationTableSpecs} />
      </Card>
    )
  }
}

export default HabilitationsList
