import React, { Component } from 'react'

// Libraries
import { Breadcrumb } from 'antd'
// import PropTypes from 'prop-types'

class CustomBreadcrumb extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Breadcrumb>
        <Breadcrumb.Item>User</Breadcrumb.Item>
        <Breadcrumb.Item>Bill</Breadcrumb.Item>
      </Breadcrumb>
    )
  }
}

export default CustomBreadcrumb
