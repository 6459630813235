// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Button, Form, Icon, Input, Radio, Select, Steps } from 'antd'
import {
  cloneDeep,
  defaultTo,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
  map
} from 'lodash'

// Components
import { Container } from 'Components/Modals'

// Styles
import './SignatureModal.less'

// Helper
import { createFormFields } from 'Helpers'
import RadioGroup from 'antd/es/radio/group'
import Dragger from 'antd/es/upload/Dragger'
import SignatureCanvas from 'react-signature-canvas'
import Text from 'antd/es/typography/Text'

const { Item: FormItem } = Form
const { Step } = Steps

// Sauvegarde du formulaire en temps rÃ©el
// Permet de conserver les valeurs si le composant est re-render
let UNSAVED_FORM = {}

class SignatureModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.object,
    okText: PropTypes.object,
    cancelText: PropTypes.object,

    // ContrÃ´le d'Ã©tat de la modale
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    loading: PropTypes.bool,

    // Contenu de la modale
    data: PropTypes.object,
    form: PropTypes.object,
    currentTab: PropTypes.string,
    openedModal: PropTypes.string,

    // Fonctions de callback
    onCancel: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // Labels et titres
    title: {
      single: 'pages.permits.modal.signature.single.title',
      multiple: 'pages.permits.modal.signature.multiple.title'
    },
    okText: {
      single: 'pages.permits.modal.signature.single.okText',
      multiple: 'pages.permits.modal.signature.multiple.okText'
    },
    cancelText: {
      single: 'pages.permits.modal.signature.single.cancelText',
      multiple: 'pages.permits.modal.signature.multiple.cancelText'
    },

    // ContrÃ´le d'Ã©tat de la modale
    width: 520,
    visible: false,
    loading: true,

    // Contenu de la modal
    data: {},
    form: {},
    currentTab: '',
    openedModal: '',

    // Fonctions de callback
    onCancel: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)
    this.sigCanvas1 = React.createRef()
    this.sigCanvas2 = React.createRef()
    this.sigCanvasController = React.createRef()
    this.sigCanvas3 = React.createRef()

    // Ãtats initiaux
    this.state = {
      step: 0,
      method: 1
    }
  }

  handleCancel = e => {
    const { onCancel, form } = this.props
    this.setState({
      step: 0,
      method: 1,
      showInvalid: false
    })

    // Reset du formulaire
    form.resetFields()
    UNSAVED_FORM = {}

    onCancel(e)
  }

  /**
   * Envoi du formulaire
   */
  handleSubmit = e => {
    e.preventDefault()
    const { data, onChange, form } = this.props
    const pad1result = this.sigCanvas1.current?.toDataURL()
    const pad2result = this.sigCanvas2.current?.toDataURL()
    let option

    form.validateFields((error, formData) => {
      if (
        (this.state.method === 1 &&
          !this.sigCanvas1.current.isEmpty() &&
          !this.sigCanvas2.current.isEmpty() ) ||
        (this.state.method === 2 ) 
      ) {
        if (!error) {
          switch (this.state.method) {
            case 1:
              formData.creatorSign = pad1result
              formData.employeeSign = pad2result
              option = 'sign_now'
              break
            case 2:
              option = 'upload'
              break
            default:
              option = 'sign_now'
              break
          }

          onChange(formData, data, option).then(() => (UNSAVED_FORM = {}))
        }
      } else {
        this.setState({ showInvalid: true })
      }
    })
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const { loading, okText, cancelText } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    return (
      <div className='signature-modal-footer'>
        {this.state.step > 0 && (
          <Button
            key='previous'
            onClick={() => this.setState({ step: this.state.step - 1, method: 1 })}
          >
            {I18n.t(`common.previous`)}
          </Button>
        )}
        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='signature-modal-footer-controls'>
          <Button key='back' onClick={this.handleCancel}>
            {I18n.t(get(cancelText, mode))}
          </Button>

          {this.state.step < 1 && (
            <Button
              key='next'
              onClick={() => this.setState({ step: this.state.step + 1 })}
            >
              {I18n.t(`common.next`)}
            </Button>
          )}
          {this.state.step >= 1 && (
            <Button
              key='submit'
              type='primary'
              loading={loading}
              onClick={this.handleSubmit}
            >
              {I18n.t(get(okText, mode))}
            </Button>
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      form,
      data,
      width,
      title,
      okText,
      loading,
      visible,
      cancelText,
      currentTab,
      openedModal
    } = this.props
    const { getFieldDecorator } = form

    let employee = data

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    return (
      <Container
        title={I18n.t(get(title, mode))}
        loading={loading}
        visible={visible}
        cancelText={I18n.t(get(cancelText, mode))}
        okText={I18n.t(get(okText, mode))}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        className={'modal signature-modal'}
        footer={this.renderFooter()}
        width={width}
      >
        <div>
          <Steps
            style={{ marginBottom: '20px' }}
            current={this.state.step}
            onChange={value => this.setState({ step: value })}
            size='small'
          >
            <Step
              title={I18n.t(`pages.permits.modal.signature.steps.method`)}
            />
            <Step title={I18n.t(`pages.permits.modal.signature.steps.sign`)} />
          </Steps>

          <Form onSubmit={this._handleSubmit}>
            {/* Choix de la mÃ©thode de signature */}
            <div>

              {this.state.step === 0 && (
                <FormItem>
                  {getFieldDecorator('choice', {
                    initialValue: 1
                  })(
                    <RadioGroup
                      onChange={choice =>
                        this.setState({ method: choice.target.value })}
                    >
                      <Radio className={'radio'} value={1}>
                        {I18n.t(`pages.permits.modal.signature.choice.first`)}
                      </Radio>
                    
                      <Radio className={'radio'} value={2}>
                        {I18n.t(`pages.permits.modal.signature.choice.third`)}
                      </Radio>
                    </RadioGroup>
                  )}
                </FormItem>
              )}
            </div>

            {/* case 1 : signature sur place */}
            <div>
              {this.state.step === 1 && this.state.method === 1 && (
                <div>
                  <FormItem
                    label={I18n.t(`pages.permits.fields.signature.title`)}
                  >
                    {getFieldDecorator('creatorSign')(
                      <div className='sigPadContainer'>
                        <SignatureCanvas
                          penColor='black'
                          ref={this.sigCanvas1}
                          canvasProps={{ className: 'sigCanvas' }}
                        />
                        <Button onClick={() => this.sigCanvas1.current.clear()}>
                          {I18n.t(`common.clear`)}
                        </Button>
                      </div>
                    )}
                  </FormItem>
                  <FormItem
                    label={I18n.t(`pages.permits.fields.signatureName.title`)}
                  >
                    {getFieldDecorator('employee', {
                      initialValue: employee.id,
                      rules: [
                        {
                          required: true,
                          message: I18n.t(
                            `pages.permits.fields.signatureName.requiredMessage`
                          )
                        }
                      ]
                    })(
                      <Select
                        placeholder={I18n.t(
                          `pages.permits.fields.signatureName.placeholder`
                        )}
                      >
                        <Select.Option key={employee.id} value={employee.id}>
                          {employee.name}
                        </Select.Option>
                        
                      </Select>
                    )}
                  </FormItem>
                  <FormItem
                    label={I18n.t(`pages.permits.fields.signature2.title`)}
                  >
                    {getFieldDecorator('employeeSign')(
                      <div className='sigPadContainer'>
                        <SignatureCanvas
                          penColor='black'
                          ref={this.sigCanvas2}
                          canvasProps={{ className: 'sigCanvas' }}
                        />
                        <Button onClick={() => this.sigCanvas2.current.clear()}>
                          {I18n.t(`common.clear`)}
                        </Button>
                      </div>
                    )}
                  </FormItem>
                  
                </div>
              )}
            </div>

          

            {/* cas 2 : joindre un permis signÃ© */}
            <div>
              {this.state.step === 1 && this.state.method === 2 && (
                <FormItem>
                  {getFieldDecorator('scan')(
                    <Dragger
                      name='scan'
                      beforeUpload={file => {
                        return false
                      }}
                    >
                      <p className='ant-upload-drag-icon'>
                        <Icon type='inbox' />
                      </p>
                      <p className='ant-upload-text'>
                        {I18n.t('pages.permits.fields.scan.title')}
                      </p>

                      <p className='ant-upload-hint'>
                        {I18n.t('pages.permits.fields.scan.print-hint')}
                      </p>

                      <p className='ant-upload-hint'>
                        {I18n.t('pages.permits.fields.scan.upload-hint')}
                      </p>

                      <div className='buttons'>
                        <Button key='import' icon='paper-clip' type='primary'>
                          {I18n.t('pages.permits.fields.scan.import')}
                        </Button>
                      </div>
                    </Dragger>
                  )}
                </FormItem>
              )}
            </div>
            {this.state.showInvalid && (
              <div className='signature-modal-danger'>
                <Text className='signature-modal-danger-text'>
                  {I18n.t(
                    'pages.permits.modal.signature.choice.missingSignature'
                  )}
                </Text>
              </div>
            )}
          </Form>
        </div>
      </Container>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange(props, fieldValue, fieldsValue) {
      UNSAVED_FORM = fieldsValue
    },
    mapPropsToFields(props) {
      const defaultProps = get(SignatureModal, 'defaultProps', {})
      let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

      if (!isEmpty(UNSAVED_FORM)) {
        formData = UNSAVED_FORM
      } else {
        // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
        forEach(formData, (value, index) => {
          const format = get(
            find(map(get(props, 'rows')), { dataIndex: index }),
            'formField.format'
          )

          formData[index] = !isNil(format) ? format(value, formData) : value
        })
      }

      return createFormFields(Form, formData)
    }
  })(SignatureModal)
)
