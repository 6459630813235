// Libraries
import React, { Component } from 'react'
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { Table, Button, Input, Icon, Menu, Dropdown } from 'antd'
import { withNavigation } from '@react-navigation/core'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import {
  defaultTo,
  map,
  get,
  isEmpty,
  isFunction,
  has,
  isNil,
  forEach,
  cloneDeep,
  head,
  size,
  find,
  remove,
  isEqual
} from 'lodash'

// Styles
import './TableLayout.less'

// Helpers
import { searchData } from 'Helpers'
import { DraggableRow, formatDragIndexes } from 'Components/TableLayout/Helpers'

const { Search } = Input
const { Column, ColumnGroup } = Table

class TableLayout extends Component {
  static propTypes = {
    // Liste de boutons Ã  afficher
    actions: PropTypes.shape({
      global: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
      selection: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
    }),

    // Titre du tableau
    title: PropTypes.string,

    // Colonnes du tableau
    columns: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // ParamÃ©trages de la pagination
    pagination: PropTypes.object,
    totalItems: PropTypes.number,

    // Identifiant de chaque lignes
    rowKey: PropTypes.string.isRequired,

    // Lignes du tableau modifiable en drag and drop
    areRowsDraggable: PropTypes.bool,
    onDraggedRowReleased: PropTypes.func,

    // ParamÃ¨tres d'internationalisation
    locale: PropTypes.object,

    // Chargement en cours
    loading: PropTypes.bool,

    // Navigation
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired
    }),

    // Permet de dÃ©finir l'affiche du titre (par dÃ©faut l'affichage affichÃ©s/trouvÃ©s)
    titleEnabled: PropTypes.bool,

    // Permet de dÃ©finir si la fonction de recherche est active
    searchEnabled: PropTypes.bool,

    onSearch: PropTypes.func,

    // Overwrite du render header gauche ou droit
    renderHeaderLeft: PropTypes.func,
    renderHeaderRight: PropTypes.func,

    // Gestion du wording des Ã©lÃ©ments affichÃ©s/trouvÃ©s
    element: PropTypes.shape({
      name: PropTypes.string,
      kind: PropTypes.oneOf(['male', 'female']),
      consonant: PropTypes.bool
    }),

    // Lignes du tableau
    rows: PropTypes.array,
    onRow: PropTypes.func
  }

  static defaultProps = {
    loading: false,
    titleEnabled: true,
    searchEnabled: true,
    areRowsDraggable: false,
    onSearch: null,
    onRow: () => {},
    element: {
      kind: 'male',
      consonant: true
    }
  }

  constructor(props) {
    super(props)

    this.state = {
      dragAndDropData: props.rows,
      searchText: '',
      filterText: ''
    }
  }

  componentWillReceiveProps(nextProps) {
    const { areRowsDraggable } = this.props
    const { dragAndDropData } = this.state

    if (!isEqual(get(nextProps, 'rows'), dragAndDropData)) {
      areRowsDraggable &&
        this.setState({
          dragAndDropData: get(nextProps, 'rows')
        })
    }
  }

  /**
   * Filtre les donnÃ©es en fonction du motif renseignÃ© dans le champs de recherche
   *
   * Retourne un tableau contenant toutes les donnÃ©es en fonction de la recherche de l'utilisateur
   * @return {array}
   */
  filterData = data => {
    let { searchText = '' } = this.state

    return searchData(data, searchText)
  }

  handleSearch = event => {
    const searchText = get(event, 'target.value', event)
    if (this.props.onSearch != null) {
      this.props.onSearch(searchText)
    } else {
      this.setState({ searchText })
    }
  }

  handleButtonClick = (onClick, routeName) => {
    if (isFunction(onClick)) {
      onClick()
    } else if (!isEmpty(routeName)) {
      this.props.navigation.navigate(routeName)
    }
  }

  /**
   * ExÃ©cutÃ© lors du reset des filtres d'un tableau
   */
  handleReset = clearFilters => {
    clearFilters()
    this.setState({ searchText: '' })
  }

  /**
   * ExÃ©cutÃ© lors du clic sur une ligne d'un tableau
   */
  handleSelectRows = (selectedRowKeys, selectedRows) => {
    this.setState({
      tablesSelections: {
        selectedRowKeys,
        selectedRows
      }
    })
  }

  /**
   * Permet de vider la selection des lignes du tableau de maniÃ¨re programmatique
   */
  clearTableSelections = () => {
    this.setState({
      tablesSelections: {}
    })
  }

  /**
   * Changement d'action pour le bouton d'action Ã  choix multiple
   */
  handleSelectAction = ({ key }) => {
    this.setState({
      selectedAction: key
    })
  }

  handleMoveRow = (dragIndex, releaseIndex) => {
    const { pagination, onDraggedRowReleased } = this.props
    const { dragAndDropData } = this.state

    const formatDragIndex = formatDragIndexes(pagination, dragIndex)
    const formatReleaseIndex = formatDragIndexes(pagination, releaseIndex)

    const draggedRow = dragAndDropData[formatDragIndex]

    dragAndDropData.splice(formatDragIndex, 1)
    dragAndDropData.splice(formatReleaseIndex, 0, draggedRow)

    this.setState(
      {
        dragAndDropData
      },
      () => onDraggedRowReleased(dragAndDropData)
    )
  }

  /**
   * Rendu de la barre de recherche
   */
  renderSearchBar = position => {
    const { searchEnabled, element } = this.props
    const { name, kind, consonant } = element

    return searchEnabled ? (
      <Search
        placeholder={
          name
            ? I18n.t(
                consonant
                  ? `fields.search.placeholder.custom.${kind}.consonant`
                  : `fields.search.placeholder.custom.${kind}.vowel`,
                {
                  name: I18n.t(
                    `components.tableLayout.elements.${name}.singular`
                  )
                }
              )
            : I18n.t('fields.search.placeholder.default')
        }
        enterButton
        onSearch={this.handleSearch}
        onChange={this.handleSearch}
      />
    ) : (
      <div />
    )
  }

  /**
   * Rendu d'un bouton associÃ© Ã  un tableau
   */
  renderButton = (
    { label = '', icon, type = 'primary', onClick, routeName, hidden = false },
    index = 0
  ) => {
    if (!hidden) {
      return (
        <Button
          key={index}
          onClick={() => this.handleButtonClick(onClick, routeName)}
          type={type}
          icon={I18n.t(icon)}
        >
          {I18n.t(label)}
        </Button>
      )
    }
  }

  renderDropdown = (
    { label = '', icon, type = 'primary', items, routeName },
    index = 0
  ) => (
    <Dropdown
      key={index}
      trigger='click'
      overlay={
        <Menu>
          {map(items, item => {
            return (
              <Menu.Item key={item.key}>
                <Button type='link' onClick={item.onClick}>
                  {I18n.t(item.label)}
                </Button>
              </Menu.Item>
            )
          })}
        </Menu>
      }
    >
      <Button type={type} icon={icon}>
        {I18n.t(label)}
      </Button>
    </Dropdown>
  )

  /**
   * Rendu des boutons principaux
   */
  renderGlobalActions = () => {
    const { actions } = this.props
    const globalActions = get(actions, 'global')

    return (
      !isEmpty(globalActions) && (
        <div className='buttons'>
          {map(
            globalActions,
            (
              {
                render = this.renderButton,
                renderDrop = this.renderDropdown,
                ...button
              },
              index
            ) => {
              if (button.items != null) return renderDrop(button, index)
              else return render(button, index)
            }
          )}
        </div>
      )
    )
  }

  /**
   * Zone pour les actions de lot
   */
  renderFooter = currentPageData => {
    return (
      <div className='actions'>
        <div className='actions-buttons'>{this.renderActionButton()}</div>
      </div>
    )
  }

  /**
   * Rendu des actions disponibles dans la modale
   */
  renderActions = actions => {
    const { selectedAction } = this.state

    return (
      <Menu
        selectedKeys={[defaultTo(selectedAction, get(head(actions), 'name'))]}
        className='table-layout-actions-menu'
        onClick={action => this.handleSelectAction(action, actions)}
      >
        {map(actions, ({ name }) => (
          <Menu.Item className='table-layout-actions-menu-item' key={name}>
            {/* Nom de l'action */}
            <span className='table-layout-actions-menu-item-title'>
              {I18n.t(`components.tableLayout.bulkActions.${name}.title`)}
            </span>

            {/* Description de l'action */}
            <span className='table-layout-actions-menu-item-description'>
              {I18n.t(`components.tableLayout.bulkActions.${name}.description`)}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  /**
   * Rendu du bouton d'action Ã  choix multiples
   */
  renderActionButton = () => {
    const { loading, actions } = this.props
    const selectionActions = get(actions, 'selection')

    // RÃ©cupÃ©ration des selections faites dans le tableau
    const selectedItems = defaultTo(
      get(this.state, 'tablesSelections.selectedRowKeys'),
      []
    )

    const disabled = isEmpty(selectedItems)

    // RÃ©cupÃ©ration des actions multiples disponibles
    const availableActions = cloneDeep(
      map(selectionActions, ({ name, ...props }, index) => ({
        ...props,
        name: defaultTo(name, index)
      }))
    )

    // Suppression des boutons masquÃ©s
    remove(availableActions, { hidden: true })

    // RÃ©cupÃ©ration de l'action sÃ©lectionnÃ©e
    const selectedAction = defaultTo(
      find(availableActions, { name: get(this.state, 'selectedAction') }),
      head(availableActions)
    )

    // Affichage sÃ©lectif si plusieurs actions sont disponibles
    return size(availableActions) > 1 ? (
      <Dropdown.Button
        disabled={loading || disabled}
        onClick={() =>
          defaultTo(get(selectedAction, 'onClick'), () => {})(
            selectedItems,
            this.clearTableSelections
          )
        }
        overlay={this.renderActions(availableActions)}
        placement='topRight'
        type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        icon={<Icon type='up' />}
      >
        {I18n.t(
          `components.tableLayout.bulkActions.${get(
            selectedAction,
            'name'
          )}.label`,
          {
            length: get(selectedItems, 'length')
          }
        )}
      </Dropdown.Button>
    ) : (
      !isEmpty(availableActions) && (
        <Button
          disabled={loading || disabled}
          onClick={() =>
            defaultTo(get(selectedAction, 'onClick'), () => {})(
              selectedItems,
              this.clearTableSelections
            )
          }
          type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        >
          {I18n.t(
            `components.tableLayout.bulkActions.${get(
              selectedAction,
              'name'
            )}.label`,
            {
              length: get(selectedItems, 'length')
            }
          )}
        </Button>
      )
    )
  }

  /**
   * Rendu des colonnes d'un tableau
   */
  renderColumns = columns => {
    // Colonne avec l'attribut `searchable`
    const searchableColumn = column => ({
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
        <div className='ant-table-search'>
          <Input
            ref={ref => (this.searchInput = ref)}
            placeholder={I18n.t('fields.search.placeholder.default')}
            onChange={event => {
              const filterText = get(event, 'target.value')

              if (isEmpty(filterText)) {
                clearFilters()
              } else {
                setSelectedKeys([filterText])
              }

              this.setState({ filterText })
            }}
            onPressEnter={confirm}
            allowClear
          />
          <Button type='primary' onClick={confirm} icon='search' />
        </div>
      ),
      filterIcon: filtered => (
        <Icon
          type='search'
          style={{ color: filtered ? '#1890ff' : undefined }}
        />
      ),
      onFilter: (value, record) =>
        record[get(column, 'dataIndex')]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => this.searchInput.select())
        }
      }
    })

    return map(columns, column => {
      const { columns, hidden, title, ...columnProps } = column

      // Exclusion des colonnes cachÃ©es (cas particulier)
      if (!hidden) {
        if (!isNil(columns)) {
          return (
            <ColumnGroup title={I18n.t(title)} {...columnProps}>
              {this.renderColumns(columns)}
            </ColumnGroup>
          )
        } else {
          // Gestion de la recherche sur cette colonne
          const searchableProps = has(columnProps, 'searchable')
            ? searchableColumn(columnProps)
            : {}

          return (
            <Column
              title={I18n.t(title)}
              {...columnProps}
              {...searchableProps}
            />
          )
        }
      }
    })
  }

  handleTableChange = (pagination, filters, sorter) => {}
  /**
   * Rendu du tableau
   */
  renderTable = () => {
    const { searchText, tablesSelections } = this.state
    const {
      title,
      loading,
      searchEnabled,
      titleEnabled,
      columns,
      rows = [],
      element,
      actions,
      locale,
      onRow,
      areRowsDraggable,
      totalItems,
      ...props
    } = this.props

    const { name, kind } = element
    const selectionActions = get(actions, 'selection')

    const dataSource = areRowsDraggable
      ? this.filterData(rows)
      : searchEnabled
      ? this.filterData(rows)
      : rows

    const length = totalItems || dataSource.length

    // Gestion de l'internationalisation de l'empty
    if (!isNil(locale)) {
      forEach(locale, (value, key) => {
        locale[key] = I18n.t(value)
      })
    }

    // Gestion de la multi suppression
    props.rowSelection =
      !isEmpty(selectionActions) && !isEmpty(dataSource)
        ? {
            ...defaultTo(props.rowSelection, {
              selectedRowKeys: defaultTo(
                get(tablesSelections, 'selectedRowKeys'),
                []
              )
            }),
            onChange: (selectedRowKeys, selectedRows) =>
              this.handleSelectRows(selectedRowKeys, selectedRows)
          }
        : props.rowSelection

    props.footer =
      !isEmpty(selectionActions) && !isEmpty(dataSource)
        ? this.renderFooter
        : props.footer

    // Gestion du message listant le nombre d'Ã©lÃ©ments affichÃ©s ou trouvÃ©s
    const titleSearch =
      searchEnabled &&
      (searchText
        ? // ÃlÃ©ments trouvÃ©s
          // Nom permettant de dÃ©finir la nature des Ã©lÃ©ments
          name
          ? I18n.t(
              length > 1
                ? `components.tableLayout.displayed-count.results.custom.${kind}.plural`
                : `components.tableLayout.displayed-count.results.custom.${kind}.singular`,
              {
                length,
                name: I18n.t(
                  length > 1
                    ? `components.tableLayout.elements.${name}.plural`
                    : `components.tableLayout.elements.${name}.singular`
                )
              }
            )
          : I18n.t(
              length > 1
                ? 'components.tableLayout.displayed-count.results.default.plural'
                : 'components.tableLayout.displayed-count.results.default.singular',
              { length }
            )
        : // ÃlÃ©ments affichÃ©s
        // Nom permettant de dÃ©finir la nature des Ã©lÃ©ments
        name
        ? I18n.t(
            length > 1
              ? `components.tableLayout.displayed-count.elements.custom.${kind}.plural`
              : `components.tableLayout.displayed-count.elements.custom.${kind}.singular`,
            {
              length,
              name: I18n.t(
                length > 1
                  ? `components.tableLayout.elements.${name}.plural`
                  : `components.tableLayout.elements.${name}.singular`
              )
            }
          )
        : I18n.t(
            length > 1
              ? 'components.tableLayout.displayed-count.elements.default.plural'
              : 'components.tableLayout.displayed-count.elements.default.singular',
            { length }
          ))

    const renderTitle = (...props) =>
      titleEnabled ? (
        !isNil(title) ? (
          title(titleSearch)
        ) : (
          <p>{titleSearch}</p>
        )
      ) : null

    const rowComponent = areRowsDraggable
      ? {
          body: { row: DraggableRow }
        }
      : undefined

    return (
      <div className='table-layout-table'>
        <DndProvider backend={HTML5Backend} context={window}>
          <Table
            locale={locale}
            title={renderTitle}
            dataSource={dataSource}
            loading={loading}
            components={rowComponent}
            onChange={this.handleTableChange}
            onRow={(record, index) => ({
              index,
              moveRow: areRowsDraggable ? this.handleMoveRow : () => {},
              ...onRow(record, index)
            })}
            {...props}
          >
            {this.renderColumns(columns)}
          </Table>
        </DndProvider>
      </div>
    )
  }

  render() {
    const { renderHeaderLeft, renderHeaderRight } = this.props

    return (
      <div className='component table-layout'>
        <header>
          {/* Contenu gauche (Default: Barre de recherche) */}
          {defaultTo(renderHeaderLeft, this.renderSearchBar)()}

          {/* Contenu droite (Default: Boutons) */}
          {defaultTo(renderHeaderRight, this.renderGlobalActions)()}
        </header>

        <main>
          {/* Tableaux */}
          {this.renderTable()}
        </main>
      </div>
    )
  }
}

export default withNavigation(TableLayout)
