import { createResource } from 'redux-rest-resource'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'companyStatistics',
  pluralName: 'companiesStatistics',
  url: `${baseURL}/dashboard`,
  credentials: 'include',
  actions: {
    fetch: {
      isArray: true,
      method: 'GET',
      gerundName: 'fetching',
      url: '.',
      reduce: ({ items, ...state }, response) => {
        const isFetching = response.status === 'pending'

        if (response.status === 'resolved') {
          items = response.body
        }

        return { ...state, items, isFetching }
      }
    },
    generate: {
      method: 'POST',
      gerundName: 'creating',
      url: './statistic',
      reduce: (state, response) => {
        const isFetching = response.status === 'pending'
        let charts
        if (response.status === 'resolved') {
          charts = response.body
        }

        return { ...state, charts, isFetching }
      }
    },
    fetchGlobals: {
      method: 'GET',
      gerundName: 'fetchingGlobals',
      url: './globals',
      reduce: (state, response) => {
        const isFetching = response.status === 'pending'
        let globals = {}

        if (response.status === 'resolved') {
          globals = response.body
        }

        return { ...state, globals, isFetching }
      }
    }
  }
})
