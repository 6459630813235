// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as capacityEquipmentActions } from 'Resources/CapacityEquipmentResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from './Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers'

const { confirm } = Modal

class CapacityEquipmentConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    equipment: PropTypes.object,
    equipments: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    equipment: {},
    equipments: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchEquipmentsData()

    if (this.getContext() === 'single') {
      this.getEquipmentData().then(this.askUpdateEquipment)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchEquipmentsData = () => {
    this.props.actions.fetchCapacityEquipments().catch(() => {
      message.error(I18n.t('api.errors.equipment.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getEquipmentData = (equipment = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getCapacityEquipment(equipment)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.equipment.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyEquipment = (equipment, clearTableSelections) => {
    this.props.actions
      .archiveManyCapacityEquipment(equipment)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.equipment.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.equipment.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveEquipment = equipment => {
    this.props.actions
      .archiveCapacityEquipment(equipment)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.equipment.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.equipment.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyEquipment = (equipment, clearTableSelections) => {
    this.props.actions
      .archiveCapacityEquipments(equipment)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.equipment.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.equipment.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteEquipment = equipment => {
    this.props.actions
      .archiveCapacityEquipment(equipment)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.equipment.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.equipment.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un equipement
   */
  updateEquipment = (updatedEquipment, originalEquipment) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateCapacityEquipment({
          id: get(originalEquipment, 'id'),
          ...updatedEquipment
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchEquipmentsData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.equipment.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.equipment.update'))
        })
    })

  /**
   * CrÃ©ation d'un equipment
   */
  createEquipment = createdEquipment =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createCapacityEquipment(createdEquipment)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.equipment.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.equipment.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyEquipments = (...params) => {
    confirm({
      title: I18n.t('pages.equipment.modal.archive.multiple.title'),
      content: I18n.t('pages.equipment.modal.archive.multiple.content'),
      okText: I18n.t('pages.equipment.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.equipment.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveEquipment(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveEquipment = () => {
    const { equipment } = this.props

    confirm({
      title: I18n.t('pages.Equipment.modal.archive.single.title'),
      content: I18n.t('pages.equipment.modal.archive.single.content'),
      okText: I18n.t('pages.equipment.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.equipment.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveEquipment(equipment)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyEquipments = (...params) => {
    confirm({
      title: I18n.t('pages.equipment.modal.delete.multiple.title'),
      content: I18n.t('pages.equipment.modal.delete.multiple.content'),
      okText: I18n.t('pages.equipment.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.equipment.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.deleteManyEquipment(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteEquipment = () => {
    const { equipment } = this.props

    confirm({
      title: I18n.t('pages.equipment.modal.delete.single.title'),
      content: I18n.t('pages.equipment.modal.delete.single.content'),
      okText: I18n.t('pages.equipment.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.equipment.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteEquipment(equipment)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateEquipment = () => {
    this.setState({ openedModal: 'updateEquipment' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateEquipment = () => {
    this.setState({ openedModal: 'createEquipment' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateEquipment') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = equipment => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(equipment, 'id') })

    this.getEquipmentData(equipment)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateEquipment()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderCapacityEquipment(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.equipment.order')))
      .catch(() => message.error(I18n.t('api.errors.equipment.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      equipments,
      equipment
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getEquipmentData: this.getEquipmentData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateEquipment: this.askCreateEquipment,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateEquipment: this.askUpdateEquipment,
      askUpdateManyEquipments: this.askUpdateEquipment,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveEquipment: this.askArchiveEquipment,
      askArchiveManyEquipments: this.askArchiveManyEquipments,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteEquipment: this.askDeleteEquipment,
      askDeleteManyEquipments: this.askDeleteManyEquipments
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteEquipment

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={equipments}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateEquipment'
              ? this.updateEquipment
              : this.createEquipment
          }
          visible={includes(
            ['createEquipment', 'updateEquipment'],
            openedModal
          )}
          data={
            includes(['transition', 'updateEquipment'], openedModal)
              ? equipment
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CapacityEquipmentConstructor, 'defaultProps', {})

  return {
    equipments: defaultTo(
      get(state, 'capacityEquipment.items'),
      defaultProps.equipments
    ),
    equipment: defaultTo(
      get(state, 'capacityEquipment.item'),
      defaultProps.equipment
    ),
    isGathering:
      defaultTo(
        get(state, 'capacityEquipment.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'capacityEquipment.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'capacityEquipment.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...capacityEquipmentActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapacityEquipmentConstructor)
