// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { isNil, get, map } from 'lodash'
import { Tooltip, Tag } from 'antd'

// Helpers
import {
  dateSort,
  numberSort,
  filterPeriod,
  alphaSort
} from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'securityMeasure',
    kind: 'female'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    },
    // Actions groupÃ©es sur les lignes du tableau
    selection: {
      delete: {
        buttonType: 'danger'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.securityMeasures.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Autorise les lignes en drag and drop
  areRowsDraggable: true,

  // Colonnes du tableau
  columns: {
    name: {
      title: 'pages.securityMeasures.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'name')
    },
    isControlled: {
      title: 'pages.securityMeasures.fields.isControlled.title',
      dataIndex: 'isControlled',
      key: 'isControlled',
      render: isControlled => {
        const associatedColors = {
          false: 'volcano',
          true: 'green'
        }
        return !isNil(isControlled) ? (
          <Tooltip
            title={I18n.t(
              `pages.securityMeasures.fields.isControlled.options.${isControlled}.tooltip`
            )}
          >
            <Tag color={get(associatedColors, isControlled)}>
              {I18n.t(
                `pages.securityMeasures.fields.isControlled.options.${isControlled}.title`
              )}
            </Tag>
          </Tooltip>
        ) : (
          <Tag color='volcano'>
            {I18n.t(
              `pages.securityMeasures.fields.isControlled.options.false.title`
            )}
          </Tag>
        )
      },
      sorter: (...params) => numberSort(params, 'isControlled'),
      filters: map([true, false], isControlled => ({
        text: I18n.t(
          `pages.securityMeasures.fields.isControlled.options.${isControlled}.title`
        ),
        value: isControlled
      })),
      onFilter: (isControlled, measure) =>
        get(measure, 'isControlled') === isControlled
    },
    dateCreated: {
      title: 'pages.securityMeasures.fields.dateCreated.title',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: dateCreated =>
        !isNil(dateCreated) ? (
          <Tooltip title={moment(dateCreated).format('L')}>
            {moment(dateCreated).calendar()}
          </Tooltip>
        ) : (
          I18n.t('common.unknown.female')
        ),
      sorter: (...params) => dateSort(params, 'dateCreated'),
      ...filterPeriod(null, moment(), 'dateCreated')
    }
  }
}

export default specs
