// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Link } from '@react-navigation/web'
import { get, defaultTo, map, isEmpty } from 'lodash'
import { Modal, Dropdown, Menu, Icon, Avatar } from 'antd'

// Services
import NavigationServices from 'Services/NavigationServices'

// Resources
import { actions as authActions } from 'Resources/AuthResource'

// Components
import { Anchor } from 'Components'

const { confirm } = Modal

// Gestion du mode de dÃ©monstration
const isMockup = process.env.REACT_APP_MOCK_MODE
const appVersion = process.env.REACT_APP_VERSION

class CustomDropdown extends Component {
  static propTypes = {
    actions: PropTypes.object,
    profile: PropTypes.object,
    links: PropTypes.object
  }

  static defaultProps = {
    profile: {},
    links: {
      // Profile
      profile: {
        title: I18n.t('components.dropdown.profile'),
        icon: 'user',
        routeName: 'Profile'
      },
      // DÃ©connexion
      logout: {
        title: I18n.t('components.dropdown.logout'),
        icon: 'logout'
      }
    }
  }

  constructor(props) {
    super(props)

    const { links } = props

    this.state = { links }
  }

  componentDidMount = () => {
    const { profile } = this.props

    // PrÃ©paration des Ã©lÃ©ments de liens
    this._prepareLinks()

    if (isEmpty(profile) && !isMockup) {
      NavigationServices.navigate('Unauthenticated')
    }
  }

  /**
   * PrÃ©paration des Ã©lÃ©ments de liens
   * Ajout des actions et autres donnÃ©es dynamiques
   */
  _prepareLinks = () => {
    const { links } = this.state

    // Ajout de l'action pour le bouton
    links.logout.action = this._askLogout

    this.setState({ links })
  }

  /**
   * Permet de dÃ©connecter l'utilisateur
   * @private
   */
  _handleLogout = () => {
    this.props.actions.logoutAuth()
  }

  /**
   * Demande de suppression d'un Administrateur
   */
  _askLogout = () => {
    confirm({
      title: I18n.t('components.modals.logout.title'),
      content: I18n.t('components.modals.logout.content'),
      okText: I18n.t('components.modals.logout.okText'),
      cancelText: I18n.t('components.modals.logout.cancelText'),
      onOk: () => {
        this._handleLogout()
      },
      maskClosable: true
    })
  }

  _renderMenuLink = (
    { title = '', routeName = '', icon = false, action, ...params },
    index
  ) => {
    return (
      (action || routeName) && (
        <Menu.Item key={index}>
          {action ? (
            <Anchor onClick={action}>
              {icon && <Icon type={icon} />}
              <span>{title}</span>
            </Anchor>
          ) : (
            <Link routeName={routeName} params={params}>
              {icon && <Icon type={icon} />}
              <span>{title}</span>
            </Link>
          )}
        </Menu.Item>
      )
    )
  }

  _renderMenu = () => {
    const { links } = this.state

    return (
      <Menu>
        {map(links, this._renderMenuLink)}
        <div style={{ padding: '5px 12px' }}>
          <span style={{ color: '#b3b3b3' }}>Version {appVersion}</span>
        </div>
      </Menu>
    )
  }

  render() {
    const { profile } = this.props
    const { lastName, firstName, avatar } = profile

    const username =
      lastName && firstName
        ? `${lastName} ${firstName}`
        : I18n.t('common.welcome')

    return (
      <Dropdown trigger={['click']} overlay={this._renderMenu()}>
        <span className='ant-dropdown-link'>
          <Avatar src={avatar} className='avatar' icon='user' />
          <span className='username'>{username}</span>
        </span>
      </Dropdown>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CustomDropdown, 'defaultProps', {})

  return {
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile)
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomDropdown)
