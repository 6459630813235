// Libraries
const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  okText: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  actions: {},
  rows: {
    o2Measure: {
      title: 'pages.permits.fields.o2Measure.title',
      dataIndex: 'o2Measure',
      key: 'o2Measure',
      formField: {
        label: 'pages.permits.fields.o2Measure.title',
        placeholder: 'pages.permits.fields.o2Measure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIECenter: {
      title: 'pages.permits.fields.LIEMeasure.title.center',
      dataIndex: 'tankLIECenter',
      key: 'tankLIECenter',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.center',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEFirstPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.first',
      dataIndex: 'tankLIEFirstPoint',
      key: 'tankLIEFirstPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.first',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIESecondPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.second',
      dataIndex: 'tankLIESecondPoint',
      key: 'tankLIESecondPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.second',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEThirdPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.third',
      dataIndex: 'tankLIEThirdPoint',
      key: 'tankLIEThirdPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.third',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEFourthPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.fourth',
      dataIndex: 'tankLIEFourthPoint',
      key: 'tankLIEFourthPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.fourth',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    inletTemperature: {
      title: 'pages.permits.fields.inletTemperature.title',
      dataIndex: 'inletTemperature',
      key: 'inletTemperature',
      formField: {
        label: 'pages.permits.fields.inletTemperature.title',
        placeholder: 'pages.permits.fields.inletTemperature.placeholder'
      },
      empty: 'common.unknown.female'
    }
  }
}

export default specs
