// Libraries
import I18n from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card } from 'antd'
import { get, defaultTo, includes, map } from 'lodash'

// Resources
import { FormLayout } from 'Components'

class InformationTab extends Component {
  static propTypes = {
    // Gestion de l'interface du formulaire
    formLayout: PropTypes.object,

    // TÃ©moins de charge API
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.object,

    // DonnÃ©es de la vue
    data: PropTypes.object
  }

  static defaultProps = {
    // Gestion de l'interface du formulaire
    formLayout: {},

    // TÃ©moins de charge API
    loading: true,

    // Utilisateur connectÃ©
    profile: {},

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: {},

    // DonnÃ©es de la vue
    data: {}
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  /**
   * RÃ©sumÃ© d'informations sur le permis
   */
  renderHeader = () => {
    const { data: company } = this.props

    return (
      <header>
        <div className='resume'>
          {/* Information sur le permis */}
          <div className='information'>
            {/* Identifiant du permis */}
            <h2 className='company-id'>
              <span>{`${get(company, 'name')}`}</span>
            </h2>

            {/* Entreprise */}
            <p className='company-company'>
              {I18n.t('pages.companies.details.date', {
                companyName: get(company, 'companyName'),
                day: moment(get(company, 'dateCreated')).format('L'),
                hour: moment(get(company, 'dateCreated')).format('HH:mm')
              })}
            </p>
          </div>
        </div>
      </header>
    )
  }

  render() {
    const {
      formLayout,
      loading,
      profile,
      constructorFunctions,
      data: company
    } = this.props

    const isUserReader = includes(map(get(profile, 'roles'), 'name'), 'READER')

    formLayout.actions.delete.onClick = company =>
      defaultTo(get(constructorFunctions, 'askDeleteCompany'), () => {})(
        company
      )

    formLayout.actions.update.hidden = isUserReader
    formLayout.actions.delete.hidden = isUserReader

    return (
      <Card className='tab information'>
        {/* Rendu du rÃ©sumÃ© d'informations */}
        {this.renderHeader()}

        <FormLayout
          data={company}
          loading={loading}
          onChange={get(constructorFunctions, 'askUpdateCompany')}
          {...formLayout}
        />
      </Card>
    )
  }
}

export default InformationTab
