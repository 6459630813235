// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { get, map, includes, size } from 'lodash'
import { Button, Tabs, Icon, Dropdown, Menu } from 'antd'

// Onglets
import { Information, PermitsTab } from './Tabs'

// Styles
import './Styles/Single.less'
import {
  CapacityFormSpecs,
  CleanFormSpecs,
  FireFormSpecs,
  SearchFormSpecs
} from 'Views/Permits/Specs'

const { TabPane } = Tabs

class PermitSingle extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,
    isDownloading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // Navigation
    navigation: PropTypes.object,

    // DonnÃ©es de la vue
    data: PropTypes.object,

    // Structure de la formLayout
    FormSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,
    isDownloading: false,

    // DonnÃ©es de la vue
    data: {},

    // Structure de la formLayout
    FormSpecs: {}
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: '',
      previewImage: '',
      fileSingle: [],
      firePermit: null,
      searchPermit: null,
      capacityPermit: null,
      cleaningPermit: null,
      activeTab: 'information'
    }
  }

  componentDidMount = () => {
    // Lecteur de fichier
    this.fileReader = new FileReader()
  }

  handleTabClick = (key) => {
    const { activeTab } = this.state;
    if (activeTab === key) {
      switch (key) {
        case 'firePermits':
          this.setState({ firePermit: null })
          break;
        case 'searchPermits':
          this.setState({ searchPermit: null })
          break;

        case 'capacityPermits':
          this.setState({ capacityPermit: null })
          break;

        case 'cleaningPermits':
          this.setState({ cleaningPermit: null })
          break;

        default:
          this.setState({ activeTab: 'information'})
      }
    }
  };

  renderTabs = () => {
    const {
      data: permit,
      constructorFunctions,
      navigation,
      FormSpecs,
      profile,
      loading,
      isDownloading
    } = this.props

    // Peut contenir un permis feu ?
    const isFirePermitAllowed =
      includes(
        map(get(permit, 'associatedPermits'), 'name'),
        'Permis de feu'
      ) && get(permit, 'status') !== 'requested'

    const isSearchPermitAllowed =
      includes(
        map(get(permit, 'associatedPermits'), 'name'),
        'Permis de fouille'
      ) && get(permit, 'status') !== 'requested'

    const isCapacityPermitAllowed =
      includes(
        map(get(permit, 'associatedPermits'), 'name'),
        'Permis de travailler dans un endroit confinÃ© (capacitÃ©)'
      ) && get(permit, 'status') !== 'requested'

    const isCleaningPermitAllowed =
      includes(
        map(get(permit, 'associatedPermits'), 'name'),
        'Permis de nettoyage'
      ) && get(permit, 'status') !== 'requested'

    const isUserReader = includes(map(get(profile, 'roles'), 'name'), 'READER')
    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR')

    const { activeTab } = this.state;

    return (
      <Tabs
        className='permit-list'
        defaultActiveKey='information'
        type='card'
        //activeKey={activeTab}
        onChange={value => {
          this.setState({activeTab: value})
          get(constructorFunctions, 'handleTabChange')
          if (value === 'firePermits') this.setState({ firePermit: null })
          if (value === 'searchPermits') this.setState({ searchPermit: null })
          if (value === 'capacityPermits') this.setState({ capacityPermit: null })
          if (value === 'cleaningPermits') this.setState({ cleaningPermit: null })
        }}
      onTabClick={this.handleTabClick}
        tabBarExtraContent={
          !isUserReader &&
          !isUserExtern &&
          (isSearchPermitAllowed ||
            isFirePermitAllowed ||
            isCapacityPermitAllowed ||
            isCleaningPermitAllowed) &&
          includes(['waiting', 'opened', 'finished'], get(permit, 'status')) && (
            <Dropdown
              placement='bottomRight'
              trigger='click'
              overlay={
                <Menu>
                  {isFirePermitAllowed && (
                    <Menu.Item>
                      <Button
                        type='link'
                        onClick={get(
                          constructorFunctions,
                          'askCreateFirePermit'
                        )}
                      >
                        {I18n.t('pages.permits.actions.add.firePermit')}
                      </Button>
                    </Menu.Item>
                  )}
                  {isSearchPermitAllowed && (
                    <Menu.Item>
                      <Button
                        type='link'
                        onClick={get(
                          constructorFunctions,
                          'askCreateSearchPermit'
                        )}
                      >
                        {I18n.t('pages.permits.actions.add.searchPermit')}
                      </Button>
                    </Menu.Item>
                  )}
                  {isCleaningPermitAllowed && (
                    <Menu.Item>
                      <Button
                        type='link'
                        onClick={get(
                          constructorFunctions,
                          'askCreateCleaningPermit'
                        )}
                      >
                        {I18n.t('pages.permits.actions.add.cleaningPermit')}
                      </Button>
                    </Menu.Item>
                  )}
                  {isCapacityPermitAllowed && (
                    <Menu.Item>
                      <Button
                        type='link'
                        onClick={get(
                          constructorFunctions,
                          'askCreateCapacityPermit'
                        )}
                      >
                        {I18n.t('pages.permits.actions.add.capacityPermit')}
                      </Button>
                    </Menu.Item>
                  )}
                </Menu>
              }
            >
              <Button icon='plus'>
                {I18n.t('pages.permits.actions.add.permit')}
              </Button>
            </Dropdown>
          )
        }
      >
        {/* Informations */}
        <TabPane
          tab={
            <span className={`permit permit-${get(permit, 'status')}`}>
              {I18n.t('pages.permits.tabs.information.title')}
            </span>
          }
          key='information'
        >
          <Information
            data={permit}
            navigation={navigation}
            profile={profile}
            loading={loading}
            isDownloading={isDownloading}
            constructorFunctions={constructorFunctions}
            formLayout={FormSpecs}
          />
        </TabPane>

        {/* Permis de feu */}
        {isFirePermitAllowed && (
          <TabPane
            tab={
              <span className={`permit`}>
                <Icon type='fire' />
                {I18n.t('pages.permits.tabs.firePermit.title', {
                  ...permit,
                  total: size(get(permit, 'firePermits'))
                })}
              </span>
            }
            key='firePermits'
          >
            <PermitsTab
              loading={loading}
              permits={get(permit, 'firePermits')}
              totalPermits={size(get(permit, 'firePermits'))}
              isDownloading={isDownloading}
              openedPermit={this.state.firePermit}
              onPermitChange={permit => {
                this.setState({ firePermit: permit })
                get(constructorFunctions, 'handleTabChange')(permit.id)
              }}
              type='firePermit'
              formLayout={FireFormSpecs}
              constructorFunctions={constructorFunctions}
            />
          </TabPane>
        )}

        {/* Permis de fouille */}
        {isSearchPermitAllowed && (
          <TabPane
            tab={
              <span className={`permit`}>
                <Icon type='search' />
                {I18n.t('pages.permits.tabs.searchPermit.title', {
                  ...permit,
                  total: size(get(permit, 'searchPermits'))
                })}
              </span>
            }
            key='searchPermits'
          >
            <PermitsTab
              loading={loading}
              permits={get(permit, 'searchPermits')}
              totalPermits={size(get(permit, 'searchPermits'))}
              isDownloading={isDownloading}
              openedPermit={this.state.searchPermit}
              onPermitChange={permit => {
                this.setState({ searchPermit: permit })
                get(constructorFunctions, 'handleTabChange')(permit.id)
              }}
              type='searchPermit'
              formLayout={SearchFormSpecs}
              constructorFunctions={constructorFunctions}
            />
          </TabPane>
        )}

        {/* Permis de nettoyage */}
        {isCleaningPermitAllowed && (
          <TabPane
            tab={
              <span className={`permit`}>
                <Icon type='delete' />
                {I18n.t('pages.permits.tabs.cleaningPermit.title', {
                  ...permit,
                  total: size(get(permit, 'cleaningPermits'))
                })}
              </span>
            }
            key='cleaningPermits'
          >
            <PermitsTab
              loading={loading}
              permits={get(permit, 'cleaningPermits')}
              totalPermits={size(get(permit, 'cleaningPermits'))}
              isDownloading={isDownloading}
              openedPermit={this.state.cleaningPermit}
              onPermitChange={permit => {
                this.setState({ cleaningPermit: permit })
                get(constructorFunctions, 'handleTabChange')(permit.id)
              }}
              type='cleaningPermit'
              formLayout={CleanFormSpecs}
              constructorFunctions={constructorFunctions}
            />
          </TabPane>
        )}

        {/* Permis de capacitÃ© */}
        {isCapacityPermitAllowed && (
          <TabPane
            tab={
              <span className={`permit`}>
                <Icon type='carry-out' />
                {I18n.t('pages.permits.tabs.capacityPermit.title', {
                  ...permit,
                  total: size(get(permit, 'capacityPermits'))
                })}
              </span>
            }
            key='capacityPermits'
          >
            <PermitsTab
              loading={loading}
              permits={get(permit, 'capacityPermits')}
              totalPermits={size(get(permit, 'capacityPermits'))}
              isDownloading={isDownloading}
              openedPermit={this.state.capacityPermit}
              onPermitChange={permit => {
                this.setState({ capacityPermit: permit })
                get(constructorFunctions, 'handleTabChange')(permit.id)
              }}
              type='capacityPermit'
              formLayout={CapacityFormSpecs}
              constructorFunctions={constructorFunctions}
            />
          </TabPane>
        )}
      </Tabs>
    )
  }

  render() {
    return (
      <main className='screen permits permits-single'>
        {/* DonnÃ©es ordonnÃ©es en onglets */}
        {this.renderTabs()}
      </main>
    )
  }
}

export default PermitSingle
