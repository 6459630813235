// Libraries
import I18n from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Card, Icon, Tooltip, Tag, Button, Dropdown, Menu } from 'antd'
import {
  get,
  isNil,
  head,
  split,
  last,
  isEmpty,
  includes,
  map,
  defaultTo
} from 'lodash'

// Resources
import { FormLayout } from 'Components'

class InformationTab extends Component {
  static propTypes = {
    // Gestion de l'interface du formulaire
    formLayout: PropTypes.object,

    // TÃ©moins de charge API
    loading: PropTypes.bool,
    isDownloading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.object,

    // DonnÃ©es de la vue
    data: PropTypes.object,
    type: PropTypes.string
  }

  static defaultProps = {
    // Gestion de l'interface du formulaire
    formLayout: {},

    // TÃ©moins de charge API
    loading: true,
    isDownloading: false,

    // Utilisateur connectÃ©
    profile: {},

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: {},

    // DonnÃ©es de la vue
    data: {},
    type: 'workingPermit'
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {}

  renderButtons = () => {
    const {
      constructorFunctions,
      data: permit,
      profile,
      type,
      isDownloading
    } = this.props
    const buttons = []

    const isUserReader = includes(map(get(profile, 'roles'), 'name'), 'READER')
    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER')
    const rolesName = map(get(profile, 'roles'), ({ name }) => name)

    buttons.push(
      <Button
        key='download'
        loading={isDownloading}
        onClick={() => {
          switch (type) {
            case 'firePermit':
              get(constructorFunctions, 'handleRevisionPDF')(
                'firePermit',
                get(permit, 'id'),
                get(permit, 'revision')
              )
              break
            case 'searchPermit':
              get(constructorFunctions, 'handleRevisionPDF')(
                'searchPermit',
                get(permit, 'id'),
                get(permit, 'revision')
              )
              break
            case 'capacityPermit':
              get(constructorFunctions, 'handleRevisionPDF')(
                'capacityPermit',
                get(permit, 'id'),
                get(permit, 'revision')
              )
              break
            case 'cleaningPermit':
              get(constructorFunctions, 'handleRevisionPDF')(
                'cleaningPermit',
                get(permit, 'id'),
                get(permit, 'revision')
              )
              break
            default:
              get(constructorFunctions, 'handleRevisionPDF')(
                'permit',
                get(permit, 'id'),
                get(permit, 'revision')
              )
              break
          }
        }}
        icon='download'
        type='primary'
      >
        {I18n.t('pages.permits.actions.display')}
      </Button>
    )

    const isUserAssociatedUser =
      get(permit, 'companyLeader.id') === get(profile, 'id')
    let isFullySignedBySDLP
    switch (type) {
      case 'firePermit':
        isFullySignedBySDLP =
          get(permit, 'isFullySignedBySDLP') && get(permit, 'permit.')
        break
      case 'searchPermit':
        isFullySignedBySDLP = get(permit, 'permit.isFullySignedBySDLP')
        break
      case 'capacityPermit':
        isFullySignedBySDLP = get(permit, 'permit.isFullySignedBySDLP')
        break
      default:
        isFullySignedBySDLP = get(permit, 'isSupervisorSigned')
        break
    }

    const canBeSignedByExtern = isUserAssociatedUser && isFullySignedBySDLP

    if (!includes(rolesName, 'READER')) {
      // Signature du document
      // Lorsque le permis n'est pas encore signÃ©, il est possible de le tÃ©lÃ©charger, le signer et importer le scan
      // todo: check if main permit is signed
      if (
        !get(permit, 'isSigned') && 
        get(permit, 'isMainPermitSigned') &&
        !includes(['waiting', 'closed', 'requested'], get(permit, 'status')) &&
        (!isUserExtern || canBeSignedByExtern)
      ) {
        buttons.push(
          <Button
            key='sign'
            icon='form'
            type='primary'
            onClick={() => {
              switch (type) {
                case 'firePermit':
                  get(constructorFunctions, 'askSignPermit')('firePermit')
                  break
                case 'searchPermit':
                  get(constructorFunctions, 'askSignPermit')('searchPermit')
                  break
                case 'capacityPermit':
                  get(constructorFunctions, 'askSignPermit')('capacityPermit')
                  break
                case 'cleaningPermit':
                  get(constructorFunctions, 'askSignPermit')('cleaningPermit')
                  break
                default:
                  get(constructorFunctions, 'askSignPermit')('permit')
                  break
              }
            }}
          >
            {I18n.t('pages.permits.actions.sign')}
          </Button>
        )
      }

      // Controle du permis de capacitÃ©
      if (
        type === 'capacityPermit' &&
        get(permit, 'isSigned') === false &&
        !isUserExtern
      ) {
        buttons.push(
          <Button
            key='control'
            icon='edit'
            onClick={get(constructorFunctions, 'askCapacityControl')}
          >
            {I18n.t('pages.permits.actions.controlCapacity')}
          </Button>
        )
      }

      // Controle du permis de nettoyage
      if (
        type === 'cleaningPermit' &&
        get(permit, 'isSigned') === false &&
        !isUserExtern
      ) {
        buttons.push(
          <Dropdown
            key='control'
            placement='bottomRight'
            trigger='click'
            overlay={
              <Menu>
                <Menu.Item>
                  <Button
                    type='link'
                    onClick={get(
                      constructorFunctions,
                      'askCleaningPhase1Control'
                    )}
                  >
                    {I18n.t('pages.permits.actions.controlCleaning.phase1')}
                  </Button>
                </Menu.Item>
                <Menu.Item>
                  <Button
                    type='link'
                    onClick={get(
                      constructorFunctions,
                      'askCleaningPhase2Control'
                    )}
                  >
                    {I18n.t('pages.permits.actions.controlCleaning.phase2')}
                  </Button>
                </Menu.Item>
              </Menu>
            }
          >
            <Button icon='edit'>
              {I18n.t('pages.permits.actions.controlCleaning.title')}
            </Button>
          </Dropdown>
        )
      }

      // Annulation du permit en attente
      // Lâutilisateur peut annuler le permis seulement lorsque le statut est âen attenteâ.
      // Avant lâannulation du permis, on lui demande dâindiquer le motif dâannulation en saisie libre.
      if (get(permit, 'status') === 'waiting' && !isUserExtern) {
        buttons.push(
          <Button
            key='cancel'
            icon='close-circle'
            type='danger'
            onClick={get(constructorFunctions, 'askCancelPermit')}
          >
            {I18n.t('pages.permits.actions.cancel')}
          </Button>
        )
      }

      // Cloture du permit terminÃ©
      // Lorsque le permis est terminÃ© et contrÃ´lÃ©, alors un bouton âclÃ´turerâ est actif.
      // En cliquant dessus, le permis passe en statut âclÃ´turÃ©â.
      // La clÃ´ture est possible uniquement si les permis feux/fouille/nettoyage/capacitÃ© associÃ©s sont clÃ´turÃ©s.
      if (
        (get(permit, 'status') === 'opened' || get(permit, 'status') === 'finished') 
         &&
        ((isEmpty(get(permit, 'firePermits')) && isEmpty(get(permit, 'searchPermits')) && isEmpty(get(permit, 'cleaningPermits')) && isEmpty(get(permit, 'capacityPermits')))
        ||
          get(permit, 'firePermits').every(
            currentValue =>
              get(currentValue, 'status') === 'closed' ||
              get(currentValue, 'status') === 'cancelled'
          )) &&
        (isEmpty(get(permit, 'searchPermits')) ||
          get(permit, 'searchPermits').every(
            currentValue =>
              get(currentValue, 'status') === 'closed' ||
              get(currentValue, 'status') === 'cancelled'
          )) &&
        (isEmpty(get(permit, 'cleaningPermits')) ||
          get(permit, 'cleaningPermits').every(
            currentValue =>
              get(currentValue, 'status') === 'closed' ||
              get(currentValue, 'status') === 'cancelled'
          )) &&
        (isEmpty(get(permit, 'capacityPermits')) ||
          get(permit, 'capacityPermits').every(
            currentValue =>
              get(currentValue, 'status') === 'closed' ||
              get(currentValue, 'status') === 'cancelled'
          )) &&
        !isUserExtern
      ) {
        buttons.push(
          <Button
            key='close'
            icon='close-circle'
            type='danger'
            onClick={() => get(constructorFunctions, 'askClosePermit')(type)}
          >
            {type === 'firePermit'
              ? I18n.t('pages.permits.actions.controlAndClose')
              : I18n.t('pages.permits.actions.close')}
          </Button>
        )
      }

      // ContrÃ´le de fin de chantier
      // Lorsque le permis est en statut âterminÃ©â, un bouton permet dâindiquer que le contrÃ´le de fin de chantier a Ã©tÃ© effectuÃ© : indiquer la date et lâheure. (champ obligatoire)
      // Action uniquement active si le permis a Ã©tÃ© signÃ©.
      else if (
        get(permit, 'status') === 'finished' &&
        get(permit, 'isSigned') &&
        !get(permit, 'isControlled') &&
        !isUserExtern
      ) {
        buttons.push(
          <Button
            key='close'
            icon='close-circle'
            type='danger'
            onClick={() => get(constructorFunctions, 'askClosePermit')(type)}
          >
            {type !== 'permit'
              ? I18n.t('pages.permits.actions.controlAndClose')
              : I18n.t('pages.permits.actions.close')}
          </Button>
        )
      }

      permit.status === 'requested' &&
        !isUserExtern &&
        !isUserReader &&
        buttons.push(
          <Button
            key='confirmRequest'
            icon='check'
            onClick={get(constructorFunctions, 'askConfirmRequest')}
          >
            {I18n.t(`pages.permits.actions.confirmRequest`)}
          </Button>
        )

      //type === 'workingPermit' &&
        permit.status !== 'requested' &&
        !isUserExtern &&
        buttons.push(
          <Button
            key='duplicate'
            icon='copy'
            onClick={() => get(constructorFunctions, 'askDuplicatePermit')(type)}
          >
            {I18n.t('pages.permits.actions.duplicate')}
          </Button>
        )
    }

    return buttons
  }

  /**
   * RÃ©sumÃ© d'informations sur le permis
   */
  renderHeader = () => {
    const { type, data: permit } = this.props

    const associatedColors = {
      waiting: '',
      cancelled: 'red',
      closed: 'blue',
      finished: 'orange',
      opened: 'green',
      excellent: 'green',
      good: 'gold',
      poor: 'volcano',
      requested: 'cyan'
    }

    return (
      <header>
        <div className='resume'>
          {/* Information sur le permis */}
          <div className='information'>
            {/* Identifiant du permis */}
            <h2 className='permit-id'>
              {/* IcÃ´ne du permis */}
              <Icon
                type={
                  type === 'firePermit'
                    ? 'fire'
                    : type === 'searchPermit'
                    ? 'search'
                    : type === 'capacityPermit'
                    ? 'carry-out'
                    : 'file-done'
                }
              />

              {/* ID du permis */}
              <span>{`#${get(permit, 'permitID')}`}</span>

              {/* Statut du permis */}
              {!isNil(get(permit, 'status')) && (
                <Tooltip title={get(permit, 'reason.reason')}>
                  <Tag
                    className='permit-status'
                    color={get(associatedColors, get(permit, 'status'))}
                  >
                    {get(permit, 'status') === 'finished' && (
                      <Icon type='warning' />
                    )}
                    {I18n.t(
                      `pages.permits.fields.status.options.${get(
                        permit,
                        'status'
                      )}.title`
                    )}
                  </Tag>
                </Tooltip>
              )}
            </h2>

            {/* Entreprise */}
            <p className='permit-company'>
              {I18n.t('pages.permits.details.companyName', {
                company: get(permit, 'company'),
                day: moment(get(permit, 'dateCreated')).format('L'),
                hour: moment(get(permit, 'dateCreated')).format('HH:mm')
              })}
            </p>

            {/* Jour et heures de validitÃ© */}
            {!isNil(get(permit, 'validityDate')) &&
              !isNil(get(permit, 'validityHours')) &&
              moment(get(permit, 'validityDate')).isValid() && (
                <p className='permit-validityDate'>
                  {I18n.t('pages.permits.details.validityDateHours', {
                    date: moment(get(permit, 'validityDate')).format('L'),
                    start: head(split(get(permit, 'validityHours'), ' - ')),
                    stop: last(split(get(permit, 'validityHours'), ' - '))
                  })}
                </p>
              )}

            {/* PÃ©riode de validitÃ© */}
            {!isNil(get(permit, 'validityPeriod')) &&
              moment(get(permit, 'validityDate.startDate')).isValid() &&
              moment(get(permit, 'validityDate.endDate')).isValid() && (
                <p className='permit-validityPeriod'>
                  {I18n.t('pages.permits.details.validityPeriod', {
                    startDate: moment(
                      get(permit, 'validityPeriod.startDate')
                    ).format('L'),
                    endDate: moment(
                      get(permit, 'validityPeriod.endDate')
                    ).format('L')
                  })}
                </p>
              )}

            {/* Date de signature */}
            {get(permit, 'isSigned') && (
              <p className='permit-signed'>
                {I18n.t('pages.permits.details.dateSigned', {
                  day: moment(get(permit, 'dateSigned')).format('L'),
                  hour: moment(get(permit, 'dateSigned')).format('HH:mm')
                })}
              </p>
            )}

            {/* Date de contrÃ´le de chantier */}
            {get(permit, 'isControlled') && (
              <p className='permit-controlled'>
                {I18n.t('pages.permits.details.dateControlled', {
                  day: moment(get(permit, 'dateControlled')).format('L'),
                  hour: moment(get(permit, 'dateControlled')).format('HH:mm')
                })}
              </p>
            )}
          </div>
        </div>

        <div className='buttons'>{this.renderButtons()}</div>
      </header>
    )
  }

  render() {
    const {
      formLayout,
      loading,
      profile,
      type,
      constructorFunctions,
      data: permit
    } = this.props

    const isUserReader = includes(map(get(profile, 'roles'), 'name'), 'READER')
    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER')

    // Masquage du bouton de modification si le permis n'est plus actif
    formLayout.actions.update.hidden =
      isUserReader ||
      isUserExtern ||
      includes(['closed', 'cancelled'], get(permit, 'status')) ||
      get(permit, 'isSigned') ||
      (get(permit, 'status') !== 'requested' && isUserExtern)

    if (type === 'firePermit') {
      permit.validityDate = defaultTo(
        get(permit, 'validityPeriod.startDate'),
        moment()
      )
    }
    

    return (
      <Card className='tab information'>
        {/* Rendu du rÃ©sumÃ© d'informations */}
        {this.renderHeader()}

        <FormLayout
          data={permit}
          loading={loading}
          onChange={value => {
            switch (type) {
              case 'firePermit':
                get(constructorFunctions, 'askUpdateFirePermit')(value)
                break
              case 'searchPermit':
                get(constructorFunctions, 'askUpdateSearchPermit')(value)
                break
              case 'capacityPermit':
                get(constructorFunctions, 'askUpdateCapacityPermit')(value)
                break
              case 'cleaningPermit':
                get(constructorFunctions, 'askUpdateCleaningPermit')(value)
                break
              default:
                get(constructorFunctions, 'askUpdatePermit')(value)
                break
            }
          }}
          {...formLayout}
        />
      </Card>
    )
  }
}

export default InformationTab
