// Libraries
import { map, isNil, isEmpty } from 'lodash'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.capacityPermit.create.single.title',
    create: 'pages.permits.modal.capacityPermit.create.single.title'
  },
  okText: {
    update: 'pages.permits.modal.capacityPermit.create.single.okText',
    create: 'pages.permits.modal.capacityPermit.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    }
  ],
  validateEachSteps: true,
  rows: {
    permitID: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.permitID.title',
        required: 'pages.permits.fields.permitID.requiredMessage',
        placeholder: 'pages.permits.fields.permitID.placeholder',
        questionMark: 'pages.permits.fields.permitID.questionMark'
      },
      empty: 'common.unknown.female'
    },
    validityDate: {
      title: 'pages.permits.fields.validityDate.title',
      dataIndex: 'validityDate',
      key: 'validityDate',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'datetime',
        label: 'pages.permits.fields.validityDate.title',
        required: 'pages.permits.fields.validityDate.requiredMessage',
        placeholder: 'pages.permits.fields.validityDate.placeholder'
      },
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? company.name : undefined,
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionMark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    tank: {
      title: 'pages.permits.fields.tank.title',
      dataIndex: 'tank',
      key: 'tank',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.tank.title',
        required: 'pages.permits.fields.tank.requiredMessage',
        placeholder: 'pages.permits.fields.tank.placeholder'
      },
      empty: 'common.unknown.male'
    },
    degassingCertificateDate: {
      title: 'pages.permits.fields.degassingCertificateDate.title',
      dataIndex: 'degassingCertificateDate',
      key: 'degassingCertificateDate',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'date',
        label: 'pages.permits.fields.degassingCertificateDate.title',
        required:
          'pages.permits.fields.degassingCertificateDate.requiredMessage',
        placeholder: 'pages.permits.fields.degassingCertificateDate.placeholder'
      },
      empty: 'common.unknown.female'
    },
    degassingCompany: {
      title: 'pages.permits.fields.degassingCompany.title',
      dataIndex: 'degassingCompany',
      key: 'degassingCompany',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.degassingCompany.title',
        placeholder: 'pages.permits.fields.degassingCompany.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? company.name : undefined,
      empty: 'common.unknown.male'
    },
    capacityPermitSecurityMeasures: {
      title: 'pages.permits.fields.securityMeasures.title',
      dataIndex: 'capacityPermitSecurityMeasures',
      key: 'capacityPermitSecurityMeasures',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.securityMeasures.title',
        placeholder: 'pages.permits.fields.securityMeasures.placeholder',
        format: securityMeasure =>
          !isNil(securityMeasure) && !isEmpty(securityMeasure)
            ? map(securityMeasure, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    unlistedSecurityMeasure: {
      title: 'pages.permits.fields.securityMeasures.unlisted.title',
      dataIndex: 'unlistedSecurityMeasures',
      key: 'unlistedSecurityMeasures',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            capacityPermitSecurityMeasures: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.securityMeasures.unlisted.title',
        required:
          'pages.permits.fields.securityMeasures.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.securityMeasures.unlisted.placeholder',
        format: securityMeasure =>
          !isNil(securityMeasure) && !isEmpty(securityMeasure)
            ? map(securityMeasure, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    capacityPermitEquipments: {
      title: 'pages.permits.fields.equipments.title',
      dataIndex: 'capacityPermitEquipments',
      key: 'capacityPermitEquipments',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.equipments.title',
        placeholder: 'pages.permits.fields.equipments.placeholder',
        format: equipment =>
          !isNil(equipment) && !isEmpty(equipment)
            ? map(equipment, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedCapacityEquipment: {
      title: 'pages.permits.fields.equipments.unlisted.title',
      dataIndex: 'unlistedEquipment',
      key: 'unlistedEquipment',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            capacityPermitEquipments: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.equipments.unlisted.title',
        required: 'pages.permits.fields.equipments.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.equipments.unlisted.placeholder',
        format: equipment =>
          !isNil(equipment) && !isEmpty(equipment)
            ? map(equipment, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    capacityPermitAdditionalProvisions: {
      title: 'pages.permits.fields.additionalProvisions.title',
      dataIndex: 'capacityPermitAdditionalProvisions',
      key: 'capacityPermitAdditionalProvisions',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.additionalProvisions.title',
        placeholder: 'pages.permits.fields.additionalProvisions.placeholder',
        format: additionalProvision =>
          !isNil(additionalProvision) && !isEmpty(additionalProvision)
            ? map(additionalProvision, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    unlistedAdditionalProvision: {
      title: 'pages.permits.fields.additionalProvisions.unlisted.title',
      dataIndex: 'unlistedAdditionalProvision',
      key: 'unlistedAdditionalProvision',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            capacityPermitAdditionalProvisions: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.additionalProvisions.unlisted.title',
        required:
          'pages.permits.fields.additionalProvisions.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.additionalProvisions.unlisted.placeholder',
        format: additionalProvision =>
          !isNil(additionalProvision) && !isEmpty(additionalProvision)
            ? map(additionalProvision, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    depotHead: {
      title: 'pages.permits.fields.depotHead.title',
      dataIndex: 'depotHead',
      key: 'depotHead',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.depotHead.title',
        required: 'pages.permits.fields.depotHead.requiredMessage',
        placeholder: 'pages.permits.fields.depotHead.placeholder',
        format: depotHead =>
          !isNil(depotHead) && !isEmpty(depotHead) ? depotHead.id : undefined
      },
      render: depotHead =>
        !isNil(depotHead) && !isEmpty(depotHead) ? depotHead.name : undefined,
      empty: 'common.unknown.male'
    },
    depotSupervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'depotSupervisor',
      key: 'depotSupervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      render: supervisor =>
        !isNil(supervisor) && !isEmpty(supervisor)
          ? supervisor.name
          : undefined,
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companyLeader.title',
        required: 'pages.permits.fields.companyLeader.requiredMessage',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        type: 'searchSelect',
        format: companyLeader =>
          !isNil(companyLeader) && !isEmpty(companyLeader)
            ? companyLeader.id
            : undefined
      },
      render: leader =>
        !isNil(leader) && !isEmpty(leader) ? leader.name : undefined,
      empty: 'common.unknown.male'
    },
    participants: {
      title: 'pages.permits.fields.participants.title',
      dataIndex: 'participants',
      key: 'participants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.participants.title',
        placeholder: 'pages.permits.fields.participants.placeholder',
        format: participants =>
          !isNil(participants) && !isEmpty(participants)
            ? map(participants, 'id')
            : undefined
      },
      render: participant =>
        !isNil(participant) && !isEmpty(participant)
          ? participant.name
          : undefined,
      empty: 'common.unknown.male'
    },
    securityParticipants: {
      title: 'pages.permits.fields.securityParticipants.title',
      dataIndex: 'securityParticipants',
      key: 'securityParticipants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.securityParticipants.title',
        placeholder: 'pages.permits.fields.securityParticipants.placeholder',
        format: participants =>
          !isNil(participants) && !isEmpty(participants)
            ? map(participants, 'id')
            : undefined
      },
      render: participant =>
        !isNil(participant) && !isEmpty(participant)
          ? participant.name
          : undefined,
      empty: 'common.unknown.male'
    }
  }
}

export default specs
