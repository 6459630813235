const specs = {
  width: 620,
  title: {
    update: 'pages.employees.modal.update.single.title',
    create: 'pages.employees.modal.create.single.title'
  },
  okText: {
    update: 'pages.employees.modal.update.single.okText',
    create: 'pages.employees.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.employees.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        label: 'pages.employees.fields.name.title',
        required: 'pages.employees.fields.name.requiredMessage',
        placeholder: 'pages.employees.fields.name.placeholder'
      },
      empty: 'common.unknown.9male'
    },
    email: {
      title: 'pages.employees.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      formField: {
        label: 'pages.employees.fields.email.title',
        placeholder: 'pages.employees.fields.email.placeholder',
        pattern: {
          type: 'email',
          message: 'pages.employees.fields.email.pattern'
        }
      },
      empty: 'common.unknown.female'
    },
    job: {
      title: 'pages.employees.fields.job.title',
      dataIndex: 'job',
      key: 'job',
      formField: {
        label: 'pages.employees.fields.job.title',
        placeholder: 'pages.employees.fields.job.placeholder'
      },
      empty: 'common.unknown.male'
    },
    contractType: {
      title: 'pages.employees.fields.contractType.title',
      dataIndex: 'contractType',
      key: 'contractType',
      formField: {
        label: 'pages.employees.fields.contractType.title',
        placeholder: 'pages.employees.fields.contractType.placeholder'
      },
      empty: 'common.unknown.male'
    },
    telephone: {
      title: 'pages.employees.fields.telephone.title',
      dataIndex: 'telephone',
      key: 'telephone',
      formField: {
        label: 'pages.employees.fields.telephone.title',
        placeholder: 'pages.employees.fields.telephone.placeholder',
        pattern: {
          pattern: '(\\+33|0)[0-9]{9}',
          message: 'pages.employees.fields.telephone.pattern'
        }
      },
      empty: 'common.unknown.female'
    },
    note: {
      title: 'pages.employees.fields.note.title',
      dataIndex: 'note',
      key: 'note',
      formField: {
        label: 'pages.employees.fields.note.title',
        placeholder: 'pages.employees.fields.note.placeholder',
        type: 'textarea'
      },
      empty: 'common.unknown.female'
    },
    user: {
      title: 'pages.employees.fields.companyUser.title',
      dataIndex: 'user',
      key: 'user',
      formField: {
        type: 'select',
        label: 'pages.employees.fields.companyUser.title',
        placeholder: 'pages.employees.fields.companyUser.placeholder'
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
