// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { isNil } from 'lodash'
import { Tooltip, Tag } from 'antd'

// Helpers
import {
  dateSort,
  filterPeriod,
  alphaSort
} from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'habilitation',
    kind: 'male'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    },
    // Actions groupÃ©es sur les lignes du tableau
    /*
    selection: {
      delete: {
        buttonType: 'danger'
      }
    }*/
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.habilitations.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Autorise les lignes en drag and drop
  areRowsDraggable: false,

  // Colonnes du tableau
  columns: {
    EmployeeName: {
      title: 'pages.employees.fields.name.title',
      dataIndex: 'employeeName',
      key: 'EmployeeName',
      render: EmployeeName => (!isNil(EmployeeName) ? EmployeeName : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'EmployeeName')
    },
    name: {
      title: 'pages.habilitations.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'name')
    },
    endDate: {
      title: 'pages.habilitations.fields.endDate.title',
      dataIndex: 'endDate',
      key: 'endDate',
      render: endDate =>
        !isNil(endDate) ? (
          <Tooltip title={moment(endDate).format('L')}>
            {moment(endDate).calendar()}
          </Tooltip>
        ) : (
          I18n.t('common.unknown.female')
        ),
      sorter: (...params) => dateSort(params, 'endDate'),
      ...filterPeriod(null, moment(), 'endDate')
    },
    isValid: {
      title: 'pages.habilitations.fields.isValid.title',
      dataIndex: 'isValid',
      key: 'isValid',
      render: isValid => (!isNil(isValid) ? 
        isValid ? 
          <Tag color={'green'}>
            
            {I18n.t('pages.habilitations.fields.isValid.OK')}
          </Tag>:
          <Tag color={'orange'}>
            {I18n.t('pages.habilitations.fields.isValid.expired')}
          </Tag> 
       : I18n.t('common.unknown.male')),
    },
    proofFile: {
      title: 'pages.habilitations.fields.proofFile.title',
      dataIndex: 'proofFile',
      key: 'proofFile'
    }
  }
}

export default specs
