// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as materialActions } from 'Resources/MaterialResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/Materials/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class MaterialsConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    material: PropTypes.object,
    materials: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    material: {},
    materials: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchMaterialsData()

    if (this.getContext() === 'single') {
      this.getMaterialData().then(this.askUpdateMaterial)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchMaterialsData = () => {
    this.props.actions.fetchMaterials().catch(() => {
      message.error(I18n.t('api.errors.material.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getMaterialData = (material = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getMaterial(material)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.material.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyMaterial = (material, clearTableSelections) => {
    this.props.actions
      .archiveManyMaterial(material)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.material.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.material.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveMaterial = material => {
    this.props.actions
      .archiveMaterial(material)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.material.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.material.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyMaterial = (material, clearTableSelections) => {
    this.props.actions
      .archiveMaterials(material)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.material.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.material.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteMaterial = material => {
    this.props.actions
      .archiveMaterial(material)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.material.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.material.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un material
   */
  updateMaterial = (updatedMaterial, originalMaterial) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateMaterial({
          id: get(originalMaterial, 'id'),
          ...updatedMaterial
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.material.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.material.update'))
        })
    })

  /**
   * CrÃ©ation d'un material
   */
  createMaterial = createdMaterial =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createMaterial(createdMaterial)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.material.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.material.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyMaterials = (...params) => {
    confirm({
      title: I18n.t('pages.materials.modal.archive.multiple.title'),
      content: I18n.t('pages.materials.modal.archive.multiple.content'),
      okText: I18n.t('pages.materials.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.materials.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveMaterial(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveMaterial = () => {
    const { material } = this.props

    confirm({
      title: I18n.t('pages.materials.modal.archive.single.title'),
      content: I18n.t('pages.materials.modal.archive.single.content'),
      okText: I18n.t('pages.materials.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.materials.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveMaterial(material)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyMaterials = (...params) => {
    confirm({
      title: I18n.t('pages.materials.modal.delete.multiple.title'),
      content: I18n.t('pages.materials.modal.delete.multiple.content'),
      okText: I18n.t('pages.materials.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.materials.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.deleteManyMaterial(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteMaterial = () => {
    const { material } = this.props

    confirm({
      title: I18n.t('pages.materials.modal.delete.single.title'),
      content: I18n.t('pages.materials.modal.delete.single.content'),
      okText: I18n.t('pages.materials.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.materials.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteMaterial(material)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateMaterial = () => {
    this.setState({ openedModal: 'updateMaterial' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateMaterial = () => {
    this.setState({ openedModal: 'createMaterial' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateMaterial') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = material => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(material, 'id') })

    this.getMaterialData(material)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateMaterial()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('Material', { id: get(material, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderMaterial(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.material.order')))
      .catch(() => message.error(I18n.t('api.errors.material.order')))
  }
  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      materials,
      material
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getMaterialData: this.getMaterialData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateMaterial: this.askCreateMaterial,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateMaterial: this.askUpdateMaterial,
      askUpdateManyMaterials: this.askUpdateMaterial,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveMaterial: this.askArchiveMaterial,
      askArchiveManyMaterials: this.askArchiveManyMaterials,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteMaterial: this.askDeleteMaterial,
      askDeleteManyMaterials: this.askDeleteManyMaterials,

      // RÃ©initialisation du mot de passe d'un material
      askResetPasswordMaterial: this.askResetPasswordMaterial
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteMaterial

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={materials}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateMaterial'
              ? this.updateMaterial
              : this.createMaterial
          }
          visible={includes(['createMaterial', 'updateMaterial'], openedModal)}
          data={
            includes(['transition', 'updateMaterial'], openedModal)
              ? material
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(MaterialsConstructor, 'defaultProps', {})

  return {
    materials: defaultTo(get(state, 'material.items'), defaultProps.materials),
    material: defaultTo(get(state, 'material.item'), defaultProps.material),
    isGathering:
      defaultTo(get(state, 'material.isFetching'), defaultProps.isGathering) ||
      defaultTo(
        get(state, 'material.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'material.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...materialActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialsConstructor)
