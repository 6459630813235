// Libraries
import React from 'react'
import I18n from 'i18next'
import { Tag } from 'antd'
import { get, isEmpty, isNil } from 'lodash'

const specs = {
  width: 620,
  title: {
    update: 'pages.users.modal.update.single.title',
    create: 'pages.users.modal.create.single.title'
  },
  okText: {
    update: 'pages.users.modal.update.single.okText',
    create: 'pages.users.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    },
    disable: {
      buttonType: 'danger'
    },
    enable: {
      buttonType: 'primary'
    }
  },
  rows: {
    fullName: {
      title: 'pages.users.fields.fullName.title',
      dataIndex: 'fullname',
      key: 'fullName',
      formField: {
        label: 'pages.users.fields.fullName.title',
        required: 'pages.users.fields.fullName.requiredMessage',
        placeholder: 'pages.users.fields.fullName.placeholder'
      },
      empty: 'common.unknown.male'
    },
    email: {
      title: 'pages.users.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      formField: {
        pattern: {
          type: 'email',
          message: 'pages.users.fields.email.patternMessage'
        },
        label: 'pages.users.fields.email.title',
        required: 'pages.users.fields.email.requiredMessage',
        placeholder: 'pages.users.fields.email.placeholder'
      },
      empty: 'common.unknown.female'
    },
    role: {
      title: 'pages.users.fields.role.title',
      dataIndex: 'roles',
      key: 'role',
      formField: {
        type: 'radios',
        label: 'pages.users.fields.role.title',
        required: 'pages.users.fields.role.requiredMessage',
        placeholder: 'pages.users.fields.role.placeholder',
        // initialValue: role,
        // options: map(['ADMIN', 'CREATOR', 'READER'], role => ({
        //   id: role.name,
        //   label: I18n.t(`pages.users.fields.role.options.${role.name}`),
        //   value: role.name,
        //   data: role.name
        // })),
        render: role => {
          const associatedColors = {
            admin: 'volcano',
            reader: 'geekblue',
            creator: 'purple'
          }

          return (
            <Tag color={get(associatedColors, role)}>
              {I18n.t(`pages.users.fields.role.options.${role}`)}
            </Tag>
          )
        }
      },
      empty: 'common.unknown.male'
    },
    company: {
      title: 'pages.users.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        type: 'searchSelect',
        required: 'pages.users.fields.companyName.requiredMessage',
        label: 'pages.users.fields.companyName.title',
        placeholder: 'pages.users.fields.companyName.placeholder'
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? company.fullname : undefined,
      empty: 'common.unknown.female'
    },
    employee: {
      title: 'pages.users.fields.companyUser.title',
      dataIndex: 'employee',
      key: 'employee',
      formField: {
        type: 'searchSelect',
        label: 'pages.users.fields.companyUser.title',
        required: 'pages.users.fields.companyUser.requiredMessage',
        placeholder: 'pages.users.fields.companyUser.placeholder',
        format: employee =>
          !isNil(employee) && !isEmpty(employee) ? employee.id : undefined
      },
      render: employee =>
        !isNil(employee) && !isEmpty(employee) ? employee.fullname : undefined,
      empty: 'common.unknown.male'
    }
  }
}

export default specs
