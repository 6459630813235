// Libraries
import I18n from 'i18next'
import { defaultTo, get, includes, map } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message } from 'antd'

// Ressources
import { actions as dashboardActions } from 'Resources/DashboardResource'

// Common components
import { ManageModal } from 'Components'
// Local components
import { ChartModal, Statistics } from './Components'

// Specs
import { ModalSpecs } from 'Views/Dashboard/Specs'

// Ãcrans
import { List } from './Screens'

class DashboardConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    companies: PropTypes.array,
    chartsData: PropTypes.object,
    globals: PropTypes.object,
    profile: PropTypes.object,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    companies: [],
    chartsData: {},
    globals: {},
    profile: {},

    // TÃ©moin d'activitÃ© API
    isGathering: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null,
      defaultChartType: ''
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchCompaniesData()
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchCompaniesData = () => {
    this.props.actions.fetchCompaniesStatistics().catch(() => {
      message.error(I18n.t('api.errors.dashboard.fetch'))
    })

    this.props.actions.fetchGlobalsCompanyStatistics().catch(() => {
      message.error(I18n.t('api.errors.dashboard.fetch'))
    })
  }
  /**
   * Demande de crÃ©ation d'un graphique
   */
  askCreateChart = () => {
    this.setState({ openedModal: 'createChart' })
  }

  /**
   * Demande d'affichage du graph
   */
  askShowChart = () => {
    this.setState({ openedModal: 'showChart' })
  }

  /**
   * CrÃ©ation d'un graphique de statistique
   */
  createChart = chartConfig =>
    new Promise((resolve, reject) => {
      this.props.actions
        .generateCompanyStatistics({
          ...chartConfig,
          period: map(chartConfig.period, p => {
            let format = ''
            switch (chartConfig.timeFormat) {
              case 'YEAR':
                format = 'YYYY'
                break
              case 'MONTH':
                format = 'MM-YYYY'
                break
              default:
                format = 'DD-MM-YYYY'
                break
            }
            return p.format(format)
          })
        })
        .then(response => {
          this.safelyCloseModal()
          this.setState({ defaultChartType: chartConfig.typeChart[0] })
          this.askShowChart()
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.dashboard.get'))
        })
    })

  /**
   * Callback:
   * GÃ©nÃ©ration du graph
   */
  handleGenerateChart = () => {
    this.safelyCloseModal()
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    this.setState({ openedModal: null })
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let { isGathering, companies, chartsData, globals, profile } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal, defaultChartType } = this.state

    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR')

    // Chargement
    const loading = isGathering

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // CrÃ©ation d'un graphique
      askCreateChart: this.askCreateChart,
      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal
    }

    // Choix du reprÃ©sentant de l'entreprise extÃ©rieure
    ModalSpecs.rows.selectCompany.formField.options = map(
      companies,
      ({ id, name }) => ({
        value: id,
        id,
        label: name
      })
    )

    return (
      <Fragment>
        <Statistics globals={globals} />
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        {!isUserExtern && (
          <List
            loading={loading}
            constructorFunctions={constructorFunctions}
            constructorState={this.state}
            dataList={companies}
          />
        )}

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleGenerateChart}
          onChange={form => this.createChart(form)}
          visible={includes(['createChart'], openedModal)}
          {...ModalSpecs}
        />

        <ChartModal
          width={800}
          loading={loading}
          title={I18n.t('pages.dashboard.modal.show.title')}
          onOk={this.safelyCloseModal}
          visible={includes(['showChart'], openedModal)}
          charts={chartsData}
          chartType={defaultChartType}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(DashboardConstructor, 'defaultProps', {})

  return {
    companies: defaultTo(
      get(state, 'companyStatistics.items'),
      defaultProps.companies
    ),
    chartsData: defaultTo(
      get(state, 'companyStatistics.charts'),
      defaultProps.chartsData
    ),
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    globals: defaultTo(
      get(state, 'companyStatistics.globals'),
      defaultProps.globals
    ),
    isGathering:
      defaultTo(
        get(state, 'companyStatistics.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'companyStatistics.isFetchingItem'),
        defaultProps.isGathering
      )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...dashboardActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardConstructor)
