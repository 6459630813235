import { isEmpty, isNil, map } from 'lodash'
import I18n from 'i18next'
import moment from 'moment'

const specs = {
  width: 800,
  mode: 'create',
  title: {
    create: 'pages.dashboard.modal.create.title'
  },
  okText: {
    create: 'pages.dashboard.modal.create.okText'
  },
  rows: {
    selectCompany: {
      title: 'pages.dashboard.fields.name.title',
      dataIndex: 'company',
      key: 'name',
      formField: {
        type: 'select',
        label: 'pages.dashboard.fields.name.title',
        required: 'pages.dashboard.fields.name.requiredMessage',
        placeholder: 'pages.dashboard.fields.name.placeholder',
        questionmark: 'pages.dashboard.fields.name.questionMark'
      },
      empty: 'common.unknown.female'
    },
    typeChart: {
      title: 'pages.dashboard.fields.typeChart.title',
      dataIndex: 'typeChart',
      key: 'typeChart',
      formField: {
        type: 'select',
        mode: 'multiple',
        label: 'pages.dashboard.fields.typeChart.title',
        required: 'pages.dashboard.fields.typeChart.requiredMessage',
        placeholder: 'pages.dashboard.fields.typeChart.placeholder',
        options: map(['PERMIT', 'FIRE', 'WORKING-HOURS'], type => ({
          id: type,
          key: type,
          label: I18n.t(`pages.dashboard.fields.typeChart.options.${type}`),
          value: type
        }))
      },
      empty: 'common.unknown.male'
    },
    timeFormat: {
      title: 'pages.dashboard.fields.timeFormat.title',
      dataIndex: 'timeFormat',
      key: 'timeFormat',
      formField: {
        type: 'radios',
        options: map(['DAY', 'MONTH', 'YEAR'], format => ({
          id: format,
          label: I18n.t(`pages.dashboard.fields.timeFormat.options.${format}`),
          value: format,
          data: format
        })),
        label: 'pages.dashboard.fields.timeFormat.title',
        required: 'pages.dashboard.fields.timeFormat.requiredMessage',
        placeholder: 'pages.dashboard.fields.timeFormat.placeholder'
      },
      empty: 'common.unknown.male'
    },
    periodDay: {
      title: 'pages.dashboard.fields.period.title',
      dataIndex: 'period',
      key: 'periodDay',
      formField: {
        style: { width: '100%' },
        type: 'period',
        label: 'pages.dashboard.fields.period.title',
        required: 'pages.dashboard.fields.period.requiredMessage',
        placeholder: [
          'pages.dashboard.fields.period.placeholder.0',
          'pages.dashboard.fields.period.placeholder.1'
        ],
        format: period => {
          return !isNil(period) && !isEmpty(period)
            ? map(period, date => moment(date))
            : undefined
        }
      },
      empty: 'common.unknown.female'
    }
  }
}

export default specs
