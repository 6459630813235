// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { get, defaultTo } from 'lodash'
import { Tabs } from 'antd'

// Onglets
import { Information, Employees, Habilitations } from './Tabs'

const { TabPane } = Tabs

class CompanySingle extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // Navigation
    navigation: PropTypes.object,

    // DonnÃ©es de la vue
    data: PropTypes.object,

    // Structure de la formLayout
    FormSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    data: {},

    // Structure de la formLayout
    FormSpecs: {}
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: ''
    }
  }

  renderTabs = () => {
    const {
      data: company,
      constructorFunctions,
      navigation,
      FormSpecs,
      profile,
      loading
    } = this.props

    const habilitations = [];
    if (company && company.employees) {
      const empl = company.employees;

      for(let i=0 ; i < empl.length ; i++){
        for(let j=0 ; j < empl[i].habilitations.length ; j++){
          habilitations.push(empl[i].habilitations[j])
          habilitations[habilitations.length-1]['employeeName'] = empl[i].name
          habilitations[habilitations.length-1]['employee'] = { "name" : empl[i].name, "id": empl[i].id }
        }
      }
    }

    return (
      <Tabs
        className='company-list'
        defaultActiveKey='information'
        type='card'
        onChange={get(constructorFunctions, 'handleTabChange')}
      >
        {/* Informations */}
        <TabPane
          tab={
            <span className={`company company-${get(company, 'status')}`}>
              {I18n.t('pages.companies.tabs.information.title')}
            </span>
          }
          key='information'
        >
          <Information
            data={company}
            navigation={navigation}
            profile={profile}
            loading={loading}
            constructorFunctions={constructorFunctions}
            formLayout={FormSpecs}
          />
        </TabPane>

        {/* EmployÃ©s */}
        <TabPane
          tab={
            <span className={`company company-${get(company, 'status')}`}>
              {I18n.t('pages.companies.tabs.employees.title')}
            </span>
          }
          key='employees'
        >
          <Employees
            dataList={defaultTo(get(company, 'employees'), [])}
            navigation={navigation}
            profile={profile}
            loading={loading}
            constructorFunctions={constructorFunctions}
            formLayout={FormSpecs}
          />
        </TabPane>

        {/* Habilitations */}
        <TabPane
          tab={
            <span className={`company company-${get(company, 'status')}`}>
              {I18n.t('pages.companies.tabs.habilitations.title')}
            </span>
          }
          key='habilitations'
        >
          <Habilitations
            //dataList={defaultTo(get(company.employees, 'habilitations'), [])}
            dataList = {habilitations}
            navigation={navigation}
            profile={profile}
            loading={loading}
            constructorFunctions={constructorFunctions}
            formLayout={FormSpecs}
          />

        </TabPane>
      </Tabs>
    )
  }

  render() {
    return (
      <main className='screen companies companies-single'>
        {/* DonnÃ©es ordonnÃ©es en onglets */}
        {this.renderTabs()}
      </main>
    )
  }
}

export default CompanySingle
