// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import React, { Component, Fragment } from 'react'
import {
  AutoComplete,
  Button,
  Checkbox,
  DatePicker,
  Dropdown,
  Form,
  Icon,
  Input,
  Menu,
  Radio,
  Select,
  Spin,
  Steps,
  Tooltip,
  Upload
} from 'antd'
import {
  cloneDeep,
  compact,
  defaultTo,
  every,
  filter,
  find,
  findIndex,
  forEach,
  get,
  has,
  head,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isObject,
  isRegExp,
  keys,
  map,
  omitBy,
  remove,
  size,
  some,
  toString,
  update
} from 'lodash'
import * as Nominatim from 'nominatim-browser'
import BraftEditor from 'braft-editor'

// Components
import { Container } from 'Components/Modals'

// Styles
import './ManageModal.less'

// Helper
import { createFormFields, isDeepNil, renderImageLinkPreview } from 'Helpers'

const { Step } = Steps
const { Dragger } = Upload
const { Item: FormItem } = Form
const { Option, OptGroup } = Select
const { Group: RadioGroup } = Radio
const { Group: CheckboxGroup } = Checkbox
const { RangePicker, TimePicker } = DatePicker

// Sauvegarde du formulaire en temps rÃ©el
// Permet de conserver les valeurs si le composant est re-render
let UNSAVED_FORM = {}

class ManageModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.object,
    okText: PropTypes.object,
    cancelText: PropTypes.object,

    // ContrÃ´le d'Ã©tat de la modale
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    validateEachSteps: PropTypes.bool,
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    zIndex: PropTypes.number,

    // Contenu de la modale
    rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    data: PropTypes.object,
    form: PropTypes.object,
    steps: PropTypes.array,
    mode: PropTypes.string,
    type: PropTypes.string,

    // Appels API
    actions: PropTypes.object,

    // Fonctions de callback
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // Labels et titres
    title: {
      update: 'components.manageModal.update.title',
      create: 'components.manageModal.create.title',
      duplicate: 'components.manageModal.duplicate.title'
    },
    okText: {
      update: 'components.manageModal.update.okText',
      create: 'components.manageModal.create.okText',
      duplicate: 'components.manageModal.duplicate.okText'
    },
    cancelText: {
      update: 'components.manageModal.update.cancelText',
      create: 'components.manageModal.create.cancelText',
      duplicate: 'components.manageModal.duplicate.cancelText',
      request: 'components.manageModal.request.cancelText'
    },

    // ContrÃ´le d'Ã©tat de la modale
    visible: false,
    loading: true,
    validateEachSteps: true,
    width: 520,
    zIndex: 1000,

    // Contenu de la modal
    rows: {},
    data: {},
    steps: [],

    // Fonctions de callback
    onOk: () => {},
    onCancel: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {}

    // Lecteur de fichier
    this.fileReader = new FileReader()
  }

  handleCancel = e => {
    const { onCancel, type, form } = this.props

    if (type !== 'subModal') {
      // Reset du formulaire
      form.resetFields()
      UNSAVED_FORM = {}

      this.setState({
        currentStep: 0,
        uploads: {}
      })
    }

    onCancel(e)
  }

  /**
   * Envoi du formulaire
   */
  handleSubmit = e => {
    e.preventDefault()
    const { data, onChange, form, rows, steps } = this.props
    const { uploads } = this.state

    form.validateFieldsAndScroll((error, formValues) => {
      if (!error) {
        const formData = cloneDeep(formValues)

        // Pour chaque upload effectuÃ©s avec succÃ¨s rÃ©cupÃ©ration du fichier Ã  envoyer
        forEach(uploads, (upload, dataIndex) => {
          formData[dataIndex] = isArray(upload)
            ? map(formData[dataIndex], 'originFileObj')
            : get(formData[dataIndex], 'file')
        })

        onChange(formData, cloneDeep(data)).then(() => {
          this.setState(
            {
              currentStep: 0,
              uploads: {}
            },
            () => {
              UNSAVED_FORM = {}
            }
          )
        })
      } else {
        // RÃ©cupÃ©ration du chemin complet de l'objet en erreur
        const getIndexOfError = (error, dataIndex) => {
          // Si on n'est pas encore arrivÃ© Ã  la fin de l'objet d'erreurs
          if (!has(error, 'errors')) {
            if (isObject(error)) {
              const nextIndex = head(compact(map(error, getIndexOfError)))

              dataIndex = !isNil(nextIndex) ? `${dataIndex}.${nextIndex}` : null
            } else {
              return null
            }
          }

          return dataIndex
        }

        // RÃ©cupÃ©ration de tous les champs en erreur
        const inErrorIndexes = map(error, getIndexOfError)

        // RÃ©cupÃ©ration de la premiÃ¨re erreur et navigation vers l'Ã©tape associÃ©e
        const inErrorStep = findIndex(steps, {
          key: get(
            find(rows, {
              dataIndex: head(inErrorIndexes)
            }),
            'formField.visibility.step'
          )
        })

        // Erreur
        // Affichage de l'Ã©tape incomplÃ¨te
        this.setState(
          {
            currentStep: defaultTo(inErrorStep, 0)
          },
          () => {
            // Scroll jusqu'Ã  l'Ã©lÃ©ment en erreur
            const element = document.getElementById(head(keys(error)))

            if (has(element, 'scrollIntoView')) {
              element.scrollIntoView()
            }
          }
        )
      }
    })
  }

  /**
   * Stocke un fichier Ã  mettre en ligne
   */
  handleChangeUpload = (dataIndex, file) => {
    this.fileReader.onloadend = obj => {
      this.setState(({ uploads = {} }) => {
        uploads[dataIndex] = {
          previewURL: get(obj, 'srcElement.result'),
          fileName: get(file, 'name')
        }

        return { uploads }
      })
    }

    this.fileReader.readAsDataURL(file)
  }

  /**
   * Permet de chercher un lieu grace Ã  une API de carte (Open Street Maps)
   */
  handleSearchLocation = search => {
    Nominatim.geocode({
      q: search,
      addressdetails: false
    }).then(results => {
      this.setState({
        addressSuggestions: results
      })
    })
  }

  /**
   * Permet de passer Ã  l'Ã©tape suivante
   */
  handleChangeStep = (askedStep, previousStep) => {
    const { steps, form, rows, validateEachSteps } = this.props
    let shouldStepChange = true

    // Si la validation de chaque Ã©tapes est demandÃ©e
    if (validateEachSteps) {
      // Si l'Ã©tape souhaitÃ©e suit celle sur laquelle nous sommes
      // VÃ©rification de toutes les Ã©tapes intermÃ©diaires
      if (previousStep < askedStep) {
        while (shouldStepChange && previousStep < askedStep) {
          // RÃ©cupÃ©ration des champs Ã  valider lors de cette Ã©tape
          const fieldsToValidate = map(
            filter(
              rows,
              // eslint-disable-next-line
              row =>
                get(row, 'formField.visibility.step') ===
                get(steps, `${previousStep}.key`)
            ),
            'dataIndex'
          )

          // Validation des champs liÃ©s Ã  cette Ã©tape
          // eslint-disable-next-line
          form.validateFieldsAndScroll(fieldsToValidate, error => {
            if (isNil(error)) {
              // Passage Ã  la vÃ©rification de l'Ã©tape suivante
              previousStep += 1
            } else {
              // Erreur
              // Affichage de l'Ã©tape incomplÃ¨te
              this.setState({
                currentStep: previousStep
              })

              // Annulation du passage Ã  l'Ã©tape souhaitÃ©e
              shouldStepChange = false
            }
          })
        }
      }
    }

    // Si toutes les Ã©tapes prÃ©cÃ©dentes sont valides
    shouldStepChange &&
      this.setState({
        currentStep: askedStep
      })
  }

  /**
   * Changement d'action pour le bouton d'action Ã  choix multiple
   */
  handleSelectAction = ({ key }) => {
    this.setState({
      selectedAction: key
    })
  }

  /**
   * Rendu des actions disponibles dans la modale
   */
  renderActions = actions => {
    const { selectedAction } = this.state

    return (
      <Menu
        selectedKeys={[defaultTo(selectedAction, get(head(actions), 'name'))]}
        className='manage-modal-footer-actions-menu'
        onClick={action => this.handleSelectAction(action, actions)}
      >
        {map(actions, ({ name }) => (
          <Menu.Item
            className='manage-modal-footer-actions-menu-item'
            key={name}
          >
            {/* Nom de l'action */}
            <span className='manage-modal-footer-actions-menu-item-title'>
              {I18n.t(`components.manageModal.actions.${name}.title`)}
            </span>

            {/* Description de l'action */}
            <span className='manage-modal-footer-actions-menu-item-description'>
              {I18n.t(`components.manageModal.actions.${name}.description`)}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  /**
   * Rendu du bouton d'action
   */
  renderActionButton = params => {
    const { loading, actions } = this.props

    // RÃ©cupÃ©ration des actions disponibles
    const availableActions = cloneDeep(
      map(defaultTo(actions, []), ({ name, ...props }, index) => ({
        ...props,
        name: defaultTo(name, index)
      }))
    )

    // Suppression des boutons masquÃ©s
    remove(availableActions, { hidden: true })

    // RÃ©cupÃ©ration de l'action sÃ©lectionnÃ©e
    const selectedAction = defaultTo(
      find(availableActions, { name: get(this.state, 'selectedAction') }),
      head(availableActions)
    )

    // Affichage sÃ©lectif si plusieurs actions sont disponibles
    return size(availableActions) > 1 ? (
      <Dropdown.Button
        disabled={loading}
        onClick={defaultTo(get(selectedAction, 'onClick'), () => {})}
        overlay={this.renderActions(availableActions)}
        placement='topRight'
        type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        icon={<Icon type='up' />}
      >
        {I18n.t(
          `components.manageModal.actions.${get(selectedAction, 'name')}.label`
        )}
      </Dropdown.Button>
    ) : (
      !isEmpty(availableActions) && (
        <Button
          disabled={loading}
          onClick={defaultTo(get(selectedAction, 'onClick'), () => {})}
          type={defaultTo(get(selectedAction, 'buttonType', 'default'))}
        >
          {I18n.t(
            `components.manageModal.actions.${get(
              selectedAction,
              'name'
            )}.label`
          )}
        </Button>
      )
    )
  }

  /**
   * Rendu d'une ligne en mode Ã©dition
   */
  renderField = ({ key, dataIndex, formField = {}, ...row }) => {
    const { form, steps, data } = this.props
    const { addressSuggestions, currentStep, uploads } = this.state
    const { getFieldDecorator } = form
    const {
      required,
      pattern,
      customInput,
      format,
      help,
      options,
      type,
      visibility,
      filterOptions,
      initialValue,
      formatRangeDates,
      ...field
    } = formField

    // Valeur initiale
    const getInitialValue = (initialValue = get(data, dataIndex)) => {
      // Formateur spÃ©cifique disponible sur la ligne ?
      if (!isNil(format)) {
        initialValue = format(get(data, dataIndex), data)
      }

      // Formatage du HTML pour WYSIWYG
      if (get(row, 'formField.type') === 'wysiwyg') {
        initialValue = BraftEditor.createEditorState(data)
      }

      return initialValue
    }

    // Obligatoire (bool ou dÃ©pendant des valeurs d'autres champs)
    const isRequired = isBoolean(required)
      ? required
      : has(required, 'fieldsValues')
      ? every(get(required, 'fieldsValues'), (value, field) => {
          // Comparaison des valeurs attendues
          // S'il s'agit d'un tableau, vÃ©rifier qu'une des valeurs attendue correspond Ã  la valeur actuelle du champ
          // Sinon vÃ©rifier que la valeur attendue correspond Ã  la valeur actuelle du champ
          return isArray(value)
            ? includes(value, form.getFieldValue(field))
            : toString(defaultTo(form.getFieldValue(field), '')).match(
                `^${value}$`
              )
        })
      : !isNil(required)

    // Construction des rÃ¨gles de l'input
    const rules = [
      {
        required: isRequired,
        message: I18n.t(defaultTo(get(required, 'message'), required))
      }
    ]

    // Construction des rÃ¨gles liÃ©es au pattern de l'input
    if (!isNil(pattern)) {
      rules.push({
        type: get(pattern, 'type'),
        pattern: get(pattern, 'pattern'),
        transform: get(pattern, 'transform'),
        validator: get(pattern, 'validator'),
        message: I18n.t(get(pattern, 'message'))
      })
    }

    // Construction du nom de classe
    field.className = `manage-modal-form-input ${defaultTo(
      get(field, 'className'),
      ''
    )} ${has(field, 'mask') ? 'ant-input' : ''}`

    // Formatage du placeholder
    field.placeholder = isArray(field.placeholder)
      ? map(field.placeholder, placeholder => I18n.t(placeholder))
      : I18n.t(defaultTo(get(field, 'placeholder'), ''))

    // Champ optionnel
    // field.placeholder = !isRequired
    //   ? `${field.placeholder} (${I18n.t('common.optional')})`
    //   : field.placeholder

    // Filtre une option
    const filterOption = option => {
      let shouldOptionRender = true

      if (!isNil(filterOptions)) {
        const rules = get(filterOptions, 'rules')

        // VÃ©rifications des rÃ¨gles de filtre
        // DÃ©termine si l'option doit Ãªtre rendue ou nom
        shouldOptionRender = some(
          rules,
          ({ dataIndex, valueFromField }, name) =>
            // Comparaison d'une valeur de l'option Ã  celle d'un autre champ
            form.getFieldValue(valueFromField) ===
            get(option, `data.${dataIndex}`)
        )
      }

      return shouldOptionRender
    }

    // CrÃ©ation des options du sÃ©lecteur
    const createOption = ({ options = [], ...option }) =>
      !isEmpty(filter(options, filterOption)) ? (
        <OptGroup {...option}>{map(options, createOption)}</OptGroup>
      ) : filterOption(option) ? (
        <Option
          key={get(option, 'id')}
          disabled={defaultTo(get(option, 'disabled'), false)}
          value={get(option, 'value')}
        >
          {I18n.t(get(option, 'label'))}
        </Option>
      ) : null
    // Construction de l'input (automatique ou custom)
    const GeneratedInput =
      // Input tableau
      type === 'tags' ||
      (get(pattern, 'type') === 'array' && type !== 'select') ? (
        <Select mode='tags' allowClear tokenSeparators={[',']} {...field}>
          {map(options, createOption)}
        </Select>
      ) : // Input password
      get(pattern, 'type') === 'password' ? (
        <Input.Password {...field} />
      ) : // Select Options
      type === 'select' ? (
        <Select allowClear {...field}>
          {map(options, createOption)}
        </Select>
      ) : // Searchable Select
      type === 'searchSelect' ? (
        <Select
          filterOption={(inputValue, option) =>
            includes(
              toString(get(option, 'props.children')).toLowerCase(),
              toString(inputValue).toLowerCase()
            ) ||
            includes(
              toString(get(option, 'props.value')).toLowerCase(),
              toString(inputValue).toLowerCase()
            )
          }
          showSearch
          showArrow
          allowClear
          {...field}
        >
          {map(options, createOption)}
        </Select>
      ) : // Location
      type === 'location' ? (
        <AutoComplete
          onSearch={this.handleSearchLocation}
          filterOption={false}
          notFoundContent={null}
          dataSource={map(addressSuggestions, 'display_name')}
          {...field}
        />
      ) : // Autocomplete
      type === 'autocomplete' ? (
        <AutoComplete filterOption={false} notFoundContent={null} {...field} />
      ) : // Checkboxes
      type === 'checkboxes' ? (
        <CheckboxGroup options={options} {...field} />
      ) : // Radios
      type === 'radios' ? (
        <RadioGroup options={options} {...field} />
      ) : // RangePicker
      type === 'period' ? (
        <RangePicker
          format={defaultTo(formatRangeDates, 'L')}
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          {...field}
        />
      ) : // Datetime
      type === 'datetime' || type === 'date' ? (
        <DatePicker
          showTime={type === 'datetime' ? { format: 'HH:mm' } : null}
          format={type === 'datetime' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
          style={{ width: '100%' }}
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          {...field}
        />
      ) : // time
      type === 'time' ? (
        <TimePicker
          format='HH:mm'
          style={{ width: '100%' }}
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          {...field}
        />
      ) : // TextArea
      type === 'textarea' ? (
        <Input.TextArea autosize={{ minRows: 2, maxRows: 6 }} {...field} />
      ) : // WYSIWYG
      type === 'wysiwyg' ? (
        <BraftEditor language='fr' {...field} />
      ) : // Upload
      type === 'upload' ? (
        <Upload
          className='manage-modal-form-upload'
          showUploadList={false}
          beforeUpload={file => {
            this.handleChangeUpload(dataIndex, file)
            return false
          }}
          multiple={false}
          {...field}
        >
          <Button className='manage-modal-form-upload-button' icon='upload'>
            {I18n.t('common.import')}
          </Button>
        </Upload>
      ) : // DragDropUpload
      type === 'dragDropUpload' ? (
        <Dragger
          name={dataIndex}
          beforeUpload={file => {
            this.setState(({ uploads = {} }) => {
              uploads[dataIndex] = isNil(uploads[dataIndex])
                ? []
                : uploads[dataIndex]

              uploads[dataIndex].push({
                fileName: get(file, 'name')
              })

              return { uploads }
            })
            return false
          }}
          {...field}
          multiple
        >
          <p className='ant-upload-drag-icon'>
            <Icon type='inbox' />
          </p>
          <p className='ant-upload-text'>
            {I18n.t('components.manageModal.fields.upload.drag.instructions')}
          </p>
          <p className='ant-upload-hint'>
            {I18n.t('components.manageModal.fields.upload.drag.hint')}
          </p>
        </Dragger>
      ) : has(field, 'mask') && !isNil(get(field, 'mask')) ? (
        <InputMask
          mask={get(field, 'mask')}
          render={(ref, props) => (
            <Input ref={input => ref(input && input.input)} {...props} />
          )}
          {...field}
        />
      ) : (
        <Input {...field} />
      )

    const CustomInput = !isNil(customInput)
      ? customInput(field)
      : GeneratedInput

    // VisibilitÃ© du champ relative Ã  l'Ã©tape actuelle du formulaire
    const isStepRelativeVisible = has(visibility, 'step')
      ? get(visibility, 'step') ===
        get(steps, `${defaultTo(currentStep, 0)}.key`)
      : true

    // VisibilitÃ© du champ relative aux valeurs des champs
    const isFieldsValuesRelativeVisible = has(visibility, 'fieldsValues')
      ? every(get(visibility, 'fieldsValues'), (value, field) => {
          // Comparaison des valeurs attendues
          // S'il s'agit d'un tableau, vÃ©rifier qu'une des valeurs attendue correspond Ã  la valeur actuelle du champ
          // Sinon vÃ©rifier que la valeur attendue correspond Ã  la valeur actuelle du champ
          return isArray(value)
            ? includes(value, form.getFieldValue(field))
            : isArray(form.getFieldValue(field))
            ? includes(form.getFieldValue(field), value)
            : toString(defaultTo(form.getFieldValue(field), '')).match(
                isRegExp(value) ? value : `^${value}$`
              )
        })
      : true

    const visible = isStepRelativeVisible && isFieldsValuesRelativeVisible

    const label = (
      <Fragment>
        {I18n.t(get(formField, 'label'))}

        {/* Champ optionnel */}
        {/* !isRequired && ` (${I18n.t('common.optional')})` */}

        {/* Petite aide pour expliquer la nature du champ par exemple */}
        {has(formField, 'questionMark') && (
          <Tooltip title={I18n.t(get(formField, 'questionMark'))}>
            <Icon type='question-circle-o' />
          </Tooltip>
        )}
      </Fragment>
    )

    const decorator =
      type === 'dragDropUpload'
        ? {
            valuePropName: 'fileList',
            getValueFromEvent: e => (Array.isArray(e) ? e : e && e.fileList)
          }
        : {}

    return (
      <FormItem
        key={key}
        help={!isNil(help) ? I18n.t(help) : undefined}
        className={`${visible ? 'visible' : 'hidden'} manage-modal-form-item`}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(dataIndex, {
          preserve: true,
          rules,
          hidden: !isFieldsValuesRelativeVisible,
          initialValue: getInitialValue(initialValue),
          ...decorator
        })(CustomInput)}

        {/* Gestion de l'input d'upload */}
        {type === 'upload' &&
          renderImageLinkPreview(
            defaultTo(
              get(uploads, `${dataIndex}.previewURL`),
              get(data, dataIndex)
            ),
            defaultTo(
              get(uploads, `${dataIndex}.fileName`),
              get(data, dataIndex)
            )
          )}
      </FormItem>
    )
  }

  /**
   * Rendu d'une Ã©tape du formulaire
   */
  renderStep = ({ title, description, ...step }, index) => {
    const { currentStep } = this.state
    const previousStep = defaultTo(currentStep, 0)

    return (
      <Step
        key={index}
        title={I18n.t(title)}
        description={I18n.t(description)}
        onClick={() => this.handleChangeStep(index, previousStep)}
        {...step}
      />
    )
  }

  /**
   * Rendu des Ã©tapes du formulaire
   */
  renderSteps = () => {
    const { steps } = this.props
    const { currentStep } = this.state

    return (
      !isEmpty(steps) && (
        <Steps size='small' current={defaultTo(currentStep, 0)}>
          {/* Champs du formulaire */}
          {map(steps, this.renderStep)}
        </Steps>
      )
    )
  }

  /**
   * Rendu des lignes formatÃ©es
   */
  renderRows = () => {
    const { rows, loading } = this.props

    // Conteneurs en fonction de l'Ã©tat du panneau
    // Conteneur de chargement pour le mode Ã©dition (Skeleton sinon)
    const LoadingWrapper = loading ? Spin : 'div'

    return (
      <LoadingWrapper>
        <Form onSubmit={this.handleSubmit} className='manage-modal-form'>
          {/* Ãtapes du formulaire */}
          {this.renderSteps()}

          {/* Champs du formulaire */}
          {map(rows, this.renderField)}

          {/* Mesureur */}
          <div
            className='manage-modal-form-measure'
            ref={ref => (this.measure = ref)}
          />
        </Form>
      </LoadingWrapper>
    )
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const currentStep = defaultTo(get(this.state, 'currentStep'), 0)
    const {
      data,
      loading,
      okText,
      cancelText,
      form,
      steps,
      mode: propsMode
    } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // create || update || duplicate
    const mode = defaultTo(propsMode, isNil(data) ? 'create' : 'update')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm =
      isEmpty(formErrors) && (formChanged || mode === 'duplicate')

    // Boutons d'Ã©tapes
    const shouldDisplayPrevious =
      !isNil(steps) && !isEmpty(steps) && currentStep !== 0
    const shouldDisplayNext =
      !isNil(steps) && !isEmpty(steps) && size(steps) - 1 !== currentStep

    return (
      <div className='manage-modal-footer'>
        {/* Bouton d'actions (suppression, archivage ...) */}
        {shouldDisplayPrevious ? (
          <Button
            key='previous'
            type='primary'
            disabled={!isValidForm}
            loading={loading}
            onClick={() => this.handleChangeStep(currentStep - 1, currentStep)}
          >
            {I18n.t('common.previous')}
          </Button>
        ) : (
          mode === 'update' && (
            <div className='manage-modal-footer-actions'>
              {this.renderActionButton()}
            </div>
          )
        )}

        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='manage-modal-footer-controls'>
          <Button key='back' onClick={this.handleCancel}>
            {I18n.t(get(cancelText, mode))}
          </Button>

          {/* Ãtapes Ã  valider avant de passer Ã  l'envoi final du formulaire ? */}
          {shouldDisplayNext ? (
            <Button
              key='next'
              type='primary'
              disabled={!isValidForm}
              loading={loading}
              onClick={() =>
                this.handleChangeStep(currentStep + 1, currentStep)
              }
            >
              {I18n.t('common.next')}
            </Button>
          ) : (
            <Button
              key='submit'
              type='primary'
              disabled={!isValidForm}
              loading={loading}
              onClick={this.handleSubmit}
            >
              {I18n.t(get(okText, mode))}
            </Button>
          )}
        </div>
      </div>
    )
  }

  render() {
    const {
      data,
      loading,
      visible,
      title,
      width,
      zIndex,
      okText,
      cancelText,
      form,
      mode: propsMode
    } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // create || update
    const mode = defaultTo(propsMode, isNil(data) ? 'create' : 'update')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm =
      isEmpty(formErrors) && (formChanged || mode === 'duplicate')

    return (
      <Container
        title={I18n.t(get(title, mode), data)}
        loading={loading}
        visible={visible}
        cancelText={I18n.t(get(cancelText, mode))}
        okText={I18n.t(get(okText, mode))}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okButtonProps={{
          disabled: !isValidForm
        }}
        className={'modal manage-modal'}
        footer={this.renderFooter()}
        width={width}
        zIndex={zIndex}
      >
        {this.renderRows()}
      </Container>
    )
  }
}

export default Form.create({
  onValuesChange(props, fieldValue, fieldsValue) {
    if (get(props, 'type') !== 'subModal') {
      UNSAVED_FORM = fieldsValue
    }
  },
  mapPropsToFields(props) {
    const defaultProps = get(ManageModal, 'defaultProps', {})
    let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

    if (!isDeepNil(UNSAVED_FORM)) {
      formData = UNSAVED_FORM
    } else {
      // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
      forEach(get(props, 'rows'), (row, index) => {
        const format = get(row, 'formField.format')

        update(formData, get(row, 'dataIndex'), data => {
          // Formateur spÃ©cifique disponible sur la ligne ?
          if (!isNil(format)) {
            data = format(data, formData)
          }

          // Formatage du HTML pour WYSIWYG
          if (get(row, 'formField.type') === 'wysiwyg') {
            data = BraftEditor.createEditorState(data)
          }

          return data
        })
      })
    }

    // Retire les lignes vides qui ne sont pas utiles ici
    // formData = omitBy(formData, isNil)

    return createFormFields(Form, formData)
  }
})(ManageModal)
