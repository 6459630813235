// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as riskActions } from 'Resources/RiskResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/Risks/Specs'

// Helpers
import { formatOrderedData } from 'Helpers'

// Ãcrans
import { List } from './Screens'

const { confirm } = Modal

class RisksConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    risk: PropTypes.object,
    risks: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    risk: {},
    risks: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchRisksData()

    if (this.getContext() === 'single') {
      this.getRiskData().then(this.askUpdateRisk)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchRisksData = () => {
    this.props.actions.fetchRisks().catch(() => {
      message.error(I18n.t('api.errors.risk.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getRiskData = (risk = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getRisk(risk)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.risk.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyRisk = (risk, clearTableSelections) => {
    this.props.actions
      .archiveManyRisk(risk)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.risk.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveRisk = risk => {
    this.props.actions
      .archiveRisk(risk)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.risk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyRisk = (risk, clearTableSelections) => {
    this.props.actions
      .archiveRisks(risk)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.risk.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteRisk = risk => {
    this.props.actions
      .archiveRisk(risk)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.risk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un risk
   */
  updateRisk = (updatedRisk, originalRisk) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateRisk({
          id: get(originalRisk, 'id'),
          ...updatedRisk
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.risk.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.risk.update'))
        })
    })

  /**
   * CrÃ©ation d'un risk
   */
  createRisk = createdRisk =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createRisk(createdRisk)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.risk.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.risk.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyRisks = (...params) => {
    confirm({
      title: I18n.t('pages.risks.modal.archive.multiple.title'),
      content: I18n.t('pages.risks.modal.archive.multiple.content'),
      okText: I18n.t('pages.risks.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.risks.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveRisk(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveRisk = () => {
    const { risk } = this.props

    confirm({
      title: I18n.t('pages.risks.modal.archive.single.title'),
      content: I18n.t('pages.risks.modal.archive.single.content'),
      okText: I18n.t('pages.risks.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.risks.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveRisk(risk)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyRisks = (...params) => {
    confirm({
      title: I18n.t('pages.risks.modal.delete.multiple.title'),
      content: I18n.t('pages.risks.modal.delete.multiple.content'),
      okText: I18n.t('pages.risks.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.risks.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.deleteManyRisk(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteRisk = () => {
    const { risk } = this.props

    confirm({
      title: I18n.t('pages.risks.modal.delete.single.title'),
      content: I18n.t('pages.risks.modal.delete.single.content'),
      okText: I18n.t('pages.risks.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.risks.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteRisk(risk)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateRisk = () => {
    this.setState({ openedModal: 'updateRisk' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateRisk = () => {
    this.setState({ openedModal: 'createRisk' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateRisk') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = risk => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(risk, 'id') })

    this.getRiskData(risk)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateRisk()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('Risk', { id: get(risk, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderRisk(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.risk.order')))
      .catch(() => message.error(I18n.t('api.errors.risk.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let { isGathering, isUpdating, isCreating, risks, risk } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getRiskData: this.getRiskData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateRisk: this.askCreateRisk,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateRisk: this.askUpdateRisk,
      askUpdateManyRisks: this.askUpdateRisk,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveRisk: this.askArchiveRisk,
      askArchiveManyRisks: this.askArchiveManyRisks,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteRisk: this.askDeleteRisk,
      askDeleteManyRisks: this.askDeleteManyRisks,

      // RÃ©initialisation du mot de passe d'un risk
      askResetPasswordRisk: this.askResetPasswordRisk
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteRisk

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={risks}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateRisk' ? this.updateRisk : this.createRisk
          }
          visible={includes(['createRisk', 'updateRisk'], openedModal)}
          data={
            includes(['transition', 'updateRisk'], openedModal) ? risk : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(RisksConstructor, 'defaultProps', {})

  return {
    risks: defaultTo(get(state, 'risk.items'), defaultProps.risks),
    risk: defaultTo(get(state, 'risk.item'), defaultProps.risk),
    isGathering:
      defaultTo(get(state, 'risk.isFetching'), defaultProps.isGathering) ||
      defaultTo(get(state, 'risk.isFetchingItem'), defaultProps.isGathering),
    isUpdating: defaultTo(
      get(state, 'risk.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...riskActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RisksConstructor)
