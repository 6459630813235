import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Libraries
import { get } from 'lodash'
import { Modal, Empty, Alert } from 'antd'

// Styles
import './Container.less'

class ModalContainer extends Component {
  static propTypes = {
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    zIndex: PropTypes.number,
    okText: PropTypes.string,
    children: PropTypes.element,
    title: PropTypes.string,
    headerText: PropTypes.string
  }

  static defaultProps = {
    visible: false,
    loading: false,
    zIndex: 1000,
    okText: 'Ok',
    children: null,
    title: null,
    headerText: null
  }

  constructor(props) {
    super(props)

    // initial state
    this.state = {}
  }

  handleOk = e => {
    get(this, 'props.onOk', () => {})(e)
  }

  handleCancel = e => {
    get(this, 'props.onCancel', () => {})(e)
  }

  render() {
    const {
      title,
      children,
      okText,
      zIndex,
      visible,
      headerText,
      loading,
      ...props
    } = this.props

    return (
      <Modal
        title={title}
        visible={visible}
        zIndex={zIndex}
        onOk={this.handleOk}
        okText={okText}
        confirmLoading={loading}
        onCancel={this.handleCancel}
        {...props}
      >
        {headerText && <Alert message={headerText} type='warning' />}

        {children || <Empty />}
      </Modal>
    )
  }
}

export default ModalContainer
