// Libraries
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LocaleProvider } from 'antd'
import { get, isEmpty, defaultTo } from 'lodash'
import React, { Component, Fragment } from 'react'

// Config
import { ReduxPersist } from 'Config'

// Moment
import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'

// Actions
import StartupActions from 'Redux/StartupRedux'

// Components
import Loading from 'Views/Loading'
// import Notification from 'Notification'
import AppNavigation from 'Navigation/NavigationRouter'

// Ant Design
const antdLocales = {
  fr: require('antd/lib/locale-provider/fr_FR').default,
  en: require('antd/lib/locale-provider/en_US').default
}

class RootContainer extends Component {
  static propTypes = {
    error: PropTypes.object,
    lang: PropTypes.object,
    startup: PropTypes.func,
    started: PropTypes.bool
  }

  constructor(props) {
    super(props)

    this.state = {
      ignore: true,
      title: ''
    }
  }

  componentDidMount() {
    // if redux persist is not active fire startup action
    if (!ReduxPersist.active) {
      this.props.startup()
    }
  }

  render() {
    const {
      started,
      error,
      lang: { language, fallbackLng = 'fr' }
    } = this.props

    const ready = started || !isEmpty(error)

    // Changement de la locale de moment
    moment.locale(language)

    return (
      <LocaleProvider
        locale={defaultTo(
          get(antdLocales, language),
          get(antdLocales, fallbackLng)
        )}
      >
        <Fragment>
          {/* Affiche un loader tant que l'application n'est pas dÃ©marrÃ©e */}
          {ready ? <AppNavigation /> : <Loading />}

          {/* Notification */}
          {/* <Notification /> */}
        </Fragment>
      </LocaleProvider>
    )
  }
}

const mapStateToProps = state => {
  return {
    lang: get(state, 'lang'),
    error: get(state, 'startup.error'),
    started: get(state, 'startup.started')
  }
}

const mapDispatchToProps = dispatch => ({
  startup: () => dispatch(StartupActions.startup())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RootContainer)
