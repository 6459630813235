// Libraries
import React from 'react'
import I18n from 'i18next'
import { Tag } from 'antd'
import moment from 'moment'
import { map, isNil, isEmpty, get } from 'lodash'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.update.single.title',
    create: 'pages.permits.modal.create.single.title'
  },
  okText: {
    update: 'pages.permits.modal.update.single.okText',
    create: 'pages.permits.modal.create.single.okText'
  },
  actions: {
    update: {
      buttonType: 'primary'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    }
  ],
  tabs: {
    changelog: {},
    scans: {}
  },
  validateEachSteps: false,
  rows: {
    preventionPlan: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.permitID.title',
        required: 'pages.permits.fields.permitID.requiredMessage',
        placeholder: 'pages.permits.fields.permitID.placeholder',
        questionMark: 'pages.permits.fields.permitID.questionMark'
      },
      empty: 'common.unknown.female'
    },
    validityDate: {
      title: 'pages.permits.fields.validityDate.title',
      dataIndex: 'validityDate',
      key: 'validityDate',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'date',
        label: 'pages.permits.fields.validityDate.title',
        required: 'pages.permits.fields.validityDate.requiredMessage',
        placeholder: 'pages.permits.fields.validityDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    },
    validityHours: {
      title: 'pages.permits.fields.validityHours.title',
      dataIndex: 'validityHours',
      key: 'validityHours',
      formField: {
        visibility: {
          step: 'main'
        },
        mask: '**:** - **:**',
        label: 'pages.permits.fields.validityHours.title',
        required: 'pages.permits.fields.validityHours.requiredMessage',
        placeholder: 'pages.permits.fields.validityHours.placeholder',
        pattern: {
          validator: (rule, value, callback) => {
            const pattern = /^(?<startTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]) - (?<endTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])$/
            const parsed = pattern.exec(value)

            const startTime = get(parsed, 'groups.startTime')
            const endTime = get(parsed, 'groups.endTime')

            let message

            if (
              /* Aucune donnÃ©e valide */
              isNil(parsed)
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.wrong'
              )
            } else if (
              /* Les deux heures sont inversÃ©es */
              moment(endTime, 'HH:mm').isBefore(moment(startTime, 'HH:mm'))
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.order'
              )
            } else if (
              /* Les deux heures sont dans la mÃªme moitiÃ© de journÃ©e */
              moment(endTime, 'HH:mm').format('a') !==
                moment(startTime, 'HH:mm').format('a') &&
              endTime !== '12:00'
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.meridiem'
              )
            }

            callback(message)
          }
        }
      },
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? (
          <Tag>{company.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionMark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    location: {
      title: 'pages.permits.fields.location.title',
      dataIndex: 'location',
      key: 'location',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.location.title',
        required: 'pages.permits.fields.location.requiredMessage',
        placeholder: 'pages.permits.fields.location.placeholder',
        questionMark: 'pages.permits.fields.location.questionMark'
      },
      empty: 'common.unknown.female'
    },
    fireSources: {
      title: 'pages.permits.fields.fireSources.title',
      dataIndex: 'fireSources',
      key: 'fireSources',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.fireSources.title',
        required: 'pages.permits.fields.fireSources.requiredMessage',
        placeholder: 'pages.permits.fields.fireSources.placeholder',
        format: fireSources =>
          !isNil(fireSources) && !isEmpty(fireSources)
            ? map(fireSources, 'id')
            : undefined
      },
      render: fireSources =>
        !isNil(fireSources) && !isEmpty(fireSources)
          ? map(fireSources, (fireSource, index) => (
              <Tag key={index}>{get(fireSource, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedFireSources: {
      title: 'pages.permits.fields.fireSources.unlisted.title',
      dataIndex: 'unlistedFireSources',
      key: 'unlistedFireSources',
      formField: {
        visibility: {
          step: 'main',
          fieldsValues: {
            fireSources: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.fireSources.unlisted.title',
        required: 'pages.permits.fields.fireSources.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.fireSources.unlisted.placeholder',
        format: fireSources =>
          !isNil(fireSources) && !isEmpty(fireSources)
            ? map(fireSources, 'id')
            : undefined
      },
      render: fireSources =>
        !isNil(fireSources) && !isEmpty(fireSources)
          ? map(fireSources, (fireSource, index) => (
              <Tag key={index}>{get(fireSource, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    securityMeasures: {
      title: 'pages.permits.fields.securityMeasures.title',
      dataIndex: 'securityMeasures',
      key: 'securityMeasures',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.securityMeasures.title',
        placeholder: 'pages.permits.fields.securityMeasures.placeholder',
        format: securityMeasures =>
          !isNil(securityMeasures) && !isEmpty(securityMeasures)
            ? map(securityMeasures, 'id')
            : undefined
      },
      render: securityMeasures =>
        !isNil(securityMeasures) && !isEmpty(securityMeasures)
          ? map(securityMeasures, (securityMeasure, index) => (
              <Tag key={index}>{get(securityMeasure, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.female'
    },
    unlistedSecurityMeasures: {
      title: 'pages.permits.fields.securityMeasures.unlisted.title',
      dataIndex: 'unlistedSecurityMeasures',
      key: 'unlistedSecurityMeasures',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            securityMeasures: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.securityMeasures.unlisted.title',
        required:
          'pages.permits.fields.securityMeasures.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.securityMeasures.unlisted.placeholder',
        format: securityMeasures =>
          !isNil(securityMeasures) && !isEmpty(securityMeasures)
            ? map(securityMeasures, 'id')
            : undefined
      },
      render: securityMeasures =>
        !isNil(securityMeasures) && !isEmpty(securityMeasures)
          ? map(securityMeasures, (securityMeasure, index) => (
              <Tag key={index}>{get(securityMeasure, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    supervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'supervisor',
      key: 'supervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        type: 'searchSelect',
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      render: supervisor =>
        !isNil(supervisor) && !isEmpty(supervisor) ? (
          <Tag>{supervisor.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companyLeader.title',
        required: 'pages.permits.fields.companyLeader.requiredMessage',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        type: 'searchSelect',
        format: leader =>
          !isNil(leader) && !isEmpty(leader) ? leader.id : undefined
      },
      render: leader =>
        !isNil(leader) && !isEmpty(leader) ? (
          <Tag>{leader.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    }
  }
}

export default specs
