import React, { Component } from 'react'
import {
  cloneDeep,
  defaultTo,
  find,
  forEach,
  get,
  isEmpty,
  isNil,
  map,
  omitBy
} from 'lodash'
import { connect } from 'react-redux'
import { Button, Divider, Form, Select } from 'antd'
import { createFormFields, isDeepNil } from 'Helpers'
import PropTypes from 'prop-types'
import I18n from 'i18next'
import { Container } from 'Components/Modals'
import FormItem from 'antd/es/form/FormItem'

let UNSAVED_FORM = {}

class ConfirmRequestModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.object,
    okText: PropTypes.object,
    cancelText: PropTypes.object,

    // ContrÃ´le d'Ã©tat de la modale
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    loading: PropTypes.bool,

    // Contenu de la modale
    data: PropTypes.object,
    form: PropTypes.object,
    supervisors: PropTypes.array,
    addSupervisor: PropTypes.func,

    // Fonctions de callback
    onCancel: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // Labels et titres
    title: {
      single: 'pages.permits.modal.confirmRequest.single.title',
      multiple: 'pages.permits.modal.confirmRequest.multiple.title'
    },
    okText: {
      single: 'pages.permits.modal.confirmRequest.single.okText',
      multiple: 'pages.permits.modal.confirmRequest.multiple.okText'
    },
    cancelText: {
      single: 'pages.permits.modal.confirmRequest.single.cancelText',
      multiple: 'pages.permits.modal.confirmRequest.multiple.cancelText'
    },

    // ContrÃ´le d'Ã©tat de la modale
    width: 520,
    visible: false,
    loading: true,

    // Contenu de la modal
    data: {},
    form: {},
    supervisors: [],
    addSupervisor: () => {},

    // Fonctions de callback
    onCancel: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {}
  }

  handleCancel = e => {
    const { onCancel, form } = this.props

    // Reset du formulaire
    form.resetFields()
    UNSAVED_FORM = {}

    onCancel(e)
  }

  /**
   * Envoi du formulaire
   */
  handleSubmit = e => {
    e.preventDefault()
    const { data, onChange, form } = this.props

    form.validateFields((error, formData) => {
      if (!error) {
        formData.id = data.id
        onChange(formData).then(() => (UNSAVED_FORM = {}))
      }
    })
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const { loading, okText, cancelText, form } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isDeepNil)
    const isValidForm = isEmpty(formErrors)

    return (
      <div className='manage-modal-footer'>
        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='manage-modal-footer-controls'>
          <Button key='back' onClick={this.handleCancel}>
            {I18n.t(get(cancelText, mode))}
          </Button>
          <Button
            key='submit'
            type='primary'
            disabled={!isValidForm}
            loading={loading}
            onClick={this.handleSubmit}
          >
            {I18n.t(get(okText, mode))}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {
      loading,
      visible,
      title,
      okText,
      cancelText,
      form,
      width,
      supervisors
    } = this.props
    const { getFieldDecorator } = form

    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm = isEmpty(formErrors) && formChanged

    return (
      <Container
        title={I18n.t(get(title, mode))}
        loading={loading}
        visible={visible}
        cancelText={I18n.t(get(cancelText, mode))}
        okText={I18n.t(get(okText, mode))}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okButtonProps={{
          disabled: !isValidForm
        }}
        className={'modal confirm-request-modal'}
        footer={this.renderFooter()}
        width={width}
      >
        <Form onSubmit={this._handleSubmit}>
          {/* Surveillant */}
          <FormItem label={I18n.t(`pages.permits.fields.supervisor.title`)}>
            {getFieldDecorator('supervisor', {
              initialValue: this.props.data.supervisor?.id,
              rules: [
                {
                  required: true,
                  message: I18n.t(
                    `pages.permits.fields.supervisor.requiredMessage`
                  )
                }
              ]
            })(
              <Select
                allowClear
                placeholder={I18n.t(
                  `pages.permits.fields.supervisor.placeholder`
                )}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 2px' }} />
                    <Button
                      icon='plus'
                      type='link'
                      onMouseDown={event => event.preventDefault()}
                      onClick={() => this.props.addSupervisor()}
                    >
                      {I18n.t('pages.permits.fields.supervisor.add')}
                    </Button>
                  </div>
                )}
              >
                {supervisors.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(ConfirmRequestModal, 'defaultProps', {})

  return {
    supervisors: defaultTo(
      get(state, 'supervisor.items'),
      defaultProps.supervisors
    )
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange(props, fieldValue, fieldsValue) {
      UNSAVED_FORM = fieldsValue
    },
    mapPropsToFields(props) {
      const defaultProps = get(ConfirmRequestModal, 'defaultProps', {})
      let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

      if (!isEmpty(UNSAVED_FORM)) {
        formData = UNSAVED_FORM
      } else {
        // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
        forEach(formData, (value, index) => {
          const format = get(
            find(map(get(props, 'rows')), { dataIndex: index }),
            'formField.format'
          )

          formData[index] = !isNil(format) ? format(value, formData) : value
        })
      }

      return createFormFields(Form, formData)
    }
  })(ConfirmRequestModal)
)
