import React, { Component } from 'react'

// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, defaultTo } from 'lodash'
import { Form, Input, Icon, Card, Button, message } from 'antd'

// Helpers
import { createFormFields } from 'Helpers'

// Resources
import { actions as profileActions } from 'Resources/ProfileResource'

const { Item: FormItem } = Form

class InformationTab extends Component {
  static propTypes = {
    form: PropTypes.object,
    actions: PropTypes.object,
    isGathering: PropTypes.bool,
    isSubmitting: PropTypes.bool
  }

  static defaultProps = {
    profile: {},
    isGathering: false,
    isSubmitting: false
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration du profil
    this._getProfileData()
  }

  /**
   * RÃ©cupÃ©ration du profil
   */
  _getProfileData = () => {
    this.props.actions.getProfile().catch(() => {
      message.error(I18n.t('api.errors.profile.get'))
    })
  }

  /**
   * Envoi du formulaire
   */
  _handleSubmit = e => {
    e.preventDefault()

    const { form, profile } = this.props

    const roles = defaultTo(get(profile, 'roles'), [])

    // Validation du formulaire
    form.validateFieldsAndScroll((error, profile) => {
      if (!error) {
        this.props.actions
          .updateProfile({
            ...profile,
            roles
          })
          .then(() => {
            message.success(I18n.t('api.success.profile.update'))
          })
          .catch(() => {
            // CrÃ©ation impossible, profileCode ou email dÃ©jÃ  utilisÃ©
            message.error(I18n.t('api.errors.profile.update'))
          })
      }
    })
  }

  _renderForm = () => {
    const { form, isSubmitting } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this._handleSubmit}>
        {/* PrÃ©nom */}
        <FormItem label={I18n.t('fields.fullName.title')}>
          {getFieldDecorator('fullname', {
            rules: [
              {
                required: true,
                message: I18n.t('fields.fullName.requiredMessage')
              }
            ]
          })(
            <Input
              prefix={<Icon type={I18n.t('fields.fullName.icon')} />}
              placeholder={I18n.t('fields.fullName.placeholder')}
            />
          )}
        </FormItem>

        {/* Email */}
        <FormItem label={I18n.t('fields.email.title')}>
          {getFieldDecorator('email', {
            rules: [
              {
                required: true,
                message: I18n.t('fields.email.requiredMessage')
              }
            ]
          })(
            <Input
              prefix={<Icon type={I18n.t('fields.email.icon')} />}
              placeholder={I18n.t('fields.email.placeholder')}
            />
          )}
        </FormItem>

        <FormItem>
          <Button
            type='primary'
            loading={isSubmitting}
            onClick={this._handleSubmit}
          >
            {I18n.t('common.save')}
          </Button>
        </FormItem>
      </Form>
    )
  }

  render() {
    const { isGathering } = this.props

    return (
      <Card loading={isGathering} className='tab information'>
        {this._renderForm()}
      </Card>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(InformationTab, 'defaultProps', {})

  return {
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    isGathering: defaultTo(
      get(state, 'profile.isFetching'),
      defaultProps.isGathering
    ),
    isSubmitting: defaultTo(
      get(state, 'profile.isUpdating'),
      defaultProps.isSubmitting
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...profileActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    mapPropsToFields(props) {
      const defaultProps = get(InformationTab, 'defaultProps', {})
      const profile = defaultTo(get(props, 'profile'), defaultProps.profile)

      return createFormFields(Form, profile)
    }
  })(InformationTab)
)
