// Libraries
import I18n from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Spin, Tag, Timeline, Tooltip, Icon } from 'antd'
import { get, map, find, groupBy, defaultTo } from 'lodash'

// Global components
import { Anchor } from 'Components'

class InformationTab extends Component {
  static propTypes = {
    // DonnÃ©es de la vue
    data: PropTypes.object,

    // TÃ©moins de charge API
    loading: PropTypes.bool,

    // Navigation
    navigation: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // DonnÃ©es de la vue
    data: PropTypes.object,

    // TÃ©moins de charge API
    loading: true,

    // Navigation
    navigation: {},

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: {}
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {
    // Lecteur de fichier
    this.fileReader = new FileReader()

    // RÃ©cupÃ©ration des informations du permit
    // this.fetchPermitChangelog()
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es de la vue
   */
  fetchPermitChangelog = () => {
    const { constructorFunctions, navigation } = this.props
    const id = navigation.getParam('id')

    constructorFunctions.fetchPermitChangelogData(id)
  }

  /**
   * Demande de tÃ©lÃ©chargement du PDF associÃ© Ã  la version
   */
  downloadRevisionPDF = revisionID => {
    const { constructorFunctions, navigation } = this.props
    const id = navigation.getParam('id')

    constructorFunctions.handleRevisionPDF(id, revisionID)
  }

  /**
   * Rendu des lignes de changements de chaque rÃ©visions
   */
  renderRevisionChanges = (revisionChanges, revisionID) => {
    const revisionDate = get(
      find(revisionChanges, { revision: revisionID }),
      'dateCreated'
    )

    // Couleurs de chaque types de modifications
    const typeColors = {
      cancelled: 'red',
      closed: 'blue',
      controlled: 'volcano',
      finished: 'blue',
      waiting: '',
      opened: 'green',
      change: '#73d13d',
      signed: '#f759ab',
      added: '#ffa940',
      removed: '#ffc53d',
      printed: '#597ef7',
      rated: '#40a9ff',
      created: '#9254de'
    }

    const date = moment(
      Date.parse(find(revisionChanges, { revision: revisionID }).value)
    ).format('L')
    return (
      <Timeline.Item className='permit-revisions-item' key={revisionID}>
        {/* RÃ©vision (ID et date) */}
        <h2 className='permit-revisions-item-title'>
          <span className='permit-revisions-item-id'>{`Revision nÂ°${revisionID}`}</span>
          <span className='permit-revisions-item-date'>
            {`(${moment(revisionDate).format('L')})`}
          </span>
        </h2>

        <Anchor
          className='permit-revisions-item-download'
          onClick={() => this.downloadRevisionPDF(revisionID)}
        >
          <Icon type='download' />
          {I18n.t('pages.permits.tabs.changelog.download')}
        </Anchor>

        {/* Rendu des lignes de modification de la rÃ©vision */}
        <ol className='permit-revisions-item-changes'>
          {map(revisionChanges, (revisionChange, index) => (
            <li key={index} className='permit-revisions-item-changes-item'>
              {/* Type de modification */}
              <Tag
                className='permit-revisions-item-changes-item-type'
                color={get(typeColors, get(revisionChange, 'type'))}
              >
                {/* {get(revisionChange, 'type') === 'waiting' && (
                  <Icon type='loading' />
                )} */}

                {I18n.t(
                  `pages.permits.tabs.changelog.types.${get(
                    revisionChange,
                    'type'
                  )}.title`
                )}
              </Tag>

              {/* Description */}
              <Tooltip
                placement='right'
                title={get(revisionChange, 'description')}
              >
                <p className='permit-revisions-item-changes-item-description'>
                  {I18n.t(
                    `pages.permits.tabs.changelog.types.${get(
                      revisionChange,
                      'type'
                    )}.description`,
                    {
                      field: I18n.t(
                        `pages.permits.fields.${get(
                          revisionChange,
                          'field'
                        )}.title`
                      ).toLowerCase(),
                      author: get(revisionChange, 'user.fullname'),
                      hour: moment(get(revisionChange, 'dateCreated')).format(
                        'HH:mm'
                      )
                    }
                  )}
                </p>
                {revisionChange.value instanceof String && (
                  <p>{`${revisionChange?.value?.split('2')[0]} (${moment(
                    Date.parse(revisionChange.value)
                  ).format('L')})`}</p>
                )}
              </Tooltip>
            </li>
          ))}
        </ol>
      </Timeline.Item>
    )
  }

  /**
   * Timeline des versions
   */
  render() {
    const { loading, data: permit } = this.props

    const changelog = defaultTo(get(permit, 'changelog'), [])

    const revisionsChanges = groupBy(changelog, 'revision')

    return (
      <Spin spinning={loading} wrapperClassName='tab changelog'>
        <Timeline className='permit-revisions'>
          {/* Rendu de chaque rÃ©visions */}
          {map(revisionsChanges, this.renderRevisionChanges)}
        </Timeline>
      </Spin>
    )
  }
}

export default InformationTab
