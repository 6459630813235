import { isEmpty, isNil, map } from 'lodash'
import I18n from 'i18next'
import moment from 'moment'

const specs = {
  width: 620,
  title: {
    update: 'pages.employees.modal.update.securityForm.title',
    create: 'pages.employees.modal.create.securityForm.title'
  },
  okText: {
    update: 'pages.employees.modal.update.securityForm.okText',
    create: 'pages.employees.modal.create.securityForm.okText'
  },
  actions: {
    export: {
      buttonType: 'edit',
    },
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.employees.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        readOnly: true,
        disabled: true,
        label: 'pages.employees.fields.name.title'
      },
      empty: 'common.unknown.9male'
    },
    job: {
      title: 'pages.employees.fields.job.title',
      dataIndex: 'job',
      key: 'job',
      formField: {
        readOnly: true,
        disabled: true,
        label: 'pages.employees.fields.job.title'
      },
      empty: 'common.unknown.male'
    },
    contractType: {
      title: 'pages.employees.fields.contractType.title',
      dataIndex: 'contractType',
      key: 'contractType',
      formField: {
        readOnly: true,
        disabled: true,
        label: 'pages.employees.fields.contractType.title'
      },
      empty: 'common.unknown.male'
    },
    isMedicalyAble: {
      title: "pages.employees.fields.isMedicalyAble.title",
      dataIndex: "isMedicalyAble",
      key: "isMedicalyAble",
      formField: {
        type: 'radios',
        label: 'pages.employees.fields.isMedicalyAble.title',
        required:
          'pages.employees.fields.isMedicalyAble.requiredMessage',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(
            `pages.companies.fields.critical.options.${bool}.title`
          ),
          value: bool,
          data: bool
        }))
      },
      empty: 'common.unknown.female'
    },
    medicalDesc: {
      title: 'pages.employees.fields.medicalDesc.title',
      dataIndex: 'medicalDesc',
      key: 'medicalDesc',
      formField: {
        label: 'pages.employees.fields.medicalDesc.title',
        placeholder: 'pages.employees.fields.medicalDesc.placeholder',
        type: 'textarea'
      },
      empty: 'common.unknown.female',
      visibility: {
        shouldBeVisible: (employee) => isNil(employee.medicalDesc) || isEmpty(employee.medicalDesc)
      }
    },
    pdpNum: {
      title: 'pages.employees.fields.pdpNum.title',
      dataIndex: 'pdpNum',
      key: 'pdpNum',
      formField: {
        label: 'pages.employees.fields.pdpNum.title',
        placeholder: 'pages.employees.fields.pdpNum.placeholder',
        type: 'textarea'
      },
      empty: 'common.unknown.female',
    },
    isCompanyLeaderInformed: {
      title: 'pages.employees.fields.securityFormInformation.title',
      dataIndex: 'isCompanyLeaderInformed',
      key: 'isCompanyLeaderInformed',
      formField: {
        type: 'radios',
        label: 'pages.employees.fields.securityFormInformation.title',
        required:
          'pages.employees.fields.securityFormInformation.requiredMessage',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(
            `pages.companies.fields.critical.options.${bool}.title`
          ),
          value: bool,
          data: bool
        }))
      },
      empty: 'common.unknown.female'
    },
    startDate: {
      title: 'pages.employees.fields.startDate.title',
      dataIndex: 'startDate',
      key: 'startDate',
      formField: {
        type: 'datetime',
        label: 'pages.employees.fields.startDate.title',
        required: 'pages.employees.fields.startDate.requiredMessage',
        placeholder: 'pages.employees.fields.startDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    },
    endDate: {
      title: 'pages.employees.fields.endDate.title',
      dataIndex: 'endDate',
      key: 'endDate',
      formField: {
        type: 'datetime',
        label: 'pages.employees.fields.endDate.title',
        required: 'pages.employees.fields.endDate.requiredMessage',
        placeholder: 'pages.employees.fields.endDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    }
  }
}

export default specs
