// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { isNil } from 'lodash'
import { Tooltip } from 'antd'

// Helpers
import {
  dateSort,
  filterPeriod,
  alphaSort
} from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'employee',
    kind: 'male'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    },
    // Actions groupÃ©es sur les lignes du tableau
    selection: {
      delete: {
        buttonType: 'danger'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.employees.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Autorise les lignes en drag and drop
  areRowsDraggable: false,

  // Colonnes du tableau
  columns: {
    name: {
      title: 'pages.employees.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'name')
    },
    email: {
      title: 'pages.employees.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      render: email =>
        !isNil(email) ? email : I18n.t('common.unknown.female'),
      sorter: (...params) => alphaSort(params, 'email')
    },
    job: {
      title: 'pages.employees.fields.job.title',
      dataIndex: 'job',
      key: 'job',
      render: job => (!isNil(job) ? job : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'job')
    },
    contractType: {
      title: 'pages.employees.fields.contractType.title',
      dataIndex: 'contractType',
      key: 'contractType',
      render: contractType =>
        !isNil(contractType) ? contractType : I18n.t('common.unknown.male'),
      sorter: (...params) => alphaSort(params, 'contractType')
    },
    telephone: {
      title: 'pages.companies.fields.telephone.title',
      dataIndex: 'telephone',
      key: 'telephone',
      render: telephone =>
        !isNil(telephone) ? telephone : I18n.t('common.unknown.male'),
      sorter: (...params) => alphaSort(params, 'telephone')
    },
    securityForm: {
      title: 'pages.companies.fields.securityForm.title',
      dataIndex: 'securityForm',
      key: 'securityForm',
      sorter: (...params) => dateSort(params, 'securityForm.date'),
      ...filterPeriod(null, moment(), 'securityForm.date')
    },
    securityFormSign: {
      title: 'pages.companies.fields.securityForm.titleSign',
      dataIndex: 'securityFormSign',
      key: 'securityFormSign'
    }
  },
  
}

export default specs
