// Libraries
const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  okText: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  actions: {},
  rows: {
    roofExplosimetryMeasure: {
      title: 'pages.permits.fields.roofExplosimetryMeasure.title',
      dataIndex: 'roofExplosimetryMeasure',
      key: 'roofExplosimetryMeasure',
      formField: {
        label: 'pages.permits.fields.roofExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.roofExplosimetryMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    o2Measure: {
      title: 'pages.permits.fields.roofO2Measure.title',
      dataIndex: 'o2Measure',
      key: 'o2Measure',
      formField: {
        label: 'pages.permits.fields.roofO2Measure.title',
        placeholder: 'pages.permits.fields.roofO2Measure.placeholder'
      },
      empty: 'common.unknown.female'
    }
  }
}

export default specs
