// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Form, Icon, Input, Button, Card, message, Alert } from 'antd'

// Images
import Images from 'Images'

// Components
import { Anchor } from 'Components'

// Resources
import { actions as authActions } from 'Resources/AuthResource'

const { Item: FormItem } = Form

// Gestion du mode de dÃ©monstration
const isMockup = process.env.REACT_APP_MOCK_MODE === 'true'

class ForgottenForm extends Component {
  static propTypes = {
    actions: PropTypes.object,
    hidden: PropTypes.bool,
    fetching: PropTypes.bool,
    form: PropTypes.object,
    switchForm: PropTypes.func
  }

  static defaultProps = {
    hidden: false,
    switchForm: () => {}
  }

  constructor(props) {
    super(props)

    // initial state
    this.state = {}
  }

  /**
   * Quand le formulaire de forgotten est submit
   * @param event
   * @private
   */
  _handleSubmitForgotten = event => {
    event.preventDefault()

    const { switchForm } = this.props

    if (!isMockup) {
      this.props.form.validateFields((error, { login }) => {
        if (!error) {
          this.props.actions
            .forgottenAuth({ login })
            .then(() => {
              message.success(I18n.t('api.success.login.reset', { login }))
              switchForm('login', {
                isTemporaryMode: true,
                isResettingMode: true
              })
            })
            .catch(() => {
              message.error(I18n.t('api.errors.login.reset'))
            })
        }
      })
    } else {
      message.success(
        I18n.t('api.success.login.reset', { login: 'example@mail.com' })
      )
      switchForm('login', { isTemporaryMode: true, isResettingMode: true })
    }
  }

  render() {
    const { hidden, fetching, form, switchForm } = this.props
    const { getFieldDecorator } = form

    return (
      <Form onSubmit={this._handleSubmitForgotten}>
        <Card
          style={{ display: hidden ? 'none' : 'block' }}
          title={
            <img src={Images.logo} className='logo' alt='Logo application' />
          }
          actions={[
            <Anchor onClick={() => switchForm('login')}>
              {I18n.t('common.back')}
            </Anchor>,
            <Button type='primary' htmlType='submit' loading={fetching}>
              {I18n.t('pages.login.retrieve-password')}
            </Button>
          ]}
        >
          <Alert
            message={I18n.t('pages.login.forgotten-password.notice')}
            type='warning'
          />

          <FormItem>
            {getFieldDecorator('login', {
              rules: [
                {
                  type: 'email',
                  message: I18n.t('fields.email.patternMessage')
                },
                {
                  required: true,
                  message: I18n.t('fields.email.requiredMessage')
                }
              ]
            })(
              <Input
                prefix={<Icon type='user' />}
                placeholder={I18n.t('fields.email.placeholder')}
              />
            )}
          </FormItem>
        </Card>
      </Form>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgottenForm)
