// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { Form, Button, Alert, DatePicker, Select, Divider } from 'antd'
import {
  get,
  map,
  defaultTo,
  cloneDeep,
  isNil,
  forEach,
  find,
  isEmpty,
  omitBy
} from 'lodash'

// Components
import { Container } from 'Components/Modals'

// Styles
import './EndModal.less'

// Helper
import { createFormFields, isDeepNil } from 'Helpers'
import moment from 'moment'

const { Item: FormItem } = Form
// Sauvegarde du formulaire en temps rÃ©el
// Permet de conserver les valeurs si le composant est re-render
let UNSAVED_FORM = {}

class EndModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.object,
    okText: PropTypes.object,
    cancelText: PropTypes.object,

    // ContrÃ´le d'Ã©tat de la modale
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    popup: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    loading: PropTypes.bool,
    openedModal: PropTypes.string,


    // Contenu de la modale
    data: PropTypes.object,
    form: PropTypes.object,
    supervisors: PropTypes.array,
    addSupervisor: PropTypes.func,

    // Fonctions de callback
    onCancel: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // Labels et titres
    title: {
      single: 'pages.permits.modal.close.single.title',
      multiple: 'pages.permits.modal.close.multiple.title'
    },
    okText: {
      single: 'pages.permits.modal.close.single.okText',
      multiple: 'pages.permits.modal.close.multiple.okText'
    },
    cancelText: {
      single: 'pages.permits.modal.close.single.cancelText',
      multiple: 'pages.permits.modal.close.multiple.cancelText'
    },

    // ContrÃ´le d'Ã©tat de la modale
    width: 520,
    popup: 480,
    visible: false,
    loading: true,

    // Contenu de la modal
    data: {},
    openedModal: '',
    form: {},
    supervisors: [],
    addSupervisor: () => {},

    // Fonctions de callback
    onCancel: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    this.sigCanvas1 = React.createRef()

    // Ãtats initiaux
    this.state = {}
  }

  handleCancel = e => {
    const { onCancel, form } = this.props

    // Reset du formulaire
    form.resetFields()
    UNSAVED_FORM = {}

    onCancel(e)
  }

  /**
   * Envoi du formulaire
   */
  handleSubmit = e => {
    e.preventDefault()
    const pad1result = this.sigCanvas1.current?.toDataURL()

    const { data, onChange, form } = this.props

    form.validateFields((error, formData) => {
      if (!error) {
        formData.id = data.id
        formData.controllerSign = pad1result

        onChange(formData).then(() => (UNSAVED_FORM = {}))
      }
    })
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const { loading, okText, cancelText, form } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isDeepNil)
    const isValidForm = isEmpty(formErrors)

    return (
      <div className='manage-modal-footer'>
        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='manage-modal-footer-controls'>
          <Button key='back' onClick={this.handleCancel}>
            {I18n.t(get(cancelText, mode))}
          </Button>
          <Button
            key='submit'
            type='primary'
            disabled={!isValidForm}
            loading={loading}
            onClick={this.handleSubmit}
          >
            {I18n.t(get(okText, mode))}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {
      loading,
      visible,
      title,
      okText,
      cancelText,
      form,
      width,
      popup,
      supervisors,
      openedModal
    } = this.props


    const { getFieldDecorator } = form

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm = isEmpty(formErrors) && formChanged

    var now = moment()
    var tomorrow = now.add(8, 'hour')

    // Mettre les dates de validitÃ© si disponibles
    if ( this.props.data !== undefined && Object.keys(this.props.data).length !== 0) {

      if (this.props.data.validityPeriod !== undefined) {
        now = moment(this.props.data.validityPeriod.startDate)
        tomorrow =moment(this.props.data.validityPeriod.endDate).add(1, 'hour')
      }
    }
    
    return (
      <Container
        title={I18n.t(get(title, mode))}
        loading={loading}
        visible={visible}
        cancelText={I18n.t(get(cancelText, mode))}
        okText={I18n.t(get(okText, mode))}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okButtonProps={{
          disabled: !isValidForm
        }}
        className={'modal end-modal'}
        footer={this.renderFooter()}
        width={width}
      >
        <Form onSubmit={this._handleSubmit}>
          <Alert
            message={I18n.t('pages.permits.modal.close.single.content')}
            type='warning'
          />

          {/* Date + heure de fin */}
          <FormItem label={I18n.t(`pages.permits.fields.endDate.title`)}>
            {getFieldDecorator('date', {
              initialValue: now,
              rules: [
                {
                  required: true,
                  message: I18n.t(
                    `pages.permits.fields.endDate.requiredMessage`
                  )
                }
              ]
            })(
              <DatePicker
                placeholder={I18n.t(`pages.permits.fields.endDate.placeholder`)}
                showTime={{
                  format: 'HH:mm'
                }}
                format='DD/MM/YYYY HH:mm'
                disabledDate={date => {
                  return (
                    date.isAfter(tomorrow) ||
                    date.isBefore(this.props.data.dateCreated)
                  )
                }}
                style={{ width: '100%' }}
                dropd
                ownClassName='calendar-fluid'
                popupStyle={{ width: popup }}
              />
            )}
          </FormItem>
          {/* Surveillant */}
          <FormItem label={I18n.t(`pages.permits.fields.supervisor.title`)}>
            {getFieldDecorator('person', {
              initialValue: this.props.data.supervisor?.id,
              rules: [
                {
                  required: false,
                  message: I18n.t(
                    `pages.permits.fields.supervisor.requiredMessage`
                  )
                }
              ]
            })(
              <Select
                allowClear
                placeholder={I18n.t(
                  `pages.permits.fields.supervisor.placeholder`
                )}
                showSearch
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                dropdownRender={menu => (
                  <div>
                    {menu}
                    <Divider style={{ margin: '4px 2px' }} />
                    <Button
                      icon='plus'
                      type='link'
                      onMouseDown={event => event.preventDefault()}
                      onClick={() => this.props.addSupervisor()}
                    >
                      {I18n.t('pages.permits.fields.supervisor.add')}
                    </Button>
                  </div>
                )}
              >
                {supervisors.map((el, index) => (
                  <Select.Option key={index} value={el.id}>
                    {el.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </FormItem>
          
          {// Signature pour le permit feu uniquement
          openedModal === 'endFirePermit' && (
          <FormItem label={I18n.t(`pages.permits.fields.signature3.firePermitTitle`)}>
                    {getFieldDecorator('controllerSign')(
                      <div className='sigPadContainer'>
                        <SignatureCanvas
                          penColor='black'
                          ref={this.sigCanvas1}
                          canvasProps={{ className: 'sigCanvas' }}
                        />
                        <Button onClick={() => this.sigCanvas1.current.clear()}>
                          {I18n.t(`common.clear`)}
                        </Button>
                      </div>
                    )}
                  </FormItem>
          )}
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(EndModal, 'defaultProps', {})

  return {
    supervisors: defaultTo(
      get(state, 'supervisor.items'),
      defaultProps.supervisors
    )
  }
}

const mapDispatchToProps = () => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange(props, fieldValue, fieldsValue) {
      UNSAVED_FORM = fieldsValue
    },
    mapPropsToFields(props) {
      const defaultProps = get(EndModal, 'defaultProps', {})
      let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

      if (!isEmpty(UNSAVED_FORM)) {
        formData = UNSAVED_FORM
      } else {
        // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
        forEach(formData, (value, index) => {
          const format = get(
            find(map(get(props, 'rows')), { dataIndex: index }),
            'formField.format'
          )

          formData[index] = !isNil(format) ? format(value, formData) : value
        })
      }

      return createFormFields(Form, formData)
    }
  })(EndModal)
)
