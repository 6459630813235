// Libraries
import I18n from 'i18next'
import { Layout, Spin } from 'antd'
import React, { Component } from 'react'

// Styles
import './LoadingScreen.less'

const { Content } = Layout

class LoadingScreen extends Component {
  static propTypes = {}

  static defaultProps = {}

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return (
      <Layout>
        <Content>
          <main className='screen loading'>
            <Spin tip={I18n.t('common.loading')} />
          </main>
        </Content>
      </Layout>
    )
  }
}

export default LoadingScreen
