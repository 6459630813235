// Libraries
import moment from 'moment'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  okText: {
    update: 'pages.permits.modal.capacityPermit.control.title'
  },
  actions: {},
  rows: {
    entryExplosimetryMeasure: {
      title: 'pages.permits.fields.entryExplosimetryMeasure.title',
      dataIndex: 'entryExplosimetryMeasure',
      key: 'entryExplosimetryMeasure',
      formField: {
        label: 'pages.permits.fields.entryExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.entryExplosimetryMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    exitExplosimetryMeasure: {
      title: 'pages.permits.fields.exitExplosimetryMeasure.title',
      dataIndex: 'exitExplosimetryMeasure',
      key: 'exitExplosimetryMeasure',
      formField: {
        label: 'pages.permits.fields.exitExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.exitExplosimetryMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    entryTemperatureMeasure: {
      title: 'pages.permits.fields.entryTemperatureMeasure.title',
      dataIndex: 'entryTemperatureMeasure',
      key: 'entryTemperatureMeasure',
      formField: {
        label: 'pages.permits.fields.entryTemperatureMeasure.title',
        placeholder: 'pages.permits.fields.entryTemperatureMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    entryTemperatureMeasureDate: {
      title: 'pages.permits.fields.entryTemperatureMeasureDate.title',
      dataIndex: 'entryTemperatureMeasureDate',
      key: 'entryTemperatureMeasureDate',
      formField: {
        type: 'date',
        label: 'pages.permits.fields.entryTemperatureMeasureDate.title',
        placeholder:
          'pages.permits.fields.entryTemperatureMeasureDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    },
    insideTemperatureMeasure: {
      title: 'pages.permits.fields.insideTemperatureMeasure.title',
      dataIndex: 'insideTemperatureMeasure',
      key: 'insideTemperatureMeasure',
      formField: {
        label: 'pages.permits.fields.insideTemperatureMeasure.title',
        placeholder: 'pages.permits.fields.insideTemperatureMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIECenter: {
      title: 'pages.permits.fields.LIEMeasure.title.center',
      dataIndex: 'tankLIECenter',
      key: 'tankLIECenter',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.center',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEFirstPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.first',
      dataIndex: 'tankLIEFirstPoint',
      key: 'tankLIEFirstPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.first',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIESecondPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.second',
      dataIndex: 'tankLIESecondPoint',
      key: 'tankLIESecondPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.second',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEThirdPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.third',
      dataIndex: 'tankLIEThirdPoint',
      key: 'tankLIEThirdPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.third',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    },
    tankLIEFourthPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.fourth',
      dataIndex: 'tankLIEFourthPoint',
      key: 'tankLIEFourthPoint',
      formField: {
        label: 'pages.permits.fields.LIEMeasure.title.fourth',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      empty: 'common.unknown.female'
    }
  }
}

export default specs
