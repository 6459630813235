import { map } from 'lodash'
import I18n from 'i18next'

const specs = {
  zIndex: 3000,
  width: 620,
  title: {
    update: 'pages.companies.modal.update.single.title',
    create: 'pages.companies.modal.create.single.title'
  },
  okText: {
    update: 'pages.companies.modal.update.single.okText',
    create: 'pages.companies.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.companies.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        label: 'pages.companies.fields.name.title',
        required: 'pages.companies.fields.name.requiredMessage',
        placeholder: 'pages.companies.fields.name.placeholder'
      },
      empty: 'common.unknown.9male'
    },
    type: {
      title: 'pages.companies.fields.type.title',
      dataIndex: 'type',
      key: 'type',
      formField: {
        label: 'pages.companies.fields.type.title',
        placeholder: 'pages.companies.fields.type.placeholder'
      },
      empty: 'common.unknown.male'
    },
    email: {
      title: 'pages.companies.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      formField: {
        label: 'pages.companies.fields.email.title',
        placeholder: 'pages.companies.fields.email.placeholder',
        pattern: {
          type: 'email',
          message: 'pages.companies.fields.email.pattern'
        }
      },
      empty: 'common.unknown.female'
    },
    telephone: {
      title: 'pages.companies.fields.telephone.title',
      dataIndex: 'telephone',
      key: 'telephone',
      formField: {
        label: 'pages.companies.fields.telephone.title',
        placeholder: 'pages.companies.fields.telephone.placeholder',
        pattern: {
          pattern: '(\\+33|0)[0-9]{9}',
          message: 'pages.companies.fields.telephone.pattern'
        }
      },
      empty: 'common.unknown.female'
    },
    critical: {
      title: 'pages.companies.fields.critical.title',
      dataIndex: 'critical',
      key: 'critical',
      formField: {
        label: 'pages.companies.fields.critical.title',
        placeholder: 'pages.companies.fields.critical.placeholder',
        type: 'radios',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(
            `pages.companies.fields.critical.options.${bool}.title`
          ),
          value: bool,
          data: bool
        }))
      },
      empty: 'common.unknown.male'
    },
    note: {
      title: 'pages.companies.fields.note.title',
      dataIndex: 'note',
      key: 'note',
      formField: {
        label: 'pages.companies.fields.note.title',
        placeholder: 'pages.companies.fields.note.placeholder',
        type: 'textarea'
      },
      empty: 'common.unknown.female'
    }
  }
}

export default specs
