import { createResource } from 'redux-rest-resource'
import { baseURL } from './'

/*
  Ceci est un exemple de resource mais pas utilisÃ©e
 */
export const { types, actions, rootReducer } = createResource({
  name: 'auth',
  url: `${baseURL}/auth`,
  actions: {
    login: { method: 'POST', gerundName: 'loggingIn', url: './login' },
    forgotten: {
      method: 'POST',
      gerundName: 'askingForgotten',
      url: './login/forgot-password'
    },
    fetchRoles: {
      method: 'GET',
      gerundName: 'fetching',
      url: './roles/',
      reduce: (state, { body: roles }) => ({
        ...state,
        roles
      })
    },
    checkLogin: {
      method: 'POST',
      gerundName: 'checkingLogin',
      url: './login/check'
    },
    checkCode: {
      method: 'POST',
      gerundName: 'checkingCode',
      url: './login/code'
    },
    resetPassword: {
      method: 'POST',
      gerundName: 'resettingPassword',
      url: './login/update-forgot-password'
    },
    setPassword: {
      method: 'POST',
      gerundName: 'settingPassword',
      url: './login/set-password'
    },
    logout: { method: 'GET', gerundName: 'loggingOut', url: './logout' },
    set: {
      isPure: true,
      reduce: (state, { context: credentials }) => ({
        ...state,
        item: credentials
      })
    },
    // Vidage du reducer
    reset: {
      isPure: true,
      reduce: ({ item, items, ...state }) => state
    }
  },
  credentials: 'include',
  stripTrailingSlashes: false
})
