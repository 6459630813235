const specs = {
  width: 620,
  title: {
    update: 'pages.condition.modal.update.single.title',
    create: 'pages.condition.modal.create.single.title'
  },
  okText: {
    update: 'pages.condition.modal.update.single.okText',
    create: 'pages.condition.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.habilitations.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        label: 'pages.habilitations.fields.name.title',
        required: 'pages.habilitations.fields.name.requiredMessage',
        placeholder: 'pages.habilitations.fields.name.placeholder'
      },
      empty: 'common.unknown.male'
    },
    code: {
      title: 'pages.habilitations.fields.code.title',
      dataIndex: 'code',
      key: 'code',
      formField: {
        label: 'pages.habilitations.fields.code.title',
        required: 'pages.habilitations.fields.code.requiredMessage',
        placeholder: 'pages.habilitations.fields.code.placeholder'
      },
      empty: 'common.unknown.male'
    },
    description: {
      title: 'pages.habilitations.fields.description.title',
      dataIndex: 'description',
      key: 'description',
      formField: {
        label: 'pages.habilitations.fields.description.title',
        required: 'pages.habilitations.fields.description.requiredMessage',
        placeholder: 'pages.habilitations.fields.description.placeholder',
        type: 'textarea'
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
