// Libraries
import I18n from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { Empty, message, Modal } from 'antd'
import {
  get,
  includes,
  defaultTo,
  isNil,
  replace,
  map,
  find,
  has
} from 'lodash'

// Ressources
import { actions as companyActions } from 'Resources/CompanyResource'
import { actions as employeeActions } from 'Resources/EmployeeResource'
import { actions as habilitationActions } from 'Resources/HabilitationResource'
import { actions as userActions } from 'Resources/UserResource'

// Common components
import { ManageModal } from 'Components'
import { SignatureModal } from './Specs/SecurityFormSignature'


// Specs
import {
  FormSpecs,
  ModalSpecs,
  EmployeeModalSpecs,
  SecurityFormModalSpecs,
  HabilitationModalSpecs
} from 'Views/Companies/Specs'

// Ãcrans
import { List, Single } from './Screens'

const { confirm } = Modal

class CompaniesConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    company: PropTypes.object,
    companies: PropTypes.array,
    totalCompanies: PropTypes.number,
    users: PropTypes.array,
    habilitation: PropTypes.object,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    }),

    // DonnÃ©es complÃ©mentaires
    profile: PropTypes.object
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    company: {},
    companies: [],
    totalCompanies: 0,
    users: [],
    habilitation: {},

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null,
      currentTab: 'information',
      employee: {},
      habilitation: {},
      habilitationsList: [],
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchCompaniesData()
    this.fetchUsersData()
    this.fetchHabilitationsData()

    if (this.getContext() === 'single') {
      this.getCompanyData()
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchCompaniesData = (
    size = 10,
    offset = 0,
    sortField = 'dateCreated',
    sortOrder = 'descend',
    filters = {},
    search = ''
  ) => {
    this.props.actions
      .fetchCompanies(null, {
        query: {
          limit: size,
          offset,
          sortField,
          sortOrder,
          filters,
          search
        }
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.fetch'))
      })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des utilisateurs
   */
  fetchUsersData = () => {
    this.props.actions.fetchUsers().catch(e => {
      e.status === 403
        ? message.error(I18n.t('api.errors.exceptions.403.desc'))
        : message.error(I18n.t('api.errors.user.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des habilitations
   */
  fetchHabilitationsData = () => {
    this.props.actions.fetchHabilitations().then(habilitationsList => {
      this.setState({ habilitationsList });
    }).catch(e => {
      e.status === 403
        ? message.error(I18n.t('api.errors.exceptions.403.desc'))
        : message.error(I18n.t('api.errors.user.fetch'))
    });
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getCompanyData = (company = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getCompany(company)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.company.get'))
        })
    })

  handleTableOrdered = (page, size, field, order, filters, search) => {
    const offset = page * size - size
    this.fetchCompaniesData(size, offset, field, order, filters, search)
  }

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyCompany = (company, clearTableSelections) => {
    this.props.actions
      .archiveCompanies(company)
      .then(() => {
        clearTableSelections()
        this.fetchCompaniesData()
        message.success(I18n.t('api.success.company.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.bulk.delete'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyEmployee = (employee, clearTableSelections) => {
    const { actions, company } = this.props

    actions
      .archiveEmployees(employee)
      .then(() => {
        clearTableSelections()
        this.getCompanyData(company)
        message.success(I18n.t('api.success.employee.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.employee.bulk.delete'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveEmployee = () => {
    const { actions, company } = this.props
    const { employee } = this.state

    actions
      .archiveEmployee(employee)
      .then(() => {
        this.getCompanyData(company)
        this.safelyCloseModal()
        message.success(I18n.t('api.success.employee.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.employee.delete'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveCompany = company => {
    this.props.actions
      .archiveCompany(company)
      .then(() => {
        this.safelyCloseModal()
        this.fetchCompaniesData()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.company.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.delete'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyCompany = (company, clearTableSelections) => {
    this.props.actions
      .deleteCompanies(company)
      .then(() => {
        clearTableSelections()
        this.fetchCompaniesData()
        message.success(I18n.t('api.success.company.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteCompany = company => {
    this.props.actions
      .deleteCompany(company)
      .then(() => {
        this.safelyCloseModal()
        this.fetchCompaniesData()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.company.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.company.delete'))
      })
  }

  /**
   *
  */
 deleteHabilitationEmployee = (habilitation) => {
  const { actions, company } = this.props

    actions
      .deleteGivenHabilitationEmployee({
        habilitation: habilitation.id,
        id: habilitation.employee.id,
      })
      .then(() => {
        this.safelyCloseModal()
        this.getCompanyData(company)
        message.success(I18n.t('api.success.employee.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.employee.delete'))
      })
 }

  /**
   * Mise Ã  jour d'un company
   */
  updateCompany = (updatedCompany, originalCompany) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateCompany({
          id: get(originalCompany, 'id'),
          ...updatedCompany
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchCompaniesData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.company.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.company.update'))
        })
    })

  /**
   * CrÃ©ation d'un company
   */
  createCompany = createdCompany =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createCompany(createdCompany)
        .then(response => {
          this.safelyCloseModal()
          this.fetchCompaniesData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.company.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.company.create'))
        })
    })

  /**
   * Mise Ã  jour d'un employÃ©
   */
  updateEmployee = (updatedEmployee, originalEmployee) =>
    new Promise((resolve, reject) => {
      const { actions, company } = this.props

      actions
        .updateEmployee({
          id: get(originalEmployee, 'id'),
          ...updatedEmployee,
          company: get(company, 'id')
        })
        .then(response => {
          this.safelyCloseModal()
          this.getCompanyData(company)
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.employee.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.employee.update'))
        })
    })

  /**
   * CrÃ©ation d'un employÃ©
   */
  createEmployee = createdEmployee =>
    new Promise((resolve, reject) => {
      const { actions, company } = this.props

      actions
        .createEmployee({ ...createdEmployee, company: get(company, 'id') })
        .then(response => {
          this.safelyCloseModal()
          this.getCompanyData(company)
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.employee.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.employee.create'))
        })
    })

  createSecurityForm = form =>
  new Promise((resolve, reject) => {

    const { employee, company } = this.state
    form.id = employee.id

    this.props.actions.createSecurityFormEmployee(form)
    .then((response) => {
      this.safelyCloseModal()
      this.getCompanyData(company)

      message.success(I18n.t('api.success.employee.create'))
    })
    .catch(error => {
      reject(error)
      message.error(I18n.t('api.errors.employee.export'))
    })
  })


  updateSecurityForm = form =>
    new Promise((resolve, reject) => {

    const { employee, company } = this.state
    form.id = employee.id
    this.props.actions.updateSecurityFormEmployee(form)
    .then((response) => {
      this.safelyCloseModal()
      this.getCompanyData(company)
      message.success(I18n.t('api.success.employee.update'))
    })
      .catch(error => {
        reject(error)
        message.error(I18n.t('api.errors.employee.update'))
      })
  })


  /**
   * Signature d'un receptionSecurity
   */
  signSecurityForm = (scan, option) =>
    new Promise((resolve, reject) => {

      const { employee, company } = this.state

      this.props.actions
        .signSecurityFormEmployee(
          { id: get(employee, 'id'), scan },
          { query: { option: option } }
        )
        .then(() => {
          this.safelyCloseModal()
          this.getCompanyData(company)
          message.success(I18n.t('api.success.permit.sign'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.sign'))
        })
    })



  exportSecurityForm = () =>
    new Promise((resolve, reject) => {

    const { employee } = this.state

    this.props.actions.exportSecurityPermitEmployee({id: employee.id})
    .then((response) => {
      this.safelyCloseModal()
      message.success(I18n.t('api.success.employee.export'))
      if (has(response.body, 'url')) {
        window.open(get(response.body, 'url'), '_blank')
      }
    })
    .catch(error => {
      reject(error)
      message.error(I18n.t('api.errors.employee.export'))
    })
  })

    /**
   * Demande d'export de l'accueil de sÃ©curitÃ©
   */
    askExportSecurityForm = () => {
      confirm({
        title: I18n.t('pages.employees.modal.export.title'),
        content: I18n.t('pages.employees.modal.export.title'),
        okText: I18n.t('pages.employees.modal.export.title'),
        cancelText: I18n.t('pages.employees.modal.export.cancelText'),

        okType: 'edit',
        onOk: () => {
          this.exportSecurityForm()
        },
        maskClosable: true
      })
    }

  /**
   * CrÃ©ation d'une habilitation pour un employee
   */
  createHabilitationEmployee = async form => {
    await new Promise((resolve, reject) => {
      const { company } = this.state
      this.props.actions.createGivenHabilitationEmployee({...form, id: form.employee})
      .then((response) => {

        if (form.proofFile != null) {
          const lengthHabilitation = response.body.habilitations.length;
          const idHabilitation = response.body.habilitations[lengthHabilitation-1].id

          const data = new FormData()
              data.append('habilitation_id', idHabilitation)
              data.append('employee_id', form.employee)
              data.append('proofFile', form.proofFile)

          new Promise(async (resolve, reject) => {
            await this.props.actions.createGivenHabilitationProofFileEmployee(data)
              .then((response) => {
                message.success(I18n.t('api.success.givenHabilitation.create'))
              })
              .catch(error => {
                reject(error)
                message.error(I18n.t('api.errors.givenHabilitation.export'))
              })
          })
      }
        this.safelyCloseModal()
        this.getCompanyData(company)
        message.success(I18n.t('api.success.givenHabilitation.create'))
      })
      .catch(error => {
        reject(error)
        message.error(I18n.t('api.errors.givenHabilitation.export'))
      })
    })
  }

  /**
   * Modification d'une habilitation pour un employee
   */
  updateHabilitationEmployee = (form, originalHabilitation) => {
    new Promise((resolve, reject) => {
      const { company } = this.props

      this.props.actions.updateGivenHabilitationEmployee({...form, id: form.employee})
        .then(response => {
          if (form.proofFile !== null && originalHabilitation.proofFile !== form.profile) {

            const data = new FormData()
            data.append('habilitation_id', form.habilitation)
            data.append('employee_id', form.employee)
            data.append('proofFile', form.proofFile)

            new Promise((resolve, reject) => {
              this.props.actions.createGivenHabilitationProofFileEmployee(data)
              .then((response) => {
                message.success(I18n.t('api.success.givenHabilitation.create'))
              })
              .catch(error => {
                reject(error)
                message.error(I18n.t('api.errors.givenHabilitation.export'))
              })
            })
          }
          this.safelyCloseModal()
          this.getCompanyData(company)
          this.fetchHabilitationsData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.givenHabilitation.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.givenHabilitation.update'))
        })
    })
  }

  /**
   * Demadne de suppression d'un employÃ©
   */
  askDeleteEmployee = () => {
    confirm({
      title: I18n.t('pages.employees.modal.delete.single.title'),
      content: I18n.t('pages.employees.modal.delete.single.content'),
      okText: I18n.t('pages.employees.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.employees.modal.delete.single.cancelText'),
      onOk: () => {
        this.archiveEmployee()
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteCompanyManyEmployees = (...params) => {
    confirm({
      title: I18n.t('pages.employees.modal.delete.multiple.title'),
      content: I18n.t('pages.employees.modal.delete.multiple.content'),
      okText: I18n.t('pages.employees.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.employees.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.archiveManyEmployee(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyCompanies = (...params) => {
    confirm({
      title: I18n.t('pages.companies.modal.archive.multiple.title'),
      content: I18n.t('pages.companies.modal.archive.multiple.content'),
      okText: I18n.t('pages.companies.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.companies.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveCompany(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveCompany = () => {
    const { company } = this.props

    confirm({
      title: I18n.t('pages.companies.modal.archive.single.title'),
      content: I18n.t('pages.companies.modal.archive.single.content'),
      okText: I18n.t('pages.companies.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.companies.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveCompany(company)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyCompanies = (...params) => {
    confirm({
      title: I18n.t('pages.companies.modal.delete.multiple.title'),
      content: I18n.t('pages.companies.modal.delete.multiple.content'),
      okText: I18n.t('pages.companies.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.companies.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.archiveManyCompany(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteCompany = () => {
    const { company } = this.props

    confirm({
      title: I18n.t('pages.companies.modal.delete.single.title'),
      content: I18n.t('pages.companies.modal.delete.single.content'),
      okText: I18n.t('pages.companies.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.companies.modal.delete.single.cancelText'),
      onOk: () => {
        this.archiveCompany(company)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de suppression de plusieurs habilitations
   */
  askDeleteManyHabilitationsEmployee = (...params) => {
    confirm({
      title: I18n.t('pages.habilitations.modal.delete.single.title'),
      content: I18n.t('pages.habilitations.modal.delete.single.content'),
      okText: I18n.t('pages.habilitations.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.habilitations.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteManyGivenHabilitationEmployee(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de suppression d'une seule habilitation
   */
  askDeleteHabilitationEmployee = () => {
    const { employee } = this.state

    confirm({
      title: I18n.t('pages.habilitations.modal.delete.single.title'),
      content: I18n.t('pages.habilitations.modal.delete.single.content'),
      okText: I18n.t('pages.habilitations.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.habilitations.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteHabilitationEmployee(employee)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateCompany = updatedCompany => {
    const { currentTab } = this.state
    const { company } = this.props

    if (currentTab === 'information' || !currentTab) {
      return this.updateCompany(updatedCompany, company)
    } else {
      // return this.updateFirePermit(updatedPermit, currentTab)
    }
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateCompany = () => {
    this.setState({ openedModal: 'createCompany' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateCompanyEmployee = () => {
    this.setState({ openedModal: 'createEmployee' })
  }

  /**
   * Demande de crÃ©ation de formulaire d'accueil
   */
  askCreateSecurityForm = employee => {
    this.setState({ openedModal: 'createSecurityForm', employee })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau d'habilitation
   */
  askCreateHabilitationEmployee = () => {
    this.setState({openedModal: 'createHabilitationEmployee'})
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateCompanyEmployee = employee => {
    if (this.state.openedModal === null)
      this.setState({ openedModal: 'updateEmployee', employee })
  }

  /**
   * Demande de modification d'une ligne du tableau d'habilitations
   */
  askUpdateHabilitationEmployee = (employee, habilitation) => {
    if (this.state.openedModal === null)
      this.setState({openedModal: 'updateHabilitationEmployee', employee, habilitation})
  }

  /*
  * Demande de modification de formulaire d'accueil
   */
  askUpdateSecurityForm = employee => {
    if (this.state.openedModal === null)
      this.setState({ openedModal: 'updateSecurityForm', employee })
  }

    /**
   * Demande de signature d'un permis
   */
    askSignSecurityForm = employee => {

      this.setState({ openedModal: 'signSecurityForm', employee })

    }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateCompany') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = company => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(company, 'id') })

    this.getCompanyData(company).catch(() => {
      this.props.navigation.setParams({ id: null })
    })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('Company', { id: get(company, 'id') })
  }

  handleTabChange = tabID => {
    if (!isNil(tabID)) {
      this.setState({ currentTab: tabID })
    }
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    const authorizations = [{ name: 'N1', id: 0 }, { name: 'N2', id: 1 }]
    // CrÃ©ation de la liste de sÃ©lÃ©ction des habilitaitons
    if (has(rows, 'authorization')) {
      rows.authorization.formField.options = map(
        authorizations,
        authorization => ({
          id: authorization,
          label: get(authorization, 'name'),
          disabled: false,
          value: get(authorization, 'id'),
          data: authorization
        })
      )

      rows.authorization.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let { isGathering, isUpdating, isCreating, companies, company } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal, employee, habilitationsList } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      handleTableOrdered: this.handleTableOrdered,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getCompanyData: this.getCompanyData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateCompany: this.askCreateCompany,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateCompany: this.askUpdateCompany,
      askUpdateManyCompanies: this.askUpdateCompany,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveCompany: this.askArchiveCompany,
      askArchiveManyCompanies: this.askArchiveManyCompanies,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteCompany: this.askDeleteCompany,
      askDeleteManyCompanies: this.askDeleteManyCompanies,
      // SecurityForm
      askCreateSecurityForm: this.askCreateSecurityForm,
      createSecurityForm: this.createSecurityForm,
      askUpdateSecurityForm: this.askUpdateSecurityForm,
      updateSecurityForm: this.updateSecurityForm,
      askExportSecurityForm: this.askExportSecurityForm,
      askSignSecurityForm: this.askSignSecurityForm,

      // Gestion des employÃ©es d'une entreprise
      askCreateCompanyEmployee: this.askCreateCompanyEmployee,
      askUpdateCompanyEmployee: this.askUpdateCompanyEmployee,

      askDeleteCompanyEmployee: this.askDeleteCompanyEmployee,
      askDeleteCompanyManyEmployees: this.askDeleteCompanyManyEmployees,

      //Habilitation
      askCreateHabilitationEmployee: this.askCreateHabilitationEmployee,
      askUpdateHabilitationEmployee: this.askUpdateHabilitationEmployee,
      createHabilitationEmployee: this.createHabilitationEmployee,
      updateHabilitationEmployee: this.updateHabilitationEmployee,
      deleteHabilitationEmployee: this.deleteHabilitationEmployee,
      askDeleteHabilitationEmployee: this.askDeleteHabilitationEmployee,
    }

    // CrÃ©ation de la liste des Ã©crans associÃ©s aux contextes du constructeur
    const contextsScreen = {
      single: Single,
      list: List
    }

    // RÃ©cupÃ©ration de l'Ã©cran appropriÃ© au contexte du constructeur
    const ContextScreen = get(contextsScreen, this.getContext(), Empty)

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteCompany
    EmployeeModalSpecs.actions.delete.onClick = this.askDeleteEmployee
    EmployeeModalSpecs.rows = this.formatModalRows(EmployeeModalSpecs.rows)

    HabilitationModalSpecs.actions.delete.onClick = this.askDeleteHabilitationEmployee
    SecurityFormModalSpecs.rows = this.formatModalRows(SecurityFormModalSpecs.rows)
    SecurityFormModalSpecs.actions.export.onClick = this.askExportSecurityForm


    // Choix du compte utilisateur associÃ©
    const companyUsers = this.props.users.filter(
      el => el.company?.id === this.props.company?.id && el.employee == null
    )
    EmployeeModalSpecs.rows.user.formField.options = map(
      companyUsers,
      ({ fullname, id }) => ({
        value: id,
        id,
        label: fullname
      })
    )

    // SÃ©lection du compte utilisateur
    EmployeeModalSpecs.rows.user.formField.onChange = selectedUser => {
      const user = find(this.props.users, ['id', selectedUser])
      this.setState({ userChosen: user })
    }

    // Choix de la liste des habilitations correspondant au salariÃ©
    HabilitationModalSpecs.rows.habilitations.formField.options = map(
      habilitationsList.body,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    HabilitationModalSpecs.rows.employees.formField.options = map(
      company.employees,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    if (openedModal === 'updateHabilitationEmployee'){
      HabilitationModalSpecs.rows.employees.formField.readOnly = true
      HabilitationModalSpecs.rows.employees.formField.disabled = true

      HabilitationModalSpecs.rows.habilitations.formField.readOnly = true
      HabilitationModalSpecs.rows.habilitations.formField.disabled = true
    } else {
      HabilitationModalSpecs.rows.employees.formField.readOnly = false
      HabilitationModalSpecs.rows.employees.formField.disabled = false

      HabilitationModalSpecs.rows.habilitations.formField.readOnly = false
      HabilitationModalSpecs.rows.habilitations.formField.disabled = false
    }

    //DÃ©finition d'un objet Habilitation pour afficher
    let habilitationEmpl = {}
    if (Array.isArray(habilitationsList.body)) {
      for (const item of habilitationsList.body) {
        if(item.id === employee.id & item.name === employee.name) {
          habilitationEmpl = item
        }
      }
    }

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <ContextScreen
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          FormSpecs={FormSpecs}
          dataList={companies}
          data={company}
          // dataContext={dataContext}
          {...this.props}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateCompany'
              ? this.updateCompany
              : this.createCompany
          }
          visible={includes(['createCompany', 'updateCompany'], openedModal)}
          data={
            includes(['transition', 'updateCompany'], openedModal)
              ? company
              : null
          }
          {...ModalSpecs}
        />

        {/* Ouverture de la modale de crÃ©ation / modification d'un employee de l'entreprise */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateEmployee'
              ? this.updateEmployee
              : this.createEmployee
          }
          visible={includes(['createEmployee', 'updateEmployee'], openedModal)}
          mode={replace(openedModal, 'Employee', '')}
          data={employee}
          {...EmployeeModalSpecs}
        />
        {/* Ouverture de la modale de crÃ©ation / modification d'un formulaire d'accueil */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onChange={openedModal === 'updateSecurityForm'
            ? this.updateSecurityForm
            : this.createSecurityForm}
          mode={replace(openedModal, 'SecurityForm', '')}
          visible={includes(['createSecurityForm', 'updateSecurityForm'], openedModal)}
          // Contrruction du
          data={{
            name: get(employee, 'name'),
            job: get(employee, 'job'),
            contractType: get(employee, 'contractType'),
            isMedicalyAble: ( employee.receptionSecurityPermits === undefined || employee.receptionSecurityPermits.length === 0) ? null : get(employee.receptionSecurityPermits[0], 'isMedicalyAble'),
            medicalDesc: ( employee.receptionSecurityPermits === undefined || employee.receptionSecurityPermits.length === 0) ? null : get(employee.receptionSecurityPermits[0], 'medicalDesc'),
            isCompanyLeaderInformed: ( employee.receptionSecurityPermits === undefined || employee.receptionSecurityPermits.length === 0) ? null : get(employee.receptionSecurityPermits[0], 'isCompanyLeaderInformed'),
            startDate: ( employee.receptionSecurityPermits === undefined || employee.receptionSecurityPermits.length === 0) ? moment() : get(employee.receptionSecurityPermits[0], 'startDate'),
            endDate: ( employee.receptionSecurityPermits === undefined || employee.receptionSecurityPermits.length === 0) ? moment().add(1, 'Y') : get(employee.receptionSecurityPermits[0], 'endDate')
          }}
          {...SecurityFormModalSpecs}

        />

        {/* Ouverture de la modale de crÃ©ation/modification d'une habilitation */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onChange={
            openedModal === 'updateHabilitationEmployee'
              ? this.updateHabilitationEmployee
              : this.createHabilitationEmployee
          }
          visible={includes(['createHabilitationEmployee', 'updateHabilitationEmployee'], openedModal)}
          mode={replace(openedModal, 'HabilitationEmployee', '')}
          data={
            openedModal === 'updateHabilitationEmployee'
            ? {
                employee: employee.employee,
                habilitation: habilitationEmpl,
                endDate: (employee.endDate === null) ? null : employee.endDate,
                proofFile: (employee.proofFile === null) ? null : employee.proofFile,
              }
            : {
                endDate: null,
              }
          }
          {...HabilitationModalSpecs}
        />


       {/* Ouverture de la modale de signature de l'accueil securitÃ© */}
{
        <SignatureModal
          data={employee}
          currentTab={this.state.currentTab}
          openedModal={openedModal}
          width={700}
          loading={loading}
          onChange={(scan, data, option) => {
            switch (openedModal) {
              default:
                this.signSecurityForm(scan, option)
                break
            }
          }}
          onOk={this.safelyCloseModal}
          onCancel={this.safelyCloseModal}
          visible={includes(
            [
              'signSecurityForm'
            ],
            openedModal
          )}
          constructorFunctions={constructorFunctions}
        />}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CompaniesConstructor, 'defaultProps', {})

  return {
    companies: defaultTo(get(state, 'company.items'), defaultProps.companies),
    totalCompanies: defaultTo(
      get(state, 'company.totalCompanies'),
      defaultProps.totalCompanies
    ),
    company: defaultTo(get(state, 'company.item'), defaultProps.company),
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    users: defaultTo(get(state, 'user.items'), defaultProps.users),
    isGathering:
      defaultTo(get(state, 'company.isFetching'), defaultProps.isGathering) ||
      defaultTo(get(state, 'company.isFetchingItem'), defaultProps.isGathering),
    isUpdating: defaultTo(
      get(state, 'company.isUpdating'),
      defaultProps.isUpdating
    ),
    habilitation: defaultTo(get(state, 'habilitation.item'), defaultProps.habilitation)
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...companyActions, ...employeeActions, ...userActions, ...habilitationActions },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompaniesConstructor)
