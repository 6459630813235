// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Form, Button, Input, Alert } from 'antd'
import {
  get,
  map,
  defaultTo,
  cloneDeep,
  isNil,
  forEach,
  find,
  isEmpty,
  omitBy
} from 'lodash'

// Components
import { Container } from 'Components/Modals'

// Styles
import './CancelModal.less'

// Helper
import { createFormFields, isDeepNil } from 'Helpers'

const { Item: FormItem } = Form
const { TextArea } = Input

// Sauvegarde du formulaire en temps rÃ©el
// Permet de conserver les valeurs si le composant est re-render
let UNSAVED_FORM = {}

class CancelModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.object,
    okText: PropTypes.object,
    cancelText: PropTypes.object,

    // ContrÃ´le d'Ã©tat de la modale
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    loading: PropTypes.bool,

    // Contenu de la modale
    data: PropTypes.object,
    form: PropTypes.object,

    // Fonctions de callback
    onCancel: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // Labels et titres
    title: {
      single: 'pages.permits.modal.cancel.single.title',
      multiple: 'pages.permits.modal.cancel.multiple.title'
    },
    okText: {
      single: 'pages.permits.modal.cancel.single.okText',
      multiple: 'pages.permits.modal.cancel.multiple.okText'
    },
    cancelText: {
      single: 'pages.permits.modal.cancel.single.cancelText',
      multiple: 'pages.permits.modal.cancel.multiple.cancelText'
    },

    // ContrÃ´le d'Ã©tat de la modale
    width: 520,
    visible: false,
    loading: true,

    // Contenu de la modal
    data: {},
    form: {},

    // Fonctions de callback
    onCancel: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {}
  }

  handleCancel = e => {
    const { onCancel, form } = this.props

    // Reset du formulaire
    form.resetFields()
    UNSAVED_FORM = {}

    onCancel(e)
  }

  /**
   * Envoi du formulaire
   */
  handleSubmit = e => {
    e.preventDefault()
    const { data, onChange, form } = this.props

    form.validateFields((error, formData) => {
      if (!error) {
        onChange(formData, data).then(() => (UNSAVED_FORM = {}))
      }
    })
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const { loading, okText, cancelText, form } = this.props

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isDeepNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm = isEmpty(formErrors) && formChanged

    return (
      <div className='manage-modal-footer'>
        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='manage-modal-footer-controls'>
          <Button key='back' onClick={this.handleCancel}>
            {I18n.t(get(cancelText, mode))}
          </Button>
          <Button
            key='submit'
            type='primary'
            disabled={!isValidForm}
            loading={loading}
            onClick={this.handleSubmit}
          >
            {I18n.t(get(okText, mode))}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const {
      loading,
      visible,
      title,
      okText,
      cancelText,
      form,
      width
    } = this.props
    const { getFieldDecorator } = form

    // RÃ©cupÃ©ration du mode en cours
    // multiple || single
    const mode = defaultTo(get(this.props, 'mode'), 'single')

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm = isEmpty(formErrors) && formChanged

    return (
      <Container
        title={I18n.t(get(title, mode))}
        loading={loading}
        visible={visible}
        cancelText={I18n.t(get(cancelText, mode))}
        okText={I18n.t(get(okText, mode))}
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okButtonProps={{
          disabled: !isValidForm
        }}
        className={'modal cancel-modal'}
        footer={this.renderFooter()}
        width={width}
      >
        <Form onSubmit={this._handleSubmit}>
          <Alert
            message={I18n.t('pages.permits.fields.reason.notice')}
            type='warning'
          />

          {/* Motif d'annulation */}
          <FormItem label={I18n.t(`pages.permits.fields.reason.title`)}>
            {getFieldDecorator('reason', {
              rules: [
                {
                  required: true,
                  message: I18n.t(`pages.permits.fields.reason.requiredMessage`)
                }
              ]
            })(
              <TextArea
                autosize={{ minRows: 2, maxRows: 6 }}
                placeholder={I18n.t('pages.permits.fields.reason.placeholder')}
              />
            )}
          </FormItem>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CancelModal, 'defaultProps', {})

  return {
    cancels: defaultTo(get(state, 'cancel.items'), defaultProps.cancels)
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    onValuesChange(props, fieldValue, fieldsValue) {
      UNSAVED_FORM = fieldsValue
    },
    mapPropsToFields(props) {
      const defaultProps = get(CancelModal, 'defaultProps', {})
      let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

      if (!isEmpty(UNSAVED_FORM)) {
        formData = UNSAVED_FORM
      } else {
        // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
        forEach(formData, (value, index) => {
          const format = get(
            find(map(get(props, 'rows')), { dataIndex: index }),
            'formField.format'
          )

          formData[index] = !isNil(format) ? format(value, formData) : value
        })
      }

      return createFormFields(Form, formData)
    }
  })(CancelModal)
)
