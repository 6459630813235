// Libraries
import I18n from 'i18next'
import { map } from 'lodash'

const specs = {
  width: 620,
  title: {
    update: 'pages.securityMeasures.modal.update.single.title',
    create: 'pages.securityMeasures.modal.create.single.title'
  },
  okText: {
    update: 'pages.securityMeasures.modal.update.single.okText',
    create: 'pages.securityMeasures.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.securityMeasures.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        label: 'pages.securityMeasures.fields.name.title',
        required: 'pages.securityMeasures.fields.name.requiredMessage',
        placeholder: 'pages.securityMeasures.fields.name.placeholder'
      },
      empty: 'common.unknown.male'
    },
    isControlled: {
      title: 'pages.securityMeasures.fields.isControlled.title',
      dataIndex: 'isControlled',
      key: 'isControlled',
      formField: {
        type: 'radios',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(
            `pages.securityMeasures.fields.isControlled.options.${bool}.title`
          ),
          value: bool,
          data: bool
        })),
        label: 'pages.securityMeasures.fields.isControlled.title',
        questionMark: 'pages.securityMeasures.fields.isControlled.questionMark',
        required: 'pages.securityMeasures.fields.isControlled.requiredMessage',
        placeholder: 'pages.securityMeasures.fields.isControlled.placeholder'
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
