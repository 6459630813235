// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Layout, Menu, Icon, Avatar } from 'antd'
import { Link } from '@react-navigation/web'
import {
  filter,
  includes,
  map,
  isNil,
  has,
  forEach,
  defaultTo,
  get,
  toNumber,
  isEmpty,
  cloneDeep,
  some
} from 'lodash'

// Components
import Images from 'Images'

const { Sider } = Layout

class CustomSider extends Component {
  static propTypes = {
    theme: PropTypes.string,
    profile: PropTypes.object,
    links: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    collapsible: PropTypes.bool
  }

  static defaultProps = {
    theme: 'light',
    collapsible: false,
    projects: [],
    profile: {},
    links: {
      // Dashboard
      dashboard: {
        routeName: 'Dashboard',
        icon: 'pages.dashboard.icon',
        title: 'pages.dashboard.title',
        matchScreens: ['Dashboard']
      },
      // Permis
      permits: {
        routeName: 'Permits',
        params: { id: null },
        icon: 'pages.permits.icon',
        title: 'pages.permits.title',
        matchScreens: ['Permits']
      },
      // Scans
      scans: {
        routeName: 'Scans',
        params: { id: null },
        roles: ['SUPERADMIN', 'ADMIN', 'CREATOR', 'READER'],
        icon: 'pages.scans.icon',
        title: 'pages.scans.title',
        matchScreens: ['Scans']
      },
      // Utilisateurs
      users: {
        routeName: 'Users',
        roles: ['SUPERADMIN', 'EXTERN_CREATOR'],
        params: { id: null },
        icon: 'pages.users.icon',
        title: 'pages.users.title',
        matchScreens: ['Users']
      },
      // Entreprise pour crÃ©ateur externe
      myCompany: {
        routeName: 'MyCompany',
        roles: ['SUPERADMIN', 'EXTERN_CREATOR'],
        params: { id: null },
        icon: 'pages.companies.icon',
        title: 'pages.companies.myCompany',
        matchScreens: ['MyCompany']
      },
      // ParamÃ¨tres
      settings: {
        subMenu: 'Settings',
        params: { id: null },
        icon: 'pages.settings.icon',
        title: 'pages.settings.title',
        roles: ['ADMIN', 'SUPERADMIN'],
        screens: {
          // Permis de feu
          firePermit: {
            subMenu: 'firePermit',
            params: { id: null },
            icon: 'pages.permits.settings.fire.icon',
            title: 'pages.permits.settings.fire.title',
            screens: {
               // Mesures de prÃ©vention
               preventiveMeasures: {
                routeName: 'PreventiveMeasures',
                params: { id: null },
                icon: 'pages.preventiveMeasures.icon',
                title: 'pages.preventiveMeasures.title',
                matchScreens: ['PreventiveMeasures']
              },
              // Origines des feux
              fireSources: {
                routeName: 'FireSources',
                params: { id: null },
                icon: 'pages.fireSources.icon',
                title: 'pages.fireSources.title',
                matchScreens: ['FireSources']
              }
            }
          },
          searchPermit: {
            subMenu: 'searchPermit',
            params: { id: null },
            icon: 'pages.permits.settings.search.icon',
            title: 'pages.permits.settings.search.title',
            screens: {
              // Mesures de sÃ©curitÃ©
              securityMeasures: {
                routeName: 'SearchSecurityMeasures',
                params: { id: null },
                icon: 'pages.securityMeasures.icon',
                title: 'pages.securityMeasures.title',
                matchScreens: ['SearchSecurityMeasures']
              },
              // Risques prÃ©visibles
              predictableRisks: {
                routeName: 'SearchPredictableRisks',
                params: { id: null },
                icon: 'pages.predictableRisks.icon',
                title: 'pages.predictableRisks.title',
                matchScreens: ['SearchPredictableRisks']
              }
            }
          },
          cleaningPermit: {
            subMenu: 'cleaningPermit',
            params: { id: null },
            icon: 'pages.permits.settings.cleaning.icon',
            title: 'pages.permits.settings.cleaning.title',
            screens: {
              // Conditions
              condition: {
                routeName: 'CleaningCondition',
                params: { id: null },
                icon: 'pages.condition.icon',
                title: 'pages.condition.title',
                matchScreens: ['CleaningCondition']
              },
              // Equipements
              equipment: {
                routeName: 'CleaningEquipment',
                params: { id: null },
                icon: 'pages.equipment.icon',
                title: 'pages.equipment.title',
                matchScreens: ['CleaningEquipment']
              },
              // Dispositions additionnelles
              additionalProvision: {
                routeName: 'CleaningAdditionalProvision',
                params: { id: null },
                icon: 'pages.additionalProvision.icon',
                title: 'pages.additionalProvision.titleShort',
                matchScreens: ['CleaningAdditionalProvision']
              }
            }
          },
          capacityPermit: {
            subMenu: 'capacityPermit',
            params: { id: null },
            icon: 'pages.permits.settings.capacity.icon',
            title: 'pages.permits.settings.capacity.title',
            screens: {
              // Mesures de sÃ©curitÃ©
              securityMeasures: {
                routeName: 'CapacitySecurityMeasures',
                params: { id: null },
                icon: 'pages.securityMeasures.icon',
                title: 'pages.securityMeasures.title',
                matchScreens: ['CapacitySecurityMeasures']
              },
              // Equipements
              equipment: {
                routeName: 'CapacityEquipment',
                params: { id: null },
                icon: 'pages.equipment.icon',
                title: 'pages.equipment.title',
                matchScreens: ['CapacityEquipment']
              },
              // Dispositions additionnelles
              additionalProvision: {
                routeName: 'CapacityAdditionalProvision',
                params: { id: null },
                icon: 'pages.additionalProvision.icon',
                title: 'pages.additionalProvision.titleShort',
                matchScreens: ['CapacityAdditionalProvision']
              }
            }
          },
          // Permis de travail
          permit: {
            subMenu: 'permit',
            params: { id: null },
            icon: 'pages.permits.settings.permit.icon',
            title: 'pages.permits.settings.permit.title',
            screens: {
              // Risques
              risks: {
                routeName: 'Risks',
                params: { id: null },
                icon: 'pages.risks.icon',
                title: 'pages.risks.title',
                matchScreens: ['Risks']
              },
              // MatÃ©riels
              materials: {
                routeName: 'Materials',
                params: { id: null },
                icon: 'pages.materials.icon',
                title: 'pages.materials.title',
                matchScreens: ['Materials']
              },
              // Mesures de prÃ©vention
              preventiveMeasures: {
                routeName: 'PreventiveMeasures',
                params: { id: null },
                icon: 'pages.preventiveMeasures.icon',
                title: 'pages.preventiveMeasures.title',
                matchScreens: ['PreventiveMeasures']
              },
              // ManÅuvres
              operations: {
                routeName: 'Operations',
                params: { id: null },
                icon: 'pages.operations.icon',
                title: 'pages.operations.title',
                matchScreens: ['Operations']
              },
              // Mesures de sÃ©curitÃ©
              securityMeasures: {
                routeName: 'SecurityMeasures',
                params: { id: null },
                icon: 'pages.securityMeasures.icon',
                title: 'pages.securityMeasures.title',
                matchScreens: ['SecurityMeasures']
              },
              // Permis associables
              associablePermits: {
                routeName: 'AssociablePermits',
                params: { id: null },
                icon: 'pages.associablePermits.icon',
                title: 'pages.associablePermits.title',
                matchScreens: ['AssociablePermits']
              }
            }
          },
          // Habilitation
          habilitations: {
            routeName: 'Habilitations',
            params: { id: null },
            icon: 'pages.habilitations.icon',
            title: 'pages.habilitations.title',
            matchScreens: ['Habilitations']
          },
          // Entreprises extÃ©rieurs
          companies: {
            routeName: 'Companies',
            params: { id: null },
            icon: 'pages.companies.icon',
            title: 'pages.companies.title',
            matchScreens: ['Companies']
          },
          // Surveillant
          supervisors: {
            routeName: 'Supervisors',
            params: { id: null },
            icon: 'pages.supervisor.icon',
            title: 'pages.supervisor.title',
            matchScreens: ['Supervisors']
          }
        }
      }
    }
  }

  constructor(props) {
    super(props)
    this.props.links.myCompany.params.id = props.profile?.company?.id

    this.state = {
      collapsed: false
    }
  }

  /**
   * PrÃ©lÃ¨ve tous les Ã©crans dans une liste d'Ã©crans groupÃ©s
   */
  _flattenLinks = (links, subMenu) => {
    const flatten = []

    forEach(links, link => {
      if (has(link, 'subMenu')) {
        flatten.push(...this._flattenLinks(link.screens, link.subMenu))
      } else {
        if (!isNil(subMenu)) {
          link.subMenu = subMenu
        }

        flatten.push(link)
      }
    })

    return flatten
  }

  _handleCollapse = collapsed => {
    this.setState({ collapsed })
  }

  _renderMenuLink = ({
    title,
    routeName,
    image,
    params,
    subMenu,
    key = routeName || subMenu,
    icon = false,
    screens,
    roles,
    ...props
  }) => {
    const { profile } = this.props
    const amIGranted =
      isNil(roles) ||
      some(map(get(profile, 'roles'), 'name'), role =>
        includes(defaultTo(roles, []), role)
      )

    return (
      amIGranted &&
      (!isNil(screens) ? (
        <Menu.SubMenu
          key={key}
          title={
            <span>
              {icon && <Icon type={I18n.t(icon)} />}
              <span>{defaultTo(I18n.t(title), '')}</span>
            </span>
          }
        >
          {map(screens, this._renderMenuLink)}
        </Menu.SubMenu>
      ) : (
        <Menu.Item key={key}>
          <Link
            routeName={defaultTo(routeName, '')}
            params={defaultTo(params, {})}
          >
            {/* Image ou icon */}
            {image ? (
              <Avatar shape='square' src={image} icon={I18n.t(icon)} />
            ) : (
              icon && <Icon type={I18n.t(icon)} />
            )}
            <span>{defaultTo(I18n.t(title), '')}</span>
          </Link>
        </Menu.Item>
      ))
    )
  }

  _renderMenu = () => {
    const { links } = this.props

    const currentScreen = {
      id: get(this.props, 'currentScreen.params.id'),
      routeName: get(this.props, 'currentScreen.routeName')
    }

    const flattenLinks = this._flattenLinks(cloneDeep(links))

    // RÃ©cupÃ©ration des actifs en fonction du matchScreens
    const associatedScreens = filter(
      flattenLinks,
      ({ matchScreens, matchIDs }) => {
        let isMatching

        // Passage en nombre pour Ãªtre sÃ»r que la comparaison soit bonne
        matchIDs = map(matchIDs, toNumber)

        // Page avec match ID prÃ©cis
        if (!isEmpty(matchIDs)) {
          isMatching =
            includes(matchScreens, get(currentScreen, 'routeName')) &&
            includes(matchIDs, toNumber(get(currentScreen, 'id')))
        } else {
          isMatching = includes(matchScreens, get(currentScreen, 'routeName'))
        }

        return isMatching
      }
    )

    const selectedKeys = map(associatedScreens, route =>
      defaultTo(get(route, 'key'), get(route, 'routeName'))
    )

    const openKeys = defaultTo(
      get(this.state, 'openKeys'),
      map(associatedScreens, 'subMenu')
    )

    return (
      <Menu
        defaultSelectedKeys={selectedKeys}
        selectedKeys={selectedKeys}
        onOpenChange={openKeys => this.setState({ openKeys })}
        defaultOpenKeys={openKeys}
        openKeys={openKeys}
        mode='inline'
      >
        {map(links, this._renderMenuLink)}
      </Menu>
    )
  }

  render() {
    const { collapsed } = this.state
    const { theme, collapsible } = this.props

    const logo = collapsed ? Images.icon : Images.logo_white

    return (
      <Sider
        theme={theme}
        width={250}
        collapsible={collapsible}
        collapsed={collapsed}
        onCollapse={this._handleCollapse}
      >
        {/* Logo de l'application */}
        <Link routeName='Dashboard'>
          <div className='ant-layout-sider-logo'>
            <img
              className='ant-layout-sider-logo-image'
              src={logo}
              alt='logo'
            />
          </div>
        </Link>

        {/* Menu */}
        {this._renderMenu()}
      </Sider>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(CustomSider, 'defaultProps', {})
  return {
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    projects: defaultTo(get(state, 'project.items'), defaultProps.projects)
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomSider)
