// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as fireSourceActions } from 'Resources/FireSourceResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/FireSources/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class FireSourcesConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    fireSource: PropTypes.object,
    fireSources: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    fireSource: {},
    fireSources: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchFireSourcesData()

    if (this.getContext() === 'single') {
      this.getFireSourceData().then(this.askUpdateFireSource)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchFireSourcesData = () => {
    this.props.actions.fetchFireSources().catch(() => {
      message.error(I18n.t('api.errors.fireSource.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getFireSourceData = (fireSource = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getFireSource(fireSource)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.fireSource.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyFireSource = (fireSource, clearTableSelections) => {
    this.props.actions
      .archiveManyFireSource(fireSource)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.fireSource.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.fireSource.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveFireSource = fireSource => {
    this.props.actions
      .archiveFireSource(fireSource)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.fireSource.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.fireSource.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyFireSource = (fireSource, clearTableSelections) => {
    this.props.actions
      .archiveFireSources(fireSource)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.fireSource.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.fireSource.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteFireSource = fireSource => {
    this.props.actions
      .archiveFireSource(fireSource)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.fireSource.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.fireSource.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un fireSource
   */
  updateFireSource = (updatedFireSource, originalFireSource) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateFireSource({
          id: get(originalFireSource, 'id'),
          ...updatedFireSource
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.fireSource.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.fireSource.update'))
        })
    })

  /**
   * CrÃ©ation d'un fireSource
   */
  createFireSource = createdFireSource =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createFireSource(createdFireSource)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.fireSource.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.fireSource.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyFireSources = (...params) => {
    confirm({
      title: I18n.t('pages.fireSources.modal.archive.multiple.title'),
      content: I18n.t('pages.fireSources.modal.archive.multiple.content'),
      okText: I18n.t('pages.fireSources.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.fireSources.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveFireSource(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveFireSource = () => {
    const { fireSource } = this.props

    confirm({
      title: I18n.t('pages.fireSources.modal.archive.single.title'),
      content: I18n.t('pages.fireSources.modal.archive.single.content'),
      okText: I18n.t('pages.fireSources.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.fireSources.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveFireSource(fireSource)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyFireSources = (...params) => {
    confirm({
      title: I18n.t('pages.fireSources.modal.delete.multiple.title'),
      content: I18n.t('pages.fireSources.modal.delete.multiple.content'),
      okText: I18n.t('pages.fireSources.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.fireSources.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.deleteManyFireSource(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteFireSource = () => {
    const { fireSource } = this.props

    confirm({
      title: I18n.t('pages.fireSources.modal.delete.single.title'),
      content: I18n.t('pages.fireSources.modal.delete.single.content'),
      okText: I18n.t('pages.fireSources.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.fireSources.modal.delete.single.cancelText'),
      onOk: () => {
        this.deleteFireSource(fireSource)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateFireSource = () => {
    this.setState({ openedModal: 'updateFireSource' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateFireSource = () => {
    this.setState({ openedModal: 'createFireSource' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateFireSource') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = fireSource => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(fireSource, 'id') })

    this.getFireSourceData(fireSource)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateFireSource()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('FireSource', { id: get(fireSource, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderFireSource(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.fireSource.order')))
      .catch(() => message.error(I18n.t('api.errors.fireSource.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      fireSources,
      fireSource
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getFireSourceData: this.getFireSourceData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateFireSource: this.askCreateFireSource,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateFireSource: this.askUpdateFireSource,
      askUpdateManyFireSources: this.askUpdateFireSource,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveFireSource: this.askArchiveFireSource,
      askArchiveManyFireSources: this.askArchiveManyFireSources,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteFireSource: this.askDeleteFireSource,
      askDeleteManyFireSources: this.askDeleteManyFireSources,

      // RÃ©initialisation du mot de passe d'un fireSource
      askResetPasswordFireSource: this.askResetPasswordFireSource
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteFireSource

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={fireSources}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateFireSource'
              ? this.updateFireSource
              : this.createFireSource
          }
          visible={includes(
            ['createFireSource', 'updateFireSource'],
            openedModal
          )}
          data={
            includes(['transition', 'updateFireSource'], openedModal)
              ? fireSource
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(FireSourcesConstructor, 'defaultProps', {})

  return {
    fireSources: defaultTo(
      get(state, 'fireSource.items'),
      defaultProps.fireSources
    ),
    fireSource: defaultTo(
      get(state, 'fireSource.item'),
      defaultProps.fireSource
    ),
    isGathering:
      defaultTo(
        get(state, 'fireSource.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'fireSource.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'fireSource.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...fireSourceActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FireSourcesConstructor)
