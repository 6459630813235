import { createResource } from 'redux-rest-resource'
import {
  remove,
  includes,
  map,
  get,
  cloneDeep,
  toNumber,
  defaultTo
} from 'lodash'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'searchPredictableRisk',
  pluralName: 'searchPredictableRisks',
  url: `${baseURL}/searchpermit-predictablerisks`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: '.' },
    fetchArchives: {
      isArray: true,
      method: 'GET',
      gerundName: 'fetching',
      url: './archive',
      reduce: (
        { archives = [], ...state },
        { body: archivedItems, status }
      ) => {
        const isFetching = status === 'pending'

        if (status === 'resolved') {
          archives = archivedItems
        }

        return { ...state, archives, isFetching }
      }
    },
    get: { method: 'GET', gerundName: 'getting', url: './:id' },
    update: {
      method: 'PUT',
      gerundName: 'updating',
      url: './:id',
      assignResponse: true,
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    create: {
      method: 'POST',
      gerundName: 'creating',
      url: '.'
    },
    order: {
      method: 'PATCH',
      gerundName: 'ordering',
      url: './order'
    },
    archive: {
      method: 'PATCH',
      gerundName: 'archiving',
      url: './:id/archive',
      reduce: ({ items, ...state }, { context: archivedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(archivedItem, 'id'), toNumber(archivedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    archiveMany: {
      isArray: true,
      alias: 'archive',
      method: 'PATCH',
      gerundName: 'archiving',
      url: './archive',
      reduce: ({ items, ...state }, { context: archivedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(archivedItems, archivedItem =>
                defaultTo(get(archivedItem, 'id'), toNumber(archivedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    delete: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './:id',
      reduce: ({ items, ...state }, { context: deletedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './delete',
      reduce: ({ items, ...state }, { context: deletedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(deletedItems, deletedItem =>
                defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    }
  }
})
