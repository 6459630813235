import { createResource } from 'redux-rest-resource'
import {
  cloneDeep,
  defaultTo,
  find,
  findIndex,
  get,
  includes,
  isPlainObject,
  map,
  merge,
  remove,
  toNumber
} from 'lodash'
import { baseURL } from '.'

export const { types, actions, rootReducer } = createResource({
  name: 'user',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    fetch: { method: 'GET', gerundName: 'fetching', url: './auth/users/' },
    fetchArchives: {
      isArray: true,
      method: 'GET',
      gerundName: 'fetching',
      url: './auth/users/archive/',
      reduce: (state, { body: archives, status }) => ({
        ...state,
        archives,
        isFetching: status === 'pending'
      })
    },
    get: { method: 'GET', gerundName: 'getting', url: './auth/users/:id/' },
    create: {
      method: 'POST',
      gerundName: 'creating',
      url: './auth/users/'
    },
    update: {
      method: 'PUT',
      gerundName: 'updating',
      url: './auth/users/:id/'
    },
    updateAvatar: {
      method: 'PUT',
      gerundName: 'updating',
      url: './auth/users/:id/avatar/',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      reduce: ({ item, items, ...state }, { body: user, status }) => {
        const isUpdating = status === 'pending'
        if (status === 'resolved') {
          const userIndex = findIndex(items, {
            id: isPlainObject(user) ? get(user, 'id') : user
          })
          item = merge(items[userIndex], user)
          items[userIndex] = item
        }
        return { ...state, item: cloneDeep(item), items, isUpdating }
      }
    },
    delete: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './auth/users/:id/',
      reduce: ({ items, ...state }, { context: deletedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './auth/users/delete/',
      reduce: ({ items, ...state }, { context: deletedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(deletedItems, deletedItem =>
                defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    archive: {
      method: 'PATCH',
      gerundName: 'archiving',
      url: './auth/users/:id/archive/',
      reduce: ({ items, archives, ...state }, { body: archived, status }) => {
        const isArchiving = status === 'pending'
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, { id: get(archived, 'id') })
          archives = [...defaultTo(archives, []), archived]
        }
        return {
          ...state,
          items: cloneDeep(items),
          archives: cloneDeep(archives),
          isArchiving
        }
      }
    },
    archiveMany: {
      isArray: true,
      alias: 'archive',
      method: 'PATCH',
      gerundName: 'archiving',
      url: './auth/users/archive/',
      reduce: ({ items, archives, ...state }, { body: archived, status }) => {
        const isArchiving = status === 'pending'
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) => includes(map(archived, 'id'), id))
          archives = [...defaultTo(archives, []), ...archived]
        }
        return {
          ...state,
          items: cloneDeep(items),
          archives: cloneDeep(archives),
          isArchiving
        }
      }
    },
    activateMany: {
      isArray: true,
      alias: 'activate',
      method: 'PATCH',
      gerundName: 'activate',
      url: './auth/users/activate/',
      reduce: ({ items, ...state }, { body: updatedItems, status }) => {
        const isUpdating = status === 'pending'

        // Si la requÃªte est terminÃ©e
        if (status === 'resolved') {
          // Mise Ã  jour des local items
          items = map(items, item =>
            defaultTo(find(updatedItems, { id: get(item, 'id') }), item)
          )
        }

        return { ...state, items, isUpdating }
      }
    },
    activate: {
      alias: 'activate',
      method: 'PATCH',
      gerundName: 'activate',
      url: './auth/users/:id/activate/',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        // Si la requÃªte est terminÃ©e
        if (status === 'resolved') {
          // Mise Ã  jour du local item
          item = updatedItem
          // Mise Ã  jour des local items
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    deactivateMany: {
      isArray: true,
      alias: 'deactivate',
      method: 'PATCH',
      gerundName: 'deactivate',
      url: './auth/users/deactivate/',
      reduce: ({ items, item, ...state }, { body: updatedItems, status }) => {
        const isUpdating = status === 'pending'

        // Si la requÃªte est terminÃ©e
        if (status === 'resolved') {
          // Mise Ã  jour des local items
          items = map(items, item =>
            defaultTo(find(updatedItems, { id: get(item, 'id') }), item)
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    deactivate: {
      alias: 'deactivate',
      method: 'PATCH',
      gerundName: 'deactivate',
      url: './auth/users/:id/deactivate/',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        // Si la requÃªte est terminÃ©e
        if (status === 'resolved') {
          // Mise Ã  jour du local item
          item = updatedItem
          // Mise Ã  jour des local items
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    restore: {
      method: 'PATCH',
      gerundName: 'restoring',
      url: './auth/users/archive/:id/restore/',
      reduce: ({ items, archives, ...state }, { body: restored, status }) => {
        const isRestoring = status === 'pending'
        if (status === 'resolved') {
          // Suppression des donnÃ©es restaurÃ©es du store
          remove(archives, { id: get(restored, 'id') })
          items = [...items, restored]
        }
        return {
          ...state,
          items: cloneDeep(items),
          archives: cloneDeep(archives),
          isRestoring
        }
      }
    },
    restoreMany: {
      isArray: true,
      alias: 'restore',
      method: 'PATCH',
      gerundName: 'restoring',
      url: './auth/users/restore/',
      reduce: ({ items, archives, ...state }, { body: restored, status }) => {
        const isRestoring = status === 'pending'
        if (status === 'resolved') {
          // Suppression des donnÃ©es restaurÃ©es du store
          remove(archives, ({ id }) => includes(map(restored, 'id'), id))
          items = [...archives, ...restored]
        }
        return {
          ...state,
          items: cloneDeep(items),
          archives: cloneDeep(archives),
          isRestoring
        }
      }
    }
  },
  stripTrailingSlashes: false
})
