// Libraries
import PropTypes from 'prop-types'
import React, { Component } from 'react'
// Common components
import { TableLayout } from 'Components'

// Specs
import { FireFormSpecs, PermitsTableSpecs } from 'Views/Permits/Specs'
import { Information } from '../Tabs'
import { Card } from 'antd'

class PermitsTab extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // DonnÃ©es de la vue
    totalPermits: PropTypes.number,
    permits: PropTypes.array,
    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func),
    isDownloading: PropTypes.bool,
    openedPermit: PropTypes.object,
    onPermitChange: PropTypes.func,
    type: PropTypes.string,
    formLayout: PropTypes.object
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    totalPermits: 0,
    permits: [],
    isDownloading: false,
    openedPermit: null,
    onPermitChange: null,
    type: 'firePermit',
    formLayout: FireFormSpecs
  }

  constructor(props) {
    super(props)

    this.state = {
      // donnÃ©es des filtres
      currentPage: 0,
      showPageSize: 10,
      sortField: 'dateCreated',
      sortOrder: 'descend',
      sortFilters: {}
    }
  }

  render() {
    const {
      loading,
      constructorFunctions,
      profile,
      totalPermits,
      permits,
      isDownloading,
      openedPermit,
      onPermitChange,
      type,
      formLayout
    } = this.props

    // Ajout des donnÃ©es au tableau
    PermitsTableSpecs.rows = permits

    // Ajout des actions au clic sur un ligne du tableau
    PermitsTableSpecs.onRow = permit => {
      return {
        onClick: () => onPermitChange(permit)
      }
    }

    // Pagination
    // Nombre total de permits
    PermitsTableSpecs.pagination.total = totalPermits
    PermitsTableSpecs.totalItems = totalPermits

    return (
      <main>
        {openedPermit == null && (
          <Card className='tab information'>
            <TableLayout loading={loading} {...PermitsTableSpecs} />
          </Card>
        )}
        {openedPermit != null && (
          <Information
            data={openedPermit}
            type={type}
            loading={loading}
            isDownloading={isDownloading}
            profile={profile}
            constructorFunctions={constructorFunctions}
            formLayout={formLayout}
          />
        )}
      </main>
    )
  }
}

export default PermitsTab
