import moment from 'moment'

const specs = {
  width: 620,
  title: {
    update: 'pages.scans.modal.update.single.title',
    create: 'pages.scans.modal.create.single.title'
  },
  okText: {
    update: 'pages.scans.modal.update.single.okText',
    create: 'pages.scans.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    validityPeriod: {
      title: 'pages.permits.fields.validityPeriod.title',
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      formField: {
        style: { width: '100%' },
        type: 'period',
        formatRangeDates: 'DD/MM/YYYY',
        defaultValue: [moment(), moment()],
        label: 'pages.permits.fields.validityPeriod.title',
        required: 'pages.permits.fields.validityPeriod.requiredMessage',
        placeholder: [
          'pages.permits.fields.validityPeriod.placeholder.0',
          'pages.permits.fields.validityPeriod.placeholder.1'
        ]
      },
      empty: 'common.unknown.female'
    },
    files: {
      title: 'pages.scans.fields.files.title',
      dataIndex: 'url',
      key: 'files',
      formField: {
        type: 'dragDropUpload',
        label: 'pages.scans.fields.files.title',
        required: 'pages.scans.fields.files.requiredMessage',
        placeholder: 'pages.scans.fields.files.placeholder',
        multiple: false
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
