// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Statistic, Icon } from 'antd'
import { keys, get } from 'lodash'
import moment from 'moment'

// Styles
import './Statistics.less'

class Statistics extends Component {
  static propTypes = {
    // donnÃ©es de la vue
    globals: PropTypes.object
  }

  static defaultProps = {
    // donnÃ©es de la vue
    globals: {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {}
  }

  // Rendu des items de statistique
  renderStatisticItems = statKeys => {
    const { globals } = this.props

    const associatedColors = {
      allPermitsCountThisYear: 'green',
      allFirePermitsCountThisYear: 'orange',
      allWorkTimeThisYear: 'cyan',
      openPermitsCountThisYear: 'blue'
    }

    return statKeys.map((key, index) => {
      return (
        <Statistic
          key={index}
          className={`dashboard-statistics-item item-${get(
            associatedColors,
            key
          )}`}
          title={I18n.t(`pages.dashboard.statistic.fields.${key}.title`)}
          value={globals[key]}
          prefix={
            <Icon
              type={I18n.t(`pages.dashboard.statistic.fields.${key}.icon`)}
            />
          }
        />
      )
    })
  }

  render() {
    const { globals } = this.props
    // rÃ©cupÃ©ration des clÃ©s sous un format de tableau afin de pouvoir mapper dessus.
    const statKeys = keys(globals, (v, k) => {
      return k
    })

    return (
      <div className='dashboard-statistics'>
        <p className='dashboard-statistics-title'>
          {I18n.t('pages.dashboard.statistic.title', { year: moment().year() })}
        </p>
        <div className='dashboard-statistics-items'>
          {this.renderStatisticItems(statKeys)}
        </div>
      </div>
    )
  }
}

export default Statistics
