// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { Tooltip } from 'antd'
import { isNil, get } from 'lodash'

// Helpers
import { filterPeriod } from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'permit',
    kind: 'male'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.permits.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Colonnes du tableau
  columns: {
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      render: company =>
        !isNil(company) ? company.name : I18n.t('common.unknown.female'),
      sorter: true
    },
    permitID: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      render: companyName =>
        !isNil(companyName) ? companyName : I18n.t('common.unknown.male'),
      sorter: true
    },
    validityPeriod: {
      title: 'pages.permits.fields.validityPeriod.title',
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      render: validityPeriod =>
        !isNil(validityPeriod) ? (
          <span className='period'>
            <span className='period-date'>
              {moment(get(validityPeriod, 'startDate')).format('L')}
            </span>
            <span className='period-date'>
              {moment(get(validityPeriod, 'endDate')).format('L')}
            </span>
          </span>
        ) : (
          I18n.t('common.unknown.female')
        ),
      // sorter: true,
      ...filterPeriod(null, moment(), [
        'validityPeriod.startDate',
        'validityPeriod.endDate'
      ])
    },
    dateCreated: {
      title: 'pages.permits.fields.dateCreated.title',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: dateCreated =>
        !isNil(dateCreated) ? (
          <Tooltip title={moment(dateCreated).format('L')}>
            {moment(dateCreated).calendar()}
          </Tooltip>
        ) : (
          I18n.t('common.unknown.female')
        ),
      sorter: true,
      ...filterPeriod(null, moment(), 'dateCreated')
    }
  }
}

export default specs
