// Libraries
import React from 'react'
import { Tag, Tooltip, Icon } from 'antd'
import moment from 'moment'
import { map, isNil, isEmpty, get } from 'lodash'
import I18n from 'i18next'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.update.single.title',
    create: 'pages.permits.modal.create.single.title'
  },
  okText: {
    update: 'pages.permits.modal.update.single.okText',
    create: 'pages.permits.modal.create.single.okText'
  },
  actions: {
    update: {
      buttonType: 'primary'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    },
    // Mesures
    {
      key: 'measures',
      title: 'pages.permits.modal.steps.measures.title'
    }
  ],
  tabs: {
    changelog: {},
    scans: {}
  },
  validateEachSteps: false,
  rows: {
    preventionPlan: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.permitID.title',
        required: 'pages.permits.fields.permitID.requiredMessage',
        placeholder: 'pages.permits.fields.permitID.placeholder',
        questionMark: 'pages.permits.fields.permitID.questionMark'
      },
      empty: 'common.unknown.female'
    },
    validityDate: {
      title: 'pages.permits.fields.validityDate.title',
      dataIndex: 'validityDate',
      key: 'validityDate',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'date',
        label: 'pages.permits.fields.validityDate.title',
        required: 'pages.permits.fields.validityDate.requiredMessage',
        placeholder: 'pages.permits.fields.validityDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    },
    validityHours: {
      title: 'pages.permits.fields.validityHours.title',
      dataIndex: 'validityHours',
      key: 'validityHours',
      formField: {
        visibility: {
          step: 'main'
        },
        mask: '**:** - **:**',
        label: 'pages.permits.fields.validityHours.title',
        required: 'pages.permits.fields.validityHours.requiredMessage',
        placeholder: 'pages.permits.fields.validityHours.placeholder',
        pattern: {
          validator: (rule, value, callback) => {
            const pattern = /^(?<startTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]) - (?<endTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])$/
            const parsed = pattern.exec(value)

            const startTime = get(parsed, 'groups.startTime')
            const endTime = get(parsed, 'groups.endTime')

            let message


            if (
              /* Aucune donnÃ©e valide */
              isNil(parsed)
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.wrong'
              )
            } else if (
              /* Les deux heures sont inversÃ©es */
              moment(endTime, 'HH:mm').isBefore(moment(startTime, 'HH:mm'))
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.order'
              )
            } else if (
              /* Les deux heures sont dans la mÃªme moitiÃ© de journÃ©e */
              moment(endTime, 'HH:mm').format('a') !==
                moment(startTime, 'HH:mm').format('a') &&
              endTime !== '12:00'
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.meridiem'
              )
            }

            callback(message)
          }
        }
      },
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'select',
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? (
          <Tag>{company.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionMark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    location: {
      title: 'pages.permits.fields.location.title',
      dataIndex: 'location',
      key: 'location',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.location.title',
        required: 'pages.permits.fields.location.requiredMessage',
        placeholder: 'pages.permits.fields.location.placeholder',
        questionMark: 'pages.permits.fields.location.questionMark'
      },
      empty: 'common.unknown.female'
    },
    tank: {
      title: 'pages.permits.fields.tank.title',
      dataIndex: 'tank',
      key: 'tank',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.tank.title',
        required: 'pages.permits.fields.tank.requiredMessage',
        placeholder: 'pages.permits.fields.tank.placeholder'
      },
      empty: 'common.unknown.male'
    },
    cleaningPermitConditions: {
      title: 'pages.permits.fields.conditions.title',
      dataIndex: 'cleaningPermitConditions',
      key: 'cleaningPermitConditions',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.conditions.title',
        placeholder: 'pages.permits.fields.conditions.placeholder',
        format: conditions =>
          !isNil(conditions) && !isEmpty(conditions)
            ? map(conditions, 'id')
            : undefined
      },
      render: conditions =>
        !isNil(conditions) && !isEmpty(conditions)
          ? map(conditions, (condition, index) => (
              <Tag key={index}>{get(condition, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedCleaningPermitConditions: {
      title: 'pages.permits.fields.conditions.unlisted.title',
      dataIndex: 'unlistedCleaningPermitConditions',
      key: 'unlistedCleaningPermitConditions',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitConditions: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.conditions.unlisted.title',
        required: 'pages.permits.fields.conditions.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.conditions.unlisted.placeholder',
        format: conditions =>
          !isNil(conditions) && !isEmpty(conditions)
            ? map(conditions, 'id')
            : undefined
      },
      render: conditions =>
        !isNil(conditions) && !isEmpty(conditions)
          ? map(conditions, (condition, index) => (
              <Tag key={index}>{get(condition, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    cleaningPermitEquipments: {
      title: 'pages.permits.fields.equipments.title',
      dataIndex: 'cleaningPermitEquipments',
      key: 'cleaningPermitEquipments',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.equipments.title',
        placeholder: 'pages.permits.fields.equipments.placeholder',
        format: equipments =>
          !isNil(equipments) && !isEmpty(equipments)
            ? map(equipments, 'id')
            : undefined
      },
      render: equipments =>
        !isNil(equipments) && !isEmpty(equipments)
          ? map(equipments, (equipment, index) => (
              <Tag key={index}>{get(equipment, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedCleaningPermitEquipments: {
      title: 'pages.permits.fields.equipments.unlisted.title',
      dataIndex: 'unlistedCleaningPermitEquipments',
      key: 'unlistedCleaningPermitEquipments',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitEquipments: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.equipments.unlisted.title',
        required: 'pages.permits.fields.equipments.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.equipments.unlisted.placeholder',
        format: equipments =>
          !isNil(equipments) && !isEmpty(equipments)
            ? map(equipments, 'id')
            : undefined
      },
      render: equipments =>
        !isNil(equipments) && !isEmpty(equipments)
          ? map(equipments, (equipment, index) => (
              <Tag key={index}>{get(equipment, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    cleaningPermitAdditionalProvisions: {
      title: 'pages.permits.fields.additionalProvisions.title',
      dataIndex: 'cleaningPermitAdditionalProvisions',
      key: 'cleaningPermitAdditionalProvisions',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.additionalProvisions.title',
        placeholder: 'pages.permits.fields.additionalProvisions.placeholder',
        format: cleaningPermitAdditionalProvisions =>
          !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
            ? map(cleaningPermitAdditionalProvisions, 'id')
            : undefined
      },
      render: cleaningPermitAdditionalProvisions =>
        !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
          ? map(cleaningPermitAdditionalProvisions, (cleaningPermitAdditionalProvision, index) => (
              <Tag key={index}>{get(cleaningPermitAdditionalProvision, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.female'
    },
    unlistedCleaningPermitAdditionalProvisions: {
      title: 'pages.permits.fields.additionalProvisions.unlisted.title',
      dataIndex: 'unlistedCleaningPermitAdditionalProvisions',
      key: 'unlistedCleaningPermitAdditionalProvisions',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitAdditionalProvisions: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.additionalProvisions.unlisted.title',
        required:
          'pages.permits.fields.additionalProvisions.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.additionalProvisions.unlisted.placeholder',
        format: cleaningPermitAdditionalProvisions =>
          !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
            ? map(cleaningPermitAdditionalProvisions, 'id')
            : undefined
      },
      render: cleaningPermitAdditionalProvisions =>
        !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
          ? map(cleaningPermitAdditionalProvisions, (cleaningPermitAdditionalProvision, index) => (
              <Tag key={index}>{get(cleaningPermitAdditionalProvision, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.female'
    },
    roofExplosimetryMeasureController: {
      title: 'pages.permits.fields.roofExplosimetryMeasureController.title',
      dataIndex: 'roofExplosimetryMeasureController',
      key: 'roofExplosimetryMeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.roofExplosimetryMeasureController.title',
        placeholder:
          'pages.permits.fields.roofExplosimetryMeasureController.placeholder',
        type: 'select',
        format: controller =>
          !isNil(controller) && !isEmpty(controller) ? controller.id : undefined
      },
      render: controller =>
        !isNil(controller) && !isEmpty(controller) ? (
          <Tag>{controller.fullname}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    o2MeasureController: {
      title: 'pages.permits.fields.o2MeasureController.title',
      dataIndex: 'o2MeasureController',
      key: 'o2MeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.o2MeasureController.title',
        placeholder: 'pages.permits.fields.o2MeasureController.placeholder',
        type: 'select',
        format: controller =>
          !isNil(controller) && !isEmpty(controller) ? controller.id : undefined
      },
      render: controller =>
        !isNil(controller) && !isEmpty(controller) ? (
          <Tag>{controller.fullname}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    entryTemperatureMeasureController: {
      title: 'pages.permits.fields.entryTemperatureMeasureController.title',
      dataIndex: 'entryTemperatureMeasureController',
      key: 'entryTemperatureMeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.entryTemperatureMeasureController.title',
        placeholder:
          'pages.permits.fields.entryTemperatureMeasureController.placeholder',
        type: 'select',
        format: controller =>
          !isNil(controller) && !isEmpty(controller) ? controller.id : undefined
      },
      render: controller =>
        !isNil(controller) && !isEmpty(controller) ? (
          <Tag>{controller.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    entryTemperatureMeasureParticipant: {
      title: 'pages.permits.fields.entryTemperatureMeasureParticipant.title',
      dataIndex: 'entryTemperatureMeasureParticipant',
      key: 'entryTemperatureMeasureParticipant',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.entryTemperatureMeasureParticipant.title',
        placeholder:
          'pages.permits.fields.entryTemperatureMeasureParticipant.placeholder',
        type: 'select',
        format: controller =>
          !isNil(controller) && !isEmpty(controller) ? controller.id : undefined
      },
      render: controller =>
        !isNil(controller) && !isEmpty(controller) ? (
          <Tag>{controller.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    personnelAuthorizedToEnter: {
      title: 'pages.permits.fields.personnelAuthorizedToEnter.title',
      dataIndex: 'personnelAuthorizedToEnter',
      key: 'personnelAuthorizedToEnter',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.personnelAuthorizedToEnter.title',
        required:
          'pages.permits.fields.personnelAuthorizedToEnter.requiredMessage',
        placeholder:
          'pages.permits.fields.personnelAuthorizedToEnter.placeholder',

        format: personnelAuthorizedToEnter =>
          !isNil(personnelAuthorizedToEnter) && !isEmpty(personnelAuthorizedToEnter) ? map(personnelAuthorizedToEnter, 'id') : undefined
      },
      render: assistants =>
        !isNil(assistants) && !isEmpty(assistants)
          ? map(assistants, (assistant, index) => (
            <Tooltip title={!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ? I18n.t('pages.permits.fields.participants.habilitationNotGranted'): ''}>
              <Tag key={index}
              color={!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ? 'orange': 'green'}
              >
                {!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ?
                      <Icon type='warning' /> : ''
                }
                {assistant.name}
              </Tag>
          </Tooltip>
          ))
          : null,
      empty: 'common.unknown.male'
    },
    securityAssistants: {
      title: 'pages.permits.fields.securityParticipants.title',
      dataIndex: 'securityAssistants',
      key: 'securityAssistants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.securityParticipants.title',
        required: 'pages.permits.fields.securityParticipants.requiredMessage',
        placeholder: 'pages.permits.fields.securityParticipants.placeholder',

        format: assistant =>
          !isNil(assistant) && !isEmpty(assistant) ? map(assistant, 'id') : undefined
      },
      render: assistants =>
        !isNil(assistants) && !isEmpty(assistants)
          ? map(assistants, (assistant, index) => (
            <Tooltip title={!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ? I18n.t('pages.permits.fields.participants.habilitationNotGranted'): ''}>
            <Tag key={index}
            color={!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ? 'orange': 'green'}
            >
              {!assistant.isHabilitationGranted ||!assistant.isAcessSecurityGranted ?
                    <Icon type='warning' /> : ''
              }
              {assistant.name}
            </Tag>
          </Tooltip>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    depotSupervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'depotSupervisor',
      key: 'depotSupervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      render: supervisor =>
        !isNil(supervisor) && !isEmpty(supervisor) ? (
          <Tag>{supervisor.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    depotHead: {
      title: 'pages.permits.fields.depotHead.title',
      dataIndex: 'depotHead',
      key: 'depotHead',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.depotHead.title',
        placeholder: 'pages.permits.fields.depotHead.placeholder',
        format: depotHead =>
          !isNil(depotHead) && !isEmpty(depotHead) ? depotHead.id : undefined
      },
      render: depotHead =>
        !isNil(depotHead) && !isEmpty(depotHead) ? (
          <Tag>{depotHead.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companyLeader.title',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        type: 'select',
        format: leader =>
          !isNil(leader) && !isEmpty(leader) ? leader.id : undefined
      },
      render: leader =>
        !isNil(leader) && !isEmpty(leader) ? (
          <Tag>{leader.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    companySupervisor: {
      title: 'pages.permits.fields.companySupervisor.title',
      dataIndex: 'companySupervisor',
      key: 'companySupervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companySupervisor.title',
        required: 'pages.permits.fields.companySupervisor.requiredMessage',
        placeholder: 'pages.permits.fields.companySupervisor.placeholder',
        type: 'select',
        format: leader =>
          !isNil(leader) && !isEmpty(leader) ? leader.id : undefined
      },
      render: leader =>
        !isNil(leader) && !isEmpty(leader) ? (
          <Tag>{leader.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    roofExplosimetryMeasure: {
      title: 'pages.permits.fields.roofExplosimetryMeasure.title',
      dataIndex: 'roofExplosimetryMeasure',
      key: 'roofExplosimetryMeasure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.roofExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.roofExplosimetryMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    o2Measure: {
      title: 'pages.permits.fields.roofO2Measure.title',
      dataIndex: 'o2Measure',
      key: 'o2Measure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.roofO2Measure.title',
        placeholder: 'pages.permits.fields.roofO2Measure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    entryExplosimetryMeasure: {
      title: 'pages.permits.fields.entryExplosimetryMeasure.title',
      dataIndex: 'entryExplosimetryMeasure',
      key: 'entryExplosimetryMeasure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.entryExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.entryExplosimetryMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    exitExplosimetryMeasure: {
      title: 'pages.permits.fields.exitExplosimetryMeasure.title',
      dataIndex: 'exitExplosimetryMeasure',
      key: 'exitExplosimetryMeasure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.exitExplosimetryMeasure.title',
        placeholder: 'pages.permits.fields.exitExplosimetryMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    entryTemperatureMeasure: {
      title: 'pages.permits.fields.entryTemperatureMeasure.title',
      dataIndex: 'entryTemperatureMeasure',
      key: 'entryTemperatureMeasure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.entryTemperatureMeasure.title',
        placeholder: 'pages.permits.fields.entryTemperatureMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} Â°C</p> : null),
      empty: 'common.unknown.female'
    },
    insideTemperatureMeasure: {
      title: 'pages.permits.fields.insideTemperatureMeasure.title',
      dataIndex: 'insideTemperatureMeasure',
      key: 'insideTemperatureMeasure',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.insideTemperatureMeasure.title',
        placeholder: 'pages.permits.fields.insideTemperatureMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} Â°C</p> : null),
      empty: 'common.unknown.female'
    },
    tankLIECenter: {
      title: 'pages.permits.fields.LIEMeasure.title.center',
      dataIndex: 'tankLIECenter',
      key: 'tankLIECenter',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.LIEMeasure.title.center',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    tankLIEFirstPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.first',
      dataIndex: 'tankLIEFirstPoint',
      key: 'tankLIEFirstPoint',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.LIEMeasure.title.first',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    tankLIESecondPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.second',
      dataIndex: 'tankLIESecondPoint',
      key: 'tankLIESecondPoint',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.LIEMeasure.title.second',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    tankLIEThirdPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.third',
      dataIndex: 'tankLIEThirdPoint',
      key: 'tankLIEThirdPoint',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.LIEMeasure.title.third',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    },
    tankLIEFourthPoint: {
      title: 'pages.permits.fields.LIEMeasure.title.fourth',
      dataIndex: 'tankLIEFourthPoint',
      key: 'tankLIEFourthPoint',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'measures'
        },
        label: 'pages.permits.fields.LIEMeasure.title.fourth',
        placeholder: 'pages.permits.fields.LIEMeasure.placeholder'
      },
      render: measure => (!isNil(measure) ? <p>{measure} %</p> : null),
      empty: 'common.unknown.female'
    }
  }
}

export default specs
