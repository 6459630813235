// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as associablePermitActions } from 'Resources/AssociablePermitResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/AssociablePermits/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class AssociablePermitsConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    associablePermit: PropTypes.object,
    associablePermits: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    associablePermit: {},
    associablePermits: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchAssociablePermitsData()

    if (this.getContext() === 'single') {
      this.getAssociablePermitData().then(this.askUpdateAssociablePermit)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchAssociablePermitsData = () => {
    this.props.actions.fetchAssociablePermits().catch(() => {
      message.error(I18n.t('api.errors.associablePermit.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getAssociablePermitData = (
    associablePermit = this.props.navigation.getParam('id')
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getAssociablePermit(associablePermit)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.associablePermit.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyAssociablePermit = (associablePermit, clearTableSelections) => {
    this.props.actions
      .archiveAssociablePermits(associablePermit)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.associablePermit.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.associablePermit.bulk.delete'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveAssociablePermit = associablePermit => {
    this.props.actions
      .archiveAssociablePermit(associablePermit)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.associablePermit.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.associablePermit.delete'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyAssociablePermit = (associablePermit, clearTableSelections) => {
    this.props.actions
      .deleteAssociablePermits(associablePermit)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.associablePermit.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.associablePermit.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteAssociablePermit = associablePermit => {
    this.props.actions
      .deleteAssociablePermit(associablePermit)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.associablePermit.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.associablePermit.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un associablePermit
   */
  updateAssociablePermit = (
    updatedAssociablePermit,
    originalAssociablePermit
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateAssociablePermit({
          id: get(originalAssociablePermit, 'id'),
          ...updatedAssociablePermit
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.associablePermit.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.associablePermit.update'))
        })
    })

  /**
   * CrÃ©ation d'un associablePermit
   */
  createAssociablePermit = createdAssociablePermit =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createAssociablePermit(createdAssociablePermit)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.associablePermit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.associablePermit.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyAssociablePermits = (...params) => {
    confirm({
      title: I18n.t('pages.associablePermits.modal.archive.multiple.title'),
      content: I18n.t('pages.associablePermits.modal.archive.multiple.content'),
      okText: I18n.t('pages.associablePermits.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.associablePermits.modal.archive.multiple.cancelText'
      ),
      onOk: () => {
        this.archiveAssociablePermit(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveAssociablePermit = () => {
    const { associablePermit } = this.props

    confirm({
      title: I18n.t('pages.associablePermits.modal.archive.single.title'),
      content: I18n.t('pages.associablePermits.modal.archive.single.content'),
      okText: I18n.t('pages.associablePermits.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.associablePermits.modal.archive.single.cancelText'
      ),
      onOk: () => {
        this.archiveAssociablePermit(associablePermit)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyAssociablePermits = (...params) => {
    confirm({
      title: I18n.t('pages.associablePermits.modal.delete.multiple.title'),
      content: I18n.t('pages.associablePermits.modal.delete.multiple.content'),
      okText: I18n.t('pages.associablePermits.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.associablePermits.modal.delete.multiple.cancelText'
      ),
      onOk: () => {
        this.archiveManyAssociablePermit(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteAssociablePermit = () => {
    const { associablePermit } = this.props

    confirm({
      title: I18n.t('pages.associablePermits.modal.delete.single.title'),
      content: I18n.t('pages.associablePermits.modal.delete.single.content'),
      okText: I18n.t('pages.associablePermits.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.associablePermits.modal.delete.single.cancelText'
      ),
      onOk: () => {
        this.archiveAssociablePermit(associablePermit)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateAssociablePermit = () => {
    this.setState({ openedModal: 'updateAssociablePermit' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateAssociablePermit = () => {
    this.setState({ openedModal: 'createAssociablePermit' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateAssociablePermit') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = associablePermit => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(associablePermit, 'id') })

    this.getAssociablePermitData(associablePermit)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateAssociablePermit()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('AssociablePermit', { id: get(associablePermit, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderAssociablePermit(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.associablePermit.order')))
      .catch(() => message.error(I18n.t('api.errors.associablePermit.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      associablePermits,
      associablePermit
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getAssociablePermitData: this.getAssociablePermitData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateAssociablePermit: this.askCreateAssociablePermit,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateAssociablePermit: this.askUpdateAssociablePermit,
      askUpdateManyAssociablePermits: this.askUpdateAssociablePermit,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveAssociablePermit: this.askArchiveAssociablePermit,
      askArchiveManyAssociablePermits: this.askArchiveManyAssociablePermits,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteAssociablePermit: this.askDeleteAssociablePermit,
      askDeleteManyAssociablePermits: this.askDeleteManyAssociablePermits,

      // RÃ©initialisation du mot de passe d'un associablePermit
      askResetPasswordAssociablePermit: this.askResetPasswordAssociablePermit
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteAssociablePermit

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={associablePermits}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateAssociablePermit'
              ? this.updateAssociablePermit
              : this.createAssociablePermit
          }
          visible={includes(
            ['createAssociablePermit', 'updateAssociablePermit'],
            openedModal
          )}
          data={
            includes(['transition', 'updateAssociablePermit'], openedModal)
              ? associablePermit
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(AssociablePermitsConstructor, 'defaultProps', {})

  return {
    associablePermits: defaultTo(
      get(state, 'associablePermit.items'),
      defaultProps.associablePermits
    ),
    associablePermit: defaultTo(
      get(state, 'associablePermit.item'),
      defaultProps.associablePermit
    ),
    isGathering:
      defaultTo(
        get(state, 'associablePermit.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'associablePermit.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'associablePermit.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...associablePermitActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AssociablePermitsConstructor)
