// Libraries
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  map,
  get,
  includes,
  pickBy,
  isEmpty,
  forEach,
  isNumber,
  isNull
} from 'lodash'

// Common components
import { TableLayout } from 'Components'

// Specs
import { TableSpecs, RequestTableSpecs } from 'Views/Permits/Specs'

// Styles
import './Styles/List.less'

import { Card, Tabs } from 'antd'
import I18n from 'i18next'

const { TabPane } = Tabs

class PermitsList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // DonnÃ©es de la vue
    totalPermits: PropTypes.number,
    totalPermitsRequests: PropTypes.number,
    permits: PropTypes.array,
    permitsRequests: PropTypes.array,

    // DonnÃ©es de construction du tableau
    TableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    totalPermits: 0,
    totalPermitsRequests: 0,
    permitsRequests: [],
    permits: []
  }

  constructor(props) {
    super(props)

    this.state = {
      // donnÃ©es des filtres
      searchText: '',
      currentPage: 0,
      showPageSize: 10,
      sortField: 'dateCreated',
      sortOrder: 'descend',
      sortFilters: {},
      data: []
    }
  }

  // gestion des filtres/champs de recherche/sorters actif sur la table
  handleTableChange = (search, current, pageSize, field, order, filters) => {
    const {
      searchText,
      currentPage,
      showPageSize,
      sortField,
      sortOrder,
      sortFilters
    } = this.state
    const { constructorFunctions } = this.props

    // s'il n'y a pas de valeur dans le champs de recherche, alors je prends les donnÃ©es passÃ©es en props
    // sinon je prends les donnÃ©es issues du state
    current = isNumber(current) ? current : currentPage
    pageSize = isNumber(pageSize) ? pageSize : showPageSize
    field = !isEmpty(field) ? field : sortField
    order = !isEmpty(order) ? order : sortOrder
    filters = !isEmpty(filters) || isNull(search) ? filters : sortFilters
    search = !isNull(search) ? search : searchText

    get(constructorFunctions, 'handleTableOrdered', () => {})(
      current,
      pageSize,
      field,
      order,
      filters,
      search
    )
  }

  renderTabs = () => {
    const { loading } = this.props

    return (
      <Tabs
        className='permit-list'
        defaultActiveKey='active'
        type='card'
        onChange={() => {
          this.setState({
            // donnÃ©es des filtres
            searchText: '',
            currentPage: 0,
            showPageSize: 10,
            sortField: 'dateCreated',
            sortOrder: 'descend',
            sortFilters: {},
            data: []
          })
        }}
      >
        {/* Permis actifs */}
        <TabPane
          tab={
            <span className={`active-permits`}>
              {I18n.t('pages.permits.tabs.active.title')}
            </span>
          }
          key='active'
        >
          <Card>
            <TableLayout loading={loading} {...TableSpecs} />
          </Card>
        </TabPane>

        {/* Requetes de permis */}
        <TabPane
          tab={
            <span className={`request-permits`}>
              {I18n.t('pages.permits.tabs.request.title')}
            </span>
          }
          key='request'
        >
          <Card>
            <TableLayout loading={loading} {...RequestTableSpecs} />
          </Card>
        </TabPane>
      </Tabs>
    )
  }

  render() {
    const {
      permits,
      totalPermits,
      permitsRequests,
      totalPermitsRequests,
      constructorFunctions,
      profile
    } = this.props

    // Ajout des donnÃ©es au tableau
    TableSpecs.rows = permits
    RequestTableSpecs.rows = permitsRequests

    // Ajout des actions au clic sur un ligne du tableau
    TableSpecs.onRow = RequestTableSpecs.onRow = (permit, id) => ({
      onClick: () =>
        get(constructorFunctions, 'handleRowClick', () => {})(permit, id)
    })

    // Pagination
    // Nombre total de permits
    TableSpecs.pagination.total = totalPermits
    TableSpecs.totalItems = totalPermits
    RequestTableSpecs.pagination.total = totalPermitsRequests
    RequestTableSpecs.totalItems = totalPermitsRequests

    // Changement de la valeur dans la barre de recherche
    TableSpecs.onSearch = RequestTableSpecs.onSearch = searchText => {
      this.setState({ searchText })
      this.handleTableChange(searchText)
    }

    // Changement de la pagination OU des filters/sorters
    TableSpecs.onChange = RequestTableSpecs.onChange = (
      pagination,
      filters,
      sorter,
      extra
    ) => {
      const { current, pageSize } = pagination
      const { field, order } = sorter

      // Je retire les filtres vides (rÃ©initialisÃ©s)
      filters = pickBy(filters, filter => !isEmpty(filter))

      // Je boucle sur les filtres afin de reconstruire les clÃ©s/valeurs pour l'API
      forEach(filters, (value, key) => {
        const filterValue = value[0]
        if (key === 'dateCreated') {
          delete filters['dateCreated']
          filters['dateCreated__gte'] = filterValue[0]
          filters['dateCreated__lte'] = filterValue[1]
        } else if (key === 'validityPeriod') {
          delete filters['validityPeriod']
          filters['validityPeriod_id__startDate__gte'] = filterValue[0]
          filters['validityPeriod_id__endDate__lte'] = filterValue[1]
        } else {
          filters[key] = value
        }
      })

      // Je stocke dans le state afin de rÃ©cupÃ©rer les valeurs en cas de recherche via la barre de recherche
      this.setState({
        currentPage: current,
        showPageSize: pageSize,
        sortField: field,
        sortOrder: order,
        sortFilters: filters
      })

      this.handleTableChange(null, current, pageSize, field, order, filters)
    }

    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR')

    if (isUserExtern) TableSpecs.actions.global.create.hidden = true
    else RequestTableSpecs.actions.global.create.hidden = true

    // Ajout des actions des boutons
    TableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreatePermit'
    )

    RequestTableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreatePermitRequest'
    )

    return (
      <main className='screen permits permits-list'>{this.renderTabs()}</main>
    )
  }
}

export default PermitsList
