import { createResource } from 'redux-rest-resource'
import { merge, cloneDeep } from 'lodash'
import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'profile',
  url: `${baseURL}`,
  credentials: 'include',
  actions: {
    get: { method: 'GET', gerundName: 'getting', url: './auth/me' },
    update: {
      method: 'PUT',
      gerundName: 'updating',
      assignResponse: true,
      url: './auth/me',
      reduce: (state, { body }) => {
        return {
          ...state,
          item: cloneDeep(merge(state.item, body))
        }
      }
    },
    changePassword: {
      method: 'POST',
      gerundName: 'updating',
      url: './auth/password'
    }
  }
})
