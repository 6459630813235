// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { isNil, defaultTo } from 'lodash'
import { Tooltip, Tag } from 'antd'

// Helpers
import {
  dateSort,
  filterPeriod,
  alphaSort,
  filterBool
} from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'company',
    kind: 'female'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    },
    // Actions groupÃ©es sur les lignes du tableau
    selection: {
      delete: {
        buttonType: 'danger'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.companies.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Autorise les lignes en drag and drop
  areRowsDraggable: false,

  // Colonnes du tableau
  columns: {
    name: {
      title: 'pages.companies.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'name')
    },
    type: {
      title: 'pages.companies.fields.type.title',
      dataIndex: 'type',
      key: 'type',
      render: type => (!isNil(type) ? type : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'type')
    },
    email: {
      title: 'pages.companies.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      render: email =>
        !isNil(email) ? email : I18n.t('common.unknown.female'),
      sorter: (...params) => alphaSort(params, 'email')
    },
    telephone: {
      title: 'pages.companies.fields.telephone.title',
      dataIndex: 'telephone',
      key: 'telephone',
      render: telephone =>
        !isNil(telephone) ? telephone : I18n.t('common.unknown.male'),
      sorter: (...params) => alphaSort(params, 'telephone')
    },
    critical: {
      title: 'pages.companies.fields.critical.title',
      dataIndex: 'critical',
      key: 'critical',
      render: critical => (
        <Tag color={isNil(critical) || !critical ? 'red' : 'green'}>
          {I18n.t(
            `pages.companies.fields.critical.options.${defaultTo(
              critical,
              'false'
            )}.title`
          )}
        </Tag>
      ),
      ...filterBool('critical')
    },
    dateCreated: {
      title: 'pages.companies.fields.dateCreated.title',
      dataIndex: 'dateCreated',
      key: 'dateCreated',
      render: dateCreated =>
        !isNil(dateCreated) ? (
          <Tooltip title={moment(dateCreated).format('L')}>
            {moment(dateCreated).calendar()}
          </Tooltip>
        ) : (
          I18n.t('common.unknown.female')
        ),
      sorter: (...params) => dateSort(params, 'dateCreated'),
      ...filterPeriod(null, moment(), 'dateCreated')
    }
  }
}

export default specs
