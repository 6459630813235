// Libraries
import React from 'react'
import { Tooltip, Icon } from 'antd'
import { lookup as mimeLookup } from 'mime-types'
import _, {
  filter,
  keys,
  mapValues,
  isPlainObject,
  every,
  isNil,
  includes,
  forEach
} from 'lodash'

/**
 * Filtre les donnÃ©es en fonction du motif renseignÃ© dans le champs de recherche
 *
 * Retourne un tableau contenant toutes les donnÃ©es en fonction de la recherche de l'utilisateur
 * @return {array}
 */
export const searchData = (data, searchText) => {
  searchText = searchText.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')

  // CrÃ©ation de l'expression rationnelle avec la recherche de l'utilisateur
  const regExp = new RegExp(searchText, 'gi')

  // DonnÃ©es filtrÃ©es sur la recherche
  const filteredData = filter(data, element => {
    let found = false

    // Pour chaque champs recherche avec le mot clÃ©
    keys(element).forEach(key => {
      found = found || JSON.stringify(element[key]).match(regExp)
    })

    return found
  })

  return filteredData
}

/**
 * Shorten number to thousands, millions, billions, etc.
 * http://en.wikipedia.org/wiki/Metric_prefix
 *
 * @param {number} num Number to shorten.
 * @param {number} [digits=0] The number of digits to appear after the decimal point.
 * @returns {string|number}
 *
 * @example
 * // returns '12.5k'
 * shortenLargeNumber(12543, 1)
 *
 * @example
 * // returns '-13k'
 * shortenLargeNumber(-12567)
 *
 * @example
 * // returns '51M'
 * shortenLargeNumber(51000000)
 *
 * @example
 * // returns 651
 * shortenLargeNumber(651)
 *
 * @example
 * // returns 0.12345
 * shortenLargeNumber(0.12345)
 */
export function shortenLargeNumber(num, digits) {
  const units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y']
  let decimal

  for (var i = units.length - 1; i >= 0; i--) {
    decimal = Math.pow(1000, i + 1)

    if (num <= -decimal || num >= decimal) {
      return +(num / decimal).toFixed(digits) + units[i]
    }
  }

  return num
}

/**
 * Permet de tronquer une string avec un nombre de caractÃ¨res dÃ©fini
 * @param {String} string
 * @param {Int} n
 * @param {Bool} withSpan
 */
export function truncateString(string = '', n, withSpan) {
  if (string.length <= n) {
    return string
  }

  const subString = string.substr(0, n - 1)

  const finalString = subString.substr(0, subString.lastIndexOf(' '))

  const isTruncated = string.length !== finalString.length

  return withSpan ? (
    <span className={isTruncated ? 'truncated' : 'original'}>
      {finalString}
    </span>
  ) : (
    finalString
  )
}

/**
 * Permet de tronquer un nom de fichier avec nombre de caractÃ¨res dÃ©fini
 * @param {String} string
 * @param {Int} n
 * @param {Bool} withSpan
 */
export function cropWithExtension(value, maxChars = 21, trailingCharCount = 6) {
  var result = value

  if (value.length > maxChars) {
    var front = value.substr(
      0,
      value.length - (maxChars - trailingCharCount - 3)
    )
    var mid = 'â¦'
    var end = value.substr(-trailingCharCount)

    result = front + mid + end
  }

  return result
}

// Map values deep
export function createFormFields(Form, object) {
  return mapValues(object, value => {
    if (isPlainObject(value)) {
      return createFormFields(Form, value)
    } else {
      return Form.createFormField({ value })
    }
  })
}

// IsNil Deep
export function isDeepNil(value) {
  return isPlainObject(value) ? every(value, isDeepNil) : isNil(value)
}

// Rendu d'un lien avec survol
export function renderImageLinkPreview(link, name = link) {
  const customLink = (
    <a target='_blank' rel='noopener noreferrer' href={link}>
      {name}
    </a>
  )

  return includes(mimeLookup(name), 'image') ? (
    <Tooltip
      className='preview-tooltip'
      title={<img className='preview-img' alt={name} title={name} src={link} />}
    >
      {customLink}
    </Tooltip>
  ) : (
    customLink
  )
}

// Remove Empty objects
export function removeEmptyObjects(obj) {
  return _(obj)
    .pickBy(_.isObject) // pick objects only
    .mapValues(removeEmptyObjects) // call only for object values
    .omitBy(_.isEmpty) // remove all empty objects
    .assign(_.omitBy(obj, _.isObject)) // assign back primitive values
    .value()
}

// RÃ©cupÃ©ration d'une icone en fonction d'un type de fichier
export function getIconMimeType(mimeType, props) {
  let icon

  switch (mimeType) {
    case 'text/plain':
    case 'text/html':
      icon = 'file-text'
      break

    // PDF
    case 'application/pdf':
      icon = 'file-pdf'
      break

    // Word et Texte OpenDocument
    case 'application/vnd.oasis.opendocument.text':
    case 'application/msword':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      icon = 'file-word'
      break

    // Excel et Calcul OpenDocument
    case 'application/vnd.oasis.opendocument.spreadsheet':
    case 'application/vnd.ms-excel':
      icon = 'file-excel'
      break

    // Images
    case 'image/jpeg':
    case 'image/png':
    case 'image/gif':
    case 'image/bmp':
    case 'image/webp':
    case 'image/svg+xml':
      icon = 'file-jpg'
      break

    // PowerPoint et PrÃ©sentation OpenDocument
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.oasis.opendocument.presentation':
      icon = 'file-ppt'
      break

    // Markdown
    case 'text/markdown':
      icon = 'file-markdown'
      break

    // Zip et archives
    case 'application/zip':
    case 'application/x-rar-compressed':
    case 'application/octet-stream':
    case 'application/x-7z-compressed':
    case 'application/java-archive':
    case 'application/x-gzip':
    case 'application/x-tar':
    case 'application/x-rar':
    case 'application/x-bzip':
    case 'application/x-bzip2':
      icon = 'file-zip'
      break

    default:
      icon = 'file'
      break
  }

  return icon
}

export function getIconMimeTypeComponent(mimeType, props) {
  return <Icon type={getIconMimeType(mimeType, props)} {...props} />
}

export function formatOrderedData(data) {
  const formattedData = {}

  forEach(data, ({ id, name }, index) => {
    formattedData[[index + 1]] = id
  })

  return formattedData
}
