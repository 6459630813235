// Libraries
import { get, isNil } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Card, Icon, Tooltip, Tag } from 'antd'

// Common components
import { TableLayout } from 'Components'

// Specs
import { EmployeeTableSpecs } from 'Views/Companies/Specs'
import I18n from 'i18next'
import moment from 'moment'

class EmployeesList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,

    // DonnÃ©es de construction du tableau
    EmployeeTableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    dataList: []
  }

  constructor(props) {
    super(props)

    this.state = {
      pagination: EmployeeTableSpecs.pagination
    }
  }

  handleOnChange = pagination => {
    this.setState({ pagination })
  }

  render() {
    const { loading, dataList, constructorFunctions } = this.props
    const { pagination } = this.state

    // Ajout des donnÃ©es au tableau
    EmployeeTableSpecs.rows = dataList

    // Ajout des actions au clic sur un ligne du tableau
    EmployeeTableSpecs.columns.name.onCell = EmployeeTableSpecs.columns.email.onCell = EmployeeTableSpecs.columns.job.onCell = EmployeeTableSpecs.columns.contractType.onCell = EmployeeTableSpecs.columns.telephone.onCell = (
      supervisor,
      id
    ) => ({
      onClick: () =>
        get(constructorFunctions, 'askUpdateCompanyEmployee', () => {})(
          supervisor,
          id
        )
    })

    EmployeeTableSpecs.columns.securityForm.onCell = (supervisor) => ({
      onClick: () => {
        if (supervisor.receptionSecurityPermits.length === 0){
          get(constructorFunctions, 'askCreateSecurityForm', () => {})(
            supervisor
            //id
          )
        } else {
          get(constructorFunctions, 'askUpdateSecurityForm', () => {})(
            supervisor
            //id
          )
        }
      }
    })

    // Ajout d'un bouton pour la signature
    EmployeeTableSpecs.columns.securityFormSign.onCell = (supervisor) => ({
      onClick: () => {
        if (!supervisor.receptionSecurityPermits.isSigned){

          get(constructorFunctions, 'askSignSecurityForm', () => {})(
            supervisor
            //id
          )
        }
      }
    })



    EmployeeTableSpecs.columns.securityFormSign.render = (form,supervisor) => {
      if (supervisor.receptionSecurityPermits.length === 0) {
        return (
          <Tag color={'red'}>
            {I18n.t('pages.companies.fields.securityForm.nosign')}
          </Tag>
        );
      } else {
        const now = new moment();
        const endDate = moment(supervisor.receptionSecurityPermits[0].endDate, 'YYYY-MM-DDTHH:mm:ss.SSSSZ')
        const isSigned = supervisor.receptionSecurityPermits[0].isSigned

        if (!isSigned) {
          return (
            <Button type='link' icon='edit'>
              {I18n.t('pages.companies.fields.securityForm.sign')}
            </Button>
          );
        } else if (isSigned && endDate > now) {
          return (
             <Tag color={'green'}>
                <Icon type='check'/>
                {I18n.t('pages.companies.fields.securityForm.signed')}
              </Tag>
           
          );
        }
        else if (isSigned && endDate < now) {
          return (
            <Tag color={'red'}>
              <Icon type='fire'/>
              {I18n.t('pages.companies.fields.securityForm.expired')}
            </Tag>
          );
        
        }
      }
    }

    // Pagination courante
    EmployeeTableSpecs.pagination = pagination
    EmployeeTableSpecs.onChange = this.handleOnChange

    EmployeeTableSpecs.columns.securityForm.render = (form, supervisor) => {
      if (!isNil(form)) {
        return (
          <Tooltip title={moment(form.date).format('L')}>
            <Button type='link' icon='edit'>
              {I18n.t('pages.companies.fields.securityForm.editButton')}
            </Button>
          </Tooltip>
        );
      } else {
        if (supervisor.receptionSecurityPermits.length === 0) {
          return (
            <Button type='link' icon='plus'>
              {I18n.t('pages.companies.fields.securityForm.createButton')}
            </Button>
          );
        } else {
          return (
            <Button type='link' icon='edit'>
              {I18n.t('pages.companies.fields.securityForm.editButton')}
            </Button>
          );
        }
      }
    };

    // Ajout des actions multiples sur le tableau
    EmployeeTableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteCompanyManyEmployees'
    )

    // Ajout des actions des boutons
    EmployeeTableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreateCompanyEmployee'
    )

    return (
      <Card className='tab employees'>
        <TableLayout loading={loading} {...EmployeeTableSpecs} />
      </Card>
    )
  }
}

export default EmployeesList
