// @flow

// Libraries
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

// Sagas
import rootSaga from 'Sagas/'

// Store
import configureStore from 'Redux/CreateStore'

// Reducer
import { reducer as startupReducer } from 'Redux/StartupRedux'

// Resources
import { navReducer } from 'Navigation/NavigationRouter'
import { reducer as webSocketReducer } from 'WebSocket/Redux'
import { rootReducer as apiReducer } from 'Resources/ApiResource'
import { rootReducer as langReducer } from 'Resources/LangResource'
import { rootReducer as authReducer } from 'Resources/AuthResource'
// import { rootReducer as rateReducer } from 'Resources/RateResource'
import { rootReducer as riskReducer } from 'Resources/RiskResource'
import { rootReducer as scanReducer } from 'Resources/ScanResource'
import { rootReducer as userReducer } from 'Resources/UserResource'
import { rootReducer as permitReducer } from 'Resources/PermitResource'
import { rootReducer as companyReducer } from 'Resources/CompanyResource'
import { rootReducer as employeeReducer } from 'Resources/EmployeeResource'
import { rootReducer as supervisorReducer } from 'Resources/SupervisorResource'
import { rootReducer as firePermitReducer } from 'Resources/FirePermitResource'
import { rootReducer as profileReducer } from 'Resources/ProfileResource'
import { rootReducer as materialReducer } from 'Resources/MaterialResource'
import { rootReducer as habilitationReducer } from 'Resources/HabilitationResource'
import { rootReducer as operationReducer } from 'Resources/OperationResource'
import { rootReducer as fireSourceReducer } from 'Resources/FireSourceResource'
import { rootReducer as securityMeasureReducer } from 'Resources/SecurityMeasureResource'
import { rootReducer as associablePermitReducer } from 'Resources/AssociablePermitResource'
import { rootReducer as preventiveMeasureReducer } from 'Resources/PreventiveMeasureResource'
import { rootReducer as companyStatisticsReducer } from 'Resources/DashboardResource'
import { rootReducer as capacitySecurityMeasureReducer } from 'Resources/CapacitySecurityMeasureResource'
import { rootReducer as capacityEquipmentReducer } from 'Resources/CapacityEquipmentResource'
import { rootReducer as capacityAdditionalProvisionReducer } from 'Resources/CapacityAdditionalProvisionResource'
import { rootReducer as capacityPermitReducer } from 'Resources/CapacityPermitResource'
import { rootReducer as searchPermitReducer } from 'Resources/SearchPermitResource'
import { rootReducer as searchSecurityMeasureReducer } from 'Resources/SearchSecurityMeasuresResource'
import { rootReducer as searchPredictableRiskReducer } from 'Resources/SearchPredictableRisksResource'
import { rootReducer as cleaningPermitReducer } from 'Resources/CleaningPermitResource'
import { rootReducer as cleaningConditionReducer } from 'Resources/CleaningConditionsResource'
import { rootReducer as cleaningEquipmentReducer } from 'Resources/CleaningEquipmentsResource'
import { rootReducer as cleaningAdditionalProvisionReducer } from 'Resources/CleaningAdditionalProvisionsResource'

// Config
import { ReduxPersist } from 'Config'

export default () => {
  /* ------------- Assemble The Reducers ------------- */
  let rootReducer = combineReducers({
    nav: navReducer,
    api: apiReducer,
    auth: authReducer,
    // rate: rateReducer,
    risk: riskReducer,
    scan: scanReducer,
    user: userReducer,
    lang: langReducer,
    permit: permitReducer,
    firePermit: firePermitReducer,
    startup: startupReducer,
    profile: profileReducer,
    material: materialReducer,
    habilitation: habilitationReducer,
    websocket: webSocketReducer,
    operation: operationReducer,
    fireSource: fireSourceReducer,
    securityMeasure: securityMeasureReducer,
    associablePermit: associablePermitReducer,
    company: companyReducer,
    employee: employeeReducer,
    supervisor: supervisorReducer,
    preventiveMeasure: preventiveMeasureReducer,
    companyStatistics: companyStatisticsReducer,
    capacitySecurityMeasure: capacitySecurityMeasureReducer,
    capacityEquipment: capacityEquipmentReducer,
    capacityAdditionalProvision: capacityAdditionalProvisionReducer,
    capacityPermit: capacityPermitReducer,
    searchPermit: searchPermitReducer,
    searchSecurityMeasure: searchSecurityMeasureReducer,
    searchPredictableRisk: searchPredictableRiskReducer,
    cleaningPermit: cleaningPermitReducer,
    cleaningCondition: cleaningConditionReducer,
    cleaningEquipment: cleaningEquipmentReducer,
    cleaningAdditionalProvision: cleaningAdditionalProvisionReducer
  })

  /* ------------- Config persistant store ------------- */
  if (ReduxPersist.active) {
    rootReducer = persistReducer(ReduxPersist.storeConfig, rootReducer)
  }

  return configureStore(rootReducer, rootSaga)
}
