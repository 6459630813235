const specs = {
  width: 620,
  title: {
    update: 'pages.associablePermits.modal.update.single.title',
    create: 'pages.associablePermits.modal.create.single.title'
  },
  okText: {
    update: 'pages.associablePermits.modal.update.single.okText',
    create: 'pages.associablePermits.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    name: {
      title: 'pages.associablePermits.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      formField: {
        label: 'pages.associablePermits.fields.name.title',
        required: 'pages.associablePermits.fields.name.requiredMessage',
        placeholder: 'pages.associablePermits.fields.name.placeholder'
      },
      empty: 'common.unknown.male'
    }
    // prefix: {
    //   title: 'pages.associablePermits.fields.prefix.title',
    //   dataIndex: 'prefix',
    //   key: 'prefix',
    //   formField: {
    //     label: 'pages.associablePermits.fields.prefix.title',
    //     required: 'pages.associablePermits.fields.prefix.requiredMessage',
    //     placeholder: 'pages.associablePermits.fields.prefix.placeholder',
    //     questionMark: 'pages.associablePermits.fields.prefix.questionMark'
    //   },
    //   empty: 'common.unknown.male'
    // }
  }
}

export default specs
