// Libraries
import I18n from 'i18next'
import moment from 'moment'
import faker from 'faker'
import download from 'downloadjs'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Button, Divider, Empty, message, Modal } from 'antd'
import React, { Component, Fragment } from 'react'

import {
  defaultTo,
  find,
  forEach,
  get,
  has,
  includes,
  isEmpty,
  isNil,
  map,
  replace,
  toNumber,
  upperFirst
} from 'lodash'

// Ressources
import { actions as firePermitActions } from 'Resources/FirePermitResource'
import { actions as riskActions } from 'Resources/RiskResource'
import { actions as permitActions } from 'Resources/PermitResource'
import { actions as operationActions } from 'Resources/OperationResource'
import { actions as preventiveMeasureActions } from 'Resources/PreventiveMeasureResource'
import { actions as materialActions } from 'Resources/MaterialResource'
import { actions as associablePermitActions } from 'Resources/AssociablePermitResource'
import { actions as fireSourceActions } from 'Resources/FireSourceResource'
import { actions as securityMeasureActions } from 'Resources/SecurityMeasureResource'
import { actions as companyActions } from 'Resources/CompanyResource'
import { actions as supervisorActions } from 'Resources/SupervisorResource'
import { actions as employeeActions } from 'Resources/EmployeeResource'
import { actions as capacityPermitActions } from 'Resources/CapacityPermitResource'
import { actions as capacityEquipmentActions } from 'Resources/CapacityEquipmentResource'
import { actions as capacitySecurityMeasureActions } from 'Resources/CapacitySecurityMeasureResource'
import { actions as capacityAdditionalProvisionActions } from 'Resources/CapacityAdditionalProvisionResource'
import { actions as searchSecurityMeasureActions } from 'Resources/SearchSecurityMeasuresResource'
import { actions as searchPredictableRiskActions } from 'Resources/SearchPredictableRisksResource'
import { actions as searchPermitActions } from 'Resources/SearchPermitResource'
import { actions as cleaningPermitActions } from 'Resources/CleaningPermitResource'
import { actions as cleaningConditionActions } from 'Resources/CleaningConditionsResource'
import { actions as cleaningEquipmentActions } from 'Resources/CleaningEquipmentsResource'
import { actions as cleaningAdditionalProvisionActions } from 'Resources/CleaningAdditionalProvisionsResource'
import { actions as userActions } from 'Resources/UserResource'

// Common components
import { ManageModal } from 'Components'

// Local components
import { CancelModal, SignatureModal } from './Components'

// Specs
import {
  CapacityControlModalSpecs,
  CapacityFormSpecs,
  CapacityModalSpecs,
  CleanControlPhase1ModalSpecs,
  CleanControlPhase2ModalSpecs,
  CleanFormSpecs,
  CleanModalSpecs,
  FireFormSpecs,
  FireModalSpecs,
  FormSpecs,
  ModalSpecs,
  RequestModalSpecs,
  SearchFormSpecs,
  SearchModalSpecs
} from 'Views/Permits/Specs'
import { ModalSpecs as SupervisorModalSpecs } from 'Views/Supervisors/Specs'
import { ModalSpecs as CompanyModalSpecs } from 'Views/Companies/Specs'
import EmployeeModalSpecs from 'Views/Companies/Specs/Employee/Modal.js'

// Ãcrans
import { List, Single } from './Screens'
import { ChangeLog, Scans } from './Screens/Tabs'
import { EndModal } from 'Views/Permits/Components/EndModal'
import { ConfirmRequestModal } from 'Views/Permits/Components/ConfirmRequestModal'

const { confirm } = Modal

class PermitsConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    permit: PropTypes.object,
    permits: PropTypes.array,
    permitsRequests: PropTypes.array,
    totalPermits: PropTypes.number,
    totalPermitsRequests: PropTypes.number,
    firePermit: PropTypes.object,
    firePermits: PropTypes.array,

    // DonnÃ©es complÃ©mentaires
    associablePermits: PropTypes.array,
    companies: PropTypes.array,
    supervisors: PropTypes.array,
    users: PropTypes.array,
    securityMeasures: PropTypes.array,
    profile: PropTypes.object,
    risks: PropTypes.array,
    searchPredictableRisks: PropTypes.array,
    searchSecurityMeasures: PropTypes.array,
    materials: PropTypes.array,
    operations: PropTypes.array,
    preventiveMeasures: PropTypes.array,
    fireSources: PropTypes.array,
    capacityEquipments: PropTypes.array,
    capacitySecurityMeasures: PropTypes.array,
    capacityAdditionalProvisions: PropTypes.array,
    cleaningEquipments: PropTypes.array,
    cleaningAdditionalProvisions: PropTypes.array,
    cleaningConditions: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,
    isDownloading: PropTypes.bool,

    currentFirePermit: PropTypes.number,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},
    currentFirePermit: 0,
    // DonnÃ©es de la vue
    permit: {},
    permits: [],
    permitsRequests: [],
    totalPermits: 0,
    totalPermitsRequests: 0,
    firePermit: {},
    firePermits: [],

    // DonnÃ©es complÃ©mentaires
    associablePermits: [],
    companies: [],
    supervisors: [],
    users: [],
    securityMeasures: [],
    searchSecurityMeasures: [],
    risks: [],
    searchPredictableRisks: [],
    materials: [],
    operations: [],
    preventiveMeasures: [],
    fireSources: [],
    capacityEquipments: [],
    capacitySecurityMeasures: [],
    capacityAdditionalProvisions: [],
    cleaningEquipments: [],
    cleaningAdditionalProvisions: [],
    cleaningConditions: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false,
    isDownloading: false
  }

  constructor(props) {
    super(props)

    this.state = {
      currentTab: '',
      companyChosen: {},
      openedModal: null,
      subOpenedModal: null,
      companyEmployees: []
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des paramÃ¨tres de sÃ©lÃ©ction
    this.fetchParameters()

    if (this.getContext() === 'single') {
      this.getPermitData().then(() => {
        if (isEmpty(this.state.companyEmployees)) {
          this.setState({
            companyEmployees: this.getCompanyEmployees()
          })
        }
      })
    } else {
      // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
      this.fetchPermitsData()
      this.fetchPermitsRequestsData()
      this.setState({
        companyEmployees: this.getCompanyEmployees()
      })
    }
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (
      oldProps.navigation.getParam('context') !==
        this.props.navigation.getParam('context') ||
      (oldProps.navigation.getParam('id') !==
        this.props.navigation.getParam('id') &&
        isEmpty(this.props.navigation.getParam('id')))
    ) {
      // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
      this.fetchPermitsData()
      this.fetchPermitsRequestsData()
    }

    if (
      oldProps.navigation.getParam('id') !==
        this.props.navigation.getParam('id') ||
      oldState.currentTab !== this.state.currentTab
    ) {
      this.setState({ companyChosen: {} })
      // RÃ©cupÃ©ration des paramÃ¨tres de sÃ©lÃ©ction
      this.fetchParameters()
    }
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es selon le contexte de la vue
   */
  getDataContext = () => {
    const { navigation } = this.props

    const context = defaultTo(navigation.getParam('context'), 'default')

    return context
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * Mise Ã  jour du contexte de donnÃ©es
   */
  setDataContext = context => {
    const { navigation } = this.props

    navigation.setParams({ context })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchPermitsData = (
    size = 10,
    offset = 0,
    sortField = 'dateCreated',
    sortOrder = 'descend',
    filters = {},
    search = ''
  ) => {
    this.props.actions
      .fetchPermits(null, {
        query: {
          limit: size,
          offset,
          sortField,
          sortOrder,
          filters,
          search
        }
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.fetch'))
      })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchPermitsRequestsData = (
    size = 10,
    offset = 0,
    sortField = 'dateCreated',
    sortOrder = 'descend',
    filters = {},
    search = ''
  ) => {
    this.props.actions
      .fetchRequestsPermit(null, {
        query: {
          limit: size,
          offset,
          sortField,
          sortOrder,
          filters,
          search
        }
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.fetch'))
      })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es de rÃ©visions du permit
   */
  fetchPermitChangelogData = (permit = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .fetchChangelogPermit(permit)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.permit.changelog.fetch'))
        })
    })

  /**
   * RÃ©cupÃ©ration des paramÃ¨tres
   */
  fetchParameters = () => {
    const { currentTab } = this.state

    // Liste des paramÃ¨tres Ã  rÃ©cupÃ©rer
    const parameters = [
      'associablePermit',
      'fireSource',
      'risk',
      'material',
      'operation',
      'preventiveMeasure',
      'securityMeasure',
      'companie:all',
      'supervisor',
      'capacityEquipment',
      'capacitySecurityMeasure',
      'capacityAdditionalProvision',
      'searchSecurityMeasure',
      'searchPredictableRisk',
      'cleaningCondition',
      'cleaningAdditionalProvision',
      'cleaningEquipment',
      'user'
    ]

    //const id = currentTab
    const id =
      currentTab === '' || currentTab === 'information'
        ? this.props.navigation.getParam('id')
        : currentTab

    const queryParameters = {
      list: { query: {} },
      single: { query: { permit: id } }
    }

    // Pour chaque paramÃ¨tres aller chercher la donnÃ©e via API
    forEach(parameters, fullParameter => {
      let fullParameters = fullParameter.split(':')
      const parameter = fullParameters.shift()

      const fetchAll = fullParameters.includes('all')

      const action = `fetch${fetchAll ? 'All' : ''}${upperFirst(parameter)}s`
      const promise = get(this.props.actions, action)
      const errorMessage = I18n.t(`api.errors.${parameter}.fetch`)
      const queryParams = get(queryParameters, this.getContext())

      // RÃ©cupÃ©ration des permits associables
      if (!isNil(promise)) {
        promise(null, queryParams).catch(() => {
          message.error(errorMessage)
        })
      } else {
        message.error(errorMessage)
      }
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getPermitData = (permit = this.props.navigation.getParam('id')) => {
    const { actions } = this.props

    return new Promise((resolve, reject) => {
      actions
        .getPermit(permit)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
          // Mettre Ã  jour l'entreprise liÃ©e au permit
          this.updateCompanyChosen(response.body.company.id)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          this.props.navigation.setParams({ id: null })
          message.error(I18n.t('api.errors.permit.get'))
        })
    })
  }

  /**
   * Gestion du PDF de la version demandÃ©e
   */
  handleRevisionPDF = (type, permitID, revisionID) => {
    const { currentTab } = this.state

    switch (type) {
      case 'firePermit':
        this.props.actions
          .downloadRevisionPDFFirePermit({
            permitID: currentTab,
            revisionID: revisionID
          })
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis_sdlp${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
            this.downloadRevisionPDFexternal(type, permitID, revisionID)
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'capacityPermit':
        this.props.actions
          .downloadRevisionPDFCapacityPermit({
            permitID: currentTab,
            revisionID: revisionID
          })
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis_sdlp${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
            this.downloadRevisionPDFexternal(type, permitID, revisionID)
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'searchPermit':
        this.props.actions
          .downloadRevisionPDFSearchPermit({
            permitID: currentTab,
            revisionID: revisionID
          })
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis_sdlp${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
            this.downloadRevisionPDFexternal(type, permitID, revisionID)
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'cleaningPermit':
        this.props.actions
          .downloadRevisionPDFCleaningPermit({
            permitID: currentTab,
            revisionID: revisionID
          })
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis_sdlp${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
            this.downloadRevisionPDFexternal(type, permitID, revisionID)
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      default:
        this.props.actions
          .downloadRevisionPDFPermit({
            permitID,
            revisionID
          })
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis_sdlp${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
            this.downloadRevisionPDFexternal(type, permitID, revisionID)
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.display'))
          })
        break
    }
  }

  /**
   * TÃ©lÃ©chargement du PDF de la version demandÃ©e copie externe
   */
  downloadRevisionPDFexternal = (type, permitID, revisionID) => {
    const { currentTab } = this.state
    switch (type) {
      case 'firePermit':
        this.props.actions
          .downloadRevisionPDFFirePermit(
            {
              permitID: currentTab,
              revisionID: revisionID
            },
            { query: { external: true } }
          )
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'searchPermit':
        this.props.actions
          .downloadRevisionPDFSearchPermit(
            {
              permitID: currentTab,
              revisionID: revisionID
            },
            { query: { external: true } }
          )
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'capacityPermit':
        this.props.actions
          .downloadRevisionPDFCapacityPermit(
            {
              permitID: currentTab,
              revisionID: revisionID
            },
            { query: { external: true } }
          )
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      case 'cleaningPermit':
        this.props.actions
          .downloadRevisionPDFCleaningPermit(
            {
              permitID: currentTab,
              revisionID: revisionID
            },
            { query: { external: true } }
          )
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
      default:
        this.props.actions
          .downloadRevisionPDFPermit(
            {
              permitID,
              revisionID
            },
            { query: { external: true } }
          )
          .then(({ body }) => {
            if (has(body, 'url')) {
              window.open(get(body, 'url'), '_blank')
            } else {
              message.success(I18n.t('api.success.permit.download'))
              download(
                new Blob(['\ufeff', body]),
                `permis${moment().valueOf()}.pdf`,
                'text/html'
              )
            }
          })
          .catch(() => {
            message.error(I18n.t('api.errors.permit.download'))
          })
        break
    }
  }

  /**
   * Notation d'un permis
   */
  ratePermit = rate =>
    new Promise((resolve, reject) => {
      const { permit } = this.props

      this.props.actions
        .ratePermit({ id: get(permit, 'id'), rate })
        .then(() => {
          this.safelyCloseModal()
          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.rate'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.rate'))
        })
    })

  /**
   * Signature d'un permis
   */
  signPermit = (scan, option) =>
    new Promise((resolve, reject) => {
      const { permit } = this.props

      this.props.actions
        .signPermit(
          { id: get(permit, 'id'), scan },
          { query: { option: option } }
        )
        .then(() => {
          this.safelyCloseModal()
          window.location.reload();

          message.success(I18n.t('api.success.permit.sign'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.sign'))
        })
    })

  /**
   * Ajout du scan de la signature du permit
   */
  addScanToPermit = scan => {
    const { permit } = this.props

    scan['id'] = get(permit, 'id')
    this.props.actions
      .createScanPermit(scan)
      .then(() => {
        this.getPermitData(permit)

        // this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.permit.documents.success'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.documents.fail'))
      })
  }

  /**
   * Ajout du scan de la signature du permit de feu
   */
  addScanToFirePermit = scan => {
    const { permit } = this.props
    const { currentTab } = this.state
    scan['id'] = currentTab
    this.props.actions
      .createScanFirePermit(scan)
      .then(() => {
        this.getPermitData(permit)
        message.success(I18n.t('api.success.permit.documents.success'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.documents.fail'))
      })
  }

  /**
   * Signature d'un permis de feu
   */
  signFirePermit = (scan, option) => {
    const { permit } = this.props
    const { currentTab } = this.state
    return this.props.actions
      .signFirePermit({ id: currentTab, scan }, { query: { option: option } })
      .then(() => {
        this.safelyCloseModal()
        window.location.reload();

        message.success(I18n.t('api.success.permit.sign'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.sign'))
      })
  }

  /**
   * Signature d'un permis de fouille
   */
  signSearchPermit = (scan, option) => {
    const { permit } = this.props
    const { currentTab } = this.state
    return this.props.actions
      .signSearchPermit({ id: currentTab, scan }, { query: { option: option } })
      .then(() => {
        this.safelyCloseModal()
        window.location.reload();
        message.success(I18n.t('api.success.permit.sign'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.sign'))
      })
  }

  /**
   * Signature d'un permis de capacity
   */
  signCapacityPermit = (scan, option) => {
    const { permit } = this.props
    const { currentTab } = this.state
    return this.props.actions
      .signCapacityPermit(
        { id: currentTab, scan },
        { query: { option: option } }
      )
      .then(() => {
        this.safelyCloseModal()
        window.location.reload();
        message.success(I18n.t('api.success.permit.sign'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.sign'))
      })
  }

  /**
   * Signature d'un permis de fouille
   */
  signCleaningPermit = (scan, option) => {
    const { permit } = this.props
    const { currentTab } = this.state
    return this.props.actions
      .signCleaningPermit(
        { id: currentTab, scan },
        { query: { option: option } }
      )
      .then(() => {
        this.safelyCloseModal()
        window.location.reload();
        message.success(I18n.t('api.success.permit.sign'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.sign'))
      })
  }

  /**
   * Annulation d'un permis
   */
  cancelPermit = reason => {
    const { permit } = this.props

    this.props.actions
      .cancelPermit({ id: get(permit, 'id'), reason })
      .then(() => {
        this.safelyCloseModal()
        // this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.permit.cancel'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.permit.cancel'))
      })
  }

  /**
   * Annulation d'un permis de feu
   */
  cancelFirePermit = reason =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      const { currentTab } = this.state
      this.props.actions
        .cancelFirePermit({ id: currentTab, reason })
        .then(() => {
          this.safelyCloseModal()
          this.getPermitData(permit)

          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.cancel'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.cancel'))
        })
    })

  /**
   * Cloture d'un permis
   */
  closePermit = data =>
    new Promise((resolve, reject) => {
      const toSend = {
        closedDate: data.date,
        closedController: data.person,
        id: data.id
      }

      this.props.actions
        .closePermit(toSend)
        .then(() => {
          this.safelyCloseModal()
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(({ status, body }) => {
          status === 400 && body === 'fire permits are not all closed'
            ? message.error(I18n.t('api.errors.permit.closeFirePermit'))
            : message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Cloture d'un permis de feu
   */
  closeFirePermit = data =>
    new Promise((resolve, reject) => {
      const toSend = {
        closedDate: data.date,
        closedController: data.person,
        id: this.state.currentTab
      }

      let controllerSign = data.controllerSign
      this.props.actions
        .closeFirePermit(toSend)
        .then(() => {
          this.props.actions
          .signControllerFirePermit( {id: this.state.currentTab, controllerSign })

          this.safelyCloseModal()
          this.getPermitData()
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Cloture d'un permis de fouille
   */
  closeSearchPermit = data =>
    new Promise((resolve, reject) => {

      const toSend = {
        closedDate: data.date,
        closedController: data.person,
        id: this.state.currentTab
      }
      this.props.actions
        .closeSearchPermit(toSend)
        .then(() => {
          this.safelyCloseModal()
          this.getPermitData()

          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Cloture d'un permis de nettoyage
   */
  closeCleaningPermit = data =>
    new Promise((resolve, reject) => {
      const toSend = {
        closedDate: data.date,
        closedController: data.person,
        id: this.state.currentTab
      }

      this.props.actions
        .closeCleaningPermit(toSend)
        .then(() => {
          this.safelyCloseModal()
          this.getPermitData()

          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Cloture d'un permis de capacitÃ©
   */
  closeCapacityPermit = data =>
    new Promise((resolve, reject) => {
      const toSend = {
        closedDate: data.date,
        closedController: data.person,
        id: this.state.currentTab
      }

      this.props.actions
        .closeCapacityPermit(toSend)
        .then(() => {
          this.safelyCloseModal()
          this.getPermitData()

          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Cloture d'un permis
   */
  controlPermit = permit =>
    new Promise((resolve, reject) => {
      this.props.actions
        .controlPermit(permit)
        .then(() => {
          this.safelyCloseModal()
          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Controle d'un permis de feu
   */
  controlFirePermit = () =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      const { currentTab } = this.state
      this.props.actions
        .controlFirePermit({ id: currentTab })
        .then(() => {
          this.safelyCloseModal()
          this.getPermitData(permit)
          // this.props.navigation.setParams({ id: null })
          message.success(I18n.t('api.success.permit.close'))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.permit.close'))
        })
    })

  /**
   * Mise Ã  jour d'un permit
   */
  updatePermit = (updatedPermit, originalPermit) =>
    new Promise((resolve, reject) => {
      updatedPermit.validityPeriod = {
        startDate: updatedPermit.validityPeriod[0],
        endDate: updatedPermit.validityPeriod[1]
      }

      if (updatedPermit.associatedPermits) {
        if (updatedPermit.associatedPermits.indexOf(-1) !== -1) {
          updatedPermit.associatedPermits.splice(
            updatedPermit.associatedPermits.indexOf(-1),
            1
          )
        }
      }
      if (updatedPermit.risks) {
        if (updatedPermit.risks.indexOf(-1) !== -1) {
          updatedPermit.risks.splice(updatedPermit.risks.indexOf(-1), 1)
        }
      }

      if (updatedPermit.materials) {
        if (updatedPermit.materials.indexOf(-1) !== -1) {
          updatedPermit.materials.splice(updatedPermit.materials.indexOf(-1), 1)
        }
      }

      if (updatedPermit.operations) {
        if (updatedPermit.operations.indexOf(-1) !== -1) {
          updatedPermit.operations.splice(
            updatedPermit.operations.indexOf(-1),
            1
          )
        }
      }

      if (updatedPermit.preventiveMeasures) {
        if (updatedPermit.preventiveMeasures.indexOf(-1) !== -1) {
          updatedPermit.preventiveMeasures.splice(
            updatedPermit.preventiveMeasures.indexOf(-1),
            1
          )
        }
      }

      this.props.actions
        .updatePermit({
          id: get(originalPermit, 'id'),
          ...updatedPermit
        })
        .then(response => {
          if (updatedPermit.operatingModeFile != null) {
            const data = new FormData()
            data.append('id', response.body.id)
            data.append('operatingModeFile', updatedPermit.operatingModeFile)
            this.props.actions
              .addOperatingModeFilePermit(data)
              .then(response => {
                this.safelyCloseModal()
                this.fetchParameters()
                this.getPermitData()
                resolve(get(response, 'body'), response)
                message.success(I18n.t(`api.success.permit.update`))
              })
          } else {
            this.safelyCloseModal()
            this.fetchParameters()
            this.fetchPermitsData()
            this.fetchPermitsRequestsData()
            resolve(get(response, 'body'), response)
            message.success(I18n.t(`api.success.permit.update`))
          }
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.update'))
        })
    })

  /**
   * Mise Ã  jour d'un permit de feu
   */
  updateFirePermit = (updatedFirePermit, FirePermitID) =>
    new Promise((resolve, reject) => {
      const { permit } = this.props

      // periode de validitÃ©
      updatedFirePermit.validityPeriod = {
        startDate: moment(updatedFirePermit.validityDate).set({
          hour: parseInt(
            updatedFirePermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            updatedFirePermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(updatedFirePermit.validityDate).set({
          hour: parseInt(
            updatedFirePermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            updatedFirePermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }
      // Supprimer l'id -1
      if (updatedFirePermit.fireSources) {
        if (updatedFirePermit.fireSources.indexOf(-1) !== -1) {
          updatedFirePermit.fireSources.splice(
            updatedFirePermit.fireSources.indexOf(-1),
            1
          )
        }
      }
      if (updatedFirePermit.securityMeasures) {
        if (updatedFirePermit.securityMeasures.indexOf(-1) !== -1) {
          updatedFirePermit.securityMeasures.splice(
            updatedFirePermit.securityMeasures.indexOf(-1),
            1
          )
        }
      }
      updatedFirePermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .updateFirePermit({
          id: FirePermitID,
          ...updatedFirePermit
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.update'))
        })
    })

  /**
   * Mise Ã  jour d'un permis de fouille
   */
  updateSearchPermit = (updatedSearchPermit, SearchPermitID) =>
    new Promise((resolve, reject) => {
      const { permit } = this.props

      // periode de validitÃ©
      updatedSearchPermit.validityPeriod = {
        startDate: moment(updatedSearchPermit.validityDate).set({
          hour: parseInt(
            updatedSearchPermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            updatedSearchPermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(updatedSearchPermit.validityDate).set({
          hour: parseInt(
            updatedSearchPermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            updatedSearchPermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }
      // Supprimer l'id -1
      if (updatedSearchPermit.securityMeasures) {
        if (updatedSearchPermit.securityMeasures.indexOf(-1) !== -1) {
          updatedSearchPermit.securityMeasures.splice(
            updatedSearchPermit.securityMeasures.indexOf(-1),
            1
          )
        }
      }
      if (updatedSearchPermit.predictableRisks) {
        if (updatedSearchPermit.predictableRisks.indexOf(-1) !== -1) {
          updatedSearchPermit.predictableRisks.splice(
            updatedSearchPermit.predictableRisks.indexOf(-1),
            1
          )
        }
      }

      updatedSearchPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .updateSearchPermit({
          id: SearchPermitID,
          ...updatedSearchPermit
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.update'))
        })
    })

  /**
   * Mise Ã  jour d'un permis de capacitÃ©
   */
  updateCapacityPermit = (updatedCapacityPermit, CapacityPermitID) =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // Supprimer l'id -1
      if (updatedCapacityPermit.capacityPermitAdditionalProvisions) {
        if (
          updatedCapacityPermit.capacityPermitAdditionalProvisions.indexOf(
            -1
          ) !== -1
        ) {
          updatedCapacityPermit.capacityPermitAdditionalProvisions.splice(
            updatedCapacityPermit.capacityPermitAdditionalProvisions.indexOf(
              -1
            ),
            1
          )
        }
      }
      if (updatedCapacityPermit.capacityPermitEquipments) {
        if (updatedCapacityPermit.capacityPermitEquipments.indexOf(-1) !== -1) {
          updatedCapacityPermit.capacityPermitEquipments.splice(
            updatedCapacityPermit.capacityPermitEquipments.indexOf(-1),
            1
          )
        }
      }
      if (updatedCapacityPermit.capacityPermitSecurityMeasures) {
        if (
          updatedCapacityPermit.capacityPermitSecurityMeasures.indexOf(-1) !==
          -1
        ) {
          updatedCapacityPermit.capacityPermitSecurityMeasures.splice(
            updatedCapacityPermit.capacityPermitSecurityMeasures.indexOf(-1),
            1
          )
        }
      }

      updatedCapacityPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .updateCapacityPermit({
          id: CapacityPermitID,
          ...updatedCapacityPermit
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.update'))
        })
    })

  /**
   * Mise Ã  jour d'un permis de nettoyage
   */
  updateCleaningPermit = (updatedCleaningPermit, CleaningPermitID) =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // periode de validitÃ©
      updatedCleaningPermit.validityPeriod = {
        startDate: moment(updatedCleaningPermit.validityDate).set({
          hour: parseInt(
            updatedCleaningPermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            updatedCleaningPermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(updatedCleaningPermit.validityDate).set({
          hour: parseInt(
            updatedCleaningPermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            updatedCleaningPermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }

      // Supprimer l'id -1
      if (updatedCleaningPermit.cleaningPermitAdditionalProvisions) {
        if (
          updatedCleaningPermit.cleaningPermitAdditionalProvisions.indexOf(
            -1
          ) !== -1
        ) {
          updatedCleaningPermit.cleaningPermitAdditionalProvisions.splice(
            updatedCleaningPermit.cleaningPermitAdditionalProvisions.indexOf(
              -1
            ),
            1
          )
        }
      }
      if (updatedCleaningPermit.cleaningPermitEquipments) {
        if (updatedCleaningPermit.cleaningPermitEquipments.indexOf(-1) !== -1) {
          updatedCleaningPermit.cleaningPermitEquipments.splice(
            updatedCleaningPermit.cleaningPermitEquipments.indexOf(-1),
            1
          )
        }
      }
      if (updatedCleaningPermit.cleaningPermitConditions) {
        if (updatedCleaningPermit.cleaningPermitConditions.indexOf(-1) !== -1) {
          updatedCleaningPermit.cleaningPermitConditions.splice(
            updatedCleaningPermit.cleaningPermitConditions.indexOf(-1),
            1
          )
        }
      }

      updatedCleaningPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .updateCleaningPermit({
          id: CleaningPermitID,
          ...updatedCleaningPermit
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.update'))
        })
    })

  /**
   * envoie des infos de controle du permis de capacitÃ©
   */
  controlCapacityPermit = measures =>
    new Promise((resolve, reject) => {
      const { currentTab } = this.state
      measures.id = currentTab
      this.props.actions
        .controlCapacityPermit(measures)
        .then(resp => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(resp, 'body'), resp)
          message.success(I18n.t(`api.success.permit.control`))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t(`api.errors.permit.control`))
        })
    })

  /**
   * envoie des infos de controle du permis de nettoyage
   */
  controlCleaningPermitPhase1 = measures =>
    new Promise((resolve, reject) => {
      const { currentTab } = this.state
      measures.id = currentTab
      this.props.actions
        .controlPhase1CleaningPermit(measures)
        .then(resp => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(resp, 'body'), resp)
          message.success(I18n.t(`api.success.permit.control`))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t(`api.errors.permit.control`))
        })
    })

  /**
   * envoie des infos de controle du permis de nettoyage
   */
  controlCleaningPermitPhase2 = measures =>
    new Promise((resolve, reject) => {
      const { currentTab } = this.state
      measures.id = currentTab
      this.props.actions
        .controlPhase2CleaningPermit(measures)
        .then(resp => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.getPermitData()
          resolve(get(resp, 'body'), resp)
          message.success(I18n.t(`api.success.permit.control`))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t(`api.errors.permit.control`))
        })
    })

  /**
   * CrÃ©ation d'un permit
   */
  createPermit = (createdPermit, isRequested = false) =>
    new Promise((resolve, reject) => {
      const { openedModal } = this.state
      const mode = replace(openedModal, 'Permit', '')
      // const { profile } = this.props
      // Identifiant unique du permit
      createdPermit.permitID = `PT_${moment().year()}-${moment().week()}-${faker.random.number(
        { min: 10, max: 99 }
      )}`

      // NumÃ©ro de la rÃ©vision
      createdPermit.revision = 0

      // ReprÃ©sentant SDLP
      // createdPermit.lead = get(profile, 'id')
      // periode de validitÃ©
      createdPermit.validityPeriod = {
        startDate: createdPermit.validityPeriod[0],
        endDate: createdPermit.validityPeriod[1]
      }

      // Supprimer l'id -1
      if (createdPermit.associatedPermits) {
        if (createdPermit.associatedPermits.indexOf(-1) !== -1) {
          createdPermit.associatedPermits.splice(
            createdPermit.associatedPermits.indexOf(-1),
            1
          )
        }
      }

      if (createdPermit.risks) {
        if (createdPermit.risks.indexOf(-1) !== -1) {
          createdPermit.risks.splice(createdPermit.risks.indexOf(-1), 1)
        }
      }

      if (createdPermit.materials) {
        if (createdPermit.materials.indexOf(-1) !== -1) {
          createdPermit.materials.splice(createdPermit.materials.indexOf(-1), 1)
        }
      }

      if (createdPermit.operations) {
        if (createdPermit.operations.indexOf(-1) !== -1) {
          createdPermit.operations.splice(
            createdPermit.operations.indexOf(-1),
            1
          )
        }
      }

      if (createdPermit.preventiveMeasures) {
        if (createdPermit.preventiveMeasures.indexOf(-1) !== -1) {
          createdPermit.preventiveMeasures.splice(
            createdPermit.preventiveMeasures.indexOf(-1),
            1
          )
        }
      }

      // requete de permis
      if (isRequested) {
        createdPermit.company = this.props.profile.company.id
        this.props.actions
          .createRequestPermit(createdPermit)
          .then(response => {
            if (createdPermit.operatingModeFile != null) {
              const data = new FormData()
              data.append('id', response.body.id)
              data.append('operatingModeFile', createdPermit.operatingModeFile)
              this.props.actions
                .addOperatingModeFilePermit(data)
                .then(response => {
                  this.safelyCloseModal()
                  this.fetchParameters()
                  resolve(get(response, 'body'), response)
                  message.success(I18n.t(`api.success.permit.${mode}`))
                })
            } else {
              this.safelyCloseModal()
              this.fetchParameters()
              resolve(get(response, 'body'), response)
              message.success(I18n.t(`api.success.permit.${mode}`))
            }
          })
          .catch(error => {
            reject(error)
            message.error(I18n.t(`api.errors.permit.${mode}`))
          })

        // permis
      } else {
        this.props.actions
          .createPermit(createdPermit)
          .then(resp => {
            if (createdPermit.operatingModeFile != null) {
              const data = new FormData()
              data.append('id', resp.body.id)
              data.append('operatingModeFile', createdPermit.operatingModeFile)
              this.props.actions
                .addOperatingModeFilePermit(data)
                .then(response => {
                  this.safelyCloseModal()
                  this.fetchParameters()
                  this.fetchPermitsData()
                  this.fetchPermitsRequestsData()
                  resolve(get(response, 'body'), response)
                  message.success(I18n.t(`api.success.permit.${mode}`))
                })
            } else {
              this.safelyCloseModal()
              this.fetchParameters()
              this.fetchPermitsData()
              this.fetchPermitsRequestsData()
              resolve(get(resp, 'body'), resp)
              message.success(I18n.t(`api.success.permit.${mode}`))
            }
          })
          .catch(error => {
            reject(error)
            message.error(I18n.t(`api.errors.permit.${mode}`))
          })
      }
    })

  /**
   *
   */
  confirmPermitRequest = request =>
    new Promise((resolve, reject) => {
      this.props.actions
        .validateRequestPermit(request)
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.fetchPermitsData()
          this.fetchPermitsRequestsData()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.create'))
        })
    })

  /**
   * CrÃ©ation d'un permis de feu
   */
  createFirePermit = createdFirePermit =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // periode de validitÃ©
      createdFirePermit.validityPeriod = {
        startDate: moment(createdFirePermit.validityDate).set({
          hour: parseInt(
            createdFirePermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            createdFirePermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(createdFirePermit.validityDate).set({
          hour: parseInt(
            createdFirePermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            createdFirePermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }
      // Supprimer l'id -1
      if (createdFirePermit.fireSources) {
        if (createdFirePermit.fireSources.indexOf(-1) !== -1) {
          createdFirePermit.fireSources.splice(
            createdFirePermit.fireSources.indexOf(-1),
            1
          )
        }
      }
      if (createdFirePermit.securityMeasures) {
        if (createdFirePermit.securityMeasures.indexOf(-1) !== -1) {
          createdFirePermit.securityMeasures.splice(
            createdFirePermit.securityMeasures.indexOf(-1),
            1
          )
        }
      }
      createdFirePermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .createFirePermit(createdFirePermit)
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.fetchPermitsData()
          this.fetchPermitsRequestsData()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.create'))
        })
    })

  /**
   * CrÃ©ation d'un permis de fouille
   */
  createSearchPermit = createdSearchPermit =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // periode de validitÃ©
      createdSearchPermit.validityPeriod = {
        startDate: moment(createdSearchPermit.validityDate).set({
          hour: parseInt(
            createdSearchPermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            createdSearchPermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(createdSearchPermit.validityDate).set({
          hour: parseInt(
            createdSearchPermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            createdSearchPermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }
      // Supprimer l'id -1
      if (createdSearchPermit.securityMeasures) {
        if (createdSearchPermit.securityMeasures.indexOf(-1) !== -1) {
          createdSearchPermit.securityMeasures.splice(
            createdSearchPermit.securityMeasures.indexOf(-1),
            1
          )
        }
      }
      if (createdSearchPermit.predictableRisks) {
        if (createdSearchPermit.predictableRisks.indexOf(-1) !== -1) {
          createdSearchPermit.predictableRisks.splice(
            createdSearchPermit.predictableRisks.indexOf(-1),
            1
          )
        }
      }
      createdSearchPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .createSearchPermit(createdSearchPermit)
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.fetchPermitsData()
          this.fetchPermitsRequestsData()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.create'))
        })
    })

  /**
   * CrÃ©ation d'un permis de capacitÃ©
   */
  createCapacityPermit = createdCapacityPermit =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // Supprimer l'id -1
      if (createdCapacityPermit.capacityPermitAdditionalProvisions) {
        if (
          createdCapacityPermit.capacityPermitAdditionalProvisions.indexOf(
            -1
          ) !== -1
        ) {
          createdCapacityPermit.capacityPermitAdditionalProvisions.splice(
            createdCapacityPermit.capacityPermitAdditionalProvisions.indexOf(
              -1
            ),
            1
          )
        }
      }
      if (createdCapacityPermit.capacityPermitEquipments) {
        if (createdCapacityPermit.capacityPermitEquipments.indexOf(-1) !== -1) {
          createdCapacityPermit.capacityPermitEquipments.splice(
            createdCapacityPermit.capacityPermitEquipments.indexOf(-1),
            1
          )
        }
      }
      if (createdCapacityPermit.capacityPermitSecurityMeasures) {
        if (
          createdCapacityPermit.capacityPermitSecurityMeasures.indexOf(-1) !==
          -1
        ) {
          createdCapacityPermit.capacityPermitSecurityMeasures.splice(
            createdCapacityPermit.capacityPermitSecurityMeasures.indexOf(-1),
            1
          )
        }
      }

      createdCapacityPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .createCapacityPermit(createdCapacityPermit)
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.fetchPermitsData()
          this.fetchPermitsRequestsData()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.create'))
        })
    })

  /**
   * CrÃ©ation d'un permis de nettoyage
   */
  createCleaningPermit = createdCleaningPermit =>
    new Promise((resolve, reject) => {
      const { permit } = this.props
      // periode de validitÃ©
      createdCleaningPermit.validityPeriod = {
        startDate: moment(createdCleaningPermit.validityDate).set({
          hour: parseInt(
            createdCleaningPermit.validityHours.split('-')[0].split(':')[0]
          ),
          minute: parseInt(
            createdCleaningPermit.validityHours.split('-')[0].split(':')[1]
          ),
          second: 0
        }),
        endDate: moment(createdCleaningPermit.validityDate).set({
          hour: parseInt(
            createdCleaningPermit.validityHours.split('-')[1].split(':')[0]
          ),
          minute: parseInt(
            createdCleaningPermit.validityHours.split('-')[1].split(':')[1]
          ),
          second: 0
        })
      }

      // Supprimer l'id -1
      if (createdCleaningPermit.cleaningPermitAdditionalProvisions) {
        if (
          createdCleaningPermit.cleaningPermitAdditionalProvisions.indexOf(
            -1
          ) !== -1
        ) {
          createdCleaningPermit.cleaningPermitAdditionalProvisions.splice(
            createdCleaningPermit.cleaningPermitAdditionalProvisions.indexOf(
              -1
            ),
            1
          )
        }
      }
      if (createdCleaningPermit.cleaningPermitEquipments) {
        if (createdCleaningPermit.cleaningPermitEquipments.indexOf(-1) !== -1) {
          createdCleaningPermit.cleaningPermitEquipments.splice(
            createdCleaningPermit.cleaningPermitEquipments.indexOf(-1),
            1
          )
        }
      }
      if (createdCleaningPermit.cleaningPermitConditions) {
        if (createdCleaningPermit.cleaningPermitConditions.indexOf(-1) !== -1) {
          createdCleaningPermit.cleaningPermitConditions.splice(
            createdCleaningPermit.cleaningPermitConditions.indexOf(-1),
            1
          )
        }
      }

      createdCleaningPermit.permit = toNumber(get(permit, 'id'))
      this.props.actions
        .createCleaningPermit(createdCleaningPermit)
        .then(response => {
          this.safelyCloseModal()
          this.fetchParameters()
          this.fetchPermitsData()
          this.fetchPermitsRequestsData()
          this.getPermitData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.permit.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.permit.create'))
        })
    })

  /**
   * Ouvre la modale de saisie des mesures du permis de capacitÃ©
   */
  askCapacityControl = () => {
    this.setState({ openedModal: 'capacityControl' })
  }

  /**
   * Ouvre la modale de saisie des mesures du permis de nettoyage
   */
  askCleaningPhase1Control = () => {
    this.setState({ openedModal: 'cleaningPhase1Control' })
  }

  /**
   * Ouvre la modale de saisie des mesures du permis de nettoyage
   */
  askCleaningPhase2Control = () => {
    this.setState({ openedModal: 'cleaningPhase2Control' })
  }

  /**
   * Demande de cloture du permit
   */
  askClosePermit = type => {
    const { currentTab } = this.state

    switch (type) {
      case 'firePermit':
        this.setState({ openedModal: 'endFirePermit' })
        break
      case 'searchPermit':
        this.setState({ openedModal: 'endSearchPermit' })
        break
      case 'capacityPermit':
        this.setState({ openedModal: 'endCapacityPermit' })
        break
      case 'cleaningPermit':
        this.setState({ openedModal: 'endCleaningPermit' })
      default:
        this.setState({ openedModal: 'endPermit' })
        break
    }
  }

  /**
   * Demande de conrole du permit
   */
  askControlPermit = () => {
    const { permit, firePermit } = this.props
    const { currentTab } = this.state
    if (currentTab === 'information' || !currentTab) {
      confirm({
        title: I18n.t('pages.permits.modal.control.single.title'),
        content: I18n.t('pages.permits.modal.control.single.content'),
        okText: I18n.t('pages.permits.modal.control.single.okText'),
        okType: 'danger',
        cancelText: I18n.t('pages.permits.modal.control.single.cancelText'),
        onOk: () => {
          this.controlPermit(permit)
        },
        maskClosable: true
      })
    } else {
      confirm({
        title: I18n.t('pages.permits.modal.control.single.title'),
        content: I18n.t('pages.permits.modal.control.single.content'),
        okText: I18n.t('pages.permits.modal.control.single.okText'),
        okType: 'danger',
        cancelText: I18n.t('pages.permits.modal.control.single.cancelText'),
        onOk: () => {
          this.controlFirePermit(firePermit)
        },
        maskClosable: true
      })
    }
  }

  /**
   * Demande de controle du permis
   */
  askControlFirePermit = () => {
    const { firePermit } = this.props
    confirm({
      title: I18n.t('pages.permits.modal.control.single.title'),
      content: I18n.t('pages.permits.modal.control.single.content'),
      okText: I18n.t('pages.permits.modal.control.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.permits.modal.control.single.cancelText'),
      onOk: () => {
        this.controlPermit(firePermit)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de notation d'un permis
   */
  askRatePermit = () => {
    this.setState({ openedModal: 'ratePermit' })
  }

  /**
   * Demande de signature d'un permis
   */
  askSignPermit = type => {
    switch (type) {
      case 'firePermit':
        this.setState({ openedModal: 'signFirePermit' })
        break
      case 'capacityPermit':
        this.setState({ openedModal: 'signCapacityPermit' })
        break
      case 'cleaningPermit':
        this.setState({ openedModal: 'signCleaningPermit' })
        break
      case 'searchPermit':
        this.setState({ openedModal: 'signSearchPermit' })
        break
      default:
        this.setState({ openedModal: 'signPermit' })
        break
    }
  }

  /**
   * Demande d'ajout du scan de la signature du permit
   */
  askAddScanToPermit = scan => {
    const { currentTab } = this.state
    if (currentTab === 'information' || !currentTab) {
      return this.addScanToPermit(scan)
    } else {
      return this.addScanToFirePermit(scan)
    }
  }

  /**
   * Demande d'annulation d'un permis
   */
  askCancelPermit = () => {
    const { currentTab } = this.state
    if (currentTab === 'information' || !currentTab) {
      this.setState({ openedModal: 'cancelPermit' })
    } else {
      this.setState({ openedModal: 'cancelFirePermit' })
    }
  }

  /**
   * Demande d'annulation d'un permis
   */
  askCancelFirePermit = () => {
    this.setState({ openedModal: 'cancelFirePermit' })
  }

  /**
   * Demande de modification d'un permis feu sur une ligne du tableau
   */
  askUpdateFirePermit = updatedPermit => {
    const { currentTab } = this.state
    return this.updateFirePermit(updatedPermit, currentTab)
  }

  /**
   * Demande de modification d'un permis fouille sur une ligne du tableau
   */
  askUpdateSearchPermit = updatedPermit => {
    const { currentTab } = this.state
    return this.updateSearchPermit(updatedPermit, currentTab)
  }

  /**
   * Demande de modification d'un permis capacitÃ© sur une ligne du tableau
   */
  askUpdateCapacityPermit = updatedPermit => {
    const { currentTab } = this.state
    return this.updateCapacityPermit(updatedPermit, currentTab)
  }

  /**
   * Demande de modification d'un permis capacitÃ© sur une ligne du tableau
   */
  askUpdateCleaningPermit = updatedPermit => {
    const { currentTab } = this.state
    return this.updateCleaningPermit(updatedPermit, currentTab)
  }

  /**
   * Validation d'une requete de permis
   */
  askConfirmRequest = () => {
    this.setState({ openedModal: 'confirmRequest' })
  }

  /**
   * Demande de crÃ©ation d'un permis feu sur une ligne du tableau
   */
  askCreateFirePermit = () => {
    this.setState({ openedModal: 'createFirePermit' })
  }

  /**
   * Demande de crÃ©ation d'un permis fouille sur une ligne du tableau
   */
  askCreateSearchPermit = () => {
    this.setState({ openedModal: 'createSearchPermit' })
  }

  /**
   * Demande de crÃ©ation d'un permis de capacitÃ© sur une ligne du tableau
   */
  askCreateCapacityPermit = () => {
    this.setState({ openedModal: 'createCapacityPermit' })
  }

  /**
   * Demande de crÃ©ation d'un permit de nettoyage sur une ligne du tableau
   */
  askCreateCleaningPermit = () => {
    this.setState({ openedModal: 'createCleaningPermit' })
  }

  /**
   * Demande de crÃ©ation d'un permis fouille sur une ligne du tableau
   */
  askCreateSearchPermit = () => {
    this.setState({ openedModal: 'createSearchPermit' })
  }

  /**
   * Demande de crÃ©ation d'un permis de capacitÃ© sur une ligne du tableau
   */
  askCreateCapacityPermit = () => {
    this.setState({ openedModal: 'createCapacityPermit' })
  }

  /**
   * Demande de crÃ©ation d'un permit de nettoyage sur une ligne du tableau
   */
  askCreateCleaningPermit = () => {
    this.setState({ openedModal: 'createCleaningPermit' })
  }

  /**
   * Demande de modification d'un permis
   */
  askUpdatePermit = updatedPermit => {
    const { permit } = this.props
    return this.updatePermit(updatedPermit, permit)
  }

  /**
   * Demande de crÃ©ation d'un permis
   */
  askCreatePermit = () => {
    this.setState({ openedModal: 'createPermit' })
  }

  /**
   * Demande de crÃ©ation d'une requete de permis
   */
  askCreatePermitRequest = () => {
    this.setState({ openedModal: 'createPermitRequest' })
  }

  /**
   * Demande de duplication d'un permis
   */
  askDuplicatePermit = type => {
    switch (type) {
      case 'firePermit':
        this.setState({ openedModal: 'duplicateFirePermit' })

        break
      case 'capacityPermit':
        this.setState({ openedModal: 'duplicateCapacityPermit' })

        break
      case 'searchPermit':
        this.setState({ openedModal: 'duplicateSearchPermit' })

        break
      case 'cleaningPermit':
        this.setState({ openedModal: 'duplicateCleaningPermit' })

        break
      default:
        this.setState({ openedModal: 'duplicatePermit' })

        break
    }
  }

  /**
   * CrÃ©ation d'un supervisor
   */
  createSupervisor = createdSupervisor =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createSupervisor(createdSupervisor)
        .then(response => {
          this.safelyCloseSubModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.supervisor.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.supervisor.create'))
        })
    })

  /**
   * CrÃ©ation d'un supervisor
   */
  createEmployee = createdEmployee =>
    new Promise((resolve, reject) => {
      const { companyEmployees } = this.state
      const currentCompany = this.getCompany()
      this.props.actions
        .createEmployee({ ...createdEmployee, company: currentCompany.id })
        .then(response => {
          //this.updateCompanyChosen(currentCompany.id)
          companyEmployees.push(response.body)
          this.safelyCloseSubModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.supervisor.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.supervisor.create'))
        })
    })

  /**
   * CrÃ©ation d'une entreprise extÃ©riereure
   */
  createCompany = createdCompany =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createCompany(createdCompany)
        .then(response => {
          this.safelyCloseSubModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.company.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.company.create'))
        })
    })



  /**
   * RÃ©actualisationde l' entreprise extÃ©riereure
   */
  updateCompanyChosen = idCompany =>
    new Promise((resolve, reject) => {
      const { subOpenedModal } = this.state
      this.props.actions
        .getCompany({id: idCompany})
        .then(response => {
          const company = get(response, 'body')
          const companyEmployees = defaultTo(get(company, 'employees'), [])
          if (subOpenedModal !== 'createEmployee') {
            this.setState({ companyChosen: company, companyEmployees })
          }
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.company.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.company.update'))
        })
    })

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updatePermit') {
      this.setState({ openedModal: null }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Fermeture de toutes les sous-modales
   */
  safelyCloseSubModal = () => {
    this.setState({ subOpenedModal: null })
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = permit => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(permit, 'id') })

    this.getPermitData(permit).catch(() => {
      this.props.navigation.setParams({ id: null })
    })
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    const {
      risks,
      permit,
      materials,
      operations,
      fireSources,
      preventiveMeasures,
      securityMeasures,
      associablePermits,
      searchSecurityMeasures,
      searchPredictableRisks,
      capacityEquipments,
      capacitySecurityMeasures,
      capacityAdditionalProvisions,
      cleaningConditions,
      cleaningAdditionalProvisions,
      cleaningEquipments
    } = this.props

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'associatedPermits')) {
      rows.associatedPermits.formField.options = map(
        associablePermits,
        permit => ({
          id: permit,
          label: get(permit, 'name'),
          disabled: false,
          value: get(permit, 'id'),
          data: associablePermits
        })
      )
      rows.associatedPermits.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'risks')) {
      rows.risks.formField.options = map(risks, risk => ({
        id: risk,
        label: get(risk, 'name'),
        disabled: false,
        value: get(risk, 'id'),
        data: risks
      }))
      rows.risks.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des risques associÃ©s au permis de fouille
    if (has(rows, 'searchPredictableRisks')) {
      rows.searchPredictableRisks.formField.options = map(
        searchPredictableRisks,
        risk => ({
          id: risk,
          label: get(risk, 'name'),
          disabled: false,
          value: get(risk, 'id'),
          data: risks
        })
      )
      rows.searchPredictableRisks.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des mesures de sÃ©curitÃ© du permis de fouille
    if (has(rows, 'searchSecurityMeasures')) {
      rows.searchSecurityMeasures.formField.options = map(
        searchSecurityMeasures,
        risk => ({
          id: risk,
          label: get(risk, 'name'),
          disabled: false,
          value: get(risk, 'id'),
          data: risks
        })
      )
      rows.searchSecurityMeasures.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'materials')) {
      rows.materials.formField.options = map(materials, material => ({
        id: material,
        label: get(material, 'name'),
        disabled: false,
        value: get(material, 'id'),
        data: materials
      }))
      rows.materials.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'operations')) {
      rows.operations.formField.options = map(operations, operation => ({
        id: operation,
        label: get(operation, 'name'),
        disabled: false,
        value: get(operation, 'id'),
        data: operation
      }))
      rows.operations.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }
    // CrÃ©ation de la liste de sÃ©lÃ©ction des preventiveMeasures
    if (has(rows, 'preventiveMeasures')) {
      rows.preventiveMeasures.formField.options = map(
        preventiveMeasures,
        preventiveMeasure => ({
          id: preventiveMeasure,
          label: get(preventiveMeasure, 'name'),
          disabled: false,
          value: get(preventiveMeasure, 'id'),
          data: preventiveMeasure
        })
      )
      rows.preventiveMeasures.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'fireSources')) {
      rows.fireSources.formField.options = map(fireSources, fireSource => ({
        id: fireSource,
        label: get(fireSource, 'name'),
        disabled: false,
        value: get(fireSource, 'id'),
        data: fireSource
      }))
      rows.fireSources.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lÃ©ction des permits associÃ©s
    if (has(rows, 'securityMeasures')) {
      rows.securityMeasures.formField.options = map(
        securityMeasures,
        securityMeasure => ({
          id: securityMeasure,
          label: get(securityMeasure, 'name'),
          disabled: false,
          value: get(securityMeasure, 'id'),
          data: securityMeasure
        })
      )
      rows.securityMeasures.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des equipements du permis de capacitÃ©
    if (has(rows, 'capacityPermitSecurityMeasures')) {
      rows.capacityPermitSecurityMeasures.formField.options = map(
        capacitySecurityMeasures,
        measure => ({
          id: measure,
          label: get(measure, 'name'),
          disabled: false,
          value: get(measure, 'id'),
          data: measure
        })
      )
      rows.capacityPermitSecurityMeasures.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des equipements du permis de capacitÃ©
    if (has(rows, 'capacityPermitEquipments')) {
      rows.capacityPermitEquipments.formField.options = map(
        capacityEquipments,
        equipment => ({
          id: equipment,
          label: get(equipment, 'name'),
          disabled: false,
          value: get(equipment, 'id'),
          data: equipment
        })
      )
      rows.capacityPermitEquipments.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des equipements du permis de capacitÃ©
    if (has(rows, 'capacityPermitAdditionalProvisions')) {
      rows.capacityPermitAdditionalProvisions.formField.options = map(
        capacityAdditionalProvisions,
        provision => ({
          id: provision,
          label: get(provision, 'name'),
          disabled: false,
          value: get(provision, 'id'),
          data: provision
        })
      )
      rows.capacityPermitAdditionalProvisions.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des equipements du permis de nettoyage
    if (has(rows, 'cleaningPermitEquipments')) {
      rows.cleaningPermitEquipments.formField.options = map(
        cleaningEquipments,
        equipment => ({
          id: equipment,
          label: get(equipment, 'name'),
          disabled: false,
          value: get(equipment, 'id'),
          data: equipment
        })
      )
      rows.cleaningPermitEquipments.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des conditions gÃ©nÃ©rales du permis de nettoyage
    if (has(rows, 'cleaningPermitConditions')) {
      rows.cleaningPermitConditions.formField.options = map(
        cleaningConditions,
        condition => ({
          id: condition,
          label: get(condition, 'name'),
          disabled: false,
          value: get(condition, 'id'),
          data: condition
        })
      )
      rows.cleaningPermitConditions.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // CrÃ©ation de la liste de sÃ©lection des dispositions supplÃ©mentaires du permis de nettoyage
    if (has(rows, 'cleaningPermitAdditionalProvisions')) {
      rows.cleaningPermitAdditionalProvisions.formField.options = map(
        cleaningAdditionalProvisions,
        provision => ({
          id: provision,
          label: get(provision, 'name'),
          disabled: false,
          value: get(provision, 'id'),
          data: provision
        })
      )
      rows.cleaningPermitAdditionalProvisions.formField.options.push({
        id: -1,
        label: I18n.t('common.other.global'),
        disabled: false,
        value: -1,
        data: -1
      })
    }

    // DÃ©sactivation de certaines dates
    if (has(rows, 'validityPeriod')) {
      rows.validityPeriod.formField.disabledDate = currentDate => {
        const isPast = moment().diff(moment(currentDate), 'days') > 0

        return isPast
      }
    }

    // DÃ©sactivation de certaines dates
    if (has(rows, 'validityDate')) {
      rows.validityDate.formField.disabledDate = currentDate => {
        const isBeyondValidityPeriod = !moment(currentDate)
          .startOf('day')
          .isBetween(
            moment(get(permit, 'validityPeriod.startDate')).startOf('day'),
            moment(get(permit, 'validityPeriod.endDate')).startOf('day'),
            null,
            '[]'
          )
        return isBeyondValidityPeriod
      }
    }
    return rows
  }

  handleTabChange = tabID => {
    if (!isNil(tabID)) {
      this.setState({ currentTab: tabID })
    }
  }

  handlePermitsTabChange = (
    tabID,
    size = 10,
    offset = 0,
    sortField = 'dateCreated',
    sortOrder = 'descend',
    filters = {},
    search = ''
  ) => {
    if (tabID === 'request') {
      this.fetchPermitsRequestsData(
        size,
        offset,
        sortField,
        sortOrder,
        filters,
        search
      )
    } else
      this.fetchPermitsData(size, offset, sortField, sortOrder, filters, search)
  }

  getPermitModalData = () => {
    const { openedModal } = this.state
    const { permit, profile } = this.props
    if (includes(['transition', 'updatePermit'], openedModal)) return permit
    else if (includes(['duplicatePermit'], openedModal)) {
      return {
        ...permit,
        validityPeriod: [moment(), moment().day(5)]
      }
    } else if (includes(['transition', 'createPermit'], openedModal))
      return {
        workingHours: '08:00 - 12:00 ~ 14:00 - 18:00',
        validityPeriod: [moment(), moment().day(5)]
      }
    else if ((includes['createPermitRequest'], openedModal)) {
      return {
        workingHours: '08:00 - 12:00 ~ 14:00 - 18:00',
        validityPeriod: [moment(), moment().day(5)],
        company: profile.company
      }
    }
    // Retourner null en cas de crÃ©ation 
    else return null
  }

  // DonnÃ©es de la modale du permis feu
  getFirePermitModalData = () => {
    const { openedModal } = this.state
    const { permit } = this.props
    if (includes(['updateFirePermit', 'duplicateFirePermit'], openedModal)) {
      let firePermit = permit.firePermits.find(f => f.id === this.state.currentTab)

      let toreturn = {
        ...firePermit,
        validityDate: moment(),
        validityPeriod: [moment(), moment().hours(5)],
        supervisor: firePermit.supervisor.id,
      }

      return toreturn

    } else if (includes(['transition', 'createFirePermit'], openedModal))
      // CrÃ©ation avec seulement les champs qui dÃ©pendent du permis principal
      return {
        permitID: get(permit, 'permitID'),
        company: get(permit, 'company'),
        location: get(permit, 'location'),
        companyLeader: get(permit, 'companyLeader')
      }
    // Retourner null en cas d'erreur 
    else return null
  }

  // DonnÃ©es de la modale du permis fouille
  getSearchPermitModalData = () => {
    const { openedModal } = this.state
    const { permit } = this.props
    if (includes(['updateSearchPermit', 'duplicateSearchPermit'], openedModal)) {
      let searchPermit = permit.searchPermits.find(f => f.id === this.state.currentTab)

      let toreturn = {
        ...searchPermit,
        validityDate: moment(),
        validityPeriod: [moment(), moment().hours(5)],
        supervisor: searchPermit.supervisor.id
      }

      return toreturn

    } else if (includes(['transition', 'createSearchPermit'], openedModal))
      // CrÃ©ation avec seulement les champs qui dÃ©pendent du permis principal
      return {
        permitID: get(permit, 'permitID'),
        company: get(permit, 'company'),
        location: get(permit, 'location'),
        companyLeader: get(permit, 'companyLeader')
      }
    // Retourner null en cas d'erreur 
    else return null
  }



    // DonnÃ©es de la modale du permis nettoyage
    getCleaningPermitModalData = () => {
      const { openedModal } = this.state
      const { permit } = this.props
      if (includes(['updateCleaningPermit', 'duplicateCleaningPermit'], openedModal)) {
        let cleaningPermit = permit.cleaningPermits.find(f => f.id === this.state.currentTab)
        let toreturn = {
          ...cleaningPermit,
          validityDate: moment(),
          validityPeriod: [moment(), moment().hours(5)]
        }
  
        return toreturn
  
      } else if (includes(['transition', 'createCleaningPermit'], openedModal))
        // CrÃ©ation avec seulement les champs qui dÃ©pendent du permis principal
        return {
          permitID: get(permit, 'permitID'),
          company: get(permit, 'company'),
          location: get(permit, 'location'),
          companyLeader: get(permit, 'companyLeader')
        }
      // Retourner null en cas d'erreur 
      else return null
    }

     // DonnÃ©es de la modale du permis capacitÃ©
    getCapacityPermitModalData = () => {
      const { openedModal } = this.state
      const { permit } = this.props
      if (includes(['updateCapacityPermit', 'duplicateCapacityPermit'], openedModal)) {

        let capacityPermit = permit.capacityPermits.find(f => f.id === this.state.currentTab)
        let toreturn = {
          ...capacityPermit,
          validityDate: moment(),
          degassingCertificateDate: moment(capacityPermit.degassingCertificateDate, 'YYYY-MM-DD')
          //supervisor: capacityPermit.supervisor.id
        }

  
        return toreturn
  
      } else if (includes(['transition', 'createCapacityPermit'], openedModal))
        // CrÃ©ation avec seulement les champs qui dÃ©pendent du permis principal
        return {
          permitID: get(permit, 'permitID'),
          company: get(permit, 'company'),
          location: get(permit, 'location'),
          companyLeader: get(permit, 'companyLeader')
        }
      // Retourner null en cas d'erreur 
      else return null
    }
  
  /**
   * RÃ©cupÃ©ration de l'entreprise
   */
  getCompany = () => {
    const { permit, companies, profile } = this.props
    const { companyChosen } = this.state

    const context = this.getContext()
    if (profile.company.isSupercompany) {
      // Si l'utilisateur est un super admin
      return context === 'single' // Si le contexte est single
        ? isEmpty(companyChosen) // Si aucune entreprise n'a Ã©tÃ© choisie
          ? find(companies, ['id', get(get(permit, 'company'), 'id')]) // On rÃ©cupÃ¨re l'entreprise du permit
          : companyChosen // Sinon on rÃ©cupÃ¨re l'entreprise choisie
        : companyChosen // Sinon on rÃ©cupÃ¨re l'entreprise choisie
    } else {
      return profile.company
    }
  }

  /**
   * RÃ©cupÃ©ration des employÃ©s de l'entreprise du permit
   */
  getCompanyEmployees = () => {
    const company = this.getCompany()

    return defaultTo(get(company, 'employees'), [])
  }

  handleTableOrdered = (page, size, field, order, filters, search) => {
    const offset = page * size - size
    this.fetchPermitsData(size, offset, field, order, filters, search)
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      isDownloading,
      companies,
      supervisors,
      permit,
      navigation,
      profile,
      users
    } = this.props

    // RÃ©cupÃ©ration du contexte de donnÃ©es actuel
    const dataContext = this.getDataContext()

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal, subOpenedModal, companyEmployees } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,
      handleTabChange: this.handleTabChange,
      handlePermitsTabChange: this.handlePermitsTabChange,

      handleTableOrdered: this.handleTableOrdered,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un permis
      getPermitData: this.getPermitData,
      // fetchPermitChangelogData: this.fetchPermitChangelogData,
      createScan: this.createScan,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreatePermit: this.askCreatePermit,
      askCreatePermitRequest: this.askCreatePermitRequest,
      askDuplicatePermit: this.askDuplicatePermit,

      // CrÃ©ation / Modification d'un permit feu
      askUpdateFirePermit: this.askUpdateFirePermit,
      askUpdateSearchPermit: this.askUpdateSearchPermit,
      askUpdateCapacityPermit: this.askUpdateCapacityPermit,
      askUpdateCleaningPermit: this.askUpdateCleaningPermit,
      askCreateFirePermit: this.askCreateFirePermit,
      askCreateSearchPermit: this.askCreateSearchPermit,
      askCreateCapacityPermit: this.askCreateCapacityPermit,
      askCreateCleaningPermit: this.askCreateCleaningPermit,
      askConfirmRequest: this.askConfirmRequest,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdatePermit: this.askUpdatePermit,
      updatePermit: this.updatePermit,
      controlCapacityPermit: this.controlCapacityPermit,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeletePermit: this.askDeletePermit,
      askDeleteManyPermits: this.askDeleteManyPermits,

      // RÃ©initialisation du mot de passe d'un permit
      askResetPasswordPermit: this.askResetPasswordPermit,

      // Gestion du PDF associÃ© Ã  la version
      handleRevisionPDF: this.handleRevisionPDF,

      // Action sur le contrat
      askCancelPermit: this.askCancelPermit,
      askCancelFirePermit: this.askCancelFirePermit,
      askClosePermit: this.askClosePermit,
      askControlPermit: this.askControlPermit,
      askControlFirePermit: this.askControlFirePermit,
      askRatePermit: this.askRatePermit,
      askSignPermit: this.askSignPermit,
      askCapacityControl: this.askCapacityControl,
      askCleaningPhase1Control: this.askCleaningPhase1Control,
      askCleaningPhase2Control: this.askCleaningPhase2Control,

      // Action sur les scans
      askAddScanToPermit: this.askAddScanToPermit,

      // Permet de gÃ©rer le contexte de donnÃ©es
      getDataContext: this.getDataContext,
      setDataContext: this.setDataContext
    }

    // CrÃ©ation de la liste des Ã©crans associÃ©s aux contextes du constructeur
    const contextsScreen = {
      single: Single,
      list: List
    }

    // RÃ©cupÃ©ration de l'Ã©cran appropriÃ© au contexte du constructeur
    const ContextScreen = get(contextsScreen, this.getContext(), Empty)

    // Formatage des lignes de la modale
    ModalSpecs.rows = this.formatModalRows(ModalSpecs.rows)
    RequestModalSpecs.rows = this.formatModalRows(RequestModalSpecs.rows)

    // Ajout des entreprises extÃ©rieurs
    ModalSpecs.rows.company.formField.options = map(
      companies,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // SÃ©lection de l'entreprise extÃ©rieure
    ModalSpecs.rows.company.formField.onChange = selectedCompany => {
      this.updateCompanyChosen(selectedCompany)
    }

    // CrÃ©ation d'un nouveau surveillant SDLP
    ModalSpecs.rows.company.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createCompany'
            })
          }
        >
          {I18n.t('pages.permits.fields.companyName.add')}
        </Button>
      </div>
    )

    // Ajout des surveillants SDLP
    ModalSpecs.rows.supervisor.formField.options = CleanModalSpecs.rows.entryTemperatureMeasureController.formField.options = map(
      supervisors,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // CrÃ©ation d'un nouveau surveillant SDLP
    ModalSpecs.rows.supervisor.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createSupervisor'
            })
          }
        >
          {I18n.t('pages.permits.fields.supervisor.add')}
        </Button>
      </div>
    )

    // Choix de la liste du personnel intervenant de l'entreprise extÃ©rieure
    ModalSpecs.rows.participants.formField.options = RequestModalSpecs.rows.participants.formField.options = CapacityModalSpecs.rows.participants.formField.options = CleanModalSpecs.rows.personnelAuthorizedToEnter.formField.options = CleanModalSpecs.rows.securityAssistants.formField.options = CleanModalSpecs.rows.entryTemperatureMeasureParticipant.formField.options = CapacityModalSpecs.rows.securityParticipants.formField.options = map(
      companyEmployees,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    ModalSpecs.rows.participants.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createEmployee'
            })
          }
        >
          {I18n.t('pages.permits.fields.supervisor.add')}
        </Button>
      </div>
    )

    // Choix du reprÃ©sentant de l'entreprise extÃ©rieure
    CleanModalSpecs.rows.companySupervisor.formField.options = RequestModalSpecs.rows.companyLeader.formField.options = ModalSpecs.rows.companyLeader.formField.options = map(
      companyEmployees,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    CleanModalSpecs.rows.o2MeasureController.formField.options = CleanFormSpecs.rows.roofExplosimetryMeasureController.formField.options = CleanFormSpecs.rows.o2MeasureController.formField.options = CleanModalSpecs.rows.roofExplosimetryMeasureController.formField.options = map(
      users,
      ({ fullname, id }) => ({
        value: id,
        id,
        label: fullname
      })
    )

    // Formatage des lignes du formulaire
    FormSpecs.rows = this.formatModalRows(FormSpecs.rows)
    FireFormSpecs.rows = this.formatModalRows(FireFormSpecs.rows)
    SearchFormSpecs.rows = this.formatModalRows(SearchFormSpecs.rows)
    CapacityFormSpecs.rows = this.formatModalRows(CapacityFormSpecs.rows)
    CleanFormSpecs.rows = this.formatModalRows(CleanFormSpecs.rows)

    // Choix de l'entreprise extÃ©rieure
    FormSpecs.rows.company.formField.options = map(
      companies,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // SÃ©lection de l'entreprise extÃ©rieure
    FormSpecs.rows.company.formField.onChange = selectedCompany => {

      this.updateCompanyChosen(selectedCompany)
    }

    // Choix du surveillant SDLP
    FormSpecs.rows.supervisor.formField.options = map(
      supervisors,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )
    FormSpecs.rows.supervisor.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createSupervisor'
            })
          }
        >
          {I18n.t('pages.permits.fields.supervisor.add')}
        </Button>
      </div>
    )

    // Choix de la liste du personnel intervenant de l'entreprise extÃ©rieure
    FormSpecs.rows.participants.formField.options = CapacityFormSpecs.rows.securityParticipants.formField.options = CapacityFormSpecs.rows.participants.formField.options = CleanFormSpecs.rows.companySupervisor.formField.options = CleanFormSpecs.rows.companyLeader.formField.options = CleanFormSpecs.rows.securityAssistants.formField.options = CleanFormSpecs.rows.entryTemperatureMeasureParticipant.formField.options = CleanFormSpecs.rows.personnelAuthorizedToEnter.formField.options = map(
      companyEmployees,
      ({ id, name }) => ({
        value: id,
        id,
        label: name
      })
    )

    // Choix du reprÃ©sentant de l'entreprise extÃ©rieure
    FormSpecs.rows.companyLeader.formField.options = map(
      companyEmployees,
      ({ id, name }) => ({
        value: id,
        id,
        label: name
      })
    )

    // Formatage des lignes de la modale
    FireModalSpecs.rows = this.formatModalRows(FireModalSpecs.rows)
    SearchModalSpecs.rows = this.formatModalRows(SearchModalSpecs.rows)
    CapacityModalSpecs.rows = this.formatModalRows(CapacityModalSpecs.rows)
    CleanModalSpecs.rwos = this.formatModalRows(CleanModalSpecs.rows)

    // Choix de l'entreprise extÃ©rieure
    FireModalSpecs.rows.company.formField.options = SearchModalSpecs.rows.company.formField.options = CleanModalSpecs.rows.company.formField.options = CapacityModalSpecs.rows.company.formField.options = map(
      companies,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    CapacityModalSpecs.rows.degassingCompany.formField.options = CapacityFormSpecs.rows.degassingCompany.formField.options =
     map(
      companies,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )


    // SÃ©lection de l'entreprise extÃ©rieure
    FireModalSpecs.rows.company.formField.onChange = SearchModalSpecs.rows.company.formField.onChange = CleanModalSpecs.rows.company.formField.onChange = CapacityModalSpecs.rows.company.formField.onChange = selectedCompany => {

      this.updateCompanyChosen(selectedCompany)

      CapacityModalSpecs.rows.companyLeader.formField.options = map(
        companyEmployees,
        ({ name, id }) => ({
          value: id,
          id,
          label: name
        })
      )
    }

    CapacityModalSpecs.rows.companyLeader.formField.options = map(
      companyEmployees,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // Choix du surveillant SDLP
    FireModalSpecs.rows.supervisor.formField.options = SearchModalSpecs.rows.supervisor.formField.options = CapacityModalSpecs.rows.depotHead.formField.options = CapacityModalSpecs.rows.depotSupervisor.formField.options = CleanModalSpecs.rows.depotHead.formField.options = CleanModalSpecs.rows.depotSupervisor.formField.options = map(
      supervisors,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // CrÃ©ation d'un nouveau surveillant
    FireModalSpecs.rows.supervisor.formField.dropdownRender = SearchModalSpecs.rows.supervisor.formField.dropdownRender = CapacityModalSpecs.rows.depotHead.formField.dropdownRender = CapacityModalSpecs.rows.depotSupervisor.formField.dropdownRender = CleanModalSpecs.rows.depotHead.formField.dropdownRender = CleanModalSpecs.rows.depotSupervisor.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createSupervisor'
            })
          }
        >
          {I18n.t('pages.permits.fields.supervisor.add')}
        </Button>
      </div>
    )

    // Choix du reprÃ©sentant de l'entreprise extÃ©rieure
    FireModalSpecs.rows.companyLeader.formField.options = SearchModalSpecs.rows.companyLeader.formField.options = CapacityModalSpecs.rows.companyLeader.formField.options = CleanModalSpecs.rows.companyLeader.formField.options = map(
      companyEmployees,
      ({ id, name }) => ({
        value: id,
        id,
        label: name
      })
    )

    // Choix de l'entreprise extÃ©rieure
    FireFormSpecs.rows.company.formField.options = SearchFormSpecs.rows.company.formField.options = CapacityFormSpecs.rows.company.formField.options = CleanFormSpecs.rows.company.formField.options = map(
      companies,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )

    // SÃ©lection de l'entreprise extÃ©rieure
    FireFormSpecs.rows.company.formField.onChange = SearchFormSpecs.rows.company.formField.onChange = CapacityFormSpecs.rows.company.formField.onChange = CleanFormSpecs.rows.company.formField.onChange = selectedCompany => {
      this.updateCompanyChosen(selectedCompany)
    }

    // Choix du surveillant SDLP
    FireFormSpecs.rows.supervisor.formField.options = SearchFormSpecs.rows.supervisor.formField.options = CapacityFormSpecs.rows.depotHead.formField.options = CapacityFormSpecs.rows.depotSupervisor.formField.options = CleanFormSpecs.rows.depotSupervisor.formField.options = CleanFormSpecs.rows.depotHead.formField.options = CleanFormSpecs.rows.entryTemperatureMeasureController.formField.options = map(
      supervisors,
      ({ name, id }) => ({
        value: id,
        id,
        label: name
      })
    )
    FireFormSpecs.rows.supervisor.formField.dropdownRender = SearchFormSpecs.rows.supervisor.formField.dropdownRender = CapacityFormSpecs.rows.depotHead.formField.dropdownRender = CapacityFormSpecs.rows.depotSupervisor.formField.dropdownRender = CleanFormSpecs.rows.depotSupervisor.formField.dropdownRender = CleanFormSpecs.rows.depotHead.formField.dropdownRender = CleanFormSpecs.rows.entryTemperatureMeasureController.formField.dropdownRender = menu => (
      <div>
        {menu}
        <Divider style={{ margin: '4px 2px' }} />
        <Button
          icon='plus'
          type='link'
          onMouseDown={event => event.preventDefault()}
          onClick={() =>
            this.setState({
              subOpenedModal: 'createSupervisor'
            })
          }
        >
          {I18n.t('pages.permits.fields.supervisor.add')}
        </Button>
      </div>
    )

    // Choix du reprÃ©sentant de l'entreprise extÃ©rieure
    FireFormSpecs.rows.companyLeader.formField.options = SearchFormSpecs.rows.companyLeader.formField.options = map(
      companyEmployees,
      ({ id, name }) => ({
        value: id,
        id,
        label: name
      })
    )

    // Ajout des onglets supplÃ©mentaires de modification et rÃ©visions
    FireFormSpecs.tabs.changelog = {
      title: I18n.t('pages.permits.tabs.changelog.title'),
      props: {
        forceRender: true
      },
      render: props => (
        <ChangeLog
          {...props}
          constructorFunctions={constructorFunctions}
          navigation={navigation}
          loading={loading}
        />
      )
    }
    FormSpecs.tabs.changelog = CapacityFormSpecs.tabs.changelog = SearchFormSpecs.tabs.changelog = CleanFormSpecs.tabs.changelog =
      FireFormSpecs.tabs.changelog

    // Ajout des onglets supplÃ©mentaires de scans
    FireFormSpecs.tabs.scans = {
      title: I18n.t('pages.permits.tabs.scans.title'),
      props: {
        forceRender: true
      },
      render: props => (
        <Scans
          {...props}
          constructorFunctions={constructorFunctions}
          navigation={navigation}
          loading={loading}
          profile={profile}
        />
      )
    }
    FormSpecs.tabs.scans = CapacityFormSpecs.tabs.scans = SearchFormSpecs.tabs.scans = CleanFormSpecs.tabs.scans =
      FireFormSpecs.tabs.scans

    const isUserExtern =
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_READER') ||
      includes(map(get(profile, 'roles'), 'name'), 'EXTERN_CREATOR')

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <ContextScreen
          loading={loading}
          isDownloading={isDownloading}
          dataContext={dataContext}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          FormSpecs={FormSpecs}
          FireFormSpecs={FireFormSpecs}
          data={permit}
          {...this.props}
        />
        {/* Ouverture de la modale de crÃ©ation / modification d'un permit de travail */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onChange={value => {
            if (openedModal === 'updatePermit') return this.updatePermit(value)
            return this.createPermit(value, false)
          }}
          visible={includes(
            ['createPermit', 'updatePermit', 'duplicatePermit'],
            openedModal
          )}
          mode={replace(openedModal, 'Permit', '')}
          data={this.getPermitModalData()}
          {...ModalSpecs}
        />
        {/* Ouverture de la modale de crÃ©ation d'une requete de permis de travail */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={value => {
            if (openedModal === 'createPermitRequest')
              return this.createPermit(value, true)
            else return this.updatePermit(value)
          }}
          visible={includes(
            ['createPermitRequest', 'duplicatePermitRequest'],
            openedModal
          )}
          mode='request'
          data={this.getPermitModalData()}
          {...RequestModalSpecs}
        />
        {/* Ouverture de la modale de crÃ©ation / modification d'un permis de feu */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onOk={this.handleRowCreated}
            onChange={
              openedModal === 'updateFirePermit'
                ? this.updateFirePermit
                : this.createFirePermit
            }
            visible={includes(
              ['createFirePermit', 'updateFirePermit', 'duplicateFirePermit'],
              openedModal
            )}
            data={this.getFirePermitModalData()}
              
            {...FireModalSpecs}
          />
        )}
        {/* Ouverture de la modale de crÃ©ation / modification d'un permis de fouille */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onOk={this.handleRowCreated}
            onChange={
              openedModal === 'updateSearchPermit'
                ? this.updateSearchPermit
                : this.createSearchPermit
            }
            visible={includes(
              ['createSearchPermit', 'updateSearchPermit', 'duplicateSearchPermit'],
              openedModal
            )}
            data={this.getSearchPermitModalData()}
            {...SearchModalSpecs}
          />
        )}
        {/* Ouverture de la modale de crÃ©ation / modification d'un permis de nettoyage */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onOk={this.handleRowCreated}
            onChange={
              openedModal === 'updateCleaningPermit'
                ? this.updateCleaningPermit
                : this.createCleaningPermit
            }
            visible={includes(
              ['createCleaningPermit', 'updateCleaningPermit', 'duplicateCleaningPermit'],
              openedModal
            )}
            data={this.getCleaningPermitModalData()}
            {...CleanModalSpecs}
          />
        )}
        {/* Ouverture de la modale de crÃ©ation / modification d'un permis de capacitÃ© */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onChange={
              openedModal === 'updateCapacityPermit'
                ? this.updateCapacityPermit
                : this.createCapacityPermit
            }
            visible={includes(
              ['createCapacityPermit', 'updateCapacityPermit', 'duplicateCapacityPermit'],
              openedModal
            )}
            data={this.getCapacityPermitModalData()}
            {...CapacityModalSpecs}
          />
        )}{' '}
        {/* Ouverture de la modale de controle du permis de capacitÃ© */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onChange={this.controlCapacityPermit}
            visible={openedModal === 'capacityControl'}
            data={find(get(permit, 'capacityPermits'), {
              id: this.state.currentTab
            })}
            mode='update'
            {...CapacityControlModalSpecs}
          />
        )}
        {/* Ouverture de la modale de controle du permis de nettoyage */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onChange={this.controlCleaningPermitPhase1}
            visible={openedModal === 'cleaningPhase1Control'}
            data={find(get(permit, 'cleaningPermits'), {
              id: this.state.currentTab
            })}
            mode='update'
            {...CleanControlPhase1ModalSpecs}
          />
        )}
        {/* Ouverture de la modale de controle du permis de nettoyage */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseModal}
            onChange={this.controlCleaningPermitPhase2}
            visible={openedModal === 'cleaningPhase2Control'}
            data={find(get(permit, 'cleaningPermits'), {
              id: this.state.currentTab
            })}
            mode='update'
            {...CleanControlPhase2ModalSpecs}
          />
        )}
        {/* Ouverture de la modale de valdiation de demande de permis */}
        {!isUserExtern && (
          <ConfirmRequestModal
            loading={loading}
            onCancel={this.safelyCloseModal}
            onOk={this.safelyCloseModal}
            onChange={this.confirmPermitRequest}
            visible={openedModal === 'confirmRequest'}
            data={permit}
            addSupervisor={() => {
              this.setState({
                subOpenedModal: 'createSupervisor'
              })
            }}
          />
        )}
        {/* Ouverture de la modale de fin de permis */}
        {!isUserExtern && (
          <EndModal
            loading={loading}
            data={permit}
            currentTab={this.state.currentTab}
            onCancel={this.safelyCloseModal}
            openedModal={openedModal}
            onOk={this.safelyCloseModal}
            onChange={(data) => {
              switch (openedModal) {
                case 'endFirePermit':
                  this.closeFirePermit(data)
                  break
                case 'endSearchPermit':
                  this.closeSearchPermit(data)
                  break
                case 'endCapacityPermit':
                  this.closeCapacityPermit(data)
                  break
                case 'endCleaningPermit':
                  this.closeCapacityPermit(data)
                  break
                default:
                  this.closePermit(data)
                  break
              }
            }}
            visible={includes(
              [
                'endFirePermit',
                'endSearchPermit',
                'endCapacityPermit',
                'endCleaningPermit',
                'endPermit'
              ],
              openedModal
            )}
            addSupervisor={() => {
              this.setState({
                subOpenedModal: 'createSupervisor'
              })
            }}
          />
        )}
        {/* Ouverture de la modale d'annulation d'un permis */}
        {!isUserExtern && (
          <CancelModal
            loading={loading}
            onCancel={this.safelyCloseModal}
            onOk={this.safelyCloseModal}
            onChange={
              openedModal === 'cancelPermit'
                ? this.cancelPermit
                : this.cancelFirePermit
            }
            visible={includes(
              ['cancelPermit', 'cancelFirePermit'],
              openedModal
            )}
            data={permit}
          />
        )}
        {/* Ouverture de la modale de signature d'un permis */}
        <SignatureModal
          data={{
            ...permit,
            defaultSignerEmail: this.props.profile.email
          }}
          currentTab={this.state.currentTab}
          openedModal={openedModal}
          width={900}
          loading={loading}
          onChange={(scan, data, option) => {
            switch (openedModal) {
              case 'signFirePermit':
                this.signFirePermit(scan, option)
                break
              case 'signCapacityPermit':
                this.signCapacityPermit(scan, option)
                break
              case 'signCleaningPermit':
                this.signCleaningPermit(scan, option)
                break
              case 'signSearchPermit':
                this.signSearchPermit(scan, option)
                break
              default:
                this.signPermit(scan, option)
                break
            }
          }}
          onOk={this.safelyCloseModal}
          onCancel={this.safelyCloseModal}
          visible={includes(
            [
              'signPermit',
              'signFirePermit',
              'signCapacityPermit',
              'signCleaningPermit',
              'signSearchPermit'
            ],
            openedModal
          )}
          constructorFunctions={constructorFunctions}
        />
        {/* Ouverture de la modale de crÃ©ation / modification d'un nouveau surveillant */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseSubModal}
            onOk={this.safelyCloseSubModal}
            onChange={this.createSupervisor}
            // onChange={this.safelyCloseSubModal}
            visible={includes(['createSupervisor'], subOpenedModal)}
            type='subModal'
            mode={null}
            data={null}
            {...SupervisorModalSpecs}
          />
        )}
        {/* Ouverture de la modale de crÃ©ation / modification d'une nouvelle entreprise */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseSubModal}
            onOk={this.safelyCloseSubModal}
            onChange={this.createCompany}
            // onChange={this.safelyCloseSubModal}
            visible={includes(['createCompany'], subOpenedModal)}
            type='subModal'
            mode={null}
            data={null}
            {...CompanyModalSpecs}
          />
        )}
        {/* Ouverture de la modale de crÃ©ation / modification d'un nouveau surveillant */}
        {!isUserExtern && (
          <ManageModal
            width={800}
            loading={loading}
            onCancel={this.safelyCloseSubModal}
            onOk={this.safelyCloseSubModal}
            onChange={this.createEmployee}
            // onChange={this.safelyCloseSubModal}
            visible={includes(['createEmployee'], subOpenedModal)}
            type='subModal'
            mode={null}
            data={null}
            {...EmployeeModalSpecs}
          />
        )}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(PermitsConstructor, 'defaultProps', {})

  return {
    associablePermits: defaultTo(
      get(state, 'associablePermit.items'),
      defaultProps.associablePermits
    ),
    companies: defaultTo(get(state, 'company.items'), defaultProps.companies),
    supervisors: defaultTo(
      get(state, 'supervisor.items'),
      defaultProps.supervisors
    ),
    users: defaultTo(get(state, 'user.items'), defaultProps.users),
    operations: defaultTo(
      get(state, 'operation.items'),
      defaultProps.operations
    ),
    preventiveMeasures: defaultTo(
      get(state, 'preventiveMeasure.items'),
      defaultProps.preventiveMeasures
    ),
    risks: defaultTo(get(state, 'risk.items'), defaultProps.risks),
    materials: defaultTo(get(state, 'material.items'), defaultProps.materials),
    fireSources: defaultTo(
      get(state, 'fireSource.items'),
      defaultProps.fireSources
    ),
    securityMeasures: defaultTo(
      get(state, 'securityMeasure.items'),
      defaultProps.securityMeasures
    ),
    capacityEquipments: defaultTo(
      get(state, 'capacityEquipment.items'),
      defaultProps.capacityEquipments
    ),
    capacitySecurityMeasures: defaultTo(
      get(state, 'capacitySecurityMeasure.items'),
      defaultProps.capacitySecurityMeasures
    ),
    capacityAdditionalProvisions: defaultTo(
      get(state, 'capacityAdditionalProvision.items'),
      defaultProps.capacityAdditionalProvisions
    ),
    cleaningEquipments: defaultTo(
      get(state, 'cleaningEquipment.items'),
      defaultProps.cleaningEquipments
    ),
    cleaningConditions: defaultTo(
      get(state, 'cleaningCondition.items'),
      defaultProps.cleaningConditions
    ),
    cleaningAdditionalProvisions: defaultTo(
      get(state, 'cleaningAdditionalProvision.items'),
      defaultProps.cleaningAdditionalProvisions
    ),
    searchSecurityMeasures: defaultTo(
      get(state, 'searchSecurityMeasure.items'),
      defaultProps.searchSecurityMeasures
    ),
    searchPredictableRisks: defaultTo(
      get(state, 'searchPredictableRisk.items'),
      defaultProps.searchPredictableRisks
    ),
    permits: defaultTo(get(state, 'permit.items'), defaultProps.permits),
    totalPermits: defaultTo(
      get(state, 'permit.totalPermits'),
      defaultProps.totalPermits
    ),
    permitsRequests: defaultTo(
      get(state, 'permit.requestsItems'),
      defaultProps.permitsRequests
    ),
    totalPermitsRequests: defaultTo(
      get(state, 'permit.totalPermitsRequests'),
      defaultProps.totalPermitsRequests
    ),
    permit: defaultTo(get(state, 'permit.item'), defaultProps.permit),
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile),
    isGathering:
      defaultTo(get(state, 'permit.isFetching'), defaultProps.isGathering) ||
      defaultTo(get(state, 'permit.isFetchingItem'), defaultProps.isGathering),
    isUpdating: defaultTo(
      get(state, 'permit.isUpdating'),
      defaultProps.isUpdating
    ),
    isDownloading:
      defaultTo(
        get(state, 'permit.isDownloading'),
        defaultProps.isDownloading
      ) ||
      defaultTo(
        get(state, 'firePermit.isDownloading'),
        defaultProps.isDownloading
      )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      ...permitActions,
      ...associablePermitActions,
      ...riskActions,
      ...operationActions,
      ...materialActions,
      ...fireSourceActions,
      ...preventiveMeasureActions,
      ...securityMeasureActions,
      ...firePermitActions,
      ...companyActions,
      ...supervisorActions,
      ...employeeActions,
      ...capacityPermitActions,
      ...capacityEquipmentActions,
      ...capacitySecurityMeasureActions,
      ...capacityAdditionalProvisionActions,
      ...searchPredictableRiskActions,
      ...searchSecurityMeasureActions,
      ...searchPermitActions,
      ...cleaningPermitActions,
      ...cleaningAdditionalProvisionActions,
      ...cleaningEquipmentActions,
      ...cleaningConditionActions,
      ...userActions
    },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PermitsConstructor)
