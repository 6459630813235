// Libraries
import I18n from 'i18next'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'
import { get, defaultTo, orderBy, includes, map } from 'lodash'
import {
  Spin,
  List,
  Card,
  Avatar,
  Form,
  Icon,
  Button,
  Upload,
  Dropdown,
  Menu,
  Tooltip
} from 'antd'

// Helpers
import { getIconMimeType, getIconMimeTypeComponent } from 'Helpers'

// Global components
import { Anchor } from 'Components'

const { Meta } = Card
const { Item: FormItem } = Form
const { Dragger } = Upload

class ScansTab extends Component {
  static propTypes = {
    // DonnÃ©es de la vue
    data: PropTypes.object,
    form: PropTypes.object,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // TÃ©moins de charge API
    loading: PropTypes.bool,

    // Navigation
    navigation: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // DonnÃ©es de la vue
    data: PropTypes.object,

    // TÃ©moins de charge API
    loading: true,

    profile: {},

    // Navigation
    navigation: {},

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: {}
  }

  constructor(props) {
    super(props)

    this.state = {
      fileList: []
    }
  }

  componentDidMount = () => {
    // Lecteur de fichier
    this.fileReader = new FileReader()

    // RÃ©cupÃ©ration des informations du permit
    // this.fetchPermitChangelog()
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es de la vue
   */
  fetchPermitChangelog = () => {
    const { constructorFunctions, navigation } = this.props
    const id = navigation.getParam('id')

    constructorFunctions.fetchPermitChangelogData(id)
  }

  handleSubmit = () => {
    const { constructorFunctions } = this.props
    const { validateFields, resetFields } = this.props.form

    validateFields((error, data) => {
      if (!error) {
        const { fileList } = this.state
        const scan = new FormData()
        fileList.forEach(file => {
          scan.append('files', file)
        })
        get(constructorFunctions, 'askAddScanToPermit')(scan)
        resetFields()
        this.setState(state => ({
          fileList: []
        }))
      }
    })
  }

  /**
   * Timeline des versions
   */
  render() {
    const { form, loading, data: permit, profile } = this.props
    const { getFieldDecorator } = form
    const { fileList } = this.state
    const rolesName = map(get(profile, 'roles'), ({ name }) => name)

    const scans = orderBy(
      defaultTo(get(permit, 'scans'), []),
      scan => moment(defaultTo(get(scan, 'dateUploaded'), 0)).valueOf(),
      'desc'
    )

    const isUserReader = includes(rolesName, 'READER')

    return (
      <Spin spinning={loading} wrapperClassName='tab scans'>
        {!isUserReader && (
          <Form>
            {/* TÃ©lÃ©chargement du scan */}
            <FormItem>
              {getFieldDecorator('scan')(
                <Dragger
                  name='files'
                  defaultFileList={[]}
                  beforeUpload={file => {
                    this.setState(state => ({
                      fileList: [...state.fileList, file]
                    }))
                    return false
                  }}
                  onRemove={file => {
                    this.setState(state => {
                      const index = state.fileList.indexOf(file)
                      const newFileList = state.fileList.slice()
                      newFileList.splice(index, 1)
                      return {
                        fileList: newFileList
                      }
                    })
                  }}
                  multiple
                >
                  <p className='ant-upload-drag-icon'>
                    <Icon type='inbox' />
                  </p>
                  <p className='ant-upload-text'>
                    {I18n.t('pages.permits.fields.scan.titleImport')}
                  </p>

                  <p className='ant-upload-hint'>
                    {I18n.t('pages.permits.fields.scan.upload-hint')}
                  </p>

                  <Button
                    key='import'
                    icon='paper-clip'
                    type='primary'
                    disabled={includes(rolesName, 'READER')}
                  >
                    {I18n.t('pages.permits.fields.scan.import')}
                  </Button>
                </Dragger>
              )}
              <Button
                type='primary'
                onClick={this.handleSubmit}
                disabled={
                  fileList.length === 0 || includes(rolesName, 'READER')
                }
                style={{ marginTop: 15 }}
              >
                {I18n.t('pages.permits.fields.scan.save')}
              </Button>
            </FormItem>
          </Form>
        )}

        <List
          style={{ marginTop: '45px' }}
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 4,
            lg: 4,
            xl: 6,
            xxl: 6
          }}
          dataSource={scans}
          renderItem={(scan, index) => (
            <a
              target='_blank'
              href={get(scan, 'url')}
              rel='noopener noreferrer'
            >
              <List.Item key={index}>
                <Card
                  style={{ height: '255px' }}
                  hoverable
                  cover={
                    <Tooltip title={get(scan, 'mimeType')}>
                      <Avatar
                        src={get(scan, 'url')}
                        icon={getIconMimeType(get(scan, 'mimeType'))}
                      />
                    </Tooltip>
                  }
                >
                  <Meta
                    title={
                      <Fragment>
                        <Tooltip title={get(scan, 'fileName')}>
                          {getIconMimeTypeComponent(get(scan, 'mimeType'))}
                          <span>{get(scan, 'fileName')}</span>
                        </Tooltip>

                        <Dropdown
                          overlay={
                            <Menu>
                              <Menu.Item key='open'>
                                <Anchor className='open'>
                                  {I18n.t('pages.permits.tabs.scans.open')}
                                </Anchor>
                              </Menu.Item>
                              <Menu.Item key='print'>
                                <Anchor className='print'>
                                  {I18n.t('pages.permits.tabs.scans.print')}
                                </Anchor>
                              </Menu.Item>
                              <Menu.Item key='download'>
                                <Anchor className='download'>
                                  {I18n.t('pages.permits.tabs.scans.download')}
                                </Anchor>
                              </Menu.Item>
                              <Menu.Divider />
                              <Menu.Item key='delete'>
                                <Anchor className='delete'>
                                  {I18n.t('pages.permits.tabs.scans.delete')}
                                </Anchor>
                              </Menu.Item>
                            </Menu>
                          }
                          trigger={['click']}
                        >
                          <Icon type='more' />
                        </Dropdown>
                      </Fragment>
                    }
                    description={I18n.t('pages.permits.tabs.scans.importedBy', {
                      date: moment(get(scan, 'dateUploaded')).fromNow()
                    })}
                  />
                </Card>
              </List.Item>
            </a>
          )}
        />
      </Spin>
    )
  }
}

export default Form.create()(ScansTab)
