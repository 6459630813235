// Libraries
import React from 'react'
import I18n from 'i18next'
import { Tag } from 'antd'
import { isNil, map, includes, get, mapValues } from 'lodash'

// Helpers
import { alphaSort } from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'user',
    kind: 'male'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus',
        items: [
          {
            key: 0,
            label: 'pages.users.table.buttons.createUser'
          },
          {
            key: 1,
            label: 'pages.users.table.buttons.createCompanyUser'
          }
        ]
      }
    },
    // Actions groupÃ©es sur les lignes du tableau
    selection: {
      delete: {
        buttonType: 'danger'
      },
      disable: {
        buttonType: 'danger'
      },
      enable: {
        buttonType: 'primary'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.users.table.empty'
  },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Colonnes du tableau
  columns: {
    fullName: {
      title: 'pages.users.fields.fullName.title',
      dataIndex: 'fullname',
      key: 'fullName',
      render: fullName =>
        !isNil(fullName) ? fullName : I18n.t('common.unknown.male'),
      sorter: (...params) => alphaSort(params, 'fullname'),
      defaultSortOrder: 'ascend'
    },
    email: {
      title: 'pages.users.fields.email.title',
      dataIndex: 'email',
      key: 'email',
      render: email =>
        !isNil(email) ? email : I18n.t('common.unknown.female'),
      sorter: (...params) => alphaSort(params, 'email')
    },
    role: {
      title: 'pages.users.fields.role.title',
      dataIndex: 'roles',
      key: 'role',
      render: roles => {
        const associatedColors = {
          ADMIN: 'volcano',
          READER: 'geekblue',
          CREATOR: 'purple',
          EXTERN_READER: 'cyan',
          EXTERN_CREATOR: 'blue'
        }

        return !isNil(roles)
          ? map(roles, role => (
              <Tag color={get(associatedColors, role.name)}>
                {I18n.t(`pages.users.fields.role.options.${role.name}`)}
              </Tag>
            ))
          : I18n.t('common.unknown.male')
      },
      sorter: (...params) => alphaSort(params, 'roles[0].name'),
      filters: map(
        ['ADMIN', 'READER', 'CREATOR', 'EXTERN_READER', 'EXTERN_CREATOR'],
        role => ({
          text: I18n.t(`pages.users.fields.role.options.${role}`),
          value: role
        })
      ),
      onFilter: (role, user) => {
        const userRoles = mapValues(get(user, 'roles'), role => {
          return role.name
        })
        return includes(userRoles, role)
      }
    },
    isActive: {
      title: 'pages.users.fields.isActive.title',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, user) =>
        get(user, 'isFirstLogin') ? (
          <Tag color='gray'>{I18n.t(`pages.users.fields.isActive.wait`)}</Tag>
        ) : isActive && !get(user, 'isFirstLogin') ? (
          <Tag color='green'>
            {I18n.t(`pages.users.fields.isActive.activated`)}
          </Tag>
        ) : !isActive ? (
          <Tag color='red'>
            {I18n.t(`pages.users.fields.isActive.deactivated`)}
          </Tag>
        ) : (
          <Tag color='gray'>{I18n.t(`pages.users.fields.isActive.wait`)}</Tag>
        )
    }
  }
}

export default specs
