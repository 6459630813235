import React from 'react'
import ReactSVG from 'react-svg'

const icons = {
  add_fire_permit: props => (
    <ReactSVG
      className='anticon'
      wrapper='span'
      src={require('Images/Icons/add_fire_permit.svg')}
      {...props}
    />
  )
}

export default icons
