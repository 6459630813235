// Libraries
import I18n from 'i18next'
import zxcvbn from 'zxcvbn'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { Form, Input, message } from 'antd'
import {
  get,
  mapValues,
  defaultTo,
  cloneDeep,
  escapeRegExp,
  isEmpty
} from 'lodash'

// Components
import { StrengthIndicator } from 'Components'
import { Container } from 'Components/Modals'

// Ressources
import { actions as profileActions } from 'Resources/ProfileResource'

// Styles
import './Modal.less'

const { Item: FormItem } = Form

class ChangePasswordModal extends Component {
  static propTypes = {
    form: PropTypes.object,
    onOk: PropTypes.func,
    onCancel: PropTypes.func,
    okText: PropTypes.string,
    title: PropTypes.string,
    actions: PropTypes.object
  }

  static defaultProps = {
    visible: false,
    formData: {},
    actions: {},
    onOk: () => {},
    onCancel: () => {},
    okText: I18n.t('pages.profile.components.modals.changePassword.okText'),
    title: I18n.t('pages.profile.components.modals.changePassword.title')
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {
      visible: get(props, 'visible'),
      loading: false
    }
  }

  componentWillReceiveProps = nextProps => {
    this.setState({
      visible: get(nextProps, 'visible')
    })
  }

  _handleSubmit = e => {
    e.preventDefault()

    const { form, onOk } = this.props

    // Validation du formulaire
    form.validateFieldsAndScroll((error, passwords) => {
      if (!error) {
        this.props.actions
          .changePasswordProfile(passwords)
          .then(() => {
            message.success(I18n.t('api.success.example.submit'))
            onOk()
            form.resetFields()
          })
          .catch(() => {
            message.error(I18n.t(''))
          })
      }
    })
  }

  _handleCancel = e => {
    const { onCancel, form } = this.props

    this.setState(
      {
        visible: false
      },
      () => {
        onCancel(e)
        form.resetFields()
      }
    )
  }

  /**
   * Validation du nouveau mot de passe
   */
  handleNewPassword = (rule, value = '', callback) => {
    const { getFieldValue } = this.props.form
    let callbackMessage

    // Ãvaluation du mot de passe
    const passwordReview = zxcvbn(value)

    // Mot de passe diffÃ©rent du prÃ©cÃ©dent ou pas suffisamment complexe
    if (!isEmpty(value) && value === getFieldValue('currentPassword')) {
      callbackMessage = I18n.t('fields.password.new.errors.same')
    } else if (
      !isEmpty(value) &&
      defaultTo(get(passwordReview, 'score'), 0) < 2
    ) {
      callbackMessage = I18n.t('fields.password.new.errors.score')
    }

    callback(callbackMessage)
  }

  render() {
    const { visible = false, loading = false } = this.state
    const { form, title, okText } = this.props
    const { getFieldDecorator } = form

    return (
      <Container
        title={title}
        visible={visible}
        loading={loading}
        onOk={this._handleSubmit}
        okText={okText}
        onCancel={this._handleCancel}
        className={'modal changePassword'}
      >
        <Form onSubmit={this._handleSubmit}>
          {/* Mot de passe actuel */}
          <FormItem label={I18n.t('fields.password.old.title')}>
            {getFieldDecorator('currentPassword', {
              rules: [
                {
                  required: true,
                  message: I18n.t('fields.password.requiredMessage')
                }
              ]
            })(
              <Input
                type='password'
                placeholder={I18n.t('fields.password.placeholder')}
              />
            )}
          </FormItem>

          {/* Nouveau mot de passe */}
          <FormItem label={I18n.t('fields.password.new.title')}>
            <StrengthIndicator
              visible={visible}
              value={form.getFieldValue('newPassword')}
            >
              {getFieldDecorator('newPassword', {
                rules: [
                  {
                    required: true,
                    message: I18n.t('fields.password.requiredMessage')
                  },
                  {
                    validator: this.handleNewPassword
                  }
                ]
              })(
                <Input
                  type='password'
                  placeholder={I18n.t('fields.password.new.placeholder')}
                />
              )}
            </StrengthIndicator>
          </FormItem>

          {/* Confirmation nouveau mot de passe */}
          <FormItem label={I18n.t('fields.password.confirm.title')}>
            {getFieldDecorator('confirmPassword', {
              rules: [
                {
                  required: true,
                  message: I18n.t('fields.password.confirm.requiredMessage')
                },
                {
                  pattern: `^${escapeRegExp(
                    form.getFieldValue('newPassword')
                  )}$`,
                  message: I18n.t('fields.password.confirm.patternMessage')
                }
              ]
            })(
              <Input
                type='password'
                placeholder={I18n.t('fields.password.confirm.placeholder')}
              />
            )}
          </FormItem>
        </Form>
      </Container>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...profileActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  Form.create({
    mapPropsToFields(props) {
      const defaultProps = get(ChangePasswordModal, 'defaultProps', {})

      const formData = cloneDeep(
        defaultTo(get(props, 'formData'), defaultProps.formData)
      )

      return mapValues(formData, object => {
        return Form.createFormField({ value: object })
      })
    }
  })(ChangePasswordModal)
)
