// Libraries
import I18n from 'i18next'
import { isNil } from 'lodash'

// Helpers
import { alphaSort, numberSort } from 'Components/TableLayout/Helpers'

const specs = {
  // Nom des Ã©lÃ©ments affichÃ©s et listÃ©s
  element: {
    name: 'dashboard',
    kind: 'female'
  },

  // Liste des boutons du tableau
  actions: {
    // Actions sur l'ensemble du tableau
    global: {
      create: {
        label: 'common.create',
        icon: 'plus'
      }
    }
  },

  // Gestion de l'internationalisation
  locale: {
    emptyText: 'pages.dashboard.table.empty'
  },

  scroll: { x: 2260 },

  // Affichage du titre
  titleEnabled: true,

  // Affichage de la zone de recherche
  searchEnabled: true,

  // Render custom des en-tÃªtes
  renderHeaderLeft: null,
  renderHeaderRight: null,

  // Gestion de la pagination
  pagination: {
    position: 'both',
    showSizeChanger: true,
    current: 1,
    pageSize: 10,
    pageSizeOptions: ['10', '25', '50', '100']
  },

  // Identifiant unique de chaque ligne du tableau
  rowKey: 'id',

  // Autorise les lignes en drag and drop
  areRowsDraggable: false,

  // Colonnes du tableau
  columns: {
    name: {
      title: 'pages.dashboard.fields.name.title',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => alphaSort(params, 'name')
    },
    permitsCountThisYear: {
      title: 'pages.dashboard.fields.permitsCountThisYear.title',
      dataIndex: 'permitsCountThisYear',
      key: 'permitsCountThisYear',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'permitsCountThisYear')
    },
    permitsCountThisMonth: {
      title: 'pages.dashboard.fields.permitsCountThisMonth.title',
      dataIndex: 'permitsCountThisMonth',
      key: 'permitsCountThisMonth',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'permitsCountThisMonth')
    },
    permitsCountThisWeek: {
      title: 'pages.dashboard.fields.permitsCountThisWeek.title',
      dataIndex: 'permitsCountThisWeek',
      key: 'permitsCountThisWeek',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'permitsCountThisWeek')
    },
    permitsCountToday: {
      title: 'pages.dashboard.fields.permitsCountToday.title',
      dataIndex: 'permitsCountToday',
      key: 'permitsCountToday',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'permitsCountToday')
    },
    permitsCount: {
      title: 'pages.dashboard.fields.permitsCount.title',
      dataIndex: 'permitsCount',
      key: 'permitsCount',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'permitsCount')
    },
    firePermitsCountThisYear: {
      title: 'pages.dashboard.fields.firePermitsCountThisYear.title',
      dataIndex: 'firePermitsCountThisYear',
      key: 'firePermitsCountThisYear',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'firePermitsCountThisYear')
    },
    firePermitsCountThisMonth: {
      title: 'pages.dashboard.fields.firePermitsCountThisMonth.title',
      dataIndex: 'firePermitsCountThisMonth',
      key: 'firePermitsCountThisMonth',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'firePermitsCountThisMonth')
    },
    firePermitsCountThisWeek: {
      title: 'pages.dashboard.fields.firePermitsCountThisWeek.title',
      dataIndex: 'firePermitsCountThisWeek',
      key: 'firePermitsCountThisWeek',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'firePermitsCountThisWeek')
    },
    firePermitsCountToday: {
      title: 'pages.dashboard.fields.firePermitsCountToday.title',
      dataIndex: 'firePermitsCountToday',
      key: 'firePermitsCountToday',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'firePermitsCountToday')
    },
    firePermitsCount: {
      title: 'pages.dashboard.fields.firePermitsCount.title',
      dataIndex: 'firePermitsCount',
      key: 'firePermitsCount',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'firePermitsCount')
    },
    workTimeThisYear: {
      title: 'pages.dashboard.fields.workTimeThisYear.title',
      dataIndex: 'workTimeThisYear',
      key: 'workTimeThisYear',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'workTimeThisYear')
    },
    workTimeThisMonth: {
      title: 'pages.dashboard.fields.workTimeThisMonth.title',
      dataIndex: 'workTimeThisMonth',
      key: 'workTimeThisMonth',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'workTimeThisMonth')
    },
    workTimeThisWeek: {
      title: 'pages.dashboard.fields.workTimeThisWeek.title',
      dataIndex: 'workTimeThisWeek',
      key: 'workTimeThisWeek',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'workTimeThisWeek')
    },
    workTimeToday: {
      title: 'pages.dashboard.fields.workTimeToday.title',
      dataIndex: 'workTimeToday',
      key: 'workTimeToday',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'workTimeToday')
    },
    workTime: {
      title: 'pages.dashboard.fields.workTime.title',
      dataIndex: 'workTime',
      key: 'workTime',
      width: 150,
      render: name => (!isNil(name) ? name : I18n.t('common.unknown.male')),
      sorter: (...params) => numberSort(params, 'workTime')
    }
  }
}

export default specs
