// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as supervisorActions } from 'Resources/SupervisorResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/Supervisors/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class SupervisorsConstructor extends Component {
  static propTypes = {
    // Actsions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    supervisor: PropTypes.object,
    supervisors: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    supervisor: {},
    supervisors: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchSupervisorsData()

    if (this.getContext() === 'single') {
      this.getSupervisorData().then(this.askUpdateSupervisor)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchSupervisorsData = () => {
    this.props.actions.fetchSupervisors().catch(() => {
      message.error(I18n.t('api.errors.supervisor.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getSupervisorData = (supervisor = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getSupervisor(supervisor)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.supervisor.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManySupervisor = (supervisors, clearTableSelections) => {
    this.props.actions
      .archiveSupervisors(supervisors)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.supervisor.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.supervisor.bulk.delete'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveSupervisor = supervisor => {
    this.props.actions
      .archiveSupervisor(supervisor)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.supervisor.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.supervisor.delete'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManySupervisor = (supervisors, clearTableSelections) => {
    this.props.actions
      .deleteSupervisors(supervisors)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.supervisor.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.supervisor.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteSupervisor = supervisor => {
    this.props.actions
      .deleteSupervisor(supervisor)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.supervisor.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.supervisor.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un associablePermit
   */
  updateSupervisor = (updatedSupervisor, originalSupervisor) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateSupervisor({
          id: get(originalSupervisor, 'id'),
          ...updatedSupervisor
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          this.fetchSupervisorsData()
          message.success(I18n.t('api.success.supervisor.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.supervisor.update'))
        })
    })

  /**
   * CrÃ©ation d'un supervisor
   */
  createSupervisor = createdSupervisor =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createSupervisor(createdSupervisor)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.supervisor.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.supervisor.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManySupervisors = (...params) => {
    confirm({
      title: I18n.t('pages.supervisor.modal.archive.multiple.title'),
      content: I18n.t('pages.supervisor.modal.archive.multiple.content'),
      okText: I18n.t('pages.supervisor.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.supervisor.modal.archive.multiple.cancelText'),
      onOk: () => {
        this.archiveSupervisor(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveSupervisor = () => {
    const { supervisor } = this.props

    confirm({
      title: I18n.t('pages.supervisor.modal.archive.single.title'),
      content: I18n.t('pages.supervisor.modal.archive.single.content'),
      okText: I18n.t('pages.supervisor.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.supervisor.modal.archive.single.cancelText'),
      onOk: () => {
        this.archiveSupervisor(supervisor)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManySupervisors = (...params) => {
    confirm({
      title: I18n.t('pages.supervisor.modal.delete.multiple.title'),
      content: I18n.t('pages.supervisor.modal.delete.multiple.content'),
      okText: I18n.t('pages.supervisor.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.supervisor.modal.delete.multiple.cancelText'),
      onOk: () => {
        this.archiveManySupervisor(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteSupervisor = () => {
    const { supervisor } = this.props

    confirm({
      title: I18n.t('pages.supervisor.modal.delete.single.title'),
      content: I18n.t('pages.supervisor.modal.delete.single.content'),
      okText: I18n.t('pages.supervisor.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t('pages.supervisor.modal.delete.single.cancelText'),
      onOk: () => {
        this.archiveSupervisor(supervisor)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateSupervisor = () => {
    this.setState({ openedModal: 'updateSupervisor' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateSupervisor = () => {
    this.setState({ openedModal: 'createSupervisor' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateSupervisor') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = supervisor => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(supervisor, 'id') })

    this.getSupervisorData(supervisor)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateSupervisor()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('AssociablePermit', { id: get(associablePermit, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderSupervisor(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.supervisor.order')))
      .catch(() => message.error(I18n.t('api.errors.supervisor.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      supervisors,
      supervisor
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getSupervisorData: this.getSupervisorData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateSupervisor: this.askCreateSupervisor,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateSupervisor: this.askUpdateSupervisor,
      askUpdateManySupervisors: this.askUpdateSupervisor,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveSupervisor: this.askArchiveSupervisor,
      askArchiveManySupervisors: this.askArchiveManySupervisors,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteSupervisor: this.askDeleteSupervisor,
      askDeleteManySupervisors: this.askDeleteManySupervisors,

      // RÃ©initialisation du mot de passe d'un Supervisor
      askResetPasswordSupervisor: this.askResetPasswordSupervisor
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteSupervisor

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={supervisors}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateSupervisor'
              ? this.updateSupervisor
              : this.createSupervisor
          }
          visible={includes(
            ['createSupervisor', 'updateSupervisor'],
            openedModal
          )}
          data={
            includes(['transition', 'updateSupervisor'], openedModal)
              ? supervisor
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(SupervisorsConstructor, 'defaultProps', {})

  return {
    supervisors: defaultTo(
      get(state, 'supervisor.items'),
      defaultProps.supervisors
    ),
    supervisor: defaultTo(
      get(state, 'supervisor.item'),
      defaultProps.supervisor
    ),
    isGathering:
      defaultTo(
        get(state, 'supervisor.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'supervisor.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'supervisor.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...supervisorActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SupervisorsConstructor)
