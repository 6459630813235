// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as securityMeasureActions } from 'Resources/SecurityMeasureResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from 'Views/SecurityMeasures/Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers/index'

const { confirm } = Modal

class SecurityMeasuresConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    securityMeasure: PropTypes.object,
    securityMeasures: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    securityMeasure: {},
    securityMeasures: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchSecurityMeasuresData()

    if (this.getContext() === 'single') {
      this.getSecurityMeasureData().then(this.askUpdateSecurityMeasure)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchSecurityMeasuresData = () => {
    this.props.actions.fetchSecurityMeasures().catch(() => {
      message.error(I18n.t('api.errors.securityMeasure.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getSecurityMeasureData = (
    securityMeasure = this.props.navigation.getParam('id')
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getSecurityMeasure(securityMeasure)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.securityMeasure.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManySecurityMeasure = (securityMeasure, clearTableSelections) => {
    this.props.actions
      .archiveManySecurityMeasure(securityMeasure)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.securityMeasure.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.securityMeasure.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveSecurityMeasure = securityMeasure => {
    this.props.actions
      .archiveSecurityMeasure(securityMeasure)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.securityMeasure.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.securityMeasure.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManySecurityMeasure = (securityMeasure, clearTableSelections) => {
    this.props.actions
      .archiveSecurityMeasures(securityMeasure)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.securityMeasure.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.securityMeasure.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteSecurityMeasure = securityMeasure => {
    this.props.actions
      .archiveSecurityMeasure(securityMeasure)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.securityMeasure.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.securityMeasure.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un securityMeasure
   */
  updateSecurityMeasure = (updatedSecurityMeasure, originalSecurityMeasure) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateSecurityMeasure({
          id: get(originalSecurityMeasure, 'id'),
          ...updatedSecurityMeasure
        })
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.securityMeasure.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.securityMeasure.update'))
        })
    })

  /**
   * CrÃ©ation d'un securityMeasure
   */
  createSecurityMeasure = createdSecurityMeasure =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createSecurityMeasure(createdSecurityMeasure)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.securityMeasure.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.securityMeasure.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManySecurityMeasures = (...params) => {
    confirm({
      title: I18n.t('pages.securityMeasures.modal.archive.multiple.title'),
      content: I18n.t('pages.securityMeasures.modal.archive.multiple.content'),
      okText: I18n.t('pages.securityMeasures.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.securityMeasures.modal.archive.multiple.cancelText'
      ),
      onOk: () => {
        this.archiveSecurityMeasure(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveSecurityMeasure = () => {
    const { securityMeasure } = this.props

    confirm({
      title: I18n.t('pages.securityMeasures.modal.archive.single.title'),
      content: I18n.t('pages.securityMeasures.modal.archive.single.content'),
      okText: I18n.t('pages.securityMeasures.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.securityMeasures.modal.archive.single.cancelText'
      ),
      onOk: () => {
        this.archiveSecurityMeasure(securityMeasure)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManySecurityMeasures = (...params) => {
    confirm({
      title: I18n.t('pages.securityMeasures.modal.delete.multiple.title'),
      content: I18n.t('pages.securityMeasures.modal.delete.multiple.content'),
      okText: I18n.t('pages.securityMeasures.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.securityMeasures.modal.delete.multiple.cancelText'
      ),
      onOk: () => {
        this.deleteManySecurityMeasure(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteSecurityMeasure = () => {
    const { securityMeasure } = this.props

    confirm({
      title: I18n.t('pages.securityMeasures.modal.delete.single.title'),
      content: I18n.t('pages.securityMeasures.modal.delete.single.content'),
      okText: I18n.t('pages.securityMeasures.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.securityMeasures.modal.delete.single.cancelText'
      ),
      onOk: () => {
        this.deleteSecurityMeasure(securityMeasure)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateSecurityMeasure = () => {
    this.setState({ openedModal: 'updateSecurityMeasure' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateSecurityMeasure = () => {
    this.setState({ openedModal: 'createSecurityMeasure' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateSecurityMeasure') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = securityMeasure => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(securityMeasure, 'id') })

    this.getSecurityMeasureData(securityMeasure)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateSecurityMeasure()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })

    // Ouverture du drawer
    // this.setState({ drawerOpened: true })

    // Navigation vers la vue dÃ©taillÃ©e
    // this.props.navigation.navigate('SecurityMeasure', { id: get(securityMeasure, 'id') })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderSecurityMeasure(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.securityMeasure.order')))
      .catch(() => message.error(I18n.t('api.errors.securityMeasure.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      securityMeasures,
      securityMeasure
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getSecurityMeasureData: this.getSecurityMeasureData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateSecurityMeasure: this.askCreateSecurityMeasure,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateSecurityMeasure: this.askUpdateSecurityMeasure,
      askUpdateManySecurityMeasures: this.askUpdateSecurityMeasure,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveSecurityMeasure: this.askArchiveSecurityMeasure,
      askArchiveManySecurityMeasures: this.askArchiveManySecurityMeasures,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteSecurityMeasure: this.askDeleteSecurityMeasure,
      askDeleteManySecurityMeasures: this.askDeleteManySecurityMeasures,

      // RÃ©initialisation du mot de passe d'un securityMeasure
      askResetPasswordSecurityMeasure: this.askResetPasswordSecurityMeasure
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteSecurityMeasure

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={securityMeasures}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateSecurityMeasure'
              ? this.updateSecurityMeasure
              : this.createSecurityMeasure
          }
          visible={includes(
            ['createSecurityMeasure', 'updateSecurityMeasure'],
            openedModal
          )}
          data={
            includes(['transition', 'updateSecurityMeasure'], openedModal)
              ? securityMeasure
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(SecurityMeasuresConstructor, 'defaultProps', {})

  return {
    securityMeasures: defaultTo(
      get(state, 'securityMeasure.items'),
      defaultProps.securityMeasures
    ),
    securityMeasure: defaultTo(
      get(state, 'securityMeasure.item'),
      defaultProps.securityMeasure
    ),
    isGathering:
      defaultTo(
        get(state, 'securityMeasure.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'securityMeasure.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'securityMeasure.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...securityMeasureActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SecurityMeasuresConstructor)
