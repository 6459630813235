import { createResource } from 'redux-rest-resource'
import {
  remove,
  includes,
  map,
  get,
  cloneDeep,
  toNumber,
  defaultTo
} from 'lodash'

import { baseURL } from './'

export const { types, actions, rootReducer } = createResource({
  name: 'permit',
  pluralName: 'permits',
  url: `${baseURL}/permits`,
  credentials: 'include',
  actions: {
    fetch: {
      method: 'GET',
      gerundName: 'fetching',
      url: '.',
      reduce: ({ items = [], ...state }, { status, ...response }) => {
        const isFetching = status === 'pending'
        let totalPermits = 0

        if (status === 'resolved') {
          items = response.body.results
          totalPermits = response.body.count
        }

        return { ...state, items, isFetching, totalPermits }
      }
    },
    get: { method: 'GET', gerundName: 'getting', url: './:id' },
    update: {
      method: 'PUT',
      gerundName: 'updating',
      url: './:id',
      assignResponse: true,
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    create: {
      method: 'POST',
      gerundName: 'creating',
      url: '.'
    },
    addOperatingModeFile: {
      method: 'PATCH',
      gerundName: 'uploading operating mode file',
      url: './operatingmodefile',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    },
    fetchChangelog: {
      method: 'GET',
      gerundName: 'fetching',
      url: './:id/changelog',
      reducer: ({ item, ...state }, { body: changelog, status }) => {
        const isFetching = status === 'pending'

        if (status === 'resolved') {
          item.changelog = changelog
        }

        return { ...state, item, isFetching }
      }
    },
    downloadRevisionPDF: {
      method: 'GET',
      gerundName: 'downloading',
      url: './:permitID/revisions/:revisionID/export',
      reduce: (state, { status }) => ({
        ...state,
        isDownloading: status === 'pending'
      })
    },
    close: {
      method: 'PATCH',
      gerundName: 'closing',
      url: './:id/close',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    control: {
      method: 'PATCH',
      gerundName: 'closing',
      url: './:id/control',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    rate: {
      method: 'PATCH',
      gerundName: 'rating',
      url: './:id/rate',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    createScan: {
      method: 'POST',
      gerundName: 'scans',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      url: './:id/scans'
    },
    sign: {
      method: 'PATCH',
      gerundName: 'signing',
      url: './:id/sign',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    cancel: {
      method: 'PATCH',
      gerundName: 'cancelling',
      url: './:id/cancel',
      reduce: ({ items, item, ...state }, { body: updatedItem, status }) => {
        const isUpdating = status === 'pending'

        if (status === 'resolved') {
          item = updatedItem
          items = map(items, item =>
            get(item, 'id') === get(updatedItem, 'id') ? updatedItem : item
          )
        }

        return { ...state, items, item, isUpdating }
      }
    },
    fetchRequests: {
      method: 'GET',
      gerundName: 'fetching requests',
      url: './request',
      reduce: ({ requestsItems = [], ...state }, { status, ...response }) => {
        const isFetching = status === 'pending'
        let totalPermitsRequests = 0

        if (status === 'resolved') {
          requestsItems = response.body.results
          totalPermitsRequests = response.body.count
        }
        return { ...state, requestsItems, isFetching, totalPermitsRequests }
      }
    },
    createRequest: {
      method: 'POST',
      gerundName: 'creating request',
      url: './request'
    },
    delete: {
      method: 'DELETE',
      gerundName: 'deleting',
      url: './:id',
      reduce: ({ items, ...state }, { context: deletedItem, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, {
            id: defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
          })
        }

        return { ...state, items: cloneDeep(items) }
      }
    },
    validateRequest: {
      method: 'PATCH',
      gerundName: 'validating request',
      url: './:id/validate'
    },
    deleteMany: {
      isArray: true,
      alias: 'delete',
      method: 'DELETE',
      gerundName: 'deleting',
      url: './delete',
      reduce: ({ items, ...state }, { context: deletedItems, status }) => {
        if (status === 'resolved') {
          // Suppression des donnÃ©es archivÃ©es du store
          remove(items, ({ id }) =>
            includes(
              map(deletedItems, deletedItem =>
                defaultTo(get(deletedItem, 'id'), toNumber(deletedItem))
              ),
              toNumber(id)
            )
          )
        }

        return { ...state, items: cloneDeep(items) }
      }
    }
  }
})
