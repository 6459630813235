// Libraries
import I18n from 'i18next'
import { createNavigator, StackRouter } from '@react-navigation/core'

// Utils
import { prepareStack } from 'Navigation/Utils'

// Layout
import { AuthenticatedLayout } from 'Navigation/Layout'

// Views
import Users from 'Views/Users'
import Risks from 'Views/Risks'
import Scans from 'Views/Scans'
import Permits from 'Views/Permits'
import Profile from 'Views/Profile'
import Materials from 'Views/Materials'
import Habilitations from 'Views/Habilitations'
import Operations from 'Views/Operations'
import FireSources from 'Views/FireSources'
import SecurityMeasures from 'Views/SecurityMeasures'
import AssociablePermits from 'Views/AssociablePermits'
import Companies from 'Views/Companies'
import Supervisors from 'Views/Supervisors'
import PreventiveMeasures from 'Views/PreventiveMeasures'
import Dashboard from 'Views/Dashboard'
import CapacitySecurityMeasures from 'Views/CapacitySecurityMeasures'
import CapacityEquipment from 'Views/CapacityEquipment'
import CapacityAdditionalProvision from 'Views/CapacityAdditionalProvision'
import SearchSecurityMeasures from 'Views/SearchSecurityMeasures'
import SearchPredictableRisks from 'Views/SearchPredictableRisks'
import CleaningEquipment from 'Views/CleaningEquipment'
import CleaningAdditionalProvision from 'Views/CleaningAdditionalProvision'
import CleaningCondition from 'Views/CleaningCondition'

// Stacks
export const Stack = StackRouter(
  prepareStack({
    Risks: {
      screen: Risks,
      path: 'risks/:id?',
      navigationOptions: {
        title: I18n.t('pages.risks.title')
      }
    },
    Materials: {
      screen: Materials,
      path: 'materials/:id?',
      navigationOptions: {
        title: I18n.t('pages.materials.title')
      }
    },
    Habilitations: {
      screen: Habilitations,
      path: 'habilitations/:id?',
      navigationOptions: {
        title: I18n.t('pages.habilitations.title')
      }
    },
    PreventiveMeasures: {
      screen: PreventiveMeasures,
      path: 'preventive-measures/:id?',
      navigationOptions: {
        title: I18n.t('pages.preventiveMeasures.title')
      }
    },
    SecurityMeasures: {
      screen: SecurityMeasures,
      path: 'security-measures/:id?',
      navigationOptions: {
        title: I18n.t('pages.securityMeasures.title')
      }
    },
    CapacitySecurityMeasures: {
      screen: CapacitySecurityMeasures,
      path: 'capacity-security-measures/:id?',
      navigationOptions: {
        title: I18n.t('pages.securityMeasures.title')
      }
    },
    CapacityEquipment: {
      screen: CapacityEquipment,
      path: 'capacity-equipments/:id?',
      navigationOptions: {
        title: I18n.t('pages.equipment.title')
      }
    },
    CapacityAdditionalProvision: {
      screen: CapacityAdditionalProvision,
      path: 'capacity-additional-provisions/:id?',
      navigationOptions: {
        title: I18n.t('pages.additionalProvision.title')
      }
    },
    SearchSecurityMeasures: {
      screen: SearchSecurityMeasures,
      path: 'search-security-measures/:id?',
      navigationOptions: {
        title: I18n.t('pages.securityMeasures.title')
      }
    },
    SearchPredictableRisks: {
      screen: SearchPredictableRisks,
      path: 'search-predictable-risks/:id?',
      navigationOptions: {
        title: I18n.t('pages.predictableRisks.title')
      }
    },
    CleaningCondition: {
      screen: CleaningCondition,
      path: 'cleaning-condition/:id?',
      navigationOptions: {
        title: I18n.t('pages.condition.title')
      }
    },
    CleaningEquipment: {
      screen: CleaningEquipment,
      path: 'cleaning-equipment/:id?',
      navigationOptions: {
        title: I18n.t('pages.equipment.title')
      }
    },
    CleaningAdditionalProvision: {
      screen: CleaningAdditionalProvision,
      path: 'cleaning-additional-provisions/:id?',
      navigationOptions: {
        title: I18n.t('pages.additionalProvision.title')
      }
    },
    FireSources: {
      screen: FireSources,
      path: 'fire-sources/:id?',
      navigationOptions: {
        title: I18n.t('pages.fireSources.title')
      }
    },
    Operations: {
      screen: Operations,
      path: 'operations/:id?',
      navigationOptions: {
        title: I18n.t('pages.operations.title')
      }
    },
    AssociablePermits: {
      screen: AssociablePermits,
      path: 'associable-permits/:id?',
      navigationOptions: {
        title: I18n.t('pages.associablePermits.title')
      }
    },
    Companies: {
      screen: Companies,
      path: 'companies/:id?',
      navigationOptions: {
        title: I18n.t('pages.companies.title')
      }
    },
    MyCompany: {
      screen: Companies,
      path: 'companies/:id',
      navigationOptions: {
        title: I18n.t('pages.companies.title')
      }
    },
    Supervisors: {
      screen: Supervisors,
      path: 'supervisors/:id?',
      navigationOptions: {
        title: I18n.t('pages.supervisor.title')
      }
    },
    Users: {
      screen: Users,
      path: 'users/:id?',
      navigationOptions: {
        title: I18n.t('pages.users.title')
      }
    },
    Scans: {
      screen: Scans,
      path: 'scans/:id?',
      navigationOptions: {
        title: I18n.t('pages.scans.title')
      }
    },
    Permits: {
      screen: Permits,
      path: 'permits/:id?',
      navigationOptions: {
        title: I18n.t('pages.permits.title')
      }
    },
    Profile: {
      screen: Profile,
      path: 'profile',
      navigationOptions: {
        title: I18n.t('pages.profile.title')
      }
    },
    Dashboard: {
      screen: Dashboard,
      path: 'dashboard',
      navigationOptions: {
        title: I18n.t('pages.dashboard.title')
      }
    }
  }),
  {
    initialRouteName: 'Dashboard'
  }
)

// Navigators
const navigationConfig = {}
const Navigator = createNavigator(AuthenticatedLayout, Stack, navigationConfig)

export default Navigator
