// Libraries
import React, { Component } from 'react'
import { includes } from 'lodash'
import PropTypes from 'prop-types'

// Styles
import './Anchor.less'

class Anchor extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired
  }

  static defaultProps = {
    onClick: () => {},
    children: null
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  onKeyDown = event => {
    const { onClick } = this.props
    const keys = ['enter', 13]

    if (
      includes(keys, event.key) ||
      includes(keys, event.code) ||
      includes(keys, event.which)
    ) {
      onClick(event)
    }
  }

  render() {
    const { onClick, children, className, ...restProps } = this.props

    return (
      <span
        tabIndex={0}
        role='link'
        className={`${className} anchor`}
        onClick={onClick}
        onKeyDown={this.onKeyDown}
        {...restProps}
      >
        {children}
      </span>
    )
  }
}

export default Anchor
