// Libraries
import I18n from 'i18next'
import { Button } from 'antd'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { get, defaultTo, isNil } from 'lodash'

// Styles
import './ExceptionScreen.less'

class ExceptionScreen extends Component {
  static propTypes = {
    error: PropTypes.object
  }

  static defaultProps = {
    error: {}
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidMount = () => {
    const { error } = this.props
    const code = get(error, 'code')

    if (isNil(code)) {
      window.location.href = '/'
    }
  }

  render() {
    const { error } = this.props

    const body = defaultTo(get(error, 'body'), '')
    const code = defaultTo(get(error, 'code'), 0)

    return (
      <main className='screen exception'>
        <div className='ant-exception-exception'>
          <div className='ant-exception-error'>
            <div className='ant-exception-imgBlock'>
              <div
                className='ant-exception-imgEle'
                style={{
                  backgroundImage: `url(${I18n.t(
                    `api.errors.exceptions.${code}.img`
                  )})`
                }}
              />
            </div>
            <div className='ant-exception-content'>
              <h1>{I18n.t(`api.errors.exceptions.${code}.title`)}</h1>
              <div className='ant-exception-desc'>
                {I18n.t(`api.errors.exceptions.${code}.desc`)}
              </div>
              <div className='ant-exception-actions'>
                <a to='/' href='/'>
                  <Button type='primary'>{I18n.t('common.back')}</Button>
                </a>
              </div>
            </div>
          </div>

          {/* Affichage des dÃ©tails de l'erreur en mode dÃ©veloppement */}
          {process.env.NODE_ENV === 'development' && (
            <div className='ant-exception-error-details'>{body}</div>
          )}
        </div>
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(ExceptionScreen, 'defaultProps', {})

  return {
    error: defaultTo(get(state, 'startup.error'), defaultProps.error)
  }
}

export default connect(mapStateToProps)(ExceptionScreen)
