// Libraries
import React from 'react'
import { isEmpty, isNil } from 'lodash'
import moment from 'moment'
import { Tag } from 'antd'

const specs = {
  width: 620,
  title: {
    update: 'pages.habilitations.modal.update.single.title',
    create: 'pages.habilitations.modal.create.single.title'
  },
  okText: {
    update: 'pages.habilitations.modal.update.single.okText',
    create: 'pages.habilitations.modal.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  rows: {
    employees: {
      title: 'pages.employees.fields.name.title',
      dataIndex: 'employee',
      key: 'employees',
      formField: {
        type: 'searchSelect',
        label: 'pages.employees.fields.name.title',
        required: 'pages.employees.fields.name.requiredMessage',
        placeholder: 'pages.employees.fields.name.placeholder',
        format: employees =>
          !isNil(employees) && !isEmpty(employees)
          ? employees.id
          : undefined
      },
      render: employee => {
       return !isNil(employee) && !isEmpty(employee)
        ? (<Tag>{employee.name}</Tag>)
        : (undefined)
        },
      empty: 'common.unknown.male'
    },
    habilitations: {
      title: 'pages.employees.fields.habilitations.title',
      dataIndex: 'habilitation',
      key: 'habilitations',
      formField: {
        type: 'searchSelect',
        label: 'pages.employees.fields.habilitations.title',
        required: 'pages.employees.fields.habilitations.requiredMessage',
        placeholder: 'pages.employees.fields.habilitations.placeholder',
        format: habilitations =>
          !isNil(habilitations) && !isEmpty(habilitations)
          ? habilitations.id
          : undefined
      },
      render: habilitation => {
        return !isNil(habilitation) && !isEmpty(habilitation)
          ? (<Tag>{habilitation.name}</Tag>)
          : (undefined)
      },
      empty: 'common.unknown.male'
    },
    endDate: {
      title: 'pages.employees.fields.endValidityDate.title',
      dataIndex: 'endDate',
      key: 'endDate',
      formField: {
        type: 'datetime',
        label: 'pages.employees.fields.endValidityDate.title',
        placeholder: 'pages.employees.fields.endValidityDate.placeholder',
        format: date => moment(date)
      },
      render: date => moment(date).format('L'),
      empty: 'common.unknown.female'
    },
    proofFile: {
      title: 'pages.employees.proofFile.files.title',
      dataIndex: 'proofFile',
      key: 'proofFile',
      formField: {
        type: 'upload',
        label: 'pages.employees.fields.proofFile.title',
        placeholder: 'pages.employees.fields.proofFile.placeholder',
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
