// Libraries
import React from 'react'
import I18n from 'i18next'
import { Tag, Icon, Tooltip } from 'antd'
import moment from 'moment'
import { get, map, isNil, isEmpty } from 'lodash'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.update.single.title',
    create: 'pages.permits.modal.create.single.title'
  },
  okText: {
    update: 'pages.permits.modal.update.single.okText',
    create: 'pages.permits.modal.create.single.okText'
  },
  actions: {
    update: {
      buttonType: 'primary'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    }
  ],
  tabs: {
    changelog: {},
    scans: {}
  },
  validateEachSteps: false,
  rows: {
    preventionPlan: {
      title: 'pages.permits.fields.preventionPlan.title',
      dataIndex: 'preventionPlan',
      key: 'preventionPlan',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.preventionPlan.title',
        required: 'pages.permits.fields.preventionPlan.requiredMessage',
        placeholder: 'pages.permits.fields.preventionPlan.placeholder',
        questionMark: 'pages.permits.fields.preventionPlan.questionMark'
      },
      empty: 'common.unknown.female'
    },
    alerts: {
      title: 'pages.permits.fields.alerts.title',
      dataIndex: 'alerts',
      key: 'alerts',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.alerts.title',
        placeholder: 'pages.permits.fields.alerts.placeholder',
      },
      render: alerts =>
        !isNil(alerts) && !isEmpty(alerts)
          ? map(alerts, (alert, index) => (
            <Tooltip title={I18n.t('pages.permits.fields.alerts.' + alert)}>
              <Tag key={index} color={'red'}>
                <Icon type='warning' /> : ''
                {alert}
              </Tag>
            </Tooltip>
            ))
          : undefined,
      empty: 'common.unknown.male'
    },
    validityPeriod: {
      title: 'pages.permits.fields.validityPeriod.title',
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      formField: {
        style: { width: '100%' },
        visibility: {
          step: 'main'
        },
        type: 'period',
        ranges: {
          // Aujourd'hui
          [I18n.t('common.today')]: [moment(), moment()],
          // Demain
          [I18n.t('common.tomorrow')]: [
            moment().add(1, 'days'),
            moment().add(1, 'days')
          ],
          // Semaine
          [I18n.t('common.week')]: [moment(), moment().day(5)],
          // Semaine prochaine
          [I18n.t('common.nextWeek')]: [
            moment()
              .startOf('week')
              .add(1, 'week'),
            moment()
              .startOf('week')
              .add(1, 'week')
              .day(5)
          ]
        },
        label: 'pages.permits.fields.validityPeriod.title',
        required: 'pages.permits.fields.validityPeriod.requiredMessage',
        placeholder: [
          'pages.permits.fields.validityPeriod.placeholder.0',
          'pages.permits.fields.validityPeriod.placeholder.1'
        ],
        questionMark: 'pages.permits.fields.validityPeriod.questionMark',
        format: period =>
          !isNil(period) && !isEmpty(period)
            ? [moment(period.startDate), moment(period.endDate)]
            : undefined
      },
      render: period =>
        !isNil(period) && !isEmpty(period)
          ? `${moment(get(period, 'startDate')).format('L')} ~ ${moment(
              get(period, 'endDate')
            ).format('L')}`
          : undefined,
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? (
          <Tag>{company.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionMark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    location: {
      title: 'pages.permits.fields.location.title',
      dataIndex: 'location',
      key: 'location',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.location.title',
        required: 'pages.permits.fields.location.requiredMessage',
        placeholder: 'pages.permits.fields.location.placeholder',
        questionMark: 'pages.permits.fields.location.questionMark'
      },
      empty: 'common.unknown.female'
    },
    associatedPermits: {
      title: 'pages.permits.fields.associatedPermits.title',
      dataIndex: 'associatedPermits',
      key: 'associatedPermits',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.associatedPermits.title',
        placeholder: 'pages.permits.fields.associatedPermits.placeholder',
        format: permits =>
          !isNil(permits) && !isEmpty(permits) ? map(permits, 'id') : undefined
      },
      render: permits =>
        !isNil(permits) && !isEmpty(permits)
          ? map(permits, (permit, index) => (
              <Tag key={index}>{get(permit, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedAssociatedPermits: {
      title: 'pages.permits.fields.associatedPermits.unlisted.title',
      dataIndex: 'unlistedAssociatedPermits',
      key: 'unlistedAssociatedPermits',
      formField: {
        visibility: {
          step: 'main',
          fieldsValues: {
            associatedPermits: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.associatedPermits.unlisted.title',
        required:
          'pages.permits.fields.associatedPermits.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.associatedPermits.unlisted.placeholder',
        format: permits =>
          !isNil(permits) && !isEmpty(permits) ? map(permits, 'id') : undefined
      },
      render: permits =>
        !isNil(permits) && !isEmpty(permits)
          ? map(permits, (permit, index) => (
              <Tag key={index}>{get(permit, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    workingHours: {
      title: 'pages.permits.fields.workingHours.title',
      dataIndex: 'workingHours',
      key: 'workingHours',
      formField: {
        visibility: {
          step: 'main'
        },
        mask: '**:** - **:** ~ **:** - **:**',
        label: 'pages.permits.fields.workingHours.title',
        required: 'pages.permits.fields.workingHours.requiredMessage',
        placeholder: 'pages.permits.fields.workingHours.placeholder',
        pattern: {
          pattern:
            '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] - (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] ~ (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] - (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$',
          message: 'pages.permits.fields.workingHours.patternMessage'
        }
      },
      empty: 'common.unknown.female'
    },
    supervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'supervisor',
      key: 'supervisor',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        format: supervisor => {
          return !isNil(supervisor) && !isEmpty(supervisor)
            ? supervisor.id
            : undefined
        }
      },
      render: supervisor => {
        return !isNil(supervisor) && !isEmpty(supervisor) ? (
          <Tag>{supervisor.name}</Tag>
        ) : (
          undefined
        )
      },
      empty: 'common.unknown.male'
    },
    risks: {
      title: 'pages.permits.fields.risks.title',
      dataIndex: 'risks',
      key: 'risks',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.risks.title',
        required: 'pages.permits.fields.risks.requiredMessage',
        placeholder: 'pages.permits.fields.risks.placeholder',
        format: risks =>
          !isNil(risks) && !isEmpty(risks) ? map(risks, 'id') : undefined
      },
      render: risks =>
        !isNil(risks) && !isEmpty(risks)
          ? map(risks, (risk, index) => (
              <Tag key={index}>{get(risk, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedRisks: {
      title: 'pages.permits.fields.risks.unlisted.title',
      dataIndex: 'unlistedRisks',
      key: 'unlistedRisks',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            risks: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.risks.unlisted.title',
        required: 'pages.permits.fields.risks.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.risks.unlisted.placeholder',
        format: risks =>
          !isNil(risks) && !isEmpty(risks) ? map(risks, 'id') : undefined
      },
      render: risks =>
        !isNil(risks) && !isEmpty(risks)
          ? map(risks, (risk, index) => (
              <Tag key={index}>{get(risk, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    materials: {
      title: 'pages.permits.fields.materials.title',
      dataIndex: 'materials',
      key: 'materials',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.materials.title',
        required: 'pages.permits.fields.materials.requiredMessage',
        placeholder: 'pages.permits.fields.materials.placeholder',
        format: materials =>
          !isNil(materials) && !isEmpty(materials)
            ? map(materials, 'id')
            : undefined
      },
      render: materials =>
        !isNil(materials) && !isEmpty(materials)
          ? map(materials, (material, index) => (
              <Tag key={index}>{get(material, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedMaterials: {
      title: 'pages.permits.fields.materials.unlisted.title',
      dataIndex: 'unlistedMaterials',
      key: 'unlistedMaterials',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            materials: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.materials.unlisted.title',
        required: 'pages.permits.fields.materials.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.materials.unlisted.placeholder',
        format: materials =>
          !isNil(materials) && !isEmpty(materials)
            ? map(materials, 'id')
            : undefined
      },
      render: materials =>
        !isNil(materials) && !isEmpty(materials)
          ? map(materials, (material, index) => (
              <Tag key={index}>{get(material, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    preventiveMeasures: {
      title: 'pages.permits.fields.preventiveMeasures.title',
      dataIndex: 'preventiveMeasures',
      key: 'preventiveMeasures',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.preventiveMeasures.title',
        required: 'pages.permits.fields.preventiveMeasures.requiredMessage',
        placeholder: 'pages.permits.fields.preventiveMeasures.placeholder',
        format: preventiveMeasures =>
          !isNil(preventiveMeasures) && !isEmpty(preventiveMeasures)
            ? map(preventiveMeasures, 'id')
            : undefined
      },
      render: preventiveMeasures =>
        !isNil(preventiveMeasures) && !isEmpty(preventiveMeasures)
          ? map(preventiveMeasures, (preventiveMeasure, index) => (
              <Tag key={index}>{get(preventiveMeasure, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedPreventiveMeasures: {
      title: 'pages.permits.fields.preventiveMeasures.unlisted.title',
      dataIndex: 'unlistedPreventiveMeasures',
      key: 'unlistedPreventiveMeasures',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            preventiveMeasures: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.preventiveMeasures.unlisted.title',
        required:
          'pages.permits.fields.preventiveMeasures.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.preventiveMeasures.unlisted.placeholder',
        format: preventiveMeasures =>
          !isNil(preventiveMeasures) &&
          !isEmpty(preventiveMeasures)
            ? map(preventiveMeasures, 'id')
            : undefined
      },
      render: preventiveMeasures =>
        !isNil(preventiveMeasures) &&
        !isEmpty(preventiveMeasures)
          ? map(
            preventiveMeasures,
              (preventiveMeasure, index) => (
                <Tag key={index}>{get(preventiveMeasure, 'name')}</Tag>
              )
            )
          : null,
      empty: 'common.unknown.male'
    },
    operations: {
      title: 'pages.permits.fields.operations.title',
      dataIndex: 'operations',
      key: 'operations',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.operations.title',
        required: 'pages.permits.fields.operations.requiredMessage',
        placeholder: 'pages.permits.fields.operations.placeholder',
        format: operations =>
          !isNil(operations) && !isEmpty(operations)
            ? map(operations, 'id')
            : undefined
      },
      render: operations =>
        !isNil(operations) && !isEmpty(operations)
          ? map(operations, (operation, index) => (
              <Tag key={index}>{get(operation, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    unlistedOperations: {
      title: 'pages.permits.fields.operations.unlisted.title',
      dataIndex: 'unlistedOperations',
      key: 'unlistedOperations',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            operations: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.operations.unlisted.title',
        required: 'pages.permits.fields.operations.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.operations.unlisted.placeholder',
        format: operations =>
          !isNil(operations) && !isEmpty(operations)
            ? map(operations, 'id')
            : undefined
      },
      render: operations =>
        !isNil(operations) && !isEmpty(operations)
          ? map(operations, (operation, index) => (
              <Tag key={index}>{get(operation, 'name')}</Tag>
            ))
          : null,
      empty: 'common.unknown.male'
    },
    procedure: {
      title: 'pages.permits.fields.procedure.title',
      dataIndex: 'isOperatingMode',
      key: 'isOperatingMode',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'radios',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(`pages.permits.fields.procedure.options.${bool}`),
          value: bool,
          data: bool
        })),
        label: 'pages.permits.fields.procedure.title',
        required: 'pages.permits.fields.procedure.requiredMessage',
        placeholder: 'pages.permits.fields.procedure.placeholder',
        questionMark: 'pages.permits.fields.procedure.questionMark'
      },
      render: procedure =>
        !isNil(procedure)
          ? I18n.t(`pages.permits.fields.procedure.options.${procedure}`)
          : undefined,
      empty: 'common.unknown.male'
    },
    procedureFile: {
      title: 'pages.permits.fields.procedureFile.title',
      dataIndex: 'operatingModeFile',
      key: 'operatingModeFile',
      formField: {
        type: 'upload',
        visibility: {
          step: 'analysis',
          fieldsValues: {
            isOperatingMode: true
          }
        },
        label: 'pages.permits.fields.procedureFile.title'
      },
      empty: 'common.unknown.male'
    },
    participants: {
      title: 'pages.permits.fields.participants.title',
      dataIndex: 'participants',
      key: 'participants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        notFoundContent: null,
        label: 'pages.permits.fields.participants.title',
        placeholder: 'pages.permits.fields.participants.placeholder',
        format: participant =>
          !isNil(participant) && !isEmpty(participant)
            ? map(participant, 'id')
            : undefined
      },
      render: participants =>
        !isNil(participants) && !isEmpty(participants)
          ? map(participants, (participant, index) => (
            <Tooltip title={!participant.isHabilitationGranted ||!participant.isAcessSecurityGranted ? I18n.t('pages.permits.fields.participants.habilitationNotGranted'): ''}>
              <Tag key={index}
              color={!participant.isHabilitationGranted ||!participant.isAcessSecurityGranted ? 'orange': 'green'}
              >
                {!participant.isHabilitationGranted ||!participant.isAcessSecurityGranted ?
                      <Icon type='warning' /> : ''
                }
                {participant.name}
              </Tag>
            </Tooltip>
            ))
          : undefined,
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.companyLeader.title',
        required: 'pages.permits.fields.companyLeader.requiredMessage',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        format: leader => {
          return !isNil(leader) && !isEmpty(leader) ? leader.id : undefined
        }
      },
      render: leader => {
        return !isNil(leader) && !isEmpty(leader) ? (
          <Tag>{leader.name}</Tag>
        ) : (
          undefined
        )
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
