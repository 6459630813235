// Libraries
import { get, includes, map } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Common components
import { TableLayout } from 'Components'

// Specs
import { TableSpecs } from 'Views/Scans/Specs'

// Styles
import './Styles/List.less'

class ScansList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // Utilisateur connectÃ©
    profile: PropTypes.object,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,

    // DonnÃ©es de construction du tableau
    TableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // Utilisateur connectÃ©
    profile: {},

    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es de la vue
    dataList: []
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { loading, dataList, constructorFunctions, profile } = this.props

    TableSpecs.actions.global.create.hidden = includes(
      map(get(profile, 'roles'), 'name'),
      'READER'
    )

    // Ajout des donnÃ©es au tableau
    TableSpecs.rows = dataList

    // Ajout des actions au clic sur un ligne du tableau
    TableSpecs.onRow = (scan, id) => ({
      onClick: () =>
        get(constructorFunctions, 'handleRowClick', () => {})(scan, id)
    })

    TableSpecs.actions.selection.delete.hidden = includes(
      map(get(profile, 'roles'), 'name'),
      'READER'
    )

    // Ajout des actions multiples sur le tableau
    TableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteManyScans'
    )

    // Ajout des actions des boutons
    TableSpecs.actions.global.create.onClick = get(
      constructorFunctions,
      'askCreateScan'
    )

    return (
      <main className='screen scans scans-list'>
        <TableLayout loading={loading} {...TableSpecs} />
      </main>
    )
  }
}

export default ScansList
