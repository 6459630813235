import I18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import ReduxDetector from 'i18next-redux-languagedetector'

import moment from 'moment'
import 'moment/locale/en-gb'
import 'moment/locale/fr'

const LngDetector = new LanguageDetector()
LngDetector.addDetector(ReduxDetector)

const commonConfig = {
  interpolation: {
    // React already does escaping
    escapeValue: false
  },
  react: {
    wait: true
  },
  resources: {
    fr: {
      translation: require('./languages/fr.json')
    }
  },
  fallbackLng: 'fr',
  whitelist: ['fr']
}

// Configurations
export function configureI18nWithRedux({ redux, config }) {
  I18n.use(LngDetector).init({
    ...commonConfig,
    ...config,
    detection: {
      // order and from where user language should be detected
      order: ['redux'],

      // keys or params to lookup language from
      lookupRedux: redux.lookupRedux,
      cacheUserLanguageRedux: redux.cacheUserLanguageRedux,

      // cache user language on
      caches: ['redux'],
      excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
    }
  })

  // Locales moment js
  const locale = I18n.language || window.localStorage.i18nextLng

  // Set the locales for momentJS
  moment.locale(locale)

  return I18n
}

I18n.use(LanguageDetector).init({
  ...commonConfig,
  detection: {
    // order and from where user language should be detected
    order: [
      'querystring',
      'cookie',
      'localStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain'
    ],

    // keys or params to lookup language from
    lookupQuerystring: 'lng',
    lookupCookie: 'i18next',
    lookupLocalStorage: 'i18nextLng',
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,

    // cache user language on
    caches: ['localStorage', 'cookie'],
    excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

    // optional expire and domain for set cookie
    cookieMinutes: 10,
    cookieDomain: 'myDomain',

    // optional htmlTag with lang attribute, the default is:
    htmlTag: document.documentElement
  }
})

// Locales moment js
const locale = I18n.language || window.localStorage.i18nextLng

// Set the locales for momentJS
moment.locale(locale)

export default I18n
