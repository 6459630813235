// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as predictableRisksActions } from 'Resources/SearchPredictableRisksResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from './Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers'

const { confirm } = Modal

class SearchPredictableRisksConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    predictableRisk: PropTypes.object,
    predictableRisks: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    predictableRisk: {},
    predictableRisks: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchPredictableRisksData()

    if (this.getContext() === 'single') {
      this.getPredictableRiskData().then(this.askUpdatePredictableRisk)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchPredictableRisksData = () => {
    this.props.actions.fetchSearchPredictableRisks().catch(() => {
      message.error(I18n.t('api.errors.risk.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getPredictableRiskData = (
    predictableRisk = this.props.navigation.getParam('id')
  ) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getSearchPredictableRisk(predictableRisk)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.risk.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyPredictableRisks = (predictableRisk, clearTableSelections) => {
    this.props.actions
      .archiveManySearchPredictableRisks(predictableRisk)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.risk.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archivePredictableRisk = predictableRisk => {
    this.props.actions
      .archiveSearchPredictableRisk(predictableRisk)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.risk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyPredictableRisks = (predictableRisk, clearTableSelections) => {
    this.props.actions
      .archiveSearchPredictableRisks(predictableRisk)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.risk.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deletePredictableRisk = predictableRisk => {
    this.props.actions
      .archiveSearchPredictableRisk(predictableRisk)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.risk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.risk.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un predictableRisk
   */
  updatePredictableRisk = (updatedPredictableRisk, originalPredictableRisk) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateSearchPredictableRisk({
          id: get(originalPredictableRisk, 'id'),
          ...updatedPredictableRisk
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchPredictableRisksData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.risk.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.risk.update'))
        })
    })

  /**
   * CrÃ©ation d'un predictableRisk
   */
  createPredictableRisk = createdPredictableRisk =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createSearchPredictableRisk(createdPredictableRisk)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.risk.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.risk.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyPredictableRisks = (...params) => {
    confirm({
      title: I18n.t('pages.predictableRisks.modal.archive.multiple.title'),
      content: I18n.t('pages.predictableRisks.modal.archive.multiple.content'),
      okText: I18n.t('pages.predictableRisks.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.predictableRisks.modal.archive.multiple.cancelText'
      ),
      onOk: () => {
        this.archivePredictableRisk(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchivePredictableRisk = () => {
    const { predictableRisk } = this.props

    confirm({
      title: I18n.t('pages.predictableRisks.modal.archive.single.title'),
      content: I18n.t('pages.predictableRisks.modal.archive.single.content'),
      okText: I18n.t('pages.predictableRisks.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.predictableRisks.modal.archive.single.cancelText'
      ),
      onOk: () => {
        this.archivePredictableRisk(predictableRisk)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyPredictableRisks = (...params) => {
    confirm({
      title: I18n.t('pages.predictableRisks.modal.delete.multiple.title'),
      content: I18n.t('pages.predictableRisks.modal.delete.multiple.content'),
      okText: I18n.t('pages.predictableRisks.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.predictableRisks.modal.delete.multiple.cancelText'
      ),
      onOk: () => {
        this.deleteManyPredictableRisks(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeletePredictableRisk = () => {
    const { predictableRisk } = this.props

    confirm({
      title: I18n.t('pages.predictableRisks.modal.delete.single.title'),
      content: I18n.t('pages.predictableRisks.modal.delete.single.content'),
      okText: I18n.t('pages.predictableRisks.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.predictableRisks.modal.delete.single.cancelText'
      ),
      onOk: () => {
        this.deletePredictableRisk(predictableRisk)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdatePredictableRisk = () => {
    this.setState({ openedModal: 'updatePredictableRisk' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreatePredictableRisk = () => {
    this.setState({ openedModal: 'createPredictableRisk' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updatePredictableRisk') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = predictableRisk => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(predictableRisk, 'id') })

    this.getPredictableRiskData(predictableRisk)
      .then(() =>
        // Ouverture de la modale
        this.askUpdatePredictableRisk()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderSearchPredictableRisk(formattedOrderedData)
      .then(() => message.success(I18n.t('api.success.risk.order')))
      .catch(() => message.error(I18n.t('api.errors.risk.order')))
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      predictableRisks,
      predictableRisk
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getPredictableRiskDate: this.getPredictableRiskData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreatePredictableRisk: this.askCreatePredictableRisk,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdatePredictableRisk: this.askUpdatePredictableRisk,
      askUpdateManyPredictableRisk: this.askUpdatePredictableRisk,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchivePredictableRisk: this.askArchivePredictableRisk,
      askArchiveManyPredictableRisks: this.askArchiveManyPredictableRisks,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeletePredictableRisk: this.askDeletePredictableRisk,
      askDeleteManyPredictableRisks: this.askDeleteManyPredictableRisks
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeletePredictableRisk

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={predictableRisks}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updatePredictableRisk'
              ? this.updatePredictableRisk
              : this.createPredictableRisk
          }
          visible={includes(
            ['createPredictableRisk', 'updatePredictableRisk'],
            openedModal
          )}
          data={
            includes(['transition', 'updatePredictableRisk'], openedModal)
              ? predictableRisk
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(
    SearchPredictableRisksConstructor,
    'defaultProps',
    {}
  )

  return {
    predictableRisks: defaultTo(
      get(state, 'searchPredictableRisk.items'),
      defaultProps.predictableRisks
    ),
    predictableRisk: defaultTo(
      get(state, 'searchPredictableRisk.item'),
      defaultProps.predictableRisk
    ),
    isGathering:
      defaultTo(
        get(state, 'searchPredictableRisk.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'searchPredictableRisk.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'searchPredictableRisk.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...predictableRisksActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchPredictableRisksConstructor)
