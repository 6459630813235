// Libraries
import { channel } from 'redux-saga'
import { get, has } from 'lodash'
import { put, take } from 'redux-saga/effects'

// Actions

// Channels
const notificationChannel = channel()

export function handleMessage(action) {
  if (has(action, 'content')) {
    try {
      const content = JSON.parse(get(action, 'content', {}))

      switch (content.updateType) {
        default:
          break
      }
    } catch (e) {
      // statements
      console.log(e)
    }
  }
}

export function connected(action) {}

export function* disconnected(action) {}

export function* watchNotificationChannel() {
  while (true) {
    const action = yield take(notificationChannel)
    yield put(action)
  }
}
