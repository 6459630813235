// Libraries
import { defaultTo, get, has, some } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

// Common components
import { TableLayout } from 'Components'

// Specs
import { TableSpecs } from 'Views/Users/Specs'

// Styles
import './Styles/List.less'
import { connect } from 'react-redux'

class UsersList extends Component {
  static propTypes = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: PropTypes.bool,

    // DonnÃ©es supplÃ©mentaires
    profile: PropTypes.object,

    // DonnÃ©es de la vue
    dataList: PropTypes.array,

    // DonnÃ©es de construction du tableau
    TableSpecs: PropTypes.object,

    // Fonctions hÃ©ritÃ©s du constructeur
    constructorFunctions: PropTypes.objectOf(PropTypes.func)
  }

  static defaultProps = {
    // TÃ©moin d'approvisionnement des donnÃ©es
    loading: true,

    // DonnÃ©es supplÃ©mentaires
    profile: {},

    // DonnÃ©es de la vue
    dataList: []
  }

  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    const { loading, dataList, constructorFunctions } = this.props

    // Ajout des donnÃ©es au tableau
    TableSpecs.rows = dataList

    // Ajout des actions au clic sur un ligne du tableau
    TableSpecs.onRow = (user, id) => ({
      onClick: () =>
        get(constructorFunctions, 'handleRowClick', () => {})(user, id)
    })

    // Ajout des actions multiples sur le tableau
    TableSpecs.actions.selection.delete.onClick = get(
      constructorFunctions,
      'askDeleteManyUsers'
    )
    TableSpecs.actions.selection.disable.onClick = get(
      constructorFunctions,
      'askDisableManyUsers'
    )
    TableSpecs.actions.selection.enable.onClick = get(
      constructorFunctions,
      'askEnableManyUsers'
    )

    // Ajout des actions des boutons
    const userRoles = this.props.profile.roles
    if (
      some(userRoles, role => has(role, 'name') && role.name === 'SUPERADMIN')
    ) {
      TableSpecs.actions.global.create.items[0].onClick = get(
        constructorFunctions,
        'askCreateUser'
      )
      TableSpecs.actions.global.create.items[1].onClick = get(
        constructorFunctions,
        'askCreateCompanyUser'
      )
    }

    if (
      !some(userRoles, role => has(role, 'name') && role.name === 'SUPERADMIN')
    ) {
      delete TableSpecs.actions.global.create.items
      TableSpecs.actions.global.create.onClick = get(
        constructorFunctions,
        'askCreateCompanyUser'
      )
    }

    return (
      <main className='screen users users-list'>
        <TableLayout loading={loading} {...TableSpecs} />
      </main>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(UsersList, 'defaultProps', {})

  return {
    profile: defaultTo(get(state, 'profile.item'), defaultProps.profile)
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsersList)
