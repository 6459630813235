// Libraries
import I18n from 'i18next'
import moment from 'moment'
import { isEmpty, isNil, map } from 'lodash'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.update.single.title',
    create: 'pages.permits.modal.create.single.title',
    duplicate: 'pages.permits.modal.duplicate.title',
    request: 'pages.permits.modal.request.title'
  },
  okText: {
    update: 'pages.permits.modal.update.single.okText',
    create: 'pages.permits.modal.create.single.okText',
    duplicate: 'pages.permits.modal.duplicate.okText',
    request: 'pages.permits.modal.request.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    }
  ],
  validateEachSteps: true,
  rows: {
    preventionPlan: {
      title: 'pages.permits.fields.preventionPlan.title',
      dataIndex: 'preventionPlan',
      key: 'preventionPlan',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.preventionPlan.title',
        required: 'pages.permits.fields.preventionPlan.requiredMessage',
        placeholder: 'pages.permits.fields.preventionPlan.placeholder',
        questionmark: 'pages.permits.fields.preventionPlan.questionMark'
      },
      empty: 'common.unknown.female'
    },
    validityPeriod: {
      title: 'pages.permits.fields.validityPeriod.title',
      dataIndex: 'validityPeriod',
      key: 'validityPeriod',
      formField: {
        style: { width: '100%' },
        visibility: {
          step: 'main'
        },
        type: 'period',
        formatRangeDates: 'DD/MM/YYYY HH:mm',
        showTime: true,
        ranges: {
          // Aujourd'hui
          [I18n.t('common.today')]: [moment(), moment()],
          // Demain
          [I18n.t('common.tomorrow')]: [
            moment().add(1, 'days'),
            moment().add(1, 'days')
          ],
          // Semaine
          [I18n.t('common.week')]: [moment(), moment().day(5)],
          // Semaine prochaine
          [I18n.t('common.nextWeek')]: [
            moment()
              .startOf('week')
              .add(1, 'week'),
            moment()
              .startOf('week')
              .add(1, 'week')
              .day(5)
          ]
        },
        label: 'pages.permits.fields.validityPeriod.title',
        required: 'pages.permits.fields.validityPeriod.requiredMessage',
        placeholder: [
          'pages.permits.fields.validityPeriod.placeholder.0',
          'pages.permits.fields.validityPeriod.placeholder.1'
        ],
        pattern: {
          validator: (rules, data, callback) => {
            const endingDate = data[1]
            const beginningDate = data[0]

            const difference = moment(endingDate).diff(beginningDate, 'hours')

            return difference >= 1
              ? difference <= 168
                ? callback()
                : callback(
                    I18n.t('pages.permits.fields.validityPeriod.limit.max')
                  )
              : callback(
                  I18n.t('pages.permits.fields.validityPeriod.limit.min')
                )
          }
        },
        questionmark: 'pages.permits.fields.validityPeriod.questionMark',
        format: period => {
          return !isNil(period) && !isEmpty(period)
            ? map(period, date => moment(date))
            : undefined
        }
      },
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      render: company => (!isNil(company) ? company.name : null),
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        notFoundContent: null,
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionmark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    location: {
      title: 'pages.permits.fields.location.title',
      dataIndex: 'location',
      key: 'location',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.location.title',
        required: 'pages.permits.fields.location.requiredMessage',
        placeholder: 'pages.permits.fields.location.placeholder',
        questionmark: 'pages.permits.fields.location.questionMark'
      },
      empty: 'common.unknown.female'
    },
    associatedPermits: {
      title: 'pages.permits.fields.associatedPermits.title',
      dataIndex: 'associatedPermits',
      key: 'associatedPermits',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.associatedPermits.title',
        placeholder: 'pages.permits.fields.associatedPermits.placeholder',
        format: permits =>
          !isNil(permits) && !isEmpty(permits) ? map(permits, 'id') : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedAssociatedPermits: {
      title: 'pages.permits.fields.associatedPermits.unlisted.title',
      dataIndex: 'unlistedAssociatedPermits',
      key: 'unlistedAssociatedPermits',
      formField: {
        visibility: {
          step: 'main',
          fieldsValues: {
            associatedPermits: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.associatedPermits.unlisted.title',
        required:
          'pages.permits.fields.associatedPermits.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.associatedPermits.unlisted.placeholder',
        format: permits =>
          !isNil(permits) && !isEmpty(permits) ? map(permits, 'id') : undefined
      },
      empty: 'common.unknown.male'
    },
    workingHours: {
      title: 'pages.permits.fields.workingHours.title',
      dataIndex: 'workingHours',
      key: 'workingHours',
      formField: {
        visibility: {
          step: 'main'
        },
        mask: '**:** - **:** ~ **:** - **:**',
        label: 'pages.permits.fields.workingHours.title',
        required: 'pages.permits.fields.workingHours.requiredMessage',
        placeholder: 'pages.permits.fields.workingHours.placeholder',
        initialValue: '08:00 - 12:00 ~ 14:00 - 18:00',
        pattern: {
          pattern:
            '^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] - (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] ~ (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9] - (0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$',
          message: 'pages.permits.fields.workingHours.patternMessage'
        }
      },
      empty: 'common.unknown.female'
    },
    supervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'supervisor',
      key: 'supervisor',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'searchSelect',
        notFoundContent: null,
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        render: supervisor => (!isNil(supervisor) ? supervisor.name : null),
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      empty: 'common.unknown.male'
    },
    risks: {
      title: 'pages.permits.fields.risks.title',
      dataIndex: 'risks',
      key: 'risks',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.risks.title',
        required: 'pages.permits.fields.risks.requiredMessage',
        placeholder: 'pages.permits.fields.risks.placeholder',
        format: risks =>
          !isNil(risks) && !isEmpty(risks) ? map(risks, 'id') : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedRisks: {
      title: 'pages.permits.fields.risks.unlisted.title',
      dataIndex: 'unlistedRisks',
      key: 'unlistedRisks',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            risks: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.risks.unlisted.title',
        required: 'pages.permits.fields.risks.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.risks.unlisted.placeholder',
        format: risks =>
          !isNil(risks) && !isEmpty(risks) ? map(risks, 'id') : undefined
      },
      empty: 'common.unknown.male'
    },
    materials: {
      title: 'pages.permits.fields.materials.title',
      dataIndex: 'materials',
      key: 'materials',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.materials.title',
        required: 'pages.permits.fields.materials.requiredMessage',
        placeholder: 'pages.permits.fields.materials.placeholder',
        format: materials =>
          !isNil(materials) && !isEmpty(materials)
            ? map(materials, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedMaterials: {
      title: 'pages.permits.fields.materials.unlisted.title',
      dataIndex: 'unlistedMaterials',
      key: 'unlistedMaterials',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            materials: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.materials.unlisted.title',
        required: 'pages.permits.fields.materials.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.materials.unlisted.placeholder',
        format: materials =>
          !isNil(materials) && !isEmpty(materials)
            ? map(materials, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    preventiveMeasures: {
      title: 'pages.permits.fields.preventiveMeasures.title',
      dataIndex: 'preventiveMeasures',
      key: 'preventiveMeasures',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.preventiveMeasures.title',
        required: 'pages.permits.fields.preventiveMeasures.requiredMessage',
        placeholder: 'pages.permits.fields.preventiveMeasures.placeholder',
        format: preventiveMeasures =>
          !isNil(preventiveMeasures) && !isEmpty(preventiveMeasures)
            ? map(preventiveMeasures, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedPreventiveMeasures: {
      title: 'pages.permits.fields.preventiveMeasures.unlisted.title',
      dataIndex: 'unlistedPreventiveMeasures',
      key: 'unlistedPreventiveMeasures',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            preventiveMeasures: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.preventiveMeasures.unlisted.title',
        required:
          'pages.permits.fields.preventiveMeasures.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.preventiveMeasures.unlisted.placeholder',
        format: preventiveMeasures =>
          !isNil(preventiveMeasures) && !isEmpty(preventiveMeasures)
            ? map(preventiveMeasures, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    operations: {
      title: 'pages.permits.fields.operations.title',
      dataIndex: 'operations',
      key: 'operations',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.operations.title',
        required: 'pages.permits.fields.operations.requiredMessage',
        placeholder: 'pages.permits.fields.operations.placeholder',
        format: operations =>
          !isNil(operations) && !isEmpty(operations)
            ? map(operations, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    unlistedOperations: {
      title: 'pages.permits.fields.operations.unlisted.title',
      dataIndex: 'unlistedOperations',
      key: 'unlistedOperations',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            operations: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.operations.unlisted.title',
        required: 'pages.permits.fields.operations.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.operations.unlisted.placeholder',
        format: operations =>
          !isNil(operations) && !isEmpty(operations)
            ? map(operations, 'id')
            : undefined
      },
      empty: 'common.unknown.male'
    },
    procedure: {
      title: 'pages.permits.fields.procedure.title',
      dataIndex: 'isOperatingMode',
      key: 'isOperatingMode',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'radios',
        options: map([true, false], bool => ({
          id: bool,
          label: I18n.t(`pages.permits.fields.procedure.options.${bool}`),
          value: bool,
          data: bool
        })),
        label: 'pages.permits.fields.procedure.title',
        required: 'pages.permits.fields.procedure.requiredMessage',
        placeholder: 'pages.permits.fields.procedure.placeholder',
        questionmark: 'pages.permits.fields.procedure.questionMark'
      },
      empty: 'common.unknown.male'
    },
    procedureFile: {
      title: 'pages.permits.fields.procedureFile.title',
      dataIndex: 'operatingModeFile',
      key: 'operatingModeFile',
      formField: {
        type: 'upload',
        visibility: {
          step: 'analysis',
          fieldsValues: {
            isOperatingMode: true
          }
        },
        label: 'pages.permits.fields.procedureFile.title'
      },
      empty: 'common.unknown.male'
    },
    participants: {
      title: 'pages.permits.fields.participants.title',
      dataIndex: 'participants',
      key: 'participants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.participants.title',
        placeholder: 'pages.permits.fields.participants.placeholder',
        render: participants =>
          !isNil(participants) && !isEmpty(participants)
            ? map(participants, 'name')
            : null,
        format: participants =>
          !isNil(participants) && !isEmpty(participants)
            ? map(participants, 'id')
            : undefined
      },
      render: participant =>
        !isNil(participant) && !isEmpty(participant)
          ? participant.name
          : undefined,
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        notFoundContent: null,
        label: 'pages.permits.fields.companyLeader.title',
        required: 'pages.permits.fields.companyLeader.requiredMessage',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        render: supervisor => (!isNil(supervisor) ? supervisor.name : null),
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      empty: 'common.unknown.male'
    }
  }
}

export default specs
