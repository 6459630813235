// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'
import React, { Component, Fragment } from 'react'
import {
  Form,
  Input,
  Select,
  Spin,
  AutoComplete,
  Tabs,
  Checkbox,
  Radio,
  DatePicker,
  TimePicker,
  Button,
  Dropdown,
  Icon,
  Menu,
  Upload,
  Skeleton,
  Tooltip,
  Empty
} from 'antd'
import {
  get,
  isNil,
  map,
  size,
  head,
  defaultTo,
  cloneDeep,
  forEach,
  find,
  omitBy,
  isEmpty,
  remove,
  isBoolean,
  has,
  every,
  isArray,
  includes,
  filter,
  some,
  isRegExp,
  groupBy,
  findIndex,
  toString,
  toInteger,
  isObject,
  update,
  keys,
  compact,
  isString,
  concat
} from 'lodash'
import * as Nominatim from 'nominatim-browser'
import BraftEditor from 'braft-editor'
import ColorPicker from 'braft-extensions/dist/color-picker'

// Helpers
import { createFormFields, isDeepNil, renderImageLinkPreview } from 'Helpers'

// Styles
import './FormLayout.less'

const { Item: FormItem } = Form
const { Option, OptGroup } = Select
const { Group: CheckboxGroup } = Checkbox
const { Group: RadioGroup } = Radio
const { TabPane } = Tabs
const { Dragger } = Upload
const { RangePicker } = DatePicker

let UNSAVED_FORM = null

class FormLayout extends Component {
  static propTypes = {
    // DonnÃ©es de la vue
    icon: PropTypes.string,
    data: PropTypes.object,
    rows: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    steps: PropTypes.array,
    tabs: PropTypes.object,

    // Actions disponibles
    actions: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),

    // Formulaire
    form: PropTypes.object,

    // ContrÃ´le de l'affichage
    mode: PropTypes.string,
    validateEachSteps: PropTypes.bool,

    // Modale de modification
    modalEditable: PropTypes.bool,
    modalControl: PropTypes.object,

    // TÃ©moins d'activitÃ© API
    loading: PropTypes.bool,

    // Fonctions
    onClose: PropTypes.func,
    onChange: PropTypes.func
  }

  static defaultProps = {
    // DonnÃ©es de la vue
    data: {},
    rows: {},
    steps: [],
    tabs: {},

    // Actions disponibles
    actions: {
      update: {
        buttonType: 'primary'
      }
    },

    // ContrÃ´le de l'affichage
    mode: 'tab',
    validateEachSteps: true,

    // Modale de modification
    modalEditable: true,
    modalControl: null,

    // TÃ©moins d'activitÃ© API
    loading: true,

    // Fonctions
    onClose: () => {},
    onChange: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {
      loading: true,
      isEditing: false,
      uploads: {},
      selectedAction: head(get(props, 'actions'))
    }

    // Lecteur de fichier
    this.fileReader = new FileReader()
    this.editorInstances = []
  }

  componentWillUnmount() {
    this.quitEditMode()
  }

  /**
   * Permet d'effectuer l'action demandÃ©e
   */
  handleAction = action => {
    const { data, modalControl } = this.props

    switch (get(action, 'name')) {
      // Passage en mode edition / Enregistrement des modifications
      case 'save':
      case 'update':
        this.askUpdate()
        break

      // Ouverture de la modale de modification
      case 'updateInModal':
        modalControl.open(data)
        break

      // Tous les autres cas effectuent les actions du onClick
      default:
        defaultTo(get(action, 'onClick'), () => {})(data)
    }
  }

  /**
   * Changement d'action pour le bouton d'action Ã  choix multiple
   */
  handleSelectAction = ({ key }) => {
    this.setState({
      selectedAction: key
    })
  }

  /**
   * Fermeture du mode Ã©dition
   */
  quitEditMode = () => {
    const { form } = this.props

    // Reset du formulaire
    form.resetFields()
    UNSAVED_FORM = {}

    // Sortie du mode Ã©dition
    this.setState({ isEditing: false, uploads: {} })
  }

  /**
   * Demande de mise Ã  jour de l'Ã©lÃ©ment
   * Passage en mode Ã©dition
   * ou
   * Mise Ã  jour API
   */
  askUpdate = () => {
    const { data, onChange, steps, rows } = this.props
    const { isEditing, uploads } = this.state

    // Pas encore en mode Ã©dition
    if (!isEditing) {
      this.setState({ currentStep: 0, isEditing: true })
    } else {
      this.props.form.validateFields((error, formData) => {
        if (!error) {
          const updatedFormData = cloneDeep(formData)

          // Pour chaque upload effectuÃ©s avec succÃ¨s rÃ©cupÃ©ration du fichier Ã  envoyer
          forEach(uploads, (upload, dataIndex) => {
            updatedFormData[dataIndex] = get(updatedFormData[dataIndex], 'file')
          })
          // this.setState({ currentStep: 0, isEditing: false, uploads: {} })
          if (isEditing) {
            onChange(updatedFormData, data)
              .then(() => {
                this.setState(
                  {
                    currentStep: 0,
                    uploads: {},
                    isEditing: false
                  },
                  () => {
                    UNSAVED_FORM = {}
                  }
                )
              })
              .catch(() => {
                // Vidage de la liste des uploads
                // Le navigateur supprime le lien vers les fichiers lors d'un envoi (fructueux ou non)
                this.setState({
                  uploads: {}
                })
              })
          }
        } else {
          // RÃ©cupÃ©ration du chemin complet de l'objet en erreur
          const getIndexOfError = (error, dataIndex) => {
            // Si on n'est pas encore arrivÃ© Ã  la fin de l'objet d'erreurs
            if (!has(error, 'errors')) {
              if (isObject(error)) {
                const nextIndex = head(compact(map(error, getIndexOfError)))

                dataIndex = !isNil(nextIndex)
                  ? `${dataIndex}.${nextIndex}`
                  : null
              } else {
                return null
              }
            }

            return dataIndex
          }

          // RÃ©cupÃ©ration de tous les champs en erreur
          const inErrorIndexes = map(error, getIndexOfError)

          // RÃ©cupÃ©ration de la premiÃ¨re erreur et navigation vers l'Ã©tape associÃ©e
          const inErrorStep = findIndex(steps, {
            key: get(
              find(rows, {
                dataIndex: head(inErrorIndexes)
              }),
              'formField.visibility.step'
            )
          })

          // Erreur
          // Affichage de l'Ã©tape incomplÃ¨te
          this.setState(
            {
              currentStep: defaultTo(inErrorStep, 0)
            },
            () => {
              // Scroll jusqu'Ã  l'Ã©lÃ©ment en erreur
              const element = document.getElementById(head(keys(error)))

              if (has(element, 'scrollIntoView')) {
                element.scrollIntoView()
              }
            }
          )
        }
      })
    }
  }

  /**
   * Stocke un fichier Ã  mettre en ligne
   */
  handleChangeUpload = (dataIndex, file) => {
    this.fileReader.onloadend = obj => {
      this.setState(({ uploads = {} }) => {
        uploads[dataIndex] = {
          previewURL: get(obj, 'srcElement.result'),
          fileName: get(file, 'name')
        }

        return { uploads }
      })
    }

    this.fileReader.readAsDataURL(file)
  }

  /**
   * Permet de chercher un lieu grace Ã  une API de carte (Open Street Maps)
   */
  handleSearchLocation = search => {
    Nominatim.geocode({
      q: search,
      addressdetails: false
    }).then(results => {
      this.setState({
        addressSuggestions: results
      })
    })
  }

  /**
   * Permet de passer Ã  l'Ã©tape suivante
   */
  handleChangeStep = (askedStep, previousStep) => {
    const { steps, form, rows, validateEachSteps } = this.props
    const { isEditing } = this.state
    let shouldStepChange = true

    // Si la validation de chaque Ã©tapes est demandÃ©e
    if (validateEachSteps) {
      // Si l'Ã©tape souhaitÃ©e suit celle sur laquelle nous sommes
      // VÃ©rification de toutes les Ã©tapes intermÃ©diaires
      if (isEditing && previousStep < askedStep) {
        while (shouldStepChange && previousStep < askedStep) {
          // RÃ©cupÃ©ration des champs Ã  valider lors de cette Ã©tape
          const fieldsToValidate = map(
            filter(
              rows,
              // eslint-disable-next-line
              row =>
                get(row, 'formField.visibility.step') ===
                get(steps, `${previousStep}.key`)
            ),
            'dataIndex'
          )

          // Validation des champs liÃ©s Ã  cette Ã©tape
          // eslint-disable-next-line
          form.validateFields(fieldsToValidate, error => {
            if (isNil(error)) {
              // Passage Ã  la vÃ©rification de l'Ã©tape suivante
              previousStep += 1
            } else {
              // Erreur
              // Affichage de l'Ã©tape incomplÃ¨te
              this.setState({
                currentStep: previousStep
              })

              // Annulation du passage Ã  l'Ã©tape souhaitÃ©e
              shouldStepChange = false
            }
          })
        }
      }
    }

    // Si toutes les Ã©tapes prÃ©cÃ©dentes sont valides
    shouldStepChange &&
      this.setState({
        currentStep: askedStep
      })
  }

  /**
   * Rendu des actions disponibles dans la modale
   */
  renderActions = actions => {
    const { selectedAction } = this.state

    return (
      <Menu
        selectedKeys={[defaultTo(selectedAction, get(head(actions), 'name'))]}
        className='form-layout-actions-menu'
        onClick={action => this.handleSelectAction(action, actions)}
      >
        {map(actions, ({ name }) => (
          <Menu.Item className='form-layout-actions-menu-item' key={name}>
            {/* Nom de l'action */}
            <span className='form-layout-actions-menu-item-title'>
              {I18n.t(`components.drawerLayout.actions.${name}.title`)}
            </span>

            {/* Description de l'action */}
            <span className='form-layout-actions-menu-item-description'>
              {I18n.t(`components.drawerLayout.actions.${name}.description`)}
            </span>
          </Menu.Item>
        ))}
      </Menu>
    )
  }

  /**
   * Rendu du bouton d'action Ã  choix multiples
   */
  renderActionButton = props => {
    const { button, buttonIndex } = props
    const { loading, modalEditable, modalControl } = this.props

    // RÃ©cupÃ©ration des actions multiples disponibles
    const availableActions =
      has(button, 'options') && size(get(button, 'options')) > 0
        ? cloneDeep(
            map(
              defaultTo(get(button, 'options'), []),
              ({ name, ...props }, index) => ({
                ...props,
                name: defaultTo(name, index)
              })
            )
          )
        : [
            {
              ...button,
              name: defaultTo(get(button, 'name'), buttonIndex)
            }
          ]

    // Suppression des boutons masquÃ©s
    remove(availableActions, { hidden: true })

    // Ajout de l'action update
    // dans une modale si le modalControl est donnÃ©
    if (
      find(availableActions, { name: 'update' }) &&
      !isNil(modalControl) &&
      modalEditable
    ) {
      availableActions.push({
        name: 'updateInModal',
        buttonType: 'primary'
      })
    }

    // RÃ©cupÃ©ration de sl'action sÃ©lectionnÃ©e
    const selectedAction = defaultTo(
      find(availableActions, { name: get(this.state, 'selectedAction') }),
      head(availableActions)
    )

    // Affichage sÃ©lectif si plusieurs actions sont disponibles
    return size(availableActions) > 1 ? (
      <Dropdown.Button
        key={buttonIndex}
        disabled={loading || get(selectedAction, 'disabled')}
        onClick={() => this.handleAction(selectedAction)}
        overlay={this.renderActions(availableActions)}
        placement='topRight'
        type={defaultTo(get(selectedAction, 'buttonType'), 'default')}
        icon={<Icon type='up' />}
      >
        {I18n.t(
          `components.drawerLayout.actions.${get(selectedAction, 'name')}.label`
        )}
      </Dropdown.Button>
    ) : (
      !isEmpty(availableActions) && (
        <Button
          key={buttonIndex}
          disabled={loading || get(selectedAction, 'disabled')}
          onClick={() => this.handleAction(selectedAction)}
          type={defaultTo(get(selectedAction, 'buttonType'), 'default')}
        >
          {I18n.t(
            `components.drawerLayout.actions.${get(
              selectedAction,
              'name'
            )}.label`
          )}
        </Button>
      )
    )
  }

  /**
   * Rendu d'une ligne en mode classique
   */
  renderRow = ({ key, dataIndex, render, empty, title, formField, ...row }) => {
    const { data, loading } = this.props

    // VisibilitÃ© du champ relative aux valeurs des champs
    const isFieldsValuesRelativeVisible = has(
      formField,
      'visibility.fieldsValues'
    )
      ? every(get(formField, 'visibility.fieldsValues'), (value, field) => {
          // Comparaison des valeurs attendues
          // S'il s'agit d'un tableau, vÃ©rifier qu'une des valeurs attendue correspond Ã  la valeur actuelle du champ
          // Sinon vÃ©rifier que la valeur attendue correspond Ã  la valeur actuelle du champ
          return isArray(value)
            ? includes(value, get(data, field))
            : isArray(get(data, dataIndex))
            ? includes(get(data, field), value)
            : toString(defaultTo(get(data, field), '')).match(
                isRegExp(value) ? value : `^${value}$`
              )
        })
      : true

    return (
      !get(formField, 'hidden') &&
      isFieldsValuesRelativeVisible && (
        <Skeleton
          key={key}
          className='form-layout-description-item-skeleton'
          paragraph={false}
          loading={loading}
        >
          <div className='form-layout-description-item'>
            {/* Nom de la ligne */}
            <span className='form-layout-description-item-name'>
              {I18n.t(title)}
            </span>

            {/* Valeur de la ligne */}
            <div className='form-layout-description-item-value'>
              {!isNil(render)
                ? !isNil(render(get(data, dataIndex), data))
                  ? render(get(data, dataIndex), data)
                  : I18n.t(empty)
                : isObject(get(data, dataIndex))
                ? JSON.stringify(get(data, dataIndex))
                : !isNil(get(data, dataIndex))
                ? get(data, dataIndex)
                : I18n.t(empty)}
            </div>
          </div>
        </Skeleton>
      )
    )
  }

  /**
   * Rendu d'une ligne en mode Ã©dition
   */
  renderRowEditing = ({ key, dataIndex, formField = {}, ...row }) => {
    const { form, steps, data } = this.props
    const { addressSuggestions, currentStep, uploads } = this.state
    const { getFieldDecorator } = form
    const {
      hidden,
      required,
      pattern,
      customInput,
      format,
      help,
      options,
      type,
      visibility,
      filterOptions,
      initialValue,
      displaySelectAll = false,
      decoratorOptions = {},
      ...field
    } = formField

    // Valeur initiale
    const getInitialValue = (initialValue = get(data, dataIndex)) => {
      // Formateur spÃ©cifique disponible sur la ligne ?
      if (!isNil(format)) {
        initialValue = format(get(data, dataIndex), data)
      }

      // Formatage du HTML pour WYSIWYG
      if (type === 'wysiwyg') {
        initialValue = BraftEditor.createEditorState(get(data, dataIndex))
      }

      return initialValue
    }

    // Obligatoire (bool ou dÃ©pendant des valeurs d'autres champs)
    const isRequired = isBoolean(required)
      ? required
      : has(required, 'fieldsValues')
      ? every(get(required, 'fieldsValues'), (value, field) => {
          // Comparaison des valeurs attendues
          // S'il s'agit d'un tableau, vÃ©rifier qu'une des valeurs attendue correspond Ã  la valeur actuelle du champ
          // Sinon vÃ©rifier que la valeur attendue correspond Ã  la valeur actuelle du champ
          return isArray(value)
            ? includes(value, form.getFieldValue(field))
            : toString(defaultTo(form.getFieldValue(field), '')).match(
                `^${value}$`
              )
        })
      : !isNil(required)

    // Construction des rÃ¨gles de l'input
    const rules = [
      {
        required: isRequired,
        message: I18n.t(defaultTo(get(required, 'message'), required))
      }
    ]

    // Construction des rÃ¨gles liÃ©es au pattern de l'input
    if (!isNil(pattern)) {
      rules.push({
        type: get(pattern, 'type'),
        pattern: get(pattern, 'pattern'),
        transform: get(pattern, 'transform'),
        validator: get(pattern, 'validator'),
        message: I18n.t(get(pattern, 'message'))
      })
    }

    // Construction du nom de classe
    field.className = `form-layout-form-input ${defaultTo(
      get(field, 'className'),
      ''
    )} ${has(field, 'mask') ? 'ant-input' : ''}`

    // Formatage du placeholder
    field.placeholder = isArray(field.placeholder)
      ? map(field.placeholder, placeholder => I18n.t(placeholder))
      : I18n.t(defaultTo(get(field, 'placeholder'), ''))

    // Champ optionnel
    // field.placeholder = !isRequired
    //   ? `${field.placeholder} (${I18n.t('common.optional')})`
    //   : field.placeholder

    // Filtre une option
    const filterOption = option => {
      let shouldOptionRender = true

      if (!isNil(filterOptions)) {
        const rules = get(filterOptions, 'rules')

        // VÃ©rifications des rÃ¨gles de filtre
        // DÃ©termine si l'option doit Ãªtre rendue ou nom
        shouldOptionRender = some(
          rules,
          ({ dataIndex, valueFromField }, name) =>
            // Comparaison d'une valeur de l'option Ã  celle d'un autre champ
            form.getFieldValue(valueFromField) ===
            get(option, `data.${dataIndex}`)
        )
      }

      return shouldOptionRender
    }

    // CrÃ©ation des options du sÃ©lecteur
    const createOption = ({ options = [], ...option }) =>
      !isEmpty(filter(options, filterOption)) ? (
        <OptGroup {...option}>{map(options, createOption)}</OptGroup>
      ) : (
        filterOption(option) && (
          <Option
            key={get(option, 'id')}
            disabled={defaultTo(get(option, 'disabled'), false)}
            value={get(option, 'value')}
            onClick={get(option, 'onChange')}
          >
            {I18n.t(get(option, 'label'))}
          </Option>
        )
      )

    const selectAllOptions = (
      <Option value='selectAll'>{I18n.t('common.selectAll')}</Option>
    )

    // Permet de changer de maniÃ¨re programmatique la valeur d'un select et de permettre la selection de tous les Ã©lÃ©ments en une fois
    if (displaySelectAll) {
      decoratorOptions.normalize = (value, prevValue = []) => {
        if (includes(value, 'selectAll') && !includes(prevValue, 'selectAll')) {
          value = map(options, 'id')
        }

        return value
      }
    }

    if (type === 'dragDropUpload') {
      decoratorOptions.valuePropName = 'fileList'
      decoratorOptions.getValueFromEvent = e =>
        Array.isArray(e) ? e : e && e.fileList
    }

    // Construction de l'input (automatique ou custom)
    const GeneratedInput =
      // Input tableau
      type === 'tags' ||
      (get(pattern, 'type') === 'array' && type !== 'select') ? (
        <Select mode='tags' allowClear tokenSeparators={[',']} {...field}>
          {displaySelectAll && selectAllOptions}
          {map(options, createOption)}
        </Select>
      ) : // Input password
      get(pattern, 'type') === 'password' ? (
        <Input.Password {...field} />
      ) : // Select Options
      type === 'select' ? (
        <Select allowClear {...field}>
          {displaySelectAll && selectAllOptions}
          {map(options, createOption)}
        </Select>
      ) : // Searchable Select
      type === 'searchSelect' ? (
        <Select
          filterOption={(inputValue, option) =>
            includes(
              toString(get(option, 'props.children')).toLowerCase(),
              toString(inputValue).toLowerCase()
            ) ||
            includes(
              toString(get(option, 'props.value')).toLowerCase(),
              toString(inputValue).toLowerCase()
            )
          }
          showSearch
          showArrow
          allowClear
          {...field}
        >
          {displaySelectAll && selectAllOptions}
          {map(options, createOption)}
        </Select>
      ) : // Location
      type === 'location' ? (
        <AutoComplete
          onSearch={this.handleSearchLocation}
          filterOption={false}
          notFoundContent={null}
          dataSource={map(addressSuggestions, 'display_name')}
          {...field}
        />
      ) : // Autocomplete
      type === 'autocomplete' ? (
        <AutoComplete filterOption={false} notFoundContent={null} {...field} />
      ) : // Checkboxes
      type === 'checkboxes' ? (
        <CheckboxGroup options={options} {...field} />
      ) : // Radios
      type === 'radios' ? (
        <RadioGroup options={options} {...field} />
      ) : // RangePicker
      type === 'period' ? (
        <RangePicker
          format='DD/MM/YYYY HH:mm'
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          className='calendar-form-input'
          showTime
          {...field}
        />
      ) : // Datetime
      type === 'datetime' || type === 'date' ? (
        <DatePicker
          showTime={type === 'datetime'}
          format={type === 'datetime' ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY'}
          style={{ width: '100%' }}
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          {...field}
        />
      ) : // time
      type === 'time' ? (
        <TimePicker
          format='HH:mm'
          style={{ width: '100%' }}
          popupStyle={{ width: get(this, 'measure.offsetWidth') }}
          onOpenChange={() => this.forceUpdate()}
          dropdownClassName='calendar-fluid'
          {...field}
        />
      ) : // TextArea
      type === 'textarea' ? (
        <Input.TextArea autosize={{ minRows: 2, maxRows: 6 }} {...field} />
      ) : // WYSIWYG
      type === 'wysiwyg' ? (
        <BraftEditor
          {...ColorPicker({
            theme: 'light',
            clearButtonText: I18n.t('common.clear'),
            closeButtonText: I18n.t('common.close')
          }).interceptor({
            language: 'fr'
          })}
          {...field}
        />
      ) : // Upload
      type === 'upload' ? (
        <Upload
          className='form-layout-form-upload'
          showUploadList={false}
          beforeUpload={file => {
            this.handleChangeUpload(dataIndex, file)
            return false
          }}
          multiple={false}
          {...field}
        >
          <Button className='form-layout-form-upload-button' icon='upload'>
            {I18n.t('common.import')}
          </Button>
        </Upload>
      ) : // DragDropUpload
      type === 'dragDropUpload' ? (
        <Dragger
          name={dataIndex}
          beforeUpload={file => {
            this.setState(({ uploads = {} }) => {
              uploads[dataIndex] = isNil(uploads[dataIndex])
                ? []
                : uploads[dataIndex]

              uploads[dataIndex].push({
                fileName: get(file, 'name')
              })

              return { uploads }
            })
            return false
          }}
          {...field}
          multiple
        >
          <p className='ant-upload-drag-icon'>
            <Icon type='inbox' />
          </p>
          <p className='ant-upload-text'>
            {I18n.t('components.manageModal.fields.upload.drag.instructions')}
          </p>
          <p className='ant-upload-hint'>
            {I18n.t('components.manageModal.fields.upload.drag.hint')}
          </p>
        </Dragger>
      ) : has(field, 'mask') && !isNil(get(field, 'mask')) ? (
        <InputMask
          mask={get(field, 'mask')}
          render={(ref, props) => (
            <Input ref={input => ref(input && input.input)} {...props} />
          )}
          {...field}
        />
      ) : (
        <Input {...field} />
      )

    const CustomInput = !isNil(customInput)
      ? customInput(field)
      : GeneratedInput

    // VisibilitÃ© du champ relative Ã  l'Ã©tape actuelle du formulaire
    const isStepRelativeVisible = has(visibility, 'step')
      ? get(visibility, 'step') ===
        get(steps, `${defaultTo(currentStep, 0)}.key`)
      : true

    // VisibilitÃ© du champ relative aux valeurs des champs
    const isFieldsValuesRelativeVisible = has(visibility, 'fieldsValues')
      ? every(get(visibility, 'fieldsValues'), (value, field) => {
          // Comparaison des valeurs attendues
          // S'il s'agit d'un tableau, vÃ©rifier qu'une des valeurs attendue correspond Ã  la valeur actuelle du champ
          // Sinon vÃ©rifier que la valeur attendue correspond Ã  la valeur actuelle du champ
          return isArray(value)
            ? includes(value, form.getFieldValue(field))
            : isArray(form.getFieldValue(field))
            ? includes(form.getFieldValue(field), value)
            : toString(defaultTo(form.getFieldValue(field), '')).match(
                isRegExp(value) ? value : `^${value}$`
              )
        })
      : true

    const visible = isStepRelativeVisible && isFieldsValuesRelativeVisible

    const label = (
      <Fragment>
        {I18n.t(get(formField, 'label'))}

        {/* Champ optionnel */}
        {/* !isRequired && ` (${I18n.t('common.optional')})` */}

        {/* Petite aide pour expliquer la nature du champ par exemple */}
        {has(formField, 'questionMark') && (
          <Tooltip title={I18n.t(get(formField, 'questionMark'))}>
            <Icon type='question-circle-o' />
          </Tooltip>
        )}
      </Fragment>
    )

    return (
      <FormItem
        key={key}
        help={!isNil(help) ? I18n.t(help) : undefined}
        className={`${
          visible && !hidden ? 'visible' : 'hidden'
        } form-layout-form-item`}
        label={label}
        hasFeedback
      >
        {getFieldDecorator(dataIndex, {
          preserve: true,
          rules,
          hidden: hidden || !isFieldsValuesRelativeVisible,
          initialValue: getInitialValue(initialValue),
          ...decoratorOptions
        })(CustomInput)}

        {/* Gestion de l'input d'upload */}
        {type === 'upload' && (
          <Fragment>
            {renderImageLinkPreview(
              defaultTo(
                get(uploads, `${dataIndex}.previewURL`),
                isString(form.getFieldValue(dataIndex))
                  ? form.getFieldValue(dataIndex)
                  : get(form.getFieldValue(dataIndex), 'file.name')
              ),
              defaultTo(
                get(uploads, `${dataIndex}.fileName`),
                isString(form.getFieldValue(dataIndex))
                  ? form.getFieldValue(dataIndex)
                  : get(form.getFieldValue(dataIndex), 'file.name')
              )
            )}

            {/* Clear icon */}
            {(!isNil(get(uploads, dataIndex)) ||
              !isNil(form.getFieldValue(dataIndex))) && (
              <Icon
                onClick={() => {
                  form.setFieldsValue({ [dataIndex]: null })

                  this.setState(({ uploads }) => {
                    uploads[dataIndex] = undefined

                    return { uploads }
                  })
                }}
                className='form-layout-form-input-clear-icon'
                type='close-circle'
                theme='filled'
              />
            )}
          </Fragment>
        )}
      </FormItem>
    )
  }

  /**
   * Rendu des lignes formatÃ©es
   */
  renderRows = rows => {
    const { loading } = this.props
    const { isEditing } = this.state

    // Rendu en fonction du mode (edition / affichage)
    const renderer = isEditing ? this.renderRowEditing : this.renderRow

    // Conteneurs en fonction de l'Ã©tat du panneau
    // Conteneur de chargement pour le mode Ã©dition (Skeleton sinon)
    const LoadingWrapper = isEditing && loading ? Spin : 'div'
    // Conteneur de formulaire pour le mode Ã©dition
    const FormWrapper = isEditing ? Form : 'div'

    return (
      <LoadingWrapper>
        <FormWrapper className='form-layout-form'>
          {map(rows, renderer)}

          {/* Mesureur */}
          <div
            className='manage-modal-form-measure'
            ref={ref => (this.measure = ref)}
          />
        </FormWrapper>
      </LoadingWrapper>
    )
  }

  renderStepsRows = () => {
    const { rows, steps, mode, data, tabs } = this.props
    const { isEditing } = this.state

    // Rangement des donnÃ©es en sections
    const sectionsRows = groupBy(rows, 'formField.visibility.step')

    let stepRows = isEmpty(steps)
      ? this.renderRows(rows)
      : /* Rendu des champs avec dÃ©coupage ou nom des steps */ map(
          sectionsRows,
          (rows, key) =>
            mode === 'tab' ? (
              <TabPane
                tab={I18n.t(get(find(steps, { key }), 'title'))}
                key={defaultTo(findIndex(steps, { key }), 0)}
              >
                {/* Rendu des lignes */}
                {this.renderRows(rows)}
              </TabPane>
            ) : (
              <section className='form-layout-description-group'>
                <h3 className='form-layout-description-group-title'>
                  {I18n.t(get(find(steps, { key }), 'title'))}
                </h3>

                {/* Rendu des lignes */}
                {this.renderRows(rows)}
              </section>
            )
        )

    if (!isEmpty(steps) && !isEditing) {
      stepRows = concat(
        stepRows,
        map(tabs, (tab, key) => (
          <TabPane
            key={key}
            tab={get(tab, 'title')}
            {...defaultTo(get(tab, 'props'), {})}
          >
            {defaultTo(get(tab, 'render'), () => <Empty />)({ data })}
          </TabPane>
        ))
      )
    }

    return stepRows
  }

  render() {
    const { loading, form, steps, mode, actions } = this.props
    const { currentStep, isEditing } = this.state

    const activeStepKey = defaultTo(currentStep, 0)

    // Validation du formulaire
    const formErrors = omitBy(form.getFieldsError(), isDeepNil)
    const formChanged = form.isFieldsTouched() || !isEmpty(UNSAVED_FORM)
    const isValidForm = isEmpty(formErrors) && formChanged

    const Container = mode === 'tab' && !isEmpty(steps) ? Tabs : Fragment
    const ContainerProps = mode === 'tab' &&
      !isEmpty(steps) && {
        tabPosition: 'left',
        activeKey: toString(activeStepKey),
        onTabClick: askedStepKey =>
          isEditing
            ? this.handleChangeStep(toInteger(askedStepKey), activeStepKey)
            : this.setState({
                currentStep: askedStepKey
              })
      }

    return (
      <div className='form-layout'>
        {/* Informations principales */}
        <main className='form-layout-description'>
          <Container {...ContainerProps}>{this.renderStepsRows()}</Container>
        </main>

        {/* Footer du drawer */}
        <footer className='form-layout-footer'>
          {/* Mode Ã©dition */}
          {!isEditing ? (
            <div className='form-layout-footer-buttons'>
              {/* Rendu du bouton Ã  choix multiples */}
              {map(actions, (button, buttonIndex) =>
                this.renderActionButton({
                  button,
                  buttonIndex
                })
              )}
            </div>
          ) : (
            <Fragment>
              {/* Bouton enregistrement du mode Ã©dition */}
              <Button
                loading={loading}
                type='primary'
                onClick={() =>
                  this.handleAction({ buttonType: 'primary', name: 'save' })
                }
                key='save'
                disabled={!isValidForm}
              >
                {I18n.t('components.drawerLayout.actions.save.title')}
              </Button>

              {/* Bouton annuler du mode Ã©dition */}
              <Button
                key='cancel'
                disabled={loading}
                onClick={this.quitEditMode}
              >
                {I18n.t('components.drawerLayout.actions.cancel.title')}
              </Button>
            </Fragment>
          )}
        </footer>
      </div>
    )
  }
}

export default Form.create({
  onValuesChange(props, fieldValue, fieldsValue) {
    UNSAVED_FORM = fieldsValue

    // Informe le container du composant
    defaultTo(get(props, 'onValuesChange'), () => {})(
      props,
      fieldValue,
      fieldsValue
    )
  },
  mapPropsToFields(props) {
    const defaultProps = get(FormLayout, 'defaultProps', {})
    let formData = cloneDeep(defaultTo(get(props, 'data'), defaultProps.data))

    if (!isDeepNil(UNSAVED_FORM)) {
      formData = UNSAVED_FORM
    } else {
      // Formatage des donnÃ©es en fonction des formateurs donnÃ©s
      forEach(get(props, 'rows'), (row, index) => {
        const format = get(row, 'formField.format')

        formData[get(row, 'dataIndex')] = defaultTo(
          get(formData, get(row, 'dataIndex')),
          get(row, 'formField.initialValue')
        )

        update(formData, get(row, 'dataIndex'), data => {
          // Formateur spÃ©cifique disponible sur la ligne ?
          if (!isNil(format)) {
            data = format(data, formData)
          }

          // Formatage du HTML pour WYSIWYG
          if (get(row, 'formField.type') === 'wysiwyg') {
            data = BraftEditor.createEditorState(data)
          }

          return data
        })
      })
    }

    // Retire les lignes vides qui ne sont pas utiles ici
    // formData = omitBy(formData, isNil)

    return createFormFields(Form, formData)
  }
})(FormLayout)
