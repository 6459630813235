// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component, Fragment } from 'react'
import { bindActionCreators } from 'redux'
import { message, Modal } from 'antd'
import { get, includes, defaultTo, isNil } from 'lodash'

// Ressources
import { actions as capacityAdditionalProvisionActions } from 'Resources/CapacityAdditionalProvisionResource'

// Common components
import { ManageModal } from 'Components'

// Specs
import { ModalSpecs } from './Specs'

// Ãcrans
import { List } from './Screens'
import { formatOrderedData } from 'Helpers'

const { confirm } = Modal

class CapacityAdditionalProvisionConstructor extends Component {
  static propTypes = {
    // Actions d'API
    actions: PropTypes.object,

    // DonnÃ©es de la vue
    provision: PropTypes.object,
    provisions: PropTypes.array,

    // TÃ©moin d'activitÃ© API
    isGathering: PropTypes.bool,
    isUpdating: PropTypes.bool,
    isCreating: PropTypes.bool,

    // Navigation interne
    navigation: PropTypes.shape({
      navigate: PropTypes.func.isRequired,
      getParam: PropTypes.func.isRequired,
      setParams: PropTypes.func.isRequired
    })
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // DonnÃ©es de la vue
    provision: {},
    provisions: [],

    // TÃ©moin d'activitÃ© API
    isGathering: false,
    isUpdating: false,
    isCreating: false
  }

  constructor(props) {
    super(props)

    this.state = {
      openedModal: null
    }
  }

  componentDidMount = () => {
    // RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
    this.fetchProvisionsData()

    if (this.getContext() === 'single') {
      this.getProvisionData().then(this.askUpdateProvision)
    }
  }

  /**
   * RÃ©cupÃ©ration du contexte actuel du constructeur de page
   */
  getContext = () => {
    return isNil(this.props.navigation.getParam('id')) ? 'list' : 'single'
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es des Ã©lÃ©ments de la vue
   */
  fetchProvisionsData = () => {
    this.props.actions.fetchCapacityAdditionalProvisions().catch(() => {
      message.error(I18n.t('api.errors.additionalProvision.fetch'))
    })
  }

  /**
   * RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'un Ã©lÃ©ment de la vue
   */
  getProvisionData = (provision = this.props.navigation.getParam('id')) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .getCapacityAdditionalProvision(provision)
        .then(response => {
          // DÃ©tails rÃ©cupÃ©rÃ©s
          resolve(get(response, 'body'), response)
        })
        .catch(error => {
          // Erreur lors du refresh
          reject(error)
          message.error(I18n.t('api.errors.additionalProvision.get'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Archivage de plusieurs lignes du tableau
   */
  archiveManyProvision = (provision, clearTableSelections) => {
    this.props.actions
      .archiveManyCapacityAdditionalProvisions(provision)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.additionalProvision.bulk.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.additionalProvision.bulk.archive'))
      })
  }

  /**
   * Archivage d'une ligne du tableau
   */
  archiveProvision = provision => {
    this.props.actions
      .archiveCapacityAdditionalProvision(provision)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.additionalProvision.archive'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.additionalProvision.archive'))
      })
  }

  /**
   * Actions groupÃ©es:
   * Suppression de plusieurs lignes du tableau
   */
  deleteManyProvisions = (provision, clearTableSelections) => {
    this.props.actions
      .archiveCapacityAdditionalProvisions(provision)
      .then(() => {
        clearTableSelections()
        message.success(I18n.t('api.success.additionalProvision.bulk.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.additionalProvision.bulk.delete'))
      })
  }

  /**
   * Suppression d'une ligne du tableau
   */
  deleteProvision = provision => {
    this.props.actions
      .archiveCapacityAdditionalProvision(provision)
      .then(() => {
        this.safelyCloseModal()
        this.props.navigation.setParams({ id: null })
        message.success(I18n.t('api.success.additionalProvision.delete'))
      })
      .catch(() => {
        message.error(I18n.t('api.errors.additionalProvision.delete'))
      })
  }

  /**
   * Mise Ã  jour d'un equipement
   */
  updateProvision = (updatedProvision, originalProvision) =>
    new Promise((resolve, reject) => {
      this.props.actions
        .updateCapacityAdditionalProvision({
          id: get(originalProvision, 'id'),
          ...updatedProvision
        })
        .then(response => {
          this.safelyCloseModal()
          this.fetchProvisionsData()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.additionalProvision.update'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.additionalProvision.update'))
        })
    })

  /**
   * CrÃ©ation d'une additional provision
   */
  createProvision = createdProvision =>
    new Promise((resolve, reject) => {
      this.props.actions
        .createCapacityAdditionalProvision(createdProvision)
        .then(response => {
          this.safelyCloseModal()
          resolve(get(response, 'body'), response)
          message.success(I18n.t('api.success.additionalProvision.create'))
        })
        .catch(error => {
          reject(error)
          message.error(I18n.t('api.errors.additionalProvision.create'))
        })
    })

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askArchiveManyProvisions = (...params) => {
    confirm({
      title: I18n.t('pages.additionalProvision.modal.archive.multiple.title'),
      content: I18n.t(
        'pages.additionalProvision.modal.archive.multiple.content'
      ),
      okText: I18n.t('pages.additionalProvision.modal.archive.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.additionalProvision.modal.archive.multiple.cancelText'
      ),
      onOk: () => {
        this.archiveProvision(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askArchiveProvision = () => {
    const { provision } = this.props

    confirm({
      title: I18n.t('pages.additionalProvision.modal.archive.single.title'),
      content: I18n.t('pages.additionalProvision.modal.archive.single.content'),
      okText: I18n.t('pages.additionalProvision.modal.archive.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.additionalProvision.modal.archive.single.cancelText'
      ),
      onOk: () => {
        this.archiveProvision(provision)
      },
      maskClosable: true
    })
  }

  /**
   * Actions groupÃ©es:
   * Demande d'archivage de plusieurs lignes du tableau
   */
  askDeleteManyProvisions = (...params) => {
    confirm({
      title: I18n.t('pages.additionalProvision.modal.delete.multiple.title'),
      content: I18n.t(
        'pages.additionalProvision.modal.delete.multiple.content'
      ),
      okText: I18n.t('pages.additionalProvision.modal.delete.multiple.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.additionalProvision.modal.delete.multiple.cancelText'
      ),
      onOk: () => {
        this.deleteManyProvisions(...params)
      },
      maskClosable: true
    })
  }

  /**
   * Demande d'archivage d'une ligne du tableau
   */
  askDeleteProvision = () => {
    const { provision } = this.props

    confirm({
      title: I18n.t('pages.additionalProvision.modal.delete.single.title'),
      content: I18n.t('pages.additionalProvision.modal.delete.single.content'),
      okText: I18n.t('pages.additionalProvision.modal.delete.single.okText'),
      okType: 'danger',
      cancelText: I18n.t(
        'pages.additionalProvision.modal.delete.single.cancelText'
      ),
      onOk: () => {
        this.deleteProvision(provision)
      },
      maskClosable: true
    })
  }

  /**
   * Demande de modification d'une ligne du tableau
   */
  askUpdateProvision = () => {
    this.setState({ openedModal: 'updateProvision' })
  }

  /**
   * Demande de crÃ©ation d'une ligne du tableau
   */
  askCreateProvision = () => {
    this.setState({ openedModal: 'createProvision' })
  }

  /**
   * Fermeture de toutes les modales
   */
  safelyCloseModal = () => {
    const { openedModal } = this.state

    if (openedModal === 'updateProvision') {
      this.setState({ openedModal: 'transition' }, () => {
        setTimeout(
          this.setState({ openedModal: null }, () => {
            this.props.navigation.setParams({ id: null })
          }),
          200
        )
      })
    } else {
      this.setState({ openedModal: null })
    }
  }

  /**
   * Callback:
   * CrÃ©ation d'une ligne de tableau rÃ©ussie
   */
  handleRowCreated = () => {
    this.safelyCloseModal()
  }

  /**
   * Callback:
   * Modification d'une ligne de tableau rÃ©ussie
   */
  handleRowUpdated = () => {
    this.safelyCloseModal()
  }

  /**
   * Clic sur une ligne du tableau
   */
  handleRowClick = provision => {
    // Navigation vers la page dÃ©taillÃ©e
    this.props.navigation.setParams({ id: get(provision, 'id') })

    this.getProvisionData(provision)
      .then(() =>
        // Ouverture de la modale
        this.askUpdateProvision()
      )
      .catch(() => {
        this.props.navigation.setParams({ id: null })
      })
  }

  handleDraggedRowReleased = orderedData => {
    const formattedOrderedData = formatOrderedData(orderedData)

    this.props.actions
      .orderCapacityAdditionalProvisions(formattedOrderedData)
      .then(() =>
        message.success(I18n.t('api.success.additionalProvision.order'))
      )
      .catch(() =>
        message.error(I18n.t('api.errors.additionalProvision.order'))
      )
  }

  /**
   * Formatage des lignes de la modale de modification / crÃ©ation
   */
  formatModalRows = rows => {
    return rows
  }

  render() {
    // RÃ©cupÃ©ration des Ã©lÃ©ments des props
    let {
      isGathering,
      isUpdating,
      isCreating,
      provisions,
      provision
    } = this.props

    // RÃ©cupÃ©ration des Ã©lÃ©ments du state
    const { openedModal } = this.state

    // Chargement
    const loading = isGathering || isUpdating || isCreating

    // CrÃ©ation de la liste des fonctions constructeur exposÃ©s au Ã©crans
    const constructorFunctions = {
      // Clic sur un ligne de tableau
      handleRowClick: this.handleRowClick,

      // Gestion de la modale
      safelyCloseModal: this.safelyCloseModal,

      // RÃ©cupÃ©ration des donnÃ©es dÃ©taillÃ©es d'une ligne du tableau
      getProvisionData: this.getProvisionData,

      // CrÃ©ation d'une ligne de tableau
      handleRowCreated: this.handleRowCreated,
      askCreateProvision: this.askCreateProvision,

      // Modification d'une ou plusieurs ligne(s) de tableau
      handleRowUpdated: this.handleRowUpdated,
      askUpdateProvision: this.askUpdateProvision,

      handleDraggedRowReleased: this.handleDraggedRowReleased,

      // Archivage d'une ou plusieurs ligne(s) du tableau
      askArchiveProvision: this.askArchiveProvision,
      askArchiveManyProvisions: this.askArchiveManyProvisions,

      // Suppression d'une ou plusieurs ligne(s) du tableau
      askDeleteProvision: this.askDeleteProvision,
      askDeleteManyProvisions: this.askDeleteManyProvisions
    }

    // Ajout des actions sur la modale
    ModalSpecs.actions.delete.onClick = this.askDeleteProvision

    // Ajout des lignes au formulaire
    // formLayout = merge(formLayout, manageModal)

    return (
      <Fragment>
        {/* Rendu de l'Ã©cran appropriÃ© au contexte du constructeur */}
        <List
          loading={loading}
          constructorFunctions={constructorFunctions}
          constructorState={this.state}
          dataList={provisions}
        />

        {/* Ouverture de la modale de crÃ©ation / modification */}
        <ManageModal
          width={800}
          loading={loading}
          onCancel={this.safelyCloseModal}
          onOk={this.handleRowCreated}
          onChange={
            openedModal === 'updateProvision'
              ? this.updateProvision
              : this.createProvision
          }
          visible={includes(
            ['createProvision', 'updateProvision'],
            openedModal
          )}
          data={
            includes(['transition', 'updateProvision'], openedModal)
              ? provision
              : null
          }
          {...ModalSpecs}
        />
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(
    CapacityAdditionalProvisionConstructor,
    'defaultProps',
    {}
  )

  return {
    provisions: defaultTo(
      get(state, 'capacityAdditionalProvision.items'),
      defaultProps.provisions
    ),
    provision: defaultTo(
      get(state, 'capacityAdditionalProvision.item'),
      defaultProps.provision
    ),
    isGathering:
      defaultTo(
        get(state, 'capacityAdditionalProvision.isFetching'),
        defaultProps.isGathering
      ) ||
      defaultTo(
        get(state, 'capacityAdditionalProvision.isFetchingItem'),
        defaultProps.isGathering
      ),
    isUpdating: defaultTo(
      get(state, 'capacityAdditionalProvision.isUpdating'),
      defaultProps.isUpdating
    )
  }
}

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    { ...capacityAdditionalProvisionActions },
    dispatch
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CapacityAdditionalProvisionConstructor)
