// Libraries
import I18n from 'i18next'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, { Component } from 'react'
import { Button, Select } from 'antd'
import { get, defaultTo, isEmpty, keys } from 'lodash'
import { Column } from '@ant-design/plots'

// Components
import { Container } from 'Components/Modals'

// Styles
import './ChartModal.less'

const { Option } = Select

class ChartModal extends Component {
  static propTypes = {
    // Labels et titres
    title: PropTypes.string,
    okText: PropTypes.string,

    // ContrÃ´le d'Ã©tat de la modale
    width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    visible: PropTypes.bool,
    loading: PropTypes.bool,

    // Contenu de la modale
    charts: PropTypes.object,
    chartType: PropTypes.string,

    // Fonctions de callback
    onOk: PropTypes.func
  }

  static defaultProps = {
    // Actions d'API
    actions: {},

    // Labels et titres
    title: 'pages.dashboard.modal.show.title',
    okText: 'pages.dashboard.modal.show.okText',

    // ContrÃ´le d'Ã©tat de la modale
    width: 520,
    visible: false,
    loading: true,

    // Contenu de la modal
    charts: {},
    chartType: '',

    // Fonctions de callback
    onOk: () => {}
  }

  constructor(props) {
    super(props)

    // Ãtats initiaux
    this.state = {
      type: ''
    }
  }

  /**
   * Fermeture de la modale
   */
  handleSubmit = e => {
    const { onOk } = this.props
    this.setState({
      type: ''
    })
    onOk(e)
  }

  /**
   * Selection d'un autre graph
   */
  handleChange = type => {
    this.setState({ type: type })
  }

  /**
   * Rendu de la zone de boutons
   */
  renderFooter = () => {
    const { loading, okText } = this.props

    return (
      <div className='manage-modal-footer'>
        {/* Boutons classiques (annuler, continuer ...) */}
        <div className='manage-modal-footer-controls'>
          <Button
            key='submit'
            type='primary'
            loading={loading}
            onClick={this.handleSubmit}
          >
            {I18n.t(okText)}
          </Button>
        </div>
      </div>
    )
  }

  render() {
    const { loading, visible, title, width, charts, chartType } = this.props
    const { type } = this.state

    const activeType = !isEmpty(type) ? type : chartType
    const data = !isEmpty(charts) ? charts[activeType] : []

    const config = {
      data,
      xField: 'date',
      yField: activeType === 'WORKING-HOURS' ? 'hours' : 'nbPermit',
      columnStyle: {
        fill: '#3c87c2'
      },
      label: {
        position: 'middle',
        style: {
          fill: '#FFFFFF',
          opacity: 0.6
        }
      },
      xAxis: {
        label: {
          autoHide: true,
          autoRotate: false
        }
      },
      theme: {
        defaultColor: '#3c87c2'
      },
      meta: {
        date: {
          alias: I18n.t('pages.dashboard.modal.show.chartField.date')
        },
        hours: {
          alias: I18n.t('pages.dashboard.modal.show.chartField.hours')
        },
        nbPermit: {
          alias: I18n.t('pages.dashboard.modal.show.chartField.nbPermit')
        }
      }
    }

    return (
      <Container
        title={I18n.t(title)}
        loading={loading}
        visible={visible}
        className={'modal chart-modal'}
        footer={this.renderFooter()}
        width={width}
        closable={false}
        destroyOnClose
      >
        <Select
          defaultValue={activeType}
          placeholder={I18n.t('pages.dashboard.fields.typeChart.placeholder')}
          onChange={this.handleChange}
        >
          {keys(charts).map(key => {
            return (
              <Option value={key} key={key}>
                {I18n.t(`pages.dashboard.fields.typeChart.options.${key}`)}
              </Option>
            )
          })}
        </Select>
        <Column {...config} />
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const defaultProps = get(ChartModal, 'defaultProps', {})

  return {
    charts: defaultTo(
      get(state, 'companyStatistics.charts'),
      defaultProps.charts
    )
  }
}

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChartModal)
