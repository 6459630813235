// Libraries
import I18n from 'i18next'
import { has, get } from 'lodash'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import React, { Component, Fragment } from 'react'
import { Form, Icon, Input, Button, Card, message } from 'antd'

// Resources
import { actions as authActions } from 'Resources/AuthResource'

// Components
import { Anchor } from 'Components'

// Images
import Images from 'Images'

const { Item: FormItem } = Form
const { Password } = Input

// Gestion du mode de dÃ©monstration
const isMockup = process.env.REACT_APP_MOCK_MODE === 'true'

class LoginForm extends Component {
  static propTypes = {
    hidden: PropTypes.bool,
    fetching: PropTypes.bool,
    form: PropTypes.object,
    actions: PropTypes.object,
    onSubmit: PropTypes.func,
    switchForm: PropTypes.func,
    isLoginMode: PropTypes.bool,
    isTemporaryMode: PropTypes.bool,
    isResettingMode: PropTypes.bool
  }

  static defaultProps = {
    hidden: true,
    onSubmit: () => {},
    switchForm: () => {}
  }

  constructor(props) {
    super(props)

    this.state = {
      isLoginMode: props.isLoginMode,
      isTemporaryMode: props.isTemporaryMode,
      isResettingMode: props.isResettingMode
    }
  }

  componentWillReceiveProps = nextProps => {
    if (has(nextProps, 'isLoginMode')) {
      this.setState({
        isLoginMode: nextProps.isLoginMode,
        isTemporaryMode: false,
        isResettingMode: nextProps.isResettingMode
      })
    } else if (has(nextProps, 'isTemporaryMode')) {
      this.setState({
        isLoginMode: false,
        isTemporaryMode: nextProps.isTemporaryMode,
        isResettingMode: nextProps.isResettingMode
      })
    } else {
      this.setState({
        isLoginMode: false,
        isTemporaryMode: false,
        isResettingMode: nextProps.isResettingMode
      })
    }
  }

  /**
   * Check the account for first login
   */
  _handleCheckPassword = event => {
    event.preventDefault()

    const { switchForm } = this.props

    if (!isMockup) {
      this.props.form.validateFields((error, { login }) => {
        // Si aucune erreur n'est dÃ©tectÃ© dans le form
        if (!error) {
          this.props.actions
            .checkLoginAuth({
              login,
              loginTicket: null,
              allowRegistration: false
            })
            .then(({ body }) => {
              switch (get(body, 'message')) {
                // Utilisateur connu
                case 'ok':
                  switchForm('login', { isLoginMode: true })
                  break

                // CrÃ©ation du mot de passe
                default:
                  message.success(I18n.t('api.success.login.reset', { login }))
                  switchForm('login', { isTemporaryMode: true })
                  break
              }
            })
            .catch(err => {
              // PremiÃ¨re connexion impossible : Compte dÃ©jÃ  existant
              if (err.status === 400 && get(err, 'body.key') === 'FORBIDDEN') {
                switchForm('login', { isLoginMode: true })
              } else if (
                err.status === 400 &&
                get(err, 'body.key') === 'NOT_FOUND'
              ) {
                message.error(I18n.t('api.errors.login.not-found'))
              } else {
                message.error(I18n.t('api.errors.login.checkLogin'))
              }
            })
        }
      })
    } else {
      switchForm('login', { isLoginMode: true })
    }
  }

  /**
   * PremiÃ¨re connexion
   */
  _handleFirstLogin = event => {
    event.preventDefault()

    const { switchForm } = this.props
    const { isResettingMode } = this.state

    if (!isMockup) {
      this.props.form.validateFields(
        (error, { login, temporaryCode: code }) => {
          // Si aucune erreur n'est dÃ©tectÃ© dans le form
          if (!error) {
            this.props.actions
              .checkCodeAuth({ login, code })
              .then(({ body }) => {
                switchForm(isResettingMode ? 'update' : 'create')
              })
              .catch(() => {
                message.error(I18n.t('api.errors.login.checkLogin'))
              })
          }
        }
      )
    } else {
      switchForm(isResettingMode ? 'update' : 'create')
    }
  }

  /**
   * Code temporaire non reÃ§u
   */
  _handleNotReceived = () => {
    const login = this.props.form.getFieldValue('login')

    if (!isMockup) {
      this.props.actions
        .checkLoginAuth({ login, allowRegistration: false })
        .then(({ body }) => {
          message.success(I18n.t('api.success.login.reset', { login }))
        })
        .catch(() => {
          message.error(I18n.t('api.errors.login.reset'))
        })
    } else {
      message.success(I18n.t('api.success.login.reset', { login }))
    }
  }

  /**
   * Demande de rÃ©cupÃ©ration de mot de passe
   */
  _handleAskForgotten = () => {
    const { switchForm } = this.props

    switchForm('forgotten')
  }

  /**
   * RÃ©cupÃ©ration des boutons d'actions de la carte de connexion
   */
  _renderActions = (isLoginMode, isTemporaryMode) => {
    const actions = []
    const { fetching, switchForm } = this.props

    // Bouton de retour
    if (isLoginMode || isTemporaryMode) {
      actions.push(
        <Anchor
          onClick={() =>
            switchForm('login', { isLoginMode: false, isTemporaryMode: false })
          }
        >
          {I18n.t('common.back')}
        </Anchor>
      )
    }

    // Bouton de mot de passe oubliÃ©
    isLoginMode &&
      actions.push(
        <Anchor onClick={() => this._handleAskForgotten()}>
          {I18n.t('pages.login.forgotten-password.title')}
        </Anchor>
      )

    // Bouton de mot de passe non-reÃ§u
    isTemporaryMode &&
      actions.push(
        <Anchor onClick={() => this._handleNotReceived()}>
          {I18n.t('pages.login.code-not-received')}
        </Anchor>
      )

    // Bouton de login
    actions.push(
      <Button type='primary' htmlType='submit' loading={fetching}>
        {isLoginMode ? I18n.t('pages.login.login') : I18n.t('common.next')}
      </Button>
    )

    return actions
  }

  _renderFormItems = (isLoginMode, isTemporaryMode) => {
    const { hidden, form } = this.props
    const { getFieldDecorator } = form

    return (
      <Fragment>
        <FormItem>
          {getFieldDecorator('login', {
            rules: [
              {
                type: 'email',
                message: I18n.t('fields.email.patternMessage')
              },
              {
                required: true,
                message: I18n.t('fields.email.requiredMessage')
              }
            ]
          })(
            <Input
              disabled={isLoginMode || isTemporaryMode}
              prefix={<Icon type='user' />}
              placeholder={I18n.t('fields.email.placeholder')}
            />
          )}
        </FormItem>

        <FormItem className={isLoginMode ? 'visible' : 'hidden'}>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: I18n.t('fields.password.requiredMessage')
              }
            ],
            hidden: hidden || !isLoginMode
          })(
            <Password
              prefix={<Icon type='lock' />}
              type='password'
              placeholder={I18n.t('fields.password.placeholder')}
            />
          )}
        </FormItem>

        <FormItem className={isTemporaryMode ? 'visible' : 'hidden'}>
          {getFieldDecorator('temporaryCode', {
            rules: [
              {
                required: true,
                message: I18n.t('fields.temporaryCode.requiredMessage')
              }
            ],
            hidden: hidden || !isTemporaryMode
          })(
            <Input
              prefix={<Icon type='lock' />}
              placeholder={I18n.t('fields.temporaryCode.placeholder')}
            />
          )}
        </FormItem>
      </Fragment>
    )
  }

  render() {
    const { hidden, onSubmit } = this.props
    const { isLoginMode, isTemporaryMode } = this.state

    // Permet de gÃ©rer le mode connexion ou premiÃ¨re connexion
    const handleSubmit = isLoginMode
      ? onSubmit
      : isTemporaryMode
      ? this._handleFirstLogin
      : this._handleCheckPassword

    return (
      <Form onSubmit={handleSubmit}>
        <Card
          style={{ display: hidden ? 'none' : 'block' }}
          title={
            <img
              src={Images.logo}
              className='logo'
              alt={I18n.t('common.application-logo')}
            />
          }
          actions={this._renderActions(isLoginMode, isTemporaryMode)}
        >
          {this._renderFormItems(isLoginMode, isTemporaryMode)}
        </Card>
      </Form>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...authActions }, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)
