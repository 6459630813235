// Libraries
// import React, { Component } from 'react'
import { defaultTo, merge, forEach } from 'lodash'

const queryString = require('query-string')

// CrÃ©ation d'un Ã©lÃ©ment stackable
export const prepareStackElement = ({ screen: Screen, ...configs }) => {
  // Identification des Ã©crans et des navigators
  // Traitements diffÃ©rent car le Navigator n'est pas un composant
  // const isNavigator = get(Screen, 'name') === 'Navigator'

  // Valeurs par dÃ©faut de navigation
  const defaultConfig = {
    path: '',
    navigationOptions: {
      title: process.env.REACT_APP_WEBSITE_NAME
    }
  }

  // Merge des valeurs par dÃ©faut
  const screenConfig = merge(defaultConfig, configs)

  // CrÃ©ation d'une copie de l'Ã©cran
  // const configuredScreen = isNavigator
  //   ? Screen
  //   : class extends Component {
  //       render() {
  //         return <Screen {...this.props} />
  //       }
  //     }

  // Application des configurations inexistantes du composant
  forEach(screenConfig, (value, config) => {
    Screen[config] = defaultTo(Screen[config], value)
  })

  return Screen
}

// CrÃ©ation d'une stack de navigation
export const prepareStack = stackElements => {
  // CrÃ©ation de la stack de navigation
  const navigationStack = {}

  // Ajout des Ã©crans Ã  la stack de navigation
  forEach(stackElements, (params, routeName) => {
    navigationStack[routeName] = prepareStackElement(params)
  })

  return navigationStack
}

export const matchPathAndParams = (a, b) => {
  if (a.path !== b.path) {
    return false
  }
  if (queryString.stringify(a.params) !== queryString.stringify(b.params)) {
    return false
  }
  return true
}
