import React, { Component } from 'react'

class CustomFooter extends Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  render() {
    return <></>
  }
}

export default CustomFooter
