/* eslint-disable react/prop-types */
// Libraries
import React from 'react'
import I18n from 'i18next'
import moment from 'moment'
import { Slider, DatePicker } from 'antd'
import {
  get,
  map,
  find,
  head,
  isEmpty,
  inRange,
  toNumber,
  defaultTo,
  isArray
} from 'lodash'

// Components
import { Anchor } from 'Components'
import { useDrag, useDrop } from 'react-dnd'

const { RangePicker } = DatePicker

/**
 * SliderFilter
 * @param {Integer} start
 * @param {Integer} end
 */
export const sliderFilter = (start, end, path, opts) => ({
  filters: [
    {
      type: 'start',
      value: toNumber(start)
    },
    {
      type: 'end',
      value: toNumber(end)
    }
  ],
  filterDropdown: filterProps => {
    const {
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      filters
    } = filterProps

    // RÃ©cupÃ©ration du minimum Ã  partir des filtres
    const min = defaultTo(get(find(filters, { type: 'start' }), 'value'), 0)

    // RÃ©cupÃ©ration du maximum Ã  partir des filtres
    const max = defaultTo(get(find(filters, { type: 'end' }), 'value'), 0)

    // Lors du changement de valeur
    const onChange = value => {
      // Vidage du filtre si les valeurs max et min sont remises Ã  celles par dÃ©faut
      if (value[0] === min && value[1] === max) {
        clearFilters()
      } else {
        setSelectedKeys([value])
      }
    }

    return (
      <div className='ant-table-filter-dropdown'>
        <div className='table-layout-filter-slider-container'>
          <Slider
            range
            max={max}
            min={min}
            onChange={onChange}
            defaultValue={[min, max]}
            marks={{
              [min]: min,
              [max]: max
            }}
            value={!isEmpty(selectedKeys) ? head(selectedKeys) : [min, max]}
            className='table-layout-filter-slider'
            {...opts}
          />
        </div>
        <div className='ant-table-filter-dropdown-btns'>
          <Anchor
            className='ant-table-filter-dropdown-link confirm'
            onClick={confirm}
          >
            {I18n.t('components.tableLayout.filter.confirm')}
          </Anchor>
          <Anchor
            className='ant-table-filter-dropdown-link confirm'
            onClick={clearFilters}
          >
            {I18n.t('components.tableLayout.filter.clear')}
          </Anchor>
        </div>
      </div>
    )
  },
  onFilter: (range, object) => inRange(toNumber(get(object, path)), ...range)
})

/**
 * PeriodFilter
 * @param {Integer} start
 * @param {Integer} end
 */
export const filterPeriod = (start, end, paths, opts) => ({
  filters: [],
  filterDropdown: filterProps => {
    const { setSelectedKeys, selectedKeys, confirm, clearFilters } = filterProps
    // Lors du changement de valeur
    const onChange = value => {
      // Vidage du filtre si les valeurs max et min sont remises Ã  celles par dÃ©faut
      if (isEmpty(value)) {
        clearFilters()
      } else {
        value[0].set({ hour: 0, minute: 0, second: 0 })
        value[1].set({ hour: 23, minute: 59, second: 59 })
        setSelectedKeys([value])
      }
    }

    return (
      <div className='ant-table-filter-dropdown'>
        <div className='table-layout-filter-period-container'>
          <RangePicker
            format='L'
            value={head(selectedKeys)}
            onChange={onChange}
            ranges={{
              // Aujourd'hui
              [I18n.t('common.today')]: [moment(), moment()],
              // Semaine
              [I18n.t('common.week')]: [
                moment().startOf('week'),
                moment().day(5)
              ],
              // Mois
              [I18n.t('common.month')]: [
                moment().startOf('month'),
                moment().endOf('month')
              ]
            }}
          />
        </div>
        <div className='ant-table-filter-dropdown-btns'>
          <Anchor
            className='ant-table-filter-dropdown-link confirm'
            onClick={confirm}
          >
            {I18n.t('components.tableLayout.filter.confirm')}
          </Anchor>
          <Anchor
            className='ant-table-filter-dropdown-link confirm'
            onClick={clearFilters}
          >
            {I18n.t('components.tableLayout.filter.clear')}
          </Anchor>
        </div>
      </div>
    )
  },
  onFilter: (range, object) => {
    const dateA = moment(get(object, isArray(paths) ? paths[0] : paths))
      .startOf('day')
      .valueOf()
    const dateB = moment(get(object, isArray(paths) ? paths[1] : paths))
      .startOf('day')
      .valueOf()
    const rangeStart = moment(get(range, 0))
      .startOf('day')
      .valueOf()
    const rangeEnd = moment(get(range, 1))
      .startOf('day')
      .valueOf()

    return dateA >= rangeStart && dateB <= rangeEnd
  }
})

/**
 * Boolean filter
 * @param {Integer} path
 */
export const filterBool = (path, opts) => ({
  filters: map([true, false], bool => ({
    text: I18n.t(`common.${defaultTo(bool, false) ? 'yes' : 'no'}`),
    value: bool
  })),
  onFilter: (bool, object) => defaultTo(get(object, path), false) === bool
})

/**
 * Alphabetically sorter
 * @param {String} path
 * @param {Object} a
 * @param {Object} b
 */
export const alphaSort = ([a, b], path) =>
  defaultTo(get(a, path), '').localeCompare(defaultTo(get(b, path), ''))

/**
 * Date sorter
 * @param {String} path
 * @param {Object} a
 * @param {Object} b
 */
export const dateSort = ([a, b], path) =>
  defaultTo(moment(get(a, path), 0).valueOf()) -
  defaultTo(moment(get(b, path), 0).valueOf())

/**
 * Date sorter
 * @param {String} path
 * @param {Object} a
 * @param {Object} b
 */
export const periodSort = ([a, b], [startDatePath, endDatePath]) => {
  a = `${moment(get(a, startDatePath)).valueOf()} - ${moment(
    get(a, endDatePath)
  ).valueOf()}`
  b = `${moment(get(b, startDatePath)).valueOf()} - ${moment(
    get(b, endDatePath)
  ).valueOf()}`

  return a.localeCompare(b)
}

/**
 * Number sorter
 * @param {String} path
 * @param {Object} a
 * @param {Object} b
 */
export const numberSort = ([a, b], path) =>
  defaultTo(get(a, path), 0) - defaultTo(get(b, path), 0)

/**
 * Render and handle draggable row
 */
export const DraggableRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}) => {
  const type = 'DraggableRow'
  const ref = React.useRef()
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = defaultTo(monitor.getItem(), {})
      return dragIndex === index
        ? {}
        : {
            isOver: monitor.isOver(),
            dropClassName:
              dragIndex < index ? ' drop-over-downward' : ' drop-over-upward'
          }
    },
    drop: item => moveRow(item.index, index)
  })
  const [, drag] = useDrag({
    item: { type, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  })
  drop(drag(ref))
  return (
    <tr
      ref={ref}
      className={`${className} ${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  )
}

/**
 * Format les index en fonction de la pagination
 * @param current
 * @param pageSize
 * @param index
 * @returns {number}
 */
export const formatDragIndexes = ({ current, pageSize }, index) => {
  return (current - 1) * pageSize + index
}
