// Libraries
import I18n from 'i18next'
import moment from 'moment'
import { map, isNil, isEmpty, get } from 'lodash'
import { Tag } from 'antd'
import React from 'react'

const specs = {
  width: 800,
  title: {
    update: 'pages.permits.modal.cleaningPermit.create.single.title',
    create: 'pages.permits.modal.cleaningPermit.create.single.title'
  },
  okText: {
    update: 'pages.permits.modal.cleaningPermit.create.single.okText',
    create: 'pages.permits.modal.cleaningPermit.create.single.okText'
  },
  actions: {
    delete: {
      buttonType: 'danger'
    }
  },
  steps: [
    // Informations principales
    {
      key: 'main',
      title: 'pages.permits.modal.steps.main.title'
    },
    // Analyse
    {
      key: 'analysis',
      title: 'pages.permits.modal.steps.analysis.title'
    },
    // Staff
    {
      key: 'staff',
      title: 'pages.permits.modal.steps.staff.title'
    }
  ],
  validateEachSteps: true,
  rows: {
    permitID: {
      title: 'pages.permits.fields.permitID.title',
      dataIndex: 'permitID',
      key: 'permitID',
      formField: {
        readOnly: true,
        disabled: true,
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.permitID.title',
        required: 'pages.permits.fields.permitID.requiredMessage',
        placeholder: 'pages.permits.fields.permitID.placeholder',
        questionMark: 'pages.permits.fields.permitID.questionMark'
      },
      empty: 'common.unknown.female'
    },
    validityDate: {
      title: 'pages.permits.fields.validityDate.title',
      dataIndex: 'validityDate',
      key: 'validityDate',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'date',
        label: 'pages.permits.fields.validityDate.title',
        required: 'pages.permits.fields.validityDate.requiredMessage',
        placeholder: 'pages.permits.fields.validityDate.placeholder'
      },
      empty: 'common.unknown.female'
    },
    validityHours: {
      title: 'pages.permits.fields.validityHours.title',
      dataIndex: 'validityHours',
      key: 'validityHours',
      formField: {
        visibility: {
          step: 'main'
        },
        mask: '**:** - **:**',
        label: 'pages.permits.fields.validityHours.title',
        required: 'pages.permits.fields.validityHours.requiredMessage',
        placeholder: 'pages.permits.fields.validityHours.placeholder',
        pattern: {
          validator: (rule, value, callback) => {
            const pattern = /^(?<startTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]) - (?<endTime>(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9])$/
            const parsed = pattern.exec(value)

            const startTime = get(parsed, 'groups.startTime')
            const endTime = get(parsed, 'groups.endTime')

            let message

            if (
              /* Aucune donnÃ©e valide */
              isNil(parsed)
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.wrong'
              )
            } else if (
              /* Les deux heures sont inversÃ©es */
              moment(endTime, 'HH:mm').isBefore(moment(startTime, 'HH:mm'))
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.order'
              )
            } else if (
              /* Les deux heures sont dans la mÃªme moitiÃ© de journÃ©e */
              moment(endTime, 'HH:mm').format('a') !==
                moment(startTime, 'HH:mm').format('a') &&
              endTime !== '12:00'
            ) {
              message = I18n.t(
                'pages.permits.fields.validityHours.patternMessage.meridiem'
              )
            }

            callback(message)
          }
        }
      },
      empty: 'common.unknown.female'
    },
    company: {
      title: 'pages.permits.fields.companyName.title',
      dataIndex: 'company',
      key: 'company',
      formField: {
        visibility: {
          step: 'main'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.companyName.title',
        required: 'pages.permits.fields.companyName.requiredMessage',
        placeholder: 'pages.permits.fields.companyName.placeholder',
        format: company =>
          !isNil(company) && !isEmpty(company) ? company.id : undefined
      },
      render: company =>
        !isNil(company) && !isEmpty(company) ? (
          <Tag>{company.name}</Tag>
        ) : (
          undefined
        ),
      empty: 'common.unknown.male'
    },
    operation: {
      title: 'pages.permits.fields.operation.title',
      dataIndex: 'operation',
      key: 'operation',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.operation.title',
        required: 'pages.permits.fields.operation.requiredMessage',
        placeholder: 'pages.permits.fields.operation.placeholder',
        questionMark: 'pages.permits.fields.operation.questionMark'
      },
      empty: 'common.unknown.female'
    },
    tank: {
      title: 'pages.permits.fields.tank.title',
      dataIndex: 'tank',
      key: 'tank',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.tank.title',
        required: 'pages.permits.fields.tank.requiredMessage',
        placeholder: 'pages.permits.fields.tank.placeholder'
      },
      empty: 'common.unknown.male'
    },
    location: {
      title: 'pages.permits.fields.location.title',
      dataIndex: 'location',
      key: 'location',
      formField: {
        visibility: {
          step: 'main'
        },
        label: 'pages.permits.fields.location.title',
        required: 'pages.permits.fields.location.requiredMessage',
        placeholder: 'pages.permits.fields.location.placeholder',
        questionMark: 'pages.permits.fields.location.questionMark'
      },
      empty: 'common.unknown.female'
    },
    cleaningPermitConditions: {
      title: 'pages.permits.fields.conditions.title',
      dataIndex: 'cleaningPermitConditions',
      key: 'cleaningPermitConditions',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.conditions.title',
        placeholder: 'pages.permits.fields.conditions.placeholder',
        format: condition =>
          !isNil(condition) && !isEmpty(condition)
            ? map(condition, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    unlistedCleaningPermitConditions: {
      title: 'pages.permits.fields.conditions.unlisted.title',
      dataIndex: 'unlistedCleaningPermitConditions',
      key: 'unlistedCleaningPermitConditions',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitConditions: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.conditions.unlisted.title',
        required: 'pages.permits.fields.conditions.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.conditions.unlisted.placeholder',
        format: condition =>
          !isNil(condition) && !isEmpty(condition)
            ? map(condition, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    cleaningPermitEquipments: {
      title: 'pages.permits.fields.equipments.title',
      dataIndex: 'cleaningPermitEquipments',
      key: 'cleaningPermitEquipments',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.equipments.title',
        placeholder: 'pages.permits.fields.equipments.placeholder',
        format: equipment =>
          !isNil(equipment) && !isEmpty(equipment)
            ? map(equipment, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    unlistedCleaningPermitEquipments: {
      title: 'pages.permits.fields.equipments.unlisted.title',
      dataIndex: 'unlistedCleaningPermitEquipments',
      key: 'unlistedCleaningPermitEquipments',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitEquipments: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.equipments.unlisted.title',
        required: 'pages.permits.fields.equipments.unlisted.requiredMessage',
        placeholder: 'pages.permits.fields.equipments.unlisted.placeholder',
        format: equipment =>
          !isNil(equipment) && !isEmpty(equipment)
            ? map(equipment, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    cleaningPermitAdditionalProvisions: {
      title: 'pages.permits.fields.additionalProvisions.title',
      dataIndex: 'cleaningPermitAdditionalProvisions',
      key: 'cleaningPermitAdditionalProvisions',
      formField: {
        visibility: {
          step: 'analysis'
        },
        type: 'checkboxes',
        label: 'pages.permits.fields.additionalProvisions.title',
        placeholder: 'pages.permits.fields.additionalProvisions.placeholder',
        format: cleaningPermitAdditionalProvisions =>
          !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
            ? map(cleaningPermitAdditionalProvisions, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    unlistedCleaningPermitAdditionalProvisions: {
      title: 'pages.permits.fields.additionalProvisions.unlisted.title',
      dataIndex: 'unlistedCleaningPermitAdditionalProvisions',
      key: 'unlistedCleaningPermitAdditionalProvisions',
      formField: {
        visibility: {
          step: 'analysis',
          fieldsValues: {
            cleaningPermitAdditionalProvisions: -1
          }
        },
        type: 'tags',
        notFoundContent: null,
        label: 'pages.permits.fields.additionalProvisions.unlisted.title',
        required:
          'pages.permits.fields.additionalProvisions.unlisted.requiredMessage',
        placeholder:
          'pages.permits.fields.additionalProvisions.unlisted.placeholder',
        format: cleaningPermitAdditionalProvisions =>
          !isNil(cleaningPermitAdditionalProvisions) && !isEmpty(cleaningPermitAdditionalProvisions)
            ? map(cleaningPermitAdditionalProvisions, 'id')
            : undefined
      },
      empty: 'common.unknown.female'
    },
    roofExplosimetryMeasureController: {
      title: 'pages.permits.fields.roofExplosimetryMeasureController.title',
      dataIndex: 'roofExplosimetryMeasureController',
      key: 'roofExplosimetryMeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.roofExplosimetryMeasureController.title',
        placeholder:
          'pages.permits.fields.roofExplosimetryMeasureController.placeholder',
        type: 'searchSelect',
        format: user => (!isNil(user) && !isEmpty(user) ? user.id : undefined)
      },
      render: user =>
        !isNil(user) && !isEmpty(user) ? user.fullname : undefined,
      empty: 'common.unknown.male'
    },
    o2MeasureController: {
      title: 'pages.permits.fields.o2MeasureController.title',
      dataIndex: 'o2MeasureController',
      key: 'o2MeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.o2MeasureController.title',
        placeholder: 'pages.permits.fields.o2MeasureController.placeholder',
        type: 'searchSelect',
        format: user => (!isNil(user) && !isEmpty(user) ? user.id : undefined)
      },
      render: user =>
        !isNil(user) && !isEmpty(user) ? user.fullname : undefined,
      empty: 'common.unknown.male'
    },
    entryTemperatureMeasureController: {
      title: 'pages.permits.fields.entryTemperatureMeasureController.title',
      dataIndex: 'entryTemperatureMeasureController',
      key: 'entryTemperatureMeasureController',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.entryTemperatureMeasureController.title',
        placeholder:
          'pages.permits.fields.entryTemperatureMeasureController.placeholder',
        type: 'searchSelect',
        format: controller =>
          !isNil(controller) && !isEmpty(controller) ? controller.id : undefined
      },
      render: controller =>
        !isNil(controller) && !isEmpty(controller)
          ? controller.fullname
          : undefined,
      empty: 'common.unknown.male'
    },
    entryTemperatureMeasureParticipant: {
      title: 'pages.permits.fields.entryTemperatureMeasureParticipant.title',
      dataIndex: 'entryTemperatureMeasureParticipant',
      key: 'entryTemperatureMeasureParticipant',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.entryTemperatureMeasureParticipant.title',
        placeholder:
          'pages.permits.fields.entryTemperatureMeasureParticipant.placeholder',
        format: staff =>
          !isNil(staff) && !isEmpty(staff) ? staff.id : undefined
      },
      render: staff =>
        !isNil(staff) && !isEmpty(staff) ? staff.fullname : undefined,
      empty: 'common.unknown.male'
    },
    personnelAuthorizedToEnter: {
      title: 'pages.permits.fields.personnelAuthorizedToEnter.title',
      dataIndex: 'personnelAuthorizedToEnter',
      key: 'personnelAuthorizedToEnter',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        required:
          'pages.permits.fields.personnelAuthorizedToEnter.requiredMessage',
        label: 'pages.permits.fields.personnelAuthorizedToEnter.title',
        placeholder:
          'pages.permits.fields.personnelAuthorizedToEnter.placeholder',
        format: personnelAuthorizedToEnter =>
          !isNil(personnelAuthorizedToEnter) &&
          !isEmpty(personnelAuthorizedToEnter)
            ? map(personnelAuthorizedToEnter, 'id')
            : undefined
      },
      render: personnelAuthorizedToEnter =>
        !isNil(personnelAuthorizedToEnter) &&
        !isEmpty(personnelAuthorizedToEnter)
          ? map(personnelAuthorizedToEnter, 'name')
          : undefined,
      empty: 'common.unknown.male'
    },
    securityAssistants: {
      title: 'pages.permits.fields.securityParticipants.title',
      dataIndex: 'securityAssistants',
      key: 'securityAssistants',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        mode: 'multiple',
        label: 'pages.permits.fields.securityParticipants.title',
        required: 'pages.permits.fields.securityParticipants.requiredMessage',
        placeholder: 'pages.permits.fields.securityParticipants.placeholder',
        format: securityAssistants =>
          !isNil(securityAssistants) && !isEmpty(securityAssistants)
            ? map(securityAssistants, 'id')
            : undefined
      },
      render: securityAssistants =>
        !isNil(securityAssistants) && !isEmpty(securityAssistants)
          ? map(securityAssistants, 'name')
          : undefined,
      empty: 'common.unknown.male'
    },
    depotHead: {
      title: 'pages.permits.fields.depotHead.title',
      dataIndex: 'depotHead',
      key: 'depotHead',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.depotHead.title',
        placeholder: 'pages.permits.fields.depotHead.placeholder',
        format: depotHead =>
          !isNil(depotHead) && !isEmpty(depotHead) ? depotHead.id : undefined
      },
      render: depotHead =>
        !isNil(depotHead) && !isEmpty(depotHead) ? depotHead.fullname : undefined,
      empty: 'common.unknown.male'
    },
    depotSupervisor: {
      title: 'pages.permits.fields.supervisor.title',
      dataIndex: 'depotSupervisor',
      key: 'depotSupervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        type: 'searchSelect',
        label: 'pages.permits.fields.supervisor.title',
        required: 'pages.permits.fields.supervisor.requiredMessage',
        placeholder: 'pages.permits.fields.supervisor.placeholder',
        format: supervisor =>
          !isNil(supervisor) && !isEmpty(supervisor) ? supervisor.id : undefined
      },
      render: supervisor =>
        !isNil(supervisor) && !isEmpty(supervisor)
          ? supervisor.fullname
          : undefined,
      empty: 'common.unknown.male'
    },
    companySupervisor: {
      title: 'pages.permits.fields.companySupervisor.title',
      dataIndex: 'companySupervisor',
      key: 'companySupervisor',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companySupervisor.title',
        required: 'pages.permits.fields.companySupervisor.requiredMessage',
        placeholder: 'pages.permits.fields.companySupervisor.placeholder',
        type: 'searchSelect',
        format: companySupervisor =>
          !isNil(companySupervisor) && !isEmpty(companySupervisor)
            ? companySupervisor.id
            : undefined
      },
      render: companySupervisor =>
        !isNil(companySupervisor) && !isEmpty(companySupervisor)
          ? companySupervisor.fullname
          : undefined,
      empty: 'common.unknown.male'
    },
    companyLeader: {
      title: 'pages.permits.fields.companyLeader.title',
      dataIndex: 'companyLeader',
      key: 'companyLeader',
      formField: {
        visibility: {
          step: 'staff'
        },
        label: 'pages.permits.fields.companyLeader.title',
        placeholder: 'pages.permits.fields.companyLeader.placeholder',
        type: 'searchSelect',
        format: companyLeader =>
          !isNil(companyLeader) && !isEmpty(companyLeader)
            ? companyLeader.id
            : undefined
      },
      render: leader =>
        !isNil(leader) && !isEmpty(leader) ? leader.fullname : undefined,
      empty: 'common.unknown.male'
    }
  }
}

export default specs
